import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/landing/Footer";
import ContentWrapper from "../components/ContentWrapper/ContentWrapper";

const NotFound = () => {
  const navigate = useNavigate();
  const [myTimeOut, setMytimeOut] = useState();
  // useEffect(() => {
  //   clearTimeout(myTimeOut);

  //   setMytimeOut(
  //     setTimeout(() => {
  //       navigate("/");
  //     }, [700])
  //   );
  // }, []);
  return (
    <>
      <ContentWrapper
        type={"AboutSection"}
        title={["PAGE NOT", "FOUND"]}
        content={`<img src="/images/NotFoundLogo.svg" alt="" />`}
      />

      <Footer />
    </>
  );
};

export default NotFound;
