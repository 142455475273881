import React, { useEffect, useState } from "react";

export default function ProfilePicPreviewAndSelect({ data, onChange }) {
  const [imagePrev, setImagePrev] = useState(null);
  const [id, setId] = useState(Math.random());

  useEffect(() => {
    if (data) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(data);
      fileReader.addEventListener("load", function () {
        setImagePrev(this.result);
      });
    }
  }, [data]);

  return (
    <div
      style={{
        height: "120px",
        width: "120px",
        borderRadius: "50%",
        position: "relative",
        backgroundImage: imagePrev ? `url(${imagePrev})` : "none",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundColor: "gainsboro",
        boxShadow: "0 0 5px -1px lightgray",
        border: "3px solid white",
      }}>
      <div
        style={{
          position: "absolute",
          right: 0,
          bottom: 0,
          height: "35px",
          width: "35px",
          backgroundColor: "whitesmoke",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          border: "1px solid black",
          overflow: "hidden",
        }}>
        <img
          src="/images/solar_camera.png"
          style={{ width: "50%", height: "50%" }}
        />
        <input
          style={{
            position: "absolute",
            opacity: 0,
          }}
          type="file"
          accept="image/png, image/jpeg, image/jpg"
          id={`${id}`}
          onChange={(e) => {
            let file = e.target.files;
            if (file) {
              onChange(e.target.files[0]);

              const fileReader = new FileReader();
              fileReader.readAsDataURL(file[0]);
              fileReader.addEventListener("load", function () {
                setImagePrev(this.result);
              });
            }
          }}
        />
        <label
          htmlFor={`${id}`}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            opacity: 0,
            height: "100%",
            width: "100%",
            cursor: "pointer",
          }}></label>
      </div>
    </div>
  );
}
