import React from "react";
import AuthenticatedWithoutSetupLayout from "../layouts/AuthenticatedWithoutSetup";
import { useSelector } from "react-redux";
import Wrappers from "../components/complete_profile/Wrappers";
import BartenderSetup from "../components/complete_profile/Bartender/BartenderSetup";
import EstablishmentSetup from "../components/complete_profile/Establishment/EstablishmentSetup";

export default function Setup() {
  const user = useSelector((state) => state.user.user);

  return (
    <>
      {user.role == 1 && <BartenderSetup />}
      {user.role == 2 && <EstablishmentSetup />}
    </>
  );
}
