import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BarJobCards from "../../../../jobCards/BarJobCards";

function CurrentG({ data }) {
  return (
    <div className="flex pt-6 px-2 gap-6 flex-wrap w-full max-md:max-w-full ">
      {data.map((elem, index) => {
        return <BarJobCards elem={elem} index={index} />;
      })}
      {data?.length < 1 ? (
        <div className="w-full flex justify-center items-center">
          <img src="/images/EmptyGig.svg" sizes="20" alt="" />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default CurrentG;
