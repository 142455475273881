import React, { useEffect, useState } from "react";
import styles from "../../../styles/codeverifyPass_Acc.module.css";
import Wrapper from "../../authWrapper/Wrapper";
import { Alert } from "@mui/material";
import OtpInput from "react-otp-input";
import { Button } from "antd";
import { ensureNumber } from "../../../utils/inputFormatter";

export default function CodeVerifyPass(props) {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(props.error); // State to hold error message

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  const veri = () => {
    props.verifyOtp(otp);
  };

  const handleOTPChange = (value, index) => {
    const numericValue = value.replace(/[^0-9]/g, "");
    if (numericValue !== value) {
      return;
    }
    setOtp(numericValue);
  };

  return (
    <Wrapper>
      <div className="flex flex-col items-center px-11 pt-12 pb-8 bg-white w-[450px] border-solid border-[5.241px] border-[color:var(--ffcc-42,#FFCC42)] rounded-[41.924px] max-md:px-5 max-md:max-w-full">
        <div className={styles.header}>Code Verification</div>
        <div className="mt-2 text-center text-small text-neutral-600 max-md:max-w-full">
          Verification code has been sent to your mail.
        </div>
        <div className="flex justify-between gap-5 mt-6 text-xl whitespace-nowrap text-neutral-800 max-md:flex-wrap max-md:max-w-full">
          <OtpInput
            value={otp}
            onChange={handleOTPChange}
            numInputs={4}
            renderSeparator={<span></span>}
            renderInput={(props) => (
              <input
                {...props}
                style={{
                  height: "55px",
                  width: "55px",
                  textAlign: "center",
                  borderRadius: "50%",
                  border: "1px solid lightgray",
                  margin: "0 5px",
                  outline: "none",
                  fontSize: "14px",
                }}
              />
            )}
          />
        </div>
        {error && (
          <Alert
            style={{ margin: "25px 0 0 0" }}
            severity="error"
            className="align-center p-3 w-[90%] text-small text-black bg-amber-300 rounded-[124.047px] max-md:px-5 max-md:max-w-full my-3">
            {error}
          </Alert>
        )}
        {props.success && (
          <Alert
            style={{ margin: "25px 0 0 0" }}
            severity="success"
            className="align-center p-3 w-[90%] text-small text-black bg-amber-300 rounded-[124.047px] max-md:px-5 max-md:max-w-full my-3">
            {props.success}
          </Alert>
        )}
        <div className="mt-7 text-base leading-6 text-center w-[338px]">
          <span className="">{error ? error.message : null}</span>
          <br />
          <span
            className="text-red-500"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setOtp("");
              props.newOtp()
            }}>
            Didn’t receive any code? Request new code again.
          </span>
        </div>
        <Button
          loading={props.loading}
          type="text"
          style={{ height: "auto" }}
          onClick={() => veri()}
          className="justify-center p-3 w-[90%] text-small text-center text-black bg-amber-300 rounded-[124.047px] max-md:px-5 max-md:max-w-full my-3 ">
          {props.type === "forget"
            ? " Verify & Reset Password"
            : "Verify & Create Account"}
        </Button>
      </div>
    </Wrapper>
  );
}
