import { Link } from "react-router-dom";
import Wrappers from "../Wrappers";
import styles from "./cp.module.css";
import { useEffect, useRef, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import ProfilePicPreviewAndSelect from "../../ProfilePicPreviewAndSelect/ProfilePicPreviewAndSelect";
import { CircularProgress, Modal } from "@mui/material";
import { updateBartBasic } from "../../../axios/axios";
import { useDispatch, useSelector } from "react-redux";
import { Bounce, toast } from "react-toastify";
import { Button } from "antd";
import { updateUser } from "../../../redux/userSlice";
import { ensureNumber, ensureString } from "../../../utils/inputFormatter";

function Cp1({ activeTab, setActiveTab, data }) {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const startDateRef = useRef(null);
  const [pickershow, setPickerShow] = useState({
    startDate: false,
  });
  const [loader, setLoader] = useState(false);
  const [apiLoader, setapiLoader] = useState(false);
  const [localData, setLocalData] = useState({
    bio: data.bio,
    yearsOfExperience: data.yearsOfExperience,
    birthDate: data.birthDate,
    profile: data.profile,
    phoneNo: user?.phoneNo || "",
    location: {
      state: data.location.state,
      city: data.location.city,
      postalCode: data.location.postalCode,
      streetName: data.location.streetName,
    },
    latitude: data.latitude,
    longitude: data.longitude,
  });

  const submitHandler = () => {
    setapiLoader(true);

    updateBartBasic({
      ...localData,
      location: JSON.stringify(localData.location),
      token: user.token,
    })
      .then((result) => {
        setapiLoader(false);

        let pic = result.data.data[0].profileImg || result.data.data[1].pic;
        dispatch(updateUser({ pic: pic, accSetCount: user.accSetCount + 1 }));
        setActiveTab((prev) => prev + 1);
      })
      .catch((err) => {
        toast.error(err.message, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setapiLoader(false);

        console.log("err", err);
      });
  };

  const handleShowPicker = (picker) => {
    const refs = {
      startDate: startDateRef,
    };

    if (pickershow[picker]) {
      refs[picker].current.blur();
    } else {
      refs[picker].current.showPicker();
    }

    setPickerShow((prevState) => ({
      ...prevState,
      [picker]: !prevState[picker],
    }));
  };
  const today = new Date();
  const year = today.getFullYear() - 18;
  const month = today.getMonth() + 1;
  const day = today.getDate();

  const maxDate = `${year}-${month < 10 ? "0" + month : month}-${
    day < 10 ? "0" + day : day
  }`;

  const changeInputHandler = ({ name, value }) => {
    if (name === "yearsOfExperience") {
      value = ensureNumber({ value: value, maxLength: 2 });
    }
    let x = { ...localData };
    x[name] = value;
    setLocalData(x);
  };

  function getLocation() {
    if (navigator.geolocation) {
      setLoader(true);

      setTimeout(() => {
        setLoader(false);
      }, 16000);

      navigator.geolocation.getCurrentPosition((position) => {
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;

        let x = { ...localData };
        x.latitude = latitude;
        x.longitude = longitude;
        console.log("Co-ordinates: ",latitude,longitude);
        
        setLocalData(x);
        setLoader(false);
      });
    }
  }

  useEffect(() => {
    getLocation();
  }, []);

  return (
    <div className="flex flex-col items-center mt-10 ">
      <style>
        {`
          .hide-calendar::-webkit-calendar-picker-indicator {
            display: none;
          }
          .hide-calendar::-moz-calendar-picker-indicator {
            display: none;
          }
          .hide-calendar::-ms-clear {
            display: none;
          }
            
        `}
      </style>
      <div className={styles.header}>Tell Us More About You John</div>
      <div className="mt-3 text-base whitespace-nowrap text-neutral-600">
        What will be your title/designation?
      </div>
      <div className="mt-8 max-w-full aspect-square w-[124px] max-md:mt-10">
        <Modal
          open={loader}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
          }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <CircularProgress sx={{ margin: "0 10px 0 0" }} />
            Auto Fetching Location...
          </div>
        </Modal>

        <ProfilePicPreviewAndSelect
          data={data.profile}
          onChange={(file) => {
            changeInputHandler({
              name: "profile",
              value: file,
            });
          }}
        />
      </div>

      <div className="flex self-stretch justify-center gap-4 mt-8 max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
        <div className="flex  items-center self-stretch px-3 py-2 w-[450px] h-[65px]  mt-3.5 text-sm bg-white border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full ">
          <img
            loading="lazy"
            src="/images/completeProfile/pen.png"
            className="my-auto aspect-[1.03] w-[25px]"
          />
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div className="pl-4 text-left text-black max-md:max-w-full">
              Create Your Bio <span className="text-error">*</span>
            </div>
            <div className="pl-4 mt-1 text-left lowercase text-zinc-500 max-md:max-w-full">
              <input
                name="bio"
                value={localData.bio}
                onChange={(e) =>
                  changeInputHandler({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
                className="w-full border-none outline-none"
                type="text"
                placeholder="Create Your Bio"></input>
            </div>
          </div>
        </div>

        <div className="flex  items-center self-stretch px-3 py-2 w-[450px] h-[65px]  mt-3.5 text-sm bg-white border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full ">
          <img
            loading="lazy"
            src="/images/completeProfile/drink_glass.png"
            className="my-auto aspect-[1.03] w-[25px]"
          />
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div className="pl-4 text-left text-black max-md:max-w-full">
              Years of Experience <span className="text-error">*</span>
            </div>
            <div className="pl-4 mt-1 text-left lowercase text-zinc-500 max-md:max-w-full">
              <input
                name="yearsOfExperience"
                value={localData.yearsOfExperience}
                onChange={(e) =>
                  changeInputHandler({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
                className="w-full border-none outline-none"
                type="text"
                placeholder="Your Years of Experience"></input>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center gap-4  self-stretch mt-1.5 max-md:flex-wrap max-md:mt-5 max-md:max-w-full">
        <div className="flex  items-center self-stretch px-3 py-2 w-[450px] h-[65px]  mt-3.5 text-sm bg-white border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full ">
          <img
            loading="lazy"
            src="/images/completeProfile/location.svg"
            className="my-auto aspect-[1.03] w-[25px]"
          />
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div className="pl-4 text-left text-black max-md:max-w-full">
              Based From Which State? <span className="text-error">*</span>
            </div>
            <div className="pl-4 mt-1 text-left lowercase text-zinc-500 max-md:max-w-full">
              <input
                name="location"
                value={localData.location.state}
                onChange={(e) =>
                  changeInputHandler({
                    name: e.target.name,
                    value: {
                      ...localData.location,
                      state: ensureString({ value: e.target.value }),
                    },
                  })
                }
                className="w-full border-none outline-none"
                type="text"
                placeholder="Enter the State"></input>
            </div>
          </div>
        </div>
        <div className="flex  items-center self-stretch px-3 py-2 w-[450px] h-[65px]  mt-3.5 text-sm bg-white border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full ">
          <img
            loading="lazy"
            src="/images/completeProfile/location.svg"
            className="my-auto aspect-[1.03] w-[25px]"
          />
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div className="pl-4 text-left text-black max-md:max-w-full">
              Based From Which City? <span className="text-error">*</span>
            </div>
            <div className="pl-4 mt-1 text-left lowercase text-zinc-500 max-md:max-w-full">
              <input
                name="location"
                value={localData.location.city}
                onChange={(e) =>
                  changeInputHandler({
                    name: e.target.name,
                    value: {
                      ...localData.location,
                      city: ensureString({ value: e.target.value }),
                    },
                  })
                }
                className="w-full border-none outline-none"
                type="text"
                placeholder="Enter the city"></input>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center gap-4  self-stretch mt-1.5 max-md:flex-wrap max-md:mt-5 max-md:max-w-full">
        <div className="flex  items-center self-stretch px-3 py-2 w-[450px] h-[65px]  mt-3.5 text-sm bg-white border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full ">
          <img
            loading="lazy"
            src="/images/completeProfile/location.svg"
            className="my-auto aspect-[1.03] w-[25px]"
          />
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div className="pl-4 text-left text-black max-md:max-w-full">
              Zip Code <span className="text-error">*</span>
            </div>
            <div className="pl-4 mt-1 text-left lowercase text-zinc-500 max-md:max-w-full">
              <input
                name="location"
                value={localData.location.postalCode}
                onChange={(e) =>
                  changeInputHandler({
                    name: e.target.name,
                    value: {
                      ...localData.location,
                      postalCode: ensureNumber({
                        value: e.target.value,
                        maxLength: 6,
                      }),
                    },
                  })
                }
                className="border-none outline-none"
                type="text"
                placeholder="Enter Your Zip Code"></input>
            </div>
          </div>
        </div>
        <div className="flex  items-center self-stretch px-3 py-2 w-[450px] h-[65px]  mt-3.5 text-sm bg-white border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full ">
          <img
            loading="lazy"
            src="/images/completeProfile/location.svg"
            className="my-auto aspect-[1.03] w-[25px]"
          />
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div className="pl-4 text-left text-black max-md:max-w-full">
              Street Name <span className="text-error">*</span>
            </div>
            <div className="pl-4 mt-1 text-left lowercase text-zinc-500 max-md:max-w-full">
              <input
                name="location"
                value={localData.location.streetName}
                onChange={(e) =>
                  changeInputHandler({
                    name: e.target.name,
                    value: {
                      ...localData.location,
                      streetName: e.target.value,
                    },
                  })
                }
                className="w-full border-none outline-none stye"
                type="text"
                placeholder="Enter Your Street Name"></input>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-start gap-4  self-stretch mt-1.5 max-md:flex-wrap max-md:mt-5 max-md:max-w-full">
        <div className="flex items-center self-stretch px-3 py-2 w-[450px] h-[65px]  mt-3.5 text-sm bg-white border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full ">
          <img
            loading="lazy"
            src="/images/completeProfile/celender.png"
            className="my-auto aspect-[1.03] w-[25px]"
          />
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div className="pl-4 text-left text-black max-md:max-w-full">
              Birthdate <span className="text-error">*</span>
            </div>
            <div className="flex pr-2 mt-1 text-left lowercase text-zinc-500 max-md:max-w-full">
              <input
                type="date"
                ref={startDateRef}
                value={localData.birthDate}
                max={maxDate}
                onChange={(e) =>
                  changeInputHandler({
                    name: "birthDate",
                    value: e.target.value,
                  })
                }
                className="hide-calendar"
              />
              <img
                loading="lazy"
                src="/images/calander-icon.svg"
                className="cursor-pointer size-5"
                onClick={() => handleShowPicker("startDate")}
              />
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{
                    width: "95%",
                    "& .MuiInputBase-root": {
                      padding: 0,
                      "& .MuiButtonBase-root": {
                        transform: "translateY(-27%)",
                      },
                      "& .MuiInputBase-input": {
                        height: "8px",
                        width: "100%",
                        color: "gray",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    },
                  }}
                  format="DD/MM/YYYY"
                  slotProps={{ textField: { size: "small" } }}
                  value={
                    localData.birthDate
                      ? dayjs(localData.birthDate, "DD/MM/YYYY")
                      : dayjs("DD/MM/YYYY")
                  }
                  onChange={(e) =>
                    changeInputHandler({
                      name: "birthDate",
                      value: e.format("DD/MM/YYYY"),
                    })
                  }
                />
              </LocalizationProvider> */}
            </div>
          </div>
        </div>
        <div
          className={`flex  items-center self-stretch px-3 py-2 w-[450px] h-[65px]  mt-3.5 text-sm border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full ${
            user?.phoneNo && user?.phoneNo != "" ? "bg-stone-200" : "bg-white"
          }`}>
          <img
            loading="lazy"
            src="/images/completeProfile/location.svg"
            className="my-auto aspect-[1.03] w-[25px]"
          />
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div className="pl-4 text-left text-black max-md:max-w-full">
              Phone No. <span className="text-error">*</span>
            </div>
            <div className="pl-4 mt-1 text-left lowercase text-zinc-500 max-md:max-w-full">
              <input
                name="phoneNo"
                value={localData.phoneNo}
                disabled={user?.phoneNo && user?.phoneNo != "" ? true : false}
                onChange={(e) => {
                  let value = ensureNumber({
                    value: e.target.value,
                    maxLength: 10,
                  });
                  changeInputHandler({
                    name: e.target.name,
                    value,
                  });
                }}
                className={`border-none outline-none ${
                  user?.phoneNo && user?.phoneNo != ""
                    ? "bg-stone-200"
                    : "bg-white"
                }`}
                type="text"
                placeholder="Enter Your Phone No."></input>
            </div>
          </div>
        </div>
      </div>

      <Button
        type="text"
        loading={apiLoader}
        onClick={submitHandler}
        style={{ cursor: "pointer", height: "auto" }}
        className="justify-center p-3 mt-10 mb-5 w-[60%] text-small text-center text-black bg-amber-300 rounded-[124.047px] max-md:px-5 max-md:max-w-full my-3 ">
        Save & Next
      </Button>
    </div>
  );
}
export default Cp1;
