import * as React from "react";
import { useSelector } from "react-redux";
import SettingMain from "../components/settings/Bartender/SettingMain";
import SettingMainEst from "../components/settings/Establishment/SettingMainEst";

function Setting(props) {
  const user = useSelector((state) => state.user.user);
  return (
    <>
      {user && (
        <>
          {user.role === 1 && <SettingMain />}
          {user.role === 2 && <SettingMainEst />}
        </>
      )}
    </>
  );
}
export default Setting;
