import React from "react";
import styles from "./CpE.module.css";
import { Bounce, toast } from "react-toastify";
import { ensureEmail, ensureNumber } from "../../../utils/inputFormatter";
import {
  CustomerRangeList,
  TimeSlotsList,
  YearRangeList,
} from "../../../utils/List";

function CpE3({ activeTab, setActiveTab, changeInputHandler, data }) {
  const submitHandler = () => {
    let msg = "";
    if (!data.customerFlow) msg = "Peak Hours is required";
    else if (!data.yearsOfExperience) msg = "Years of experience is required";
    else if (!data.accommodates) msg = "Accomodates is required";
    // else if (!data.websiteLink) msg = "Website Link is required";

    if (msg) {
      toast.error(msg, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } else {
      setActiveTab((prev) => prev + 1);
    }
  };

  return (
    <div className="flex relative justify-center items-center self-center px-16 py-10  w-[85%] text-xs max-w-[1599px] max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col max-w-full w-[1299px]">
        <div
          className={`self-center text-2xl leading-9 text-center text-black max-md:max-w-full} ${styles.header}`}>
          Tell Us More About Your Establishment
        </div>

        <div className="flex justify-between mt-5 max-md:flex-wrap max-md:max-w-full">
          <div className="flex gap-4 justify-between w-[500px] px-5 py-3.5 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full">
            <img
              loading="lazy"
              src="/images/completeProfile/drink_glass.png"
              className="my-auto aspect-square w-[23px]"
            />
            <div className="flex flex-col flex-1 max-md:max-w-full">
              <div className="text-left text-black max-md:max-w-full">
                Peak Hours <span style={{ color: "#ff3333" }}>*</span>
              </div>
              <div className="mt-2 text-left text-zinc-500 max-md:max-w-full">
                <select
                  className="w-full"
                  name="customerFlow"
                  id="customerFlow"
                  value={data.customerFlow}
                  onChange={(e) =>
                    changeInputHandler({
                      name: e.target.name,
                      value: e.target.value,
                    })
                  }>
                  <option value="" disabled>
                    Select a Peak Hours
                  </option>
                  {TimeSlotsList?.map((elem, ind) => (
                    <option key={`${elem.value}_${ind}`} value={elem.value}>
                      {elem.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="flex gap-4 justify-between w-[500px] px-5 py-3.5 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full">
            <img
              loading="lazy"
              src="/images/completeProfile/dressCode.png"
              className="my-auto aspect-square w-[22px]"
            />
            <div className="flex flex-col flex-1 max-md:max-w-full">
              <div className="text-left text-black max-md:max-w-full">
                Number of years in business{" "}
                <span style={{ color: "#ff3333" }}>*</span>
              </div>
              <div className="mt-2 text-left text-zinc-500 max-md:max-w-full">
                <select
                  className="w-full"
                  name="yearsOfExperience"
                  id="yearsOfExperience"
                  value={data.yearsOfExperience}
                  onChange={(e) =>
                    changeInputHandler({
                      name: e.target.name,
                      value: e.target.value,
                    })
                  }>
                  <option value="" disabled>
                    Select Years In Business
                  </option>
                  {YearRangeList?.map((elem, ind) => (
                    <option key={`${elem.value}_${ind}`} value={elem.value}>
                      {elem.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between mt-5 max-md:flex-wrap max-md:max-w-full">
          <div className="flex gap-4 self-start px-5 py-3.5 mt-6 w-[500px] bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full">
            <img
              loading="lazy"
              src="/images/completeProfile/tick-circle-solid.png"
              className="my-auto aspect-square w-[23px]"
            />
            <div className="flex flex-col flex-1 max-md:max-w-full">
              <div className="text-left text-black max-md:max-w-full">
                Accommodates how many people{" "}
                <span style={{ color: "#ff3333" }}>*</span>
              </div>
              <div className="mt-2 text-left text-zinc-500 max-md:max-w-full">
                <select
                  className="w-full border-none outline-none"
                  name="accommodates"
                  value={data.accommodates}
                  id="accommodates"
                  onChange={(e) =>
                    changeInputHandler({
                      name: e.target.name,
                      value: e.target.value,
                    })
                  }>
                  <option value="" disabled>
                    Select Accommodates
                  </option>
                  {CustomerRangeList?.map((elem, ind) => (
                    <option key={`${elem.value}_${ind}`} value={elem.value}>
                      {elem.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="flex gap-4 self-start px-5 py-3.5 mt-6 w-[500px] bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full">
            <img
              loading="lazy"
              src="/images/completeProfile/tick-circle-solid.png"
              className="my-auto aspect-square w-[23px]"
            />
            <div className="flex flex-col flex-1 max-md:max-w-full">
              <div className="text-left text-black max-md:max-w-full">
                Website Link
              </div>
              <div className="mt-2 text-left text-zinc-500 max-md:max-w-full">
                <input
                  name="websiteLink"
                  value={data.websiteLink}
                  onChange={(e) =>
                    changeInputHandler({
                      name: e.target.name,
                      value: ensureEmail({ value: e.target.value }),
                    })
                  }
                  className="w-full border-none outline-none"
                  type="text"
                  placeholder="Website Link"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          onClick={submitHandler}
          style={{ cursor: "pointer" }}
          className="justify-center self-center px-5 py-5 mt-16 w-[60%] text-sm text-center text-white bg-red-500 rounded-[92.207px] max-md:mt-10 max-md:max-w-full">
          Save & Next
        </div>
      </div>
    </div>
  );
}
export default CpE3;
