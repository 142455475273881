import React from "react";
import NavigationWrapper from "../components/dashboard/NavigationWrapper";
import { useSelector } from "react-redux";
import WrapperDashEst from "../components/authWrapper/WrapperDashEst";
import BartenderWrapperDash from "../components/dashboard/WrapperDash";

function WithMainLayout(Component) {
  const user = useSelector((state) => state.user.user);

  if (user?.role == 1) {
    return (props) => (
      <BartenderWrapperDash>
        <Component />
      </BartenderWrapperDash>
    );
  } else {
    return (props) => (
      <WrapperDashEst>
        <Component />
      </WrapperDashEst>
    );
  }
}

export default WithMainLayout;
