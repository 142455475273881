import React, { useEffect, useState } from "react";
import styles from "./CpE.module.css";
import { Modal } from "@mui/material";
import Wrappers from "../Wrappers";
import CpE3 from "./CpE3";
import CpE4 from "./CpE4";
import CpE5 from "./CpE5";
import CpE6 from "./CpE6";
import ProfilePicPreviewAndSelect from "../../ProfilePicPreviewAndSelect/ProfilePicPreviewAndSelect";
import { createEstablishmentApi } from "../../../axios/axios";
import { useSelector } from "react-redux";
import { Bounce, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "antd";

function CpE2({ estaDetails, setEstadetails }) {
  const user = useSelector((state) => state.user.user);

  const [activeTab, setActiveTab] = useState(1);
  const [selectedEsta, setSelectedEsta] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [dashloading, setDashLoading] = useState(false);

  const [completedEsta, setcompletedEsta] = useState(0);
  const navigate = useNavigate();
  const estaSetterHandler = (index) => {
    if (!estaDetails[index][0].EprofileImg) {
      toast.error(
        "Please select an Image for Establishment before Proceeding",
        {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );

      return;
    }
    setSelectedEsta(index);
  };
  const changeInputHandler = ({ name, value, index = null, tab = null }) => {
    let indprep = index != null ? index : selectedEsta;
    let acTab = tab != null ? tab : activeTab - 1;

    let x = [...estaDetails];
    x[indprep][acTab][name] = value;

    setEstadetails(x);
  };

  const createEstablishment = () => {
    let data = estaDetails[selectedEsta];

    setLoading(true);

    createEstablishmentApi({ data, token: user?.token })
      .then((result) => {
        setLoading(false);
        const x = [...estaDetails];

        x[selectedEsta][0]["uploadedProfileImg"] = result.data.data.EprofileImg;
        x[selectedEsta][0].EprofileImg = "";
        x[selectedEsta][0].eid = result.data.data._id;
        x[selectedEsta][3].Images = [];
        x[selectedEsta][3]["pic"] = result.data.data.pic;

        setEstadetails(x);

        setcompletedEsta((prev) => prev + 1);
        setSelectedEsta(-1);
      })
      .catch((err) => {
        setLoading(false);

        toast.error(err.message, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });

        console.log("create Esta Error", err);
      });
  };

  return (
    <>
      <div
        className={`relative flex items-center self-center justify-center w-full px-16 py-12 max-md:px-5 max-md:mt-10 max-md:max-w-full ${
          selectedEsta == -1 ? "" : "hidden"
        }`}>
        <div className="flex flex-col items-center mb-20 max-w-full w-[639px] max-md:mb-10">
          <div
            className={`text-2xl leading-9 text-center text-black max-md:max-w-full} ${styles.header}`}>
            How Many Establishments Do You Own?
          </div>
          <div className="self-stretch mt-2.5 text-base text-neutral-600 w-full max-md:max-w-full">
            Add the establishment where you want to use Bartender service
          </div>
          <div>
            {Array.from({ length: estaDetails.length }).map((elem, index) => (
              <div
                key={`c${index}`}
                className="flex justify-between gap-5 mt-20 max-md:mt-10">
                <ProfilePicPreviewAndSelect
                  data={estaDetails[index][0].EprofileImg}
                  onChange={(file) => {
                    changeInputHandler({
                      name: "EprofileImg",
                      value: file,
                      index: index,
                    });
                  }}
                />
                <div className="flex flex-col flex-1 my-auto">
                  <div
                    className={`text-2xl leading-7 text-left text-black whitespace-nowrap} ${styles.header}`}>
                    Establishment {index + 1}
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => estaSetterHandler(index)}
                    className="mt-1 text-lg leading-7 text-left text-red-500">
                    {index <= completedEsta - 1
                      ? "Edit Details"
                      : "Add details"}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {completedEsta > 0 && (
            <Button
              onClick={() => {
                setDashLoading(true);
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              }}
              loading={dashloading}
              className="justify-center self-stretch px-5 py-5 mt-36 text-sm text-center text-white bg-red-500 rounded-[92.207px] max-md:mt-10 max-md:max-w-full">
              Go to Dashboard
            </Button>
          )}
        </div>
      </div>

      {selectedEsta !== -1 && (
        <div
          className={`${
            selectedEsta !== -1 ? "" : "hidden"
          } absolute top-0 h-[100vh] w-full`}>
          <>
            <Wrappers
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              showHeader={true}
              totalSteps={4}
              type={"esta"}>
              {activeTab === 1 && (
                <CpE3
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  changeInputHandler={changeInputHandler}
                  data={estaDetails[selectedEsta][activeTab - 1]}
                />
              )}
              {activeTab === 2 && (
                <CpE4
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  changeInputHandler={changeInputHandler}
                  data={estaDetails[selectedEsta][activeTab - 1]}
                />
              )}
              {activeTab === 3 && (
                <CpE5
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  changeInputHandler={changeInputHandler}
                  data={estaDetails[selectedEsta][activeTab - 1]}
                />
              )}
              {activeTab === 4 && (
                <CpE6
                  loading={loading}
                  changeInputHandler={changeInputHandler}
                  data={estaDetails[selectedEsta][activeTab - 1]}
                  createEstablishment={createEstablishment}
                />
              )}
            </Wrappers>
          </>
        </div>
      )}
    </>
  );
}

export default CpE2;
