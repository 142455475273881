import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "../../profile.module.css";
import {
  fetchEstablishmentDetails,
  updateEstablishmentBasic,
} from "../../../../axios/axios";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { countWords } from "../../../../utils/countwords";
import { CuisineList, DressCodeList, PosSystemsList } from "../../../../utils/List";

function Edit1() {
  const { id } = useParams();
  const user = useSelector((state) => state.user?.user);
  const [estabbasic, setEstabBasic] = useState({
    establishmentName: "",
    discription: "",
    serviceType: "",
    dressCode: "",
    posSystem: "",
    eid: id || "",
  });

  // customerFlow: "",
  // yearsOfExperience: "",
  // websiteLink: "",

  const navigate = useNavigate();

  const fetchEstabDetails = () => {
    fetchEstablishmentDetails({ token: user?.token, eid: id })
      .then((res) => {
        if (res.data) {
          const {
            establishmentName,
            discription,
            serviceType,
            dressCode,
            posSystem,
            // yearsOfExperience,
            // websiteLink,
          } = res.data;
          setEstabBasic((prevState) => ({
            ...prevState,
            establishmentName: establishmentName || prevState.establishmentName,
            discription: discription || prevState.discription,
            serviceType: serviceType || prevState.serviceType,
            dressCode: dressCode || prevState.dressCode,
            posSystem: posSystem || prevState.posSystem,
            // yearsOfExperience: yearsOfExperience || prevState.yearsOfExperience,
            // websiteLink: websiteLink || prevState.websiteLink,
          }));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = () => {
    const formdata = new FormData();
    formdata.append("establishmentName", estabbasic.establishmentName);
    formdata.append("discription", estabbasic.discription);
    formdata.append("serviceType", estabbasic.serviceType);
    formdata.append("dressCode", estabbasic.dressCode);
    formdata.append("posSystem", estabbasic.posSystem);
    // formdata.append("yearsOfExperience", estabbasic.yearsOfExperience);
    // formdata.append("websiteLink", estabbasic.websiteLink);
    formdata.append("eid", estabbasic.eid);
    updateEstablishmentBasic({ token: user?.token, formdata })
      .then((res) => navigate(`/establishment-details/${id}`))
      .catch((err) => toast.error(err?.message));
  };

  useEffect(() => {
    if (!id) return;

    fetchEstabDetails();
  }, []);

  return (
    <div>
      <img
        loading="lazy"
        src="/images/completeProfile/complete_Profile_backImg.png"
        className="object-cover fixed inset-0 size-full"
      />

      <div
        style={{
          backgroundImage: `url('/images/headerBackground.svg')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "0 0 25px 25px",
        }}
        className="flex overflow-hidden relative px-20 text-2xl w-[100%] text-white min-h-[100px] rounded-b-3xl max-md:flex-wrap max-md:px-5">
        <div className="my-auto flex min-w-full">
          <div
            onClick={() => navigate(`/establishment-details/${id}/`)}
            className="border-2 px-4 rounded-[10px] py-1 mr-5 cursor-pointer">
            &#10094;
          </div>
          <span className={`${styles.header} `}>Edit Your Profile</span>
        </div>
      </div>
      <div className="flex relative justify-center items-center self-center px-16 py-10 mx-auto my-7 rounded-[20px] w-full bg-white text-xs max-w-[1099px] max-md:px-5 max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-col max-w-full w-[1299px]">
          <div
            className={`self-center text-xl leading-9 text-center text-black max-md:max-w-full} ${styles.header}`}>
            Tell Us More About Your Establishment
          </div>

          <div className="flex gap-4 justify-between px-5 py-3.5 mt-8 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full">
            <img
              loading="lazy"
              src="/images/completeProfile/building.png"
              className="my-auto aspect-square w-[22px]"
            />
            <div className="flex flex-col flex-1 max-md:max-w-full">
              <div className="text-black text-left max-md:max-w-full">
                Name of Establishment{" "}
                <span className="text-establishment-red">*</span>
              </div>
              <div className="mt-2 text-zinc-500 text-left max-md:max-w-full">
                <input
                  className="border-none outline-none"
                  type="text"
                  placeholder="Name of Establishment"
                  value={estabbasic.establishmentName}
                  onChange={(e) =>
                    setEstabBasic({
                      ...estabbasic,
                      establishmentName: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="flex gap-4 justify-between items-start px-5 pt-3.5 mt-6 bg-white rounded-3xl border-gray-200 border-solid border-[0.922px] max-md:flex-wrap max-md:max-w-full">
            <img
              loading="lazy"
              src="/images/completeProfile/pen.png"
              className="aspect-[1.04] w-[23px]"
            />
            <div className="flex flex-col flex-1 max-md:max-w-full">
              <div className="text-black text-left max-md:max-w-full">
                Write about your Establishment{" "}
                <span className="text-establishment-red">*</span>
              </div>
              <div className="mt-2 text-zinc-500 text-left max-md:max-w-full">
                <textarea
                  className="border-none outline-none"
                  value={estabbasic.discription}
                  onChange={(e) =>
                    setEstabBasic({
                      ...estabbasic,
                      discription: e.target.value,
                    })
                  }></textarea>
              </div>
            </div>
          </div>
          <div className="self-end mt-4 mr-3 text-base text-neutral-600 max-md:mr-2.5">
            {countWords(estabbasic.discription)}/200
          </div>
          <div className="flex  justify-between  mt-5 gap-7 max-md:flex-wrap max-md:max-w-full">
            <div className="flex gap-4 justify-between w-full px-5 py-3.5 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full">
              <img
                loading="lazy"
                src="/images/completeProfile/drink_glass.png"
                className="my-auto aspect-square w-[23px]"
              />
              <div className="flex flex-col  flex-1 max-md:max-w-full">
                <div className="text-black text-left max-md:max-w-full">
                  Type of Cusinie{" "}
                  <span className="text-establishment-red">*</span>
                </div>
                <div className="mt-2 text-zinc-500 text-left max-md:max-w-full">
                  <select
                    className="w-full border-none outline-none"
                    type="text"
                    placeholder="Type of service"
                    value={estabbasic.serviceType}
                    onChange={(e) =>
                      setEstabBasic({
                        ...estabbasic,
                        serviceType: e.target.value,
                      })
                    }>
                    {CuisineList?.map((elem, ind) => (
                      <option key={`${elem.value}_${ind}`} value={elem.value}>
                        {elem.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="flex gap-4 justify-between w-full px-5 py-3.5 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full">
              <img
                loading="lazy"
                src="/images/completeProfile/dressCode.png"
                className="my-auto aspect-square w-[22px]"
              />
              <div className="flex flex-col flex-1 max-md:max-w-full">
                <div className="text-black text-left max-md:max-w-full">
                  Dress Code (Optional)
                </div>
                <div className="mt-2 text-zinc-500 text-left max-md:max-w-full">
                  <select
                    className="w-full border-none outline-none"
                    type="text"
                    placeholder="Dress Code (Optional)"
                    value={estabbasic.dressCode}
                    onChange={(e) =>
                      setEstabBasic({
                        ...estabbasic,
                        dressCode: e.target.value,
                      })
                    }>
                    <option value="" disabled>
                      Select a Dress
                    </option>

                    {DressCodeList?.map((elem, ind) => (
                      <option key={`${elem.value}_${ind}`} value={elem.value}>
                        {elem.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="flex  justify-between gap-7 mt-5 max-md:flex-wrap max-md:max-w-full">
            <div className="flex gap-4 justify-between w-full px-5 py-3.5 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full">
              <img
                loading="lazy"
                src="/images/completeProfile/tick-circle-solid.png"
                className="my-auto aspect-square w-[23px]"
              />
              <div className="flex flex-col  flex-1 max-md:max-w-full">
                <div className="text-black text-left max-md:max-w-full">
                  POS Software (Optional)
                </div>
                <div className="mt-2 text-zinc-500 text-left max-md:max-w-full">
                  <select
                    className=" w-full border-none outline-none"
                    type="text"
                    placeholder="POS Software (Optional)"
                    value={estabbasic.posSystem}
                    onChange={(e) =>
                      setEstabBasic({
                        ...estabbasic,
                        posSystem: e.target.value,
                      })
                    }>
                    <option value="" disabled>
                      Select a POS System
                    </option>

                    {PosSystemsList?.map((elem, ind) => (
                      <option key={`${elem.value}_${ind}`} value={elem.value}>
                        {elem.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            {/* <div className="flex gap-4 justify-between w-full px-5 py-3.5 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full">
              <img
                loading="lazy"
                src="/images/completeProfile/multiUser.png"
                className="my-auto aspect-square w-[22px]"
              />
              <div className="flex flex-col flex-1 max-md:max-w-full">
                <div className="text-black text-left max-md:max-w-full">
                  Working Shift
                </div>
                <div className="mt-2 text-zinc-500 text-left max-md:max-w-full">
                  <input
                    className="border-none outline-none"
                    type="text"
                    placeholder="Customer flow"
                    value={estabbasic.customerFlow}
                    onChange={(e) =>
                      setEstabBasic({
                        ...estabbasic,
                        customerFlow: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div> */}
          </div>
          {/* <div className="flex  justify-between  mt-5 gap-7 max-md:flex-wrap max-md:max-w-full">
            <div className="flex gap-4 justify-between w-full px-5 py-3.5 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full">
              <img
                loading="lazy"
                src="/images/completeProfile/portFolio.png"
                className="my-auto aspect-square w-[23px]"
              />
              <div className="flex flex-col  flex-1 max-md:max-w-full">
                <div className="text-black text-left max-md:max-w-full">
                  Website Link
                </div>
                <div className="mt-2 text-zinc-500 text-left max-md:max-w-full">
                  <input
                    className="border-none outline-none"
                    type="text"
                    placeholder="Website Link"
                    value={estabbasic.websiteLink}
                    onChange={(e) =>
                      setEstabBasic({
                        ...estabbasic,
                        websiteLink: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-4 justify-between w-full px-5 py-3.5 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full">
              <img
                loading="lazy"
                src="/images/completeProfile/solarStar.png"
                className="my-auto aspect-square w-[22px]"
              />
              <div className="flex flex-col flex-1 max-md:max-w-full">
                <div className="text-black text-left max-md:max-w-full">
                  Number of years in business
                </div>
                <div className="mt-2 text-zinc-500 text-left max-md:max-w-full">
                  <input
                    className="border-none outline-none"
                    type="text"
                    placeholder="Number of years in business"
                    value={estabbasic.yearsOfExperience}
                    onChange={(e) =>
                      setEstabBasic({
                        ...estabbasic,
                        yearsOfExperience: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div> */}
          <div
            onClick={() => handleSubmit()}
            className="justify-center self-center px-5 py-5 cursor-pointer mt-16 w-[60%] text-sm text-center text-white bg-red-500 rounded-[92.207px] max-md:mt-10 max-md:max-w-full">
            Save
          </div>
        </div>
      </div>
    </div>
  );
}
export default Edit1;
