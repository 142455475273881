import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Elements,
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import WrapperDashEst from "../../authWrapper/WrapperDashEst";
import DropdownEst from "../../dashboard/Establishment/DropdownEst";
import NotificationEst from "../../dashboard/Establishment/NotificationEst";
// import stripePromise from "../../stripeConfig.js";
import style from "../Earning.module.css";

import { loadStripe } from "@stripe/stripe-js";
import { AddNewCards } from "../../../axios/axios";
import { useSelector } from "react-redux";
import EstabDashHeader from "../../dashboard/Establishment/EstabDashHeader";
import { toast } from "react-toastify";
import { Button } from "antd";
import { ensureString } from "../../../utils/inputFormatter";

const stripePromise = loadStripe(
  "pk_test_51OgX4YB8nRV0LVFHV6MJmRI0ZIxNqv4EwtjcV9M5BchTLBoMaTeznjIlU7Z6GseqYyGaL7rxFMprzdjolEBXQbj7001tZjqr1U"
);

function AddCardOp() {
  const cardElementOptions = {
    style: {
      base: {
        fontSize: "16px",
        color: "#32325d",
        "::placeholder": {
          color: "#aab7c4",
        },
        "::selection": {
          color: "#c4f0ff",
        },
      },
      invalid: {
        color: "#fa755a",
      },
    },
    hidePostalCode: true,
    disabled: false,
  };

  const user = useSelector((state) => state.user.user);
  const [showModaldrop, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const mainModaldrop = <DropdownEst />;
  const [showModalnotify, setShowModalnotify] = useState(false);
  const mainModalnotify = <NotificationEst />;
  const [name, setName] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  const [errormsg, setErrorMsg] = useState({});
  const checkInput = ["name", "elements"];

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(name);

    if (name.trim() === "") {
      toast.error("Name is Required");
      setErrorMsg({ ...errormsg, name: true });
      return;
    } else {
      // setLoading(true);
      const cardNumberElement = elements.getElement(CardNumberElement);
      const cardExpiryElement = elements.getElement(CardExpiryElement);
      const cardCvcElement = elements.getElement(CardCvcElement);

      // const cardElement = elements.getElement(CardElement);
      const { error, token } = await stripe.createToken(cardNumberElement, {
        name,
      });
      console.log(error?.message);
      console.log(token);

      // if (error) {
      //   setLoading(false);
      //   toast.error(error?.message || "Card Details Required");
      //   setErrorMsg({ ...errormsg, element: true });
      // } else {
      //   AddNewCards({ token: user?.token, cToken: token.id })
      //     .then((res) => {
      //       toast.success(res?.message);
      //       goBack();
      //     })
      //     .catch((err) => {
      //       setLoading(false);
      //       toast.error(err?.message);
      //     });
      // }
    }
  };
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif,',
        fontSmoothing: "antialiased",
        fontSize: "12px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <WrapperDashEst>
      <EstabDashHeader
        leftContent={
          <>
            <div className="pt-3 my-auto uppercase grow">
              <span className=" text-neutral-700">Payment Options</span>{" "}
            </div>
          </>
        }
      />
      <div className="flex justify-center max-w-[700px] mx-auto mt-5 items-center px-16 py-12 text-xs bg-white rounded-3xl shadow-2xl max-md:px-5 max-md:max-w-full">
        <form className="flex flex-col max-w-full w-[440px]">
          <div
            className={`self-center text-2xl leading-9 text-center text-black ${style.header}`}>
            Add New Card
          </div>
          <div
            className={`flex flex-col px-5 py-2.5 mt-10 bg-white border ${
              errormsg?.name ? "border-error" : "border-gray-200"
            } border-solid rounded-[92.207px] max-md:mt-10 max-md:max-w-full`}>
            <div
              className={` max-md:max-w-full  ${
                errormsg?.name ? "text-error" : "text-black"
              } `}>
              Cardholder Name <span className="text-error">*</span>
            </div>
            <input
              className={`mt-1.5 text-zinc-500 max-md:max-w-full border-none outline-none ${
                errormsg?.name ? "placeholder-error" : ""
              }`}
              type="text"
              placeholder="Cardholder Name"
              value={name}
              onChange={(e) => {
                if (errormsg?.name) {
                  delete errormsg["name"];
                }
                let value = ensureString({ value: e.target.value });

                // if (value != "" && value != " ") {
                setName(value);
                // }
              }}
            />
          </div>
          {/* <div
            className={`flex flex-col px-5 py-2.5 mt-4 bg-white border ${
              errormsg?.element ? "border-error" : "border-gray-200"
            } border-solid rounded-[92.207px] max-md:mt-10 max-md:max-w-full`}> */}
          {/* <div
              className={` max-md:max-w-full  ${
                errormsg?.element ? "text-error" : "text-black"
              } `}>
              Card Details <span className="text-error">*</span>
            </div> */}
          {/* <CardElement
              onChange={(event) => {
                if (errormsg?.element) {
                  delete errormsg["element"];
                }
                if (event.error) {
                  console.error("Card validation error:", event.error.message);
                }
              }}
              className="mt-1.5 text-zinc-500 max-md:max-w-full border-none outline-none"
              options={cardElementOptions}
            /> */}
          <form className="payment-form">
            <div
              className={`flex flex-col px-5 py-2.5 mt-2 bg-white border ${
                errormsg?.name ? "border-error" : "border-gray-200"
              } border-solid rounded-[92.207px] max-md:mt-10 max-md:max-w-full`}>
              <div
                className={` max-md:max-w-full  ${
                  errormsg?.name ? "text-error" : "text-black"
                } `}>
                Card Number<span className="text-error">*</span>
              </div>
              <CardNumberElement
                options={CARD_ELEMENT_OPTIONS}
                className={`mt-1.5 text-zinc-500 max-md:max-w-full border-none outline-none ${
                  errormsg?.name ? "placeholder-error" : ""
                }`}
              />
            </div>
            <div
              className={`flex flex-col px-5 py-2.5 mt-2 bg-white border ${
                errormsg?.name ? "border-error" : "border-gray-200"
              } border-solid rounded-[92.207px] max-md:mt-10 max-md:max-w-full`}>
              <div
                className={` max-md:max-w-full  ${
                  errormsg?.name ? "text-error" : "text-black"
                } `}>
                Expiry Date<span className="text-error">*</span>
              </div>
              <CardExpiryElement
                options={CARD_ELEMENT_OPTIONS}
                className={`mt-1.5 text-zinc-500 text-xs max-md:max-w-full border-none outline-none ${
                  errormsg?.name ? "placeholder-error" : ""
                }`}
              />
            </div>
            <div
              className={`flex flex-col px-5 py-2.5 mt-2 bg-white border ${
                errormsg?.name ? "border-error" : "border-gray-200"
              } border-solid rounded-[92.207px] max-md:mt-10 max-md:max-w-full`}>
              <div
                className={` max-md:max-w-full  ${
                  errormsg?.name ? "text-error" : "text-black"
                } `}>
                CVV<span className="text-error">*</span>
              </div>

              <CardCvcElement
                options={CARD_ELEMENT_OPTIONS}
                className={`mt-1.5 text-zinc-500 max-md:max-w-full border-none outline-none ${
                  errormsg?.name ? "placeholder-error" : ""
                }`}
              />
            </div>
          </form>

          <Button
            loading={loading}
            type="text"
            disabled={!stripe}
            onClick={(e) => handleSubmit(e)}
            className="justify-center py-3.5 mt-8 text-sm text-center cursor-pointer text-white bg-red-500 rounded-[92.207px]  h-14">
            {" "}
            Save Card
          </Button>
        </form>
      </div>
    </WrapperDashEst>
  );
}

export default function AddCardOpWrapper() {
  return (
    <Elements stripe={stripePromise}>
      <AddCardOp />
    </Elements>
  );
}
