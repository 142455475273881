import dayjs from "dayjs";
import React from "react";

function AboutGig({ jobdata }) {
  return (
    <div className="DyamiceCOntianer">
      <div className=" flex flex-col mt-10 ml-7 text-sm text-neutral-400 max-md:ml-2.5">
        Job Title
        <span className="mt-1  text-sm text-neutral-800 max-md:ml-2.5">
          {jobdata?.jobTitle}
        </span>
      </div>
      <div className=" flex flex-col mt-6 ml-7 text-sm text-neutral-400 max-md:ml-2.5">
        About the Gig
        <span className="mt-1  text-sm text-neutral-800 max-md:ml-2.5">
          {jobdata?.jobDiscription}
        </span>
      </div>
      <div className=" flex flex-col mt-6 ml-7 text-sm text-neutral-400 max-md:ml-2.5">
        Shift Time
        <span className="mt-1  text-sm text-neutral-800 max-md:ml-2.5">
          {dayjs(jobdata?.startTime, "HH:mm").format("hh:mm A")} -{" "}
          {dayjs(jobdata?.endTime, "HH:mm").format("hh:mm A")}
        </span>
      </div>
      <div className=" flex flex-col mt-6 ml-7 text-sm text-neutral-400 max-md:ml-2.5">
        Years of experience
        <span className="mt-1  text-sm text-neutral-800 max-md:ml-2.5">
          {jobdata?.yearsOfExperience} Years
        </span>
      </div>
      <div className=" flex flex-col mt-6 ml-7 text-sm text-neutral-400 max-md:ml-2.5">
        Pay
        <span className="mt-1  text-sm text-neutral-800 max-md:ml-2.5">
          ${jobdata?.payHourly}/hour{" "}
        </span>
      </div>
      <div className=" flex flex-col mt-6 ml-7 text-sm text-neutral-400 max-md:ml-2.5">
        Skills Required
        <span className="mt-1  text-sm text-neutral-800 max-md:ml-2.5">
          {jobdata?.skillsRequired.length > 0 &&
            jobdata?.skillsRequired?.map((elem, ind) => (
              <p key={`Skill_${ind}`}>{elem}</p>
            ))}
        </span>
      </div>
      <div className=" flex flex-col mt-6 ml-7 text-sm text-neutral-400 max-md:ml-2.5">
        Duties & Responsibilities{" "}
        <span className="mt-1  text-sm text-neutral-800 max-md:ml-2.5">
          <ul className="list-disc ml-7">
            {jobdata?.dutiesRes.length > 0 &&
              jobdata?.dutiesRes?.map((elem, ind) => (
                <li key={`Duties_${ind}`}>{elem}</li>
              ))}
          </ul>
        </span>
      </div>
    </div>
  );
}

export default AboutGig;
