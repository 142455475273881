import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ChatBrt from "../components/Chat/Bartendar/ChatBrt";
import ChatEsta from "../components/Chat/Establishment/ChatEsta";
import { useSelector } from "react-redux";

function Chat(props) {
  const user = useSelector((state) => state.user.user);

  return (
    <>
      {user && (
        <>
          {user.role === 1 && <ChatBrt />}
          {user.role === 2 && <ChatEsta />}
        </>
      )}
    </>
  );
}
export default Chat;
