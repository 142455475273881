import styles from "./cp.module.css";
import { useState } from "react";

import { Button } from "antd";
import { Bounce, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../redux/userSlice";
import { updateBartLinksDoc } from "../../../axios/axios";
import { ensureEmail } from "../../../utils/inputFormatter";

function Cp4({ activeTab, setActiveTab, data }) {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const [apiLoader, setapiLoader] = useState(false);

  const [localData, setLocalData] = useState({
    portfoliolink: data.portfoliolink,
    linkedinlink: data.linkedinlink,
    instagramlink: data.instagramlink,
    resume: data.resume,
    certificates: data.certificates,
  });

  const submitHandler = () => {
    setapiLoader(true);

    updateBartLinksDoc({ data: localData, token: user.token })
      .then((result) => {
        setapiLoader(false);

        dispatch(updateUser({ accSetCount: user.accSetCount + 1 }));
        setActiveTab((prev) => prev + 1);
      })
      .catch((err) => {
        toast.error(err.message, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setapiLoader(false);

        console.log("err", err);
      });
  };
  const validFileType = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "application/pdf",
  ];
  const handleFileRemove = (fileType) => {
    // Update the local state to remove the file
    changeInputHandler({
      name: fileType,
      value: null,
    });

    // Reset the input field to allow reselecting the same file
    const fileInput = document.getElementById(fileType);
    if (fileInput) {
      fileInput.value = ""; // Clear the input value
    }
  };

  const changeInputHandler = ({ name, value }) => {
    if (
      name === "resume" &&
      value != null &&
      !validFileType.includes(value?.type)
    ) {
      toast.error("Invalid File Type Selected!");
      return;
    }

    let x = { ...localData };

    if (name === "certificates") {
      x.certificates = [...value];
      setLocalData(x);
      document.getElementById("certi").value = "";
      return;
    }

    x[name] = value;

    setLocalData(x);
  };
  return (
    <div className="flex relative flex-col items-center self-center px-5 py-10  w-full rounded-3xl max-md:mt-10 max-md:max-w-full">
      <div
        className={`text-2xl leading-9 text-center text-black} ${styles.header}`}>
        Links & Documents
      </div>
      <div className="mt-2.5 text-base whitespace-nowrap text-neutral-600">
        Add your links, resume and certificates
      </div>
      <div className="flex flex-col items-start  self-stretch px-6 mt-8 max-md:px-5 max-md:max-w-full ">
        <div className="flex gap-5 justify-between self-stretch text-xs max-md:flex-wrap max-md:max-w-full} ">
          <div className="flex gap-4 justify-between px-5 py-3.5 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full">
            <img
              loading="lazy"
              src="/images/completeProfile/portfolio.png"
              className="my-auto aspect-square w-[23px]"
            />

            <div className="flex flex-col flex-1 w-[290px] max-md:max-w-full">
              <div className="text-black text-start max-md:max-w-full">
                Portfolio Link
              </div>
              <div className="mt-2 text-zinc-500 text-start  max-md:max-w-full}">
                <input
                  className="border-none outline-none"
                  type="text"
                  placeholder="Add your portfolio link"
                  value={localData.portfoliolink}
                  name={"portfoliolink"}
                  onChange={(e) =>
                    changeInputHandler({
                      name: e.target.name,
                      value: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="flex gap-4 justify-between px-5 py-3.5 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full">
            <img
              loading="lazy"
              src="/images/completeProfile/linkdin.png"
              className="my-auto aspect-square w-[23px]"
            />
            <div className="flex flex-col flex-1 w-[290px] max-md:max-w-full">
              <div className="text-black text-start max-md:max-w-full">
                LinkedIn Link
              </div>
              <div className="mt-2 text-zinc-500 text-start  max-md:max-w-full}">
                <input
                  className="border-none outline-none"
                  type="text"
                  placeholder="Add your LinkedIn link"
                  value={localData.linkedinlink}
                  name={"linkedinlink"}
                  onChange={(e) =>
                    changeInputHandler({
                      name: e.target.name,
                      value: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="flex gap-4 justify-between px-5 py-3.5 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full">
            <img
              loading="lazy"
              src="/images/completeProfile/insta.png"
              className="my-auto aspect-square w-[23px]"
            />
            <div className="flex flex-col flex-1 w-[290px] max-md:max-w-full">
              <div className="text-black text-start max-md:max-w-full">
                Instagram Link
              </div>
              <div className="mt-2 text-zinc-500 text-start  max-md:max-w-full}">
                <input
                  className="border-none outline-none"
                  type="text"
                  placeholder="Add your Instagram link"
                  value={localData.instagramlink}
                  name={"instagramlink"}
                  onChange={(e) =>
                    changeInputHandler({
                      name: e.target.name,
                      value: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ width: "100%", maxWidth: "880px", margin: "auto" }}>
          <div
            className={`mt-12 text-2xl text-black whitespace-nowrap max-md:mt-10 max-md:ml-2.5} ${styles.headers}`}>
            Your Resume
          </div>

          <div
            style={{ position: "relative" }}
            className="flex justify-center items-center self-center h-[110px] px-16 py-7 mt-4 max-w-full text-center rounded-xl border border-dashed bg-red-500 bg-opacity-10 border-[color:var(--ff-3333,#F33)] leading-[153.3%] w-[100%] max-md:px-5">
            <input
              type="file"
              id="resume"
              name="resume"
              accept="image/png, image/jpeg, image/jpg, application/pdf"
              onChange={(e) =>
                changeInputHandler({
                  name: "resume",
                  value: e.target.files[0],
                })
              }
              style={{ position: "absolute", opacity: 0 }}
            />
            <label
              htmlFor="resume"
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                opacity: 0,
                cursor: "pointer",
              }}
            />
            <div className="flex flex-col max-w-full w-[208px]">
              <img
                loading="lazy"
                src="/images/completeProfile/upload.png"
                className="self-center aspect-square w-[30px]"
              />
              <div className="mt-2 text-xs text-black ">
                <span className="underline text-red-500">Browse</span>
                <span className="text-black"> your file</span>
              </div>
              <div className="mt-1 text-xs text-neutral-400">
                Supports JPG, JPEG, PNG, PDF
              </div>
            </div>
          </div>

          {localData.resume && (
            <div className="flex flex-col relative items-center pb-3 mt-5 h-[auto] max-w-full bg-sky-200 rounded-xl w-[174px] max-md:ml-2.5">
              <div className=" absolute top-[-7px] end-[-7px] ">
                <img
                  style={{ cursor: "pointer" }}
                  loading="lazy"
                  src="/images/completeProfile/red_cross.png"
                  className="w-6 aspect-[0.95]"
                  onClick={() => handleFileRemove("resume")}
                />
              </div>
              <div className="flex justify-center items-center self-stretch px-16 py-5 bg-white rounded-xl border-4 border-sky-50 border-solid max-md:px-5">
                <img
                  loading="lazy"
                  src="/images/completeProfile/pdf.png"
                  className="w-5 aspect-[0.95]"
                />
              </div>
              <div className="mt-1 text-xs leading-4 text-black">
                {localData.resume.name?.substring(0, 15) + "..."}
              </div>
              <div className=" text-xs leading-4 whitespace-nowrap text-stone-500">
                {(localData.resume.size / 1024).toFixed(2)} KB
              </div>
            </div>
          )}

          <div
            className={`mt-16 text-base text-black whitespace-nowrap max-md:mt-10 max-md:ml-2.5} ${styles.headers}`}>
            Your Certifications
          </div>

          <div
            style={{ position: "relative" }}
            className="flex justify-center items-center self-center h-[110px] px-16 py-7 mt-4 max-w-full text-center rounded-xl border border-dashed bg-red-500 bg-opacity-10 border-[color:var(--ff-3333,#F33)] leading-[153.3%] w-[100%] max-md:px-5">
            <input
              type="file"
              id="certi"
              name="certi"
              accept="image/png, image/jpeg, image/jpg, application/pdf"
              multiple={true}
              style={{ position: "absolute", opacity: 0 }}
              onChange={(e) => {
                console.log(e.target.files);
                changeInputHandler({
                  name: "certificates",
                  value: [...localData.certificates, ...e.target.files],
                });
              }}
            />
            <label
              htmlFor="certi"
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                opacity: 0,
                cursor: "pointer",
              }}
            />
            <div className="flex flex-col max-w-full w-[208px]">
              <img
                loading="lazy"
                src="/images/completeProfile/upload.png"
                className="self-center aspect-square w-[30px]"
              />
              <div className="mt-2 text-xs text-black ">
                <span className="underline text-red-500">Browse</span>
                <span className="text-black"> your file</span>
              </div>
              <div className="mt-1 text-xs text-neutral-400">
                Supports JPG, JPEG, PNG, PDF
              </div>
            </div>
          </div>

          <div className="mt-5 text-base italic whitespace-nowrap max-md:ml-2.5">
            <span style={{ color: "gray" }}>
              Do not have any certifications? Here are few
            </span>
            <span className="font-bold text-red-500"> links</span>
          </div>
          <div className="flex flex-wrap gap-10">
            {localData.certificates &&
              localData.certificates.map((elem, ind) => (
                <div
                  key={`certiR${ind}`}
                  className="flex flex-col relative items-center pb-3 mt-5 h-[auto] max-w-full bg-sky-200 rounded-xl w-[174px] max-md:ml-2.5">
                  <div className=" absolute top-[-7px] end-[-7px] ">
                    <img
                      style={{ cursor: "pointer" }}
                      loading="lazy"
                      src="/images/completeProfile/red_cross.png"
                      className="w-6 aspect-[0.95]"
                      onClick={() => {
                        let x = [...localData.certificates];
                        x.splice(x.indexOf(elem), 1);

                        changeInputHandler({
                          name: "certificates",
                          value: x,
                        });
                      }}
                    />
                  </div>
                  <div className="flex justify-center items-center self-stretch px-16 py-5 bg-white rounded-xl border-4 border-sky-50 border-solid max-md:px-5">
                    <img
                      loading="lazy"
                      src="/images/completeProfile/pdf.png"
                      className="w-5 aspect-[0.95]"
                    />
                  </div>
                  <div className="mt-1 text-xs leading-4 text-black">
                    {elem.name.substring(0, 15) + "..."}
                  </div>
                  <div className=" text-xs leading-4 whitespace-nowrap text-stone-500">
                    {(elem.size / 1024).toFixed(2)} KB
                  </div>
                </div>
              ))}
          </div>
        </div>

        <Button
          type="text"
          style={{ height: "auto" }}
          onClick={submitHandler}
          className="justify-center self-center px-5 py-5 mt-12 w-[50%] text-sm text-center text-black bg-amber-300 rounded-[92.207px] max-md:mt-10 max-md:max-w-full">
          Save & Next
        </Button>
      </div>
    </div>
  );
}
export default Cp4;
