import React, { useState } from "react";
import Footer from "../components/landing/Footer";
import { Link, useNavigate } from "react-router-dom";
import { ensureEmail, ensureString } from "../utils/inputFormatter";
import { Button, Modal } from "antd";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { deleteAccountInt, signInApi, verifyApi } from "../axios/axios";
import { Bounce, toast } from "react-toastify";
import { Padding } from "@mui/icons-material";
import OTPInput from "react-otp-input";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch } from "react-redux";
import { logout } from "../redux/userSlice";

function DeleteAcc() {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [showOtp, setshowOtp] = useState(false);
  const [refId, setRefId] = useState("");
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    if (e.target.name === "email" || e.target.name === "password") {
      e.target.value = ensureEmail({ value: e.target.value });
    }

    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState(""); // State to hold OTP values

  const delteAccount = (e) => {
    if (!user.email || user.email == "") {
      toast.error("Enter Field Empty!");
      return;
    } else {
      setShowModal(true);
    }
  };

  //   const togglePasswordVisibility = () => {
  //     setPasswordVisible(!passwordVisible);
  //   };

  const loginFunc = async () => {
    try {
      setLoading(true);
      const data = await deleteAccountInt({ email: user?.email });
      toast.success(data.data.message);
      setRefId(data.data?.data?.refId);
      setshowOtp(true);
      setShowModal(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
      setShowModal(false);
      setLoading(false);
    }
  };

  const DelAcc = async () => {
    try {
      setLoading(true);
      const data = await verifyApi({ otp, refId, type: 3 });
      toast.success(data.data.message);
      dispatch(logout());
      navigate("/");
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
      setShowModal(false);
      setLoading(false);
    }
  };

  return (
    <div className="h-[100vh] overflow-x-hidden">
      <header className="pb-[20px] px-[30px] text-white flex justify-end gap-[20px] bg-stone-500 h-[10%] border-b-4 border-[#FFCC42]">
        <img className="" src="./images/barooom.png" alt="logo" />
      </header>
      <div className="h-[60%] flex justify-center items-center">
        <AnimatePresence>
          {!showOtp ? (
            <motion.div
              key="showotp"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="flex justify-center items-center relative flex-col w-[25%] bg-white shadow-xl rounded-xl p-4 h-[80%]">
              <img
                className="h-20 absolute top-0 translate-y-[-50%]"
                src="./images/barooom.png"
                alt="logo"
              />
              <h1 className="font-Righteous text-xl">
                You are about to delete your account
              </h1>

              <div className="flex gap-5 justify-between items-center self-stretch px-3 py-2 ml-7 mr-7 mt-3.5 text-sm bg-white border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                <img
                  loading="lazy"
                  src="/images/person.png"
                  className="my-auto aspect-[1.03] w-[25px]"
                  alt="person"
                />
                <div className="flex flex-col flex-1 max-md:max-w-full">
                  <div className="text-black max-md:max-w-full">
                    Enter Email ID or Phone Number
                  </div>
                  <div className="mt-1 text-zinc-500 max-md:max-w-full">
                    <input
                      className="border-none outline-none w-full"
                      type="text"
                      name="email"
                      value={user.email}
                      onChange={handleInputChange}
                      placeholder="Your email address or phone number"
                    />
                  </div>
                </div>
              </div>
              {/* Password input */}
              {/* <div className="flex gap-5 justify-between items-center self-stretch px-3 py-2 ml-7 mr-7 mt-3.5 text-sm bg-white border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
            <img
              loading="lazy"
              src="/images/lock.png"
              className="self-stretch my-auto aspect-[1.03] w-[25px]"
              alt="lock"
            />
            <div className="flex flex-col flex-1 px-0 self-stretch max-md:max-w-full">
              <div className="text-black max-md:max-w-full">Password</div>

              <div className="mt-1 text-zinc-500 max-md:max-w-full">
                <input
                  className="border-none outline-none w-full"
                  type={passwordVisible ? "text" : "password"} // Conditionally set input type based on password visibility
                  name="password"
                  value={user.password}
                  onChange={handleInputChange}
                  placeholder="Enter your password"
                />
              </div>
            </div>
            {passwordVisible ? (
              <VisibilityOffIcon
                sx={{ color: "gray", cursor: "pointer" }}
                onClick={togglePasswordVisibility}
              />
            ) : (
              <VisibilityIcon
                sx={{ color: "gray", cursor: "pointer" }}
                onClick={togglePasswordVisibility}
              />
            )}
          </div> */}
              <Button
                loading={loading}
                type="text"
                style={{ height: "auto" }}
                onClick={delteAccount}
                className="justify-center p-3 w-[85%] mt-4 text-small text-center text-black bg-amber-300 rounded-[124.047px] max-md:px-5 max-md:max-w-full my-3">
                Delete Account
              </Button>
            </motion.div>
          ) : (
            <motion.div
              key="bartenderavail"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="flex justify-center items-center relative flex-col w-[25%] bg-white shadow-xl rounded-xl p-4 h-[80%]">
              <img
                className="h-20 absolute top-0 translate-y-[-50%]"
                src="./images/barooom.png"
                alt="logo"
              />
              <h1 className="font-Righteous text-xl">
                Enter otp to verify and delete your account
              </h1>
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={<span></span>}
                renderInput={(props) => (
                  <input
                    {...props}
                    style={{
                      height: "55px",
                      width: "55px",
                      textAlign: "center",
                      borderRadius: "50%",
                      border: "1px solid lightgray",
                      margin: "15px 5px",
                      outline: "none",
                      fontSize: "14px",
                    }}
                  />
                )}
              />
              <Button
                loading={loading}
                type="text"
                style={{ height: "auto" }}
                onClick={DelAcc}
                className="justify-center p-3 w-[85%] mt-4 text-small text-center text-black bg-amber-300 rounded-[124.047px] max-md:px-5 max-md:max-w-full my-3">
                Confirm Delete Account
              </Button>
            </motion.div>
          )}
        </AnimatePresence>

        <Modal
          open={showModal}
          centered
          sx={{
            color: "white",
          }}
          footer={null}
          onOk={() => console.log("THIS")}
          onCancel={() => setShowModal(false)}>
          <div className="h-32">
            <h1 className="text-xl font-Righteous">
              Are your sure you want to delete your account ?
            </h1>
            <div className="w-full flex justify-evenly pt-5">
              <buton
                className="text-lg p-4 rounded-xl border-[2px] border-yellow-300 hover:bg-stone-400  cursor-pointer"
                onClick={() => {
                  loginFunc();
                }}>
                Yes
              </buton>
              <buton
                className="text-lg p-4 rounded-xl border-[2px] border-yellow-300 hover:bg-stone-400 cursor-pointer"
                onClick={() => {
                  setShowModal(false);
                }}>
                No
              </buton>
            </div>
          </div>
        </Modal>
      </div>
      <Footer />
    </div>
  );
}

export default DeleteAcc;
