import { Link } from "react-router-dom";
import styles from "./cp.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { updateBartDesig } from "../../../axios/axios";
import { Bounce, toast } from "react-toastify";
import { updateUser } from "../../../redux/userSlice";
import { Button } from "antd";
function Cp2({ activeTab, setActiveTab, data }) {
  const user = useSelector((state) => state.user.user);
  const [apiLoader, setapiLoader] = useState(false);
  const [localData, setLocalData] = useState({
    designation: data.designation,
  });
  const dispatch = useDispatch();

  const submitHandler = () => {
    setapiLoader(true);

    updateBartDesig({ designation: localData.designation, token: user.token })
      .then((result) => {
        dispatch(updateUser({ accSetCount: user.accSetCount + 1 }));
        setActiveTab((prev) => prev + 1);
      })
      .catch((err) => {
        toast.error(err.message, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setapiLoader(false);

        console.log("err", err);
      });
  };

  const changeInputHandler = ({ name, value }) => {
    let x = { ...localData };
    if (Array.isArray(x[name])) {
      if (x[name].includes(value)) {
        x[name] = x[name].filter((item) => item !== value);
      } else {
        x[name].push(value);
      }
    } else {
      x[name] = [value];
    }

    setLocalData(x);
  };

  useEffect(() => {
    console.log("user", user);
  }, [user]);

  return (
    <div className="self-center px-5 mt-20  w-full max-w-[950px] align-center max-md:max-w-full">
      <div
        className={`self-stretch text-2xl leading-9 text-center text-black} ${styles.header}`}>
        Your Designation
      </div>
      <div className="mt-2.5 text-base whitespace-nowrap text-neutral-600">
        Select all that apply.
      </div>

      <div className="px-5 mt-10 max-w-[585px] ml-auto mr-auto">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
          <div
            className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full"
            style={{ cursor: "pointer" }}
            onClick={() =>
              changeInputHandler({
                name: "designation",
                value: "bartender",
              })
            }>
            <div
              className={
                localData.designation?.includes("bartender")
                  ? `flex flex-col px-6 py-7 mx-auto text-lg font-medium tracking-widest leading-8 text-center text-red-500 capitalize whitespace-nowrap rounded-2xl border-solid bg-red-500 bg-opacity-20 border-[3.032px] border-[color:var(--ff-3333,#F33)] h-[150px] w-[150px] max-md:px-5 max-md:mt-10`
                  : `flex flex-col px-3 py-5 mx-auto text-lg font-medium tracking-widest leading-8 text-center capitalize whitespace-nowrap bg-white rounded-2xl border-solid shadow-lg border-[1.516px] border-zinc-400 h-[150px] text-zinc-400 w-[150px] max-md:px-5 max-md:mt-10`
              }>
              <img
                loading="lazy"
                src={
                  localData.designation?.includes("bartender")
                    ? "/images/completeProfile/cocktail-glass.png"
                    : "/images/completeProfile/cocktail-glass-gray.png"
                }
                className="self-center w-15 aspect-[1.01]"
              />
              <div className="mt-1">Bartender</div>
            </div>
          </div>
          <div
            style={{ cursor: "pointer" }}
            className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full"
            onClick={() =>
              changeInputHandler({
                name: "designation",
                value: "bar-back",
              })
            }>
            <div
              className={
                localData.designation?.includes("bar-back")
                  ? `flex flex-col px-6 py-7 mx-auto text-lg font-medium tracking-widest leading-8 text-center text-red-500 capitalize whitespace-nowrap rounded-2xl border-solid bg-red-500 bg-opacity-20 border-[3.032px] border-[color:var(--ff-3333,#F33)] h-[150px] w-[150px] max-md:px-5 max-md:mt-10`
                  : `flex flex-col px-3 py-5 mx-auto text-lg font-medium tracking-widest leading-8 text-center capitalize whitespace-nowrap bg-white rounded-2xl border-solid shadow-lg border-[1.516px] border-zinc-400 h-[150px] text-zinc-400 w-[150px] max-md:px-5 max-md:mt-10`
              }>
              <img
                style={{ color: "red" }}
                loading="lazy"
                src="/images/completeProfile/barback.png"
                className="self-center w-20 aspect-[1.01]"
              />
              <div className="mt-1">Bar-Back</div>
            </div>
          </div>
          <div
            style={{ cursor: "pointer" }}
            className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full"
            onClick={() =>
              changeInputHandler({
                name: "designation",
                value: "server",
              })
            }>
            <div
              className={
                localData.designation?.includes("server")
                  ? `flex flex-col px-6 py-7 mx-auto text-lg font-medium tracking-widest leading-8 text-center text-red-500 capitalize whitespace-nowrap rounded-2xl border-solid bg-red-500 bg-opacity-20 border-[3.032px] border-[color:var(--ff-3333,#F33)] h-[150px] w-[150px] max-md:px-5 max-md:mt-10`
                  : `flex flex-col px-3 py-5 mx-auto text-lg font-medium tracking-widest leading-8 text-center capitalize whitespace-nowrap bg-white rounded-2xl border-solid shadow-lg border-[1.516px] border-zinc-400 h-[150px] text-zinc-400 w-[150px] max-md:px-5 max-md:mt-10`
              }>
              <img
                loading="lazy"
                src="/images/completeProfile/shutterstock.png"
                className="self-center w-20 aspect-[1.01]"
              />
              <div className="mt-1">Server</div>
            </div>
          </div>
        </div>
      </div>

      <Button
        loading={apiLoader}
        type={"text"}
        onClick={submitHandler}
        style={{ height: "auto" }}
        className="justify-center p-3 mt-10 mb-5 w-[60%] text-small text-center text-black bg-amber-300 rounded-[124.047px] max-md:px-5 max-md:max-w-full my-3 ">
        Save & Next
      </Button>
    </div>
  );
}
export default Cp2;
