import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  isUnread: false,
};

export const notificationSlice = createSlice({
  name: "isUnread",
  initialState,
  reducers: {
    checktrue(state) {
      state.isUnread = true;
    },
    checkfalse(state) {
      state.isUnread = false;
    },
  },
});

export const { checktrue, checkfalse } = notificationSlice.actions;

export default notificationSlice.reducer;
