import { useState, useEffect } from "react";
import WrapperDash from "../../dashboard/WrapperDash";
import style from "../setting.module.css";
import Dropdown from "../../dashboard/Bartender/Dropdown";
import Notification from "../../dashboard/Bartender/Notification";
import { Link, useNavigate } from "react-router-dom";
import * as React from "react";
import {
  getnotificationsetting,
  patchnotificationsetting,
} from "../../../axios/axios";
import { useSelector } from "react-redux";
import DashHeader from "../../dashboard/DashHeader";

function NotifictionSetting() {
  const user = useSelector((state) => state.user.user);
  const [showModal, setShowModal] = useState(false);
  const mainModal = <Dropdown></Dropdown>;
  const navigate = useNavigate();
  const [showModalnotify, setShowModalnotify] = useState(false);
  const mainModalnotify = <Notification></Notification>;
  const [notify, setNoitifyCheck] = useState({
    notification: false,
    session: false,
    message: false,
  });

  const handlesettingchange = (category) => {
    setNoitifyCheck({
      ...notify,
      [category]: !notify[category],
    });

    patchnotificationsetting({
      token: user?.token,
      value: category,
      category: !notify[category],
    })
      .then((result) => {
        console.log(result.data.data);
        let res = result.data.data;
        let x = {
          ...notify,
          notification: res.notification,
          session: res.session,
          message: res.message,
        };
        setNoitifyCheck(x);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getnotificationset = () => {
    getnotificationsetting({
      token: user?.token,
    })
      .then((result) => {
        let res = result.data.data;
        let x = {
          ...notify,
          notification: res.notification,
          session: res.session,
          message: res.message,
        };
        setNoitifyCheck(x);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getnotificationset();
  }, []);

  return (
    <div>
      {showModal && mainModal}
      {showModalnotify && mainModalnotify}
      <div className="flex flex-col grow shrink-0 basis-0 max-md:max-w-full max-w-full">
        <div className="flex flex-col px-5  bg-zinc-50 max-md:px-5 max-md:max-w-full">
          <DashHeader
            btn={
              <>
                <div
                  className="uppercase w-[60px] flex justify-center items-center border-2 border-black rounded-xl cursor-pointer"
                  onClick={() => navigate("/settings")}>
                  <span className="text-neutral-700">&lt;</span>{" "}
                </div>
              </>
            }
          />
          {/* <div className="shrink-0 mt-5 h-px border border-solid bg-zinc-100 border-zinc-300 max-md:max-w-full" /> */}
          <div className="flex flex-col px-14 pb-[100px] py-9 mt-8 bg-white rounded-3xl shadow-2xl max-md:px-5 max-md:max-w-full">
            <div className="flex flex-col max-w-full">
              <div className="flex flex-col items-center self-center max-w-full text-lg leading-7 text-black w-[763px]">
                <div className={`text-[22px] leading-8} ${style.header}`}>
                  Notification Settings
                </div>
                <div className="self-stretch mt-3.5 text-[16px] text-center leading-5 text-neutral-700 max-md:max-w-full">
                  Manage your alerts effortlessly. Use the toggle to turn
                  notifications on or off for chats, jobs, and general updates.
                </div>
                <div className="flex gap-5 px-9 py-5 mt-14 max-w-full whitespace-nowrap bg-white border border-gray-200 border-solid rounded-[129.664px] w-[533px] max-md:flex-wrap max-md:px-5 max-md:mt-10">
                  <div className="flex-auto text-[18px] my-auto">
                    All Notifications
                  </div>
                  <label>
                    <input
                      type="checkbox"
                      checked={notify.notification}
                      onChange={() => handlesettingchange("notification")}
                      class="accent-red-500 w-[24px] h-[24px]"
                    />
                  </label>
                </div>

                <div className="flex gap-5 px-9 py-5 mt-4 max-w-full whitespace-nowrap bg-white border border-gray-200 border-solid rounded-[129.664px] w-[533px] max-md:flex-wrap max-md:px-5 max-md:mt-10">
                  <div className="flex-auto text-[18px] my-auto">
                    Chat Notification
                  </div>
                  <label>
                    <input
                      type="checkbox"
                      checked={notify.message}
                      onChange={() => handlesettingchange("message")}
                      class="accent-red-500 w-[24px] h-[24px]"
                    />
                  </label>
                </div>
                <div className="flex gap-5 px-9 py-5 mt-4 max-w-full whitespace-nowrap bg-white border border-gray-200 border-solid rounded-[129.664px] w-[533px] max-md:flex-wrap max-md:px-5 max-md:mt-10">
                  <div className="flex-auto text-[18px] my-auto">
                    Job Notification
                  </div>
                  <label>
                    <input
                      type="checkbox"
                      checked={notify.session}
                      onChange={() => handlesettingchange("session")}
                      class="accent-red-500 w-[24px] h-[24px]"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotifictionSetting;
