import { createSlice } from "@reduxjs/toolkit";
// import { User } from '@/types';

export const initialState = {
  ntoken: null,
};

export const tokenslice = createSlice({
  name: "ntoken",
  initialState,
  reducers: {
    addtoken(state, action) {
      state.ntoken = action.payload;
    },
    removetoken(state) {
      state.ntoken = null;
    },
  },
});

export const { addtoken, removetoken } = tokenslice.actions;

export default tokenslice.reducer;
