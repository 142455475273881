import React from "react";
import configuration from "../../../../config/configuration";

function EstabPhotos({ Estabpic }) {
  console.log(Estabpic?.pic);

  return (
    <div>
      <p className=" flex flex-col mt-6 ml-7 text-sm text-neutral-400 max-md:ml-2.5">
        Establishment Images
      </p>
      <div className="flex flex-wrap gap-5 mt-4 ml-7">
        {Estabpic?.pic && Estabpic?.pic.length > 0
          ? Estabpic?.pic.map((elem, ind) => {
              return (
                <img
                  key={`Estab_Images_${ind}`}
                  width={{ width: "100%", height: "auto" }}
                  loading="lazy"
                  className="flex-1 shrink-0 max-w-[150px] aspect-[0.98] rounded-xl"
                  src={`${configuration.cdnFront}/${elem}`}
                  alt="Establishment Images"
                />
              );
            })
          : ""}
      </div>
    </div>
  );
}

export default EstabPhotos;
