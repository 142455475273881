import styles from "../../../eventDeatail.module.css";
import { TimeZoneList } from "../../../../../utils/List";
import AutoComplete from "../../../../AutoComplete";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ensureNumber } from "../../../../../utils/inputFormatter";

function EditEsta3({
  activejtab,
  setActiveJTab,
  handleChange,
  newestablishmentdata,
  handleLocationChange,
  handleAutoComplete,
}) {
  const [errormsg, setErrorMsg] = useState({});
  const [loading, setloading] = useState(true);

  const checkInput = ["latitude", "longitude"];
  const checkLocationInput = ["streetName", "city", "state", "postalCode"];

  const handleRegistration3 = () => {
    const errors = checkInput.reduce((acc, field) => {
      if (!newestablishmentdata[field] || newestablishmentdata[field] === "") {
        acc[field] = `${field} is required`;
      }
      return acc;
    }, {});

    const Locerrors = checkLocationInput.reduce((acc, field) => {
      if (
        !newestablishmentdata.location[field] ||
        newestablishmentdata.location[field] === ""
      ) {
        acc[field] = `${field} is required`;
      }
      return acc;
    }, {});
    if (Object.keys(errors).length > 0) {
      toast.error("Mandatory Fields Empty. Use Search Feature to Proceed");
      // toast.error('')
      setErrorMsg(errors);
    } else if (Object.keys(Locerrors).length > 0) {
      toast.error("Location Fields Empty");
      setErrorMsg({ ...errors, ...Locerrors });
    } else {
      setErrorMsg({});
      setActiveJTab(activejtab + 1);
    }
  };
  const prefilledTimeZone = newestablishmentdata.timeZone;
  const isPrefilledInList = TimeZoneList.some(
    (elem) => elem.value === prefilledTimeZone
  );

  return (
    <div className="flex relative justify-center items-center self-center px-16  py-12 w-full max-w-[1599px] max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col mx-[50px] items-center max-w-full w-[1300px]">
        <div
          className={`text-2xl leading-9 text-center text-black} ${styles.header}`}>
          Location
        </div>
        <div className="flex justify-center items-center py-5 mt-10 max-w-full text-center rounded-xl border border-dashed leading-[153.3%] w-[80%] h-fit">
          <AutoComplete
            handleAutoComplete={handleAutoComplete}
            setloading={setloading}
          />
        </div>
        {/* <div className="flex justify-center items-center px-16 py-5 mt-10 max-w-full text-center rounded-xl border border-dashed bg-red-500 bg-opacity-10 border-[color:var(--ff-3333,#F33)] leading-[153.3%] w-[80%] h-[120px] max-md:px-5 max-md:mt-10">
          <div className="flex flex-col items-center max-w-full w-[164px]">
            <img
              loading="lazy"
              src="/images/completeProfile/redLoction.png"
              className="aspect-square w-[31px]"
            />
            <div className="self-stretch mt-2.5 text-xs text-black">
              Auto Locate Your Location
            </div>
   
          </div>
        </div> */}
        {!loading && (
          <>
            {" "}
            <div className="mt-8 text-base font-bold text-black">OR</div>
            <div className="flex gap-5 justify-between self-stretch mt-7 text-xs max-md:flex-wrap max-md:max-w-full">
              <div className="flex flex-col flex-1 px-5 py-3.5 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:max-w-full">
                <div className="text-black text-left max-md:max-w-full">
                  Select Time Zone <span className="text-error">*</span>
                </div>
                <div className="mt-2 text-zinc-500 text-start max-md:max-w-full">
                  <select
                    className="w-full border-none outline-none"
                    name="timeZone"
                    value={newestablishmentdata.timeZone}
                    id="timeZone"
                    onChange={(e) => handleChange(e)}>
                    {!isPrefilledInList && prefilledTimeZone && (
                      <option value={prefilledTimeZone}>
                        {prefilledTimeZone}
                      </option>
                    )}
                    {TimeZoneList?.map((elem, ind) => (
                      <option key={`${elem.value}_${ind}`} value={elem.value}>
                        {elem.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="flex gap-5 justify-between self-stretch mt-7 text-xs max-md:flex-wrap max-md:max-w-full">
              <div
                className={`flex flex-col flex-1 px-5 py-3.5 bg-white ${
                  errormsg?.streetName ? "border-error" : "border-gray-200"
                } border-solid border-[0.922px] rounded-[92.207px] max-md:max-w-full`}>
                <div
                  className={`${
                    errormsg?.streetName ? "text-error" : "text-black"
                  } text-left max-md:max-w-full`}>
                  Street Name <span className="text-error">*</span>
                </div>
                <div className="mt-2 text-zinc-500 text-start max-md:max-w-full">
                  <input
                    className="border-none outline-none w-full"
                    type="text"
                    placeholder="Street Name"
                    id="streetName"
                    name="streetName"
                    value={newestablishmentdata.location.streetName}
                    onChange={(e) => {
                      if (errormsg?.streetName) {
                        delete errormsg["streetName"];
                      }
                      handleLocationChange(e);
                    }}
                  />
                </div>
              </div>
              <div
                className={`flex flex-col flex-1 px-5 py-3.5 whitespace-nowrap bg-white ${
                  errormsg?.city ? "border-error" : "border-gray-200"
                } border-solid border-[0.922px] rounded-[92.207px] max-md:max-w-full`}>
                <div
                  className={`${
                    errormsg?.city ? "text-error" : "text-black"
                  } text-left max-md:max-w-full`}>
                  City <span className="text-error">*</span>
                </div>
                <div className="mt-2 text-zinc-500 text-left max-md:max-w-full">
                  <input
                    className="border-none outline-none w-full"
                    type="text"
                    placeholder="City"
                    id="city"
                    name="city"
                    value={newestablishmentdata.location.city}
                    onChange={(e) => {
                      if (errormsg?.city) {
                        delete errormsg["city"];
                      }
                      handleLocationChange(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className={`flex gap-5 justify-between self-stretch mt-6 text-xs max-md:flex-wrap max-md:max-w-full`}>
              <div
                className={`flex flex-col flex-1 px-5 py-3.5 whitespace-nowrap bg-white ${
                  errormsg?.state ? "border-error" : "border-gray-200"
                } border-solid border-[0.922px] rounded-[92.207px] max-md:max-w-full`}>
                <div
                  className={`${
                    errormsg?.state ? "text-error" : "text-black"
                  } text-left max-md:max-w-full`}>
                  State <span className="text-error">*</span>
                </div>
                <div className="mt-2 text-zinc-500 text-left max-md:max-w-full">
                  <input
                    className="border-none outline-none w-full"
                    type="text"
                    placeholder="State"
                    id="state"
                    name="state"
                    value={newestablishmentdata.location.state}
                    onChange={(e) => {
                      if (errormsg?.state) {
                        delete errormsg["state"];
                      }
                      handleLocationChange(e);
                    }}
                  />
                </div>
              </div>
              <div
                className={`flex flex-col flex-1 px-5 py-3.5 bg-white ${
                  errormsg?.postalCode ? "border-error" : "border-gray-200"
                } border-solid border-[0.922px] rounded-[92.207px] max-md:max-w-full`}>
                <div
                  className={`${
                    errormsg?.postalCode ? "text-error" : "text-black"
                  } text-left max-md:max-w-full`}>
                  Postal Code <span className="text-error">*</span>
                </div>
                <div className="mt-2 text-zinc-500 text-left max-md:max-w-full">
                  <input
                    className="border-none outline-none w-full"
                    type="text"
                    placeholder="Postal Code"
                    id="postalCode"
                    name="postalCode"
                    value={newestablishmentdata.location.postalCode}
                    onChange={(e) => {
                      if (errormsg?.postalCode) {
                        delete errormsg["postalCode"];
                      }

                      handleLocationChange(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        <button
          onClick={() => {
            handleRegistration3();
          }}
          className="justify-center px-5 py-5 mt-20 w-[60%] text-sm text-center text-white bg-red-500 rounded-[92.207px] max-md:mt-10 max-md:max-w-full">
          Save & Next
        </button>
      </div>
    </div>
  );
}
export default EditEsta3;
