import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthenticatedWithPending = ({ children }) => {
    const user = useSelector((state) => state.user.user);
    const navigate = useNavigate();

    const [allowView, setAllowView] = useState(false);

    useEffect(() => {
        if (user === null) {
            navigate("/");
        } else {
            if (!user.profileSetup) {
                navigate("/setup");
            }
            else if(user.account_status == 5){
                setAllowView(true);
            }
            else {
                navigate("/dashboard");
            }
        }
    }, [user]);

    return (
        <>
            {allowView && children}
        </>
    );
};

export default AuthenticatedWithPending;
