import dayjs from "dayjs";
import React, { useState } from "react";
import { useSwipeable } from 'react-swipeable';

const useSwipeHandlerNoLimit = ({ onPanelChange, panel }) => {
  return useSwipeable({
    onSwipedLeft: () => onPanelChange(dayjs(panel).add(1, "month")),
    onSwipedRight: () => onPanelChange(dayjs(panel).subtract(1, "month")),
  });
};

export default useSwipeHandlerNoLimit;
