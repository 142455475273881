import React from "react";
import style from "./Register.module.css";
import { Link } from "react-router-dom/dist";

const Register = () => {
  return (
    <>
      <div className={style.main}>
        <div className={style.left}>
          <form action="">
            <div className={style.formBox}>
              {/* <h1>Register Now!</h1>
              <input type="text" placeholder="Enter Your Name" />
              <input type="email" placeholder="Enter Your Email ID" />
              <input type="number" placeholder="Enter Your Phone Number" />
              <textarea
              className={style.myTextArea}
                cols={30}
                rows={6}
                placeholder="Other Message"
              ></textarea> */}
              <Link to={"/signup"}>
                <button className={style.btn}>Register Now</button>
              </Link>
            </div>
          </form>
        </div>
        <div className={style.right}>
          <div className={style.Innerright}>
            <img className={style.iphoneImg} src={"/Register/Register/iPhone.png"} alt="" />
            <img src={"/Register/Register/background.png"} alt="" />
            <img className={style.glassImg} src={"/Register/Register/Glass.png"} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
