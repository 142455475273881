import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "../../profile.module.css";
import {
  fetchEstablishmentDetails,
  updateEstablishmentBasic,
  updateEstablishmentMore,
} from "../../../../axios/axios";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  CustomerRangeList,
  TimeSlotsList,
  YearRangeList,
} from "../../../../utils/List";

function EditMore() {
  const { id } = useParams();
  const user = useSelector((state) => state.user?.user);
  const [estabbasic, setEstabBasic] = useState({
    customerFlow: "",
    accommodates: "",
    yearsOfExperience: "",
    websiteLink: "",
    eid: id || "",
  });

  const navigate = useNavigate();

  const fetchEstabDetails = () => {
    fetchEstablishmentDetails({ token: user?.token, eid: id })
      .then((res) => {
        if (res.data) {
          const { customerFlow, accommodates, yearsOfExperience, websiteLink } =
            res.data;
          setEstabBasic((prevState) => ({
            ...prevState,
            customerFlow: customerFlow || prevState.customerFlow,
            accommodates: accommodates || prevState.accommodates,
            yearsOfExperience: yearsOfExperience || prevState.yearsOfExperience,
            websiteLink: websiteLink || prevState.websiteLink,
          }));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = () => {
    const formdata = new FormData();
    formdata.append("customerFlow", estabbasic.customerFlow);
    formdata.append("accommodates", estabbasic.accommodates);
    formdata.append("yearsOfExperience", estabbasic.yearsOfExperience);
    formdata.append("websiteLink", estabbasic.websiteLink);
    formdata.append("eid", estabbasic.eid);
    updateEstablishmentMore({ token: user?.token, formdata })
      .then((res) => {
        console.log(res);
        toast.success(res.message);
        navigate(`/establishment-details/${id}`);
      })
      .catch((err) => toast.error(err?.message));
  };

  useEffect(() => {
    if (!id) return;

    fetchEstabDetails();
  }, []);

  return (
    <div>
      <img
        loading="lazy"
        src="/images/completeProfile/complete_Profile_backImg.png"
        className="object-cover fixed inset-0 size-full"
      />

      <div
        style={{
          backgroundImage: `url('/images/headerBackground.svg')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "0 0 25px 25px",
        }}
        className="flex overflow-hidden relative px-20 text-2xl w-[100%] text-white min-h-[100px] rounded-b-3xl max-md:flex-wrap max-md:px-5">
        <div className="my-auto flex min-w-full">
          <div
            onClick={() => navigate(`/establishment-details/${id}/`)}
            className="border-2 px-4 rounded-[10px] py-1 mr-5 cursor-pointer">
            &#10094;
          </div>
          <span className={`${styles.header} `}>Edit Your Profile</span>
        </div>
      </div>
      <div className="flex relative justify-center items-center self-center px-16 py-10 mx-auto my-7 rounded-[20px] w-full bg-white text-xs max-w-[1099px] max-md:px-5 max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-col max-w-full w-[1299px]">
          <div
            className={`self-center text-xl leading-9 text-center text-black max-md:max-w-full} ${styles.header}`}>
            Tell Us More About Your Establishment
          </div>

          <div className="flex  justify-between  mt-5 gap-7 max-md:flex-wrap max-md:max-w-full">
            <div className="flex gap-4 justify-between w-full px-5 py-3.5 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full">
              <img
                loading="lazy"
                src="/images/completeProfile/drink_glass.png"
                className="my-auto aspect-square w-[23px]"
              />
              <div className="flex flex-col  flex-1 max-md:max-w-full">
                <div className="text-black text-left max-md:max-w-full">
                  Peak Hours <span className="text-establishment-red">*</span>
                </div>
                <div className="mt-2 text-zinc-500 text-left max-md:max-w-full">
                  <select
                    className="w-full border-none outline-none"
                    type="text"
                    placeholder="Type of service"
                    value={estabbasic.customerFlow}
                    onChange={(e) =>
                      setEstabBasic({
                        ...estabbasic,
                        customerFlow: e.target.value,
                      })
                    }>
                    {TimeSlotsList?.map((elem, ind) => (
                      <option key={`${elem.value}_${ind}`} value={elem.value}>
                        {elem.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="flex gap-4 justify-between w-full px-5 py-3.5 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full">
              <img
                loading="lazy"
                src="/images/completeProfile/dressCode.png"
                className="my-auto aspect-square w-[22px]"
              />
              <div className="flex flex-col flex-1 max-md:max-w-full">
                <div className="text-black text-left max-md:max-w-full">
                  Accommodates <span className="text-establishment-red">*</span>
                </div>
                <div className="mt-2 text-zinc-500 text-left max-md:max-w-full">
                  <select
                    className="w-full border-none outline-none"
                    type="text"
                    placeholder="Dress Code (Optional)"
                    value={estabbasic.accommodates}
                    onChange={(e) =>
                      setEstabBasic({
                        ...estabbasic,
                        accommodates: e.target.value,
                      })
                    }>
                    {CustomerRangeList?.map((elem, ind) => (
                      <option key={`${elem.value}_${ind}`} value={elem.value}>
                        {elem.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="flex  justify-between  mt-5 gap-7 max-md:flex-wrap max-md:max-w-full">
            <div className="flex gap-4 justify-between w-full px-5 py-3.5 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full">
              <img
                loading="lazy"
                src="/images/completeProfile/portFolio.png"
                className="my-auto aspect-square w-[23px]"
              />
              <div className="flex flex-col  flex-1 max-md:max-w-full">
                <div className="text-black text-left max-md:max-w-full">
                  Website Link
                </div>
                <div className="mt-2 text-zinc-500 text-left max-md:max-w-full">
                  <input
                    className="border-none outline-none"
                    type="text"
                    placeholder="Website Link"
                    value={estabbasic.websiteLink}
                    onChange={(e) =>
                      setEstabBasic({
                        ...estabbasic,
                        websiteLink: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-4 justify-between w-full px-5 py-3.5 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full">
              <img
                loading="lazy"
                src="/images/completeProfile/solarStar.png"
                className="my-auto aspect-square w-[22px]"
              />
              <div className="flex flex-col flex-1 max-md:max-w-full">
                <div className="text-black text-left max-md:max-w-full">
                  Number of years in business{" "}
                  <span className="text-establishment-red">*</span>
                </div>
                <div className="mt-2 text-zinc-500 text-left max-md:max-w-full">
                  <select
                    className="w-full border-none outline-none"
                    type="text"
                    placeholder="Number of years in business"
                    value={estabbasic.yearsOfExperience}
                    onChange={(e) =>
                      setEstabBasic({
                        ...estabbasic,
                        yearsOfExperience: e.target.value,
                      })
                    }>
                    {YearRangeList?.map((elem, ind) => (
                      <option key={`${elem.value}_${ind}`} value={elem.value}>
                        {elem.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() => handleSubmit()}
            className="justify-center self-center px-5 py-5 mt-16 cursor-pointer w-[60%] text-sm text-center text-white bg-red-500 rounded-[92.207px] max-md:mt-10 max-md:max-w-full">
            Save
          </div>
        </div>
      </div>
    </div>
  );
}
export default EditMore;
