import React from "react";
import ViewProfile from "../components/Profile/Bartender/ViewProfile";
import { useSelector } from "react-redux";
import ViewBartenderProfile from "../components/Profile/Establishment/ViewBartenderProfile";

function ViewUser() {
  const user = useSelector((state) => state.user.user);
  return (
    <>
      {user && (
        <>
          {user.role === 1 && <ViewProfile />}
          {user.role === 2 && <ViewBartenderProfile />}
        </>
      )}
    </>
  );
}

export default ViewUser;
