import { createSlice } from "@reduxjs/toolkit";
// import { User } from '@/types';

export const initialState = {
  user: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login(state, action) {
      //const { user, keepLogged } = action.payload;

      state.user = action.payload;

      const userString = JSON.stringify(state.user);
      // if (keepLogged) {
      localStorage.setItem("bsuser", userString);
      // } else {
      // sessionStorage.setItem("bsuser", userString);
      //}
    },
    logout(state) {
      state.user = null;
      sessionStorage.removeItem("bsuser");
      localStorage.removeItem("bsuser");
    },
    updateUser(state, action) {
      state.user = { ...state.user, ...action.payload };
      const userString = JSON.stringify(state.user);
      if (localStorage.getItem("bsuser")) {
        localStorage.setItem("bsuser", userString);
      } else {
        sessionStorage.setItem("bsuser", userString);
      }
    },
  },
});

export const { login, logout, updateUser } = userSlice.actions;

export default userSlice.reducer;
