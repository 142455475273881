import { useState, useRef, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";

function InternalBar({ tab, onClick, NavItems, type }) {
  const [indicatorPosition, setIndicatorPosition] = useState({
    left: 0,
    width: 0,
  });
  const itemRefs = useRef([]);

  useEffect(() => {
    if (itemRefs.current[tab]) {
      const { offsetLeft, offsetWidth } = itemRefs.current[tab];
      setIndicatorPosition({ left: offsetLeft, width: offsetWidth });
    }
  }, [tab]);

  const handleClick = (index) => {
    onClick(index);
  };

  return (
    <div className="flex relative m-auto p-[8px] justify-center w-fit text-base text-center bg-white border border-gray-200 border-solid rounded-[100px] text-neutral-700 ">
      <AnimatePresence>
        <motion.div
          key="indicator"
          style={{
            position: "absolute",
            backgroundColor: "#FF3333",
            borderRadius: "100px",
          }}
          className="h-[80%] self-center my-6"
          initial={{ width: 0, left: 0 }}
          animate={{
            width: indicatorPosition.width,
            left: indicatorPosition.left,
          }}
          exit={{ width: 0, left: 0 }}
          transition={{ duration: 0.3 }}
        />
        <div className="flex w-full max-w-full">
          {NavItems?.map((elem, ind) => {
            const isActive = tab === ind;
            return (
              <motion.div
                key={`Nav_${ind}`}
                ref={(el) => (itemRefs.current[ind] = el)}
                style={{
                  padding: `${type != 3 ? "10px 25px" : "5px 15px"}`,
                  position: "relative",
                }}
                onClick={() => handleClick(ind)}
                className={`flex items-center gap-2 w-fit ${type != 3 ? "text-[14px]" : "text-[10px]"
                  } h-10 rounded-[100px] cursor-pointer `}>
                {type != 3 && (
                  <img
                    loading="lazy"
                    src={elem?.imgSrc || "/images/completeProfile/cocktail.png"}
                    style={{
                      width: `${type != 3 ? "25px" : "15px"}`,
                      height: `${type != 3 ? "25px" : "15px"}`,
                    }}
                    className="hidden lg:block"
                  />
                )}
                <p
                  className={`whitespace-nowrap transition-colors duration-100 delay-100 ${isActive ? "text-white" : "text-black"
                    }`}>
                  {elem?.label}
                </p>
              </motion.div>
            );
          })}
        </div>
      </AnimatePresence>
    </div>
  );
}

export default InternalBar;
