import { getMessaging, onMessage } from "firebase/messaging";
import { useDispatch } from "react-redux";
import { checktrue } from "../redux/notificationSlice";

/**
 * Sets up a listener for Firebase notifications and executes callback functions based on the notification type.
 *
 * @param {Function} onChatMessage - Function to handle chat messages.
 * @param {Function} onPaymentRedirect - Function to handle payment redirects.
 * @param {Function} onSystemNotification - Function to handle system or announcement notifications.
 */

const setupNotificationListener = ({
  onChatMessage,
  handlePaymentstatus,
  onSystemNotification,
  onNotification,
}) => {
  const messaging = getMessaging();
  const handleNotification = (payload) => {
    let { type, Msg, path } = payload.data;
    console.log("Received Foreground message ");

    if (path) {
      try {
        path = JSON.parse(path);
      } catch (error) {
        console.log("Invalid JSON String Passed!");
      }
    }

    if (
      type != "payment_action" &&
      type != "System" &&
      type != "payment_success"
    ) {
      if (onNotification) onNotification({ isNotify: true });
    }
    switch (type) {
      case "Chat":
        if (onChatMessage) onChatMessage({ data: payload.data });
        break;
      case "payment_success":
        if (handlePaymentstatus) handlePaymentstatus({ status: true, Msg });
        break;
      case "payment_action":
        if (handlePaymentstatus)
          handlePaymentstatus({ status: false, url: Msg });
        break;
      case "System":
        if (onSystemNotification) onSystemNotification(Msg);
        break;

      default:
        console.log("Unknown notification type:", type);
    }
  };

  onMessage(messaging, handleNotification);
};

export default setupNotificationListener;
