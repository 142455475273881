import styles from "./cp.module.css";
import { useEffect, useState } from "react";
import { Button, Select } from "antd";
import { updateBartSkills } from "../../../axios/axios";
import { Bounce, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../redux/userSlice";

const Cp3 = ({ activeTab, setActiveTab, data }) => {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const [apiLoader, setapiLoader] = useState(false);

  const [localData, setLocalData] = useState({
    skills: data.skills,
    professionalSports: data.professionalSports,
    collegiateSports: data.collegiateSports,
    festivals: data.festivals,
    others: data.others,
  });

  const [suggestedSkills, setSuggestSkills] = useState([
    "Bartending",
    "Barback",
    "FOH Support",
    "Mixologist",
    "Host",
    "Cashier",
    "Server",
  ]);
  const [professionalSports, setProfessionalSports] = useState([
    "Football",
    "Basketball",
    "Soccer",
    "Baseball",
    "Hockey",
  ]);
  const [collegiateSports, setCollegiateSports] = useState([
    "Football",
    "Basketball",
    "Soccer",
    "Baseball",
    "Hockey",
  ]);
  const [festivals, setFestivals] = useState(["Art", "Food & Beverage"]);
  const [others, setOthers] = useState([
    "Theatre/Plays",
    "Sports, Bars & Restaurants",
    "Neighborhood & Restaurants",
  ]);

  const submitHandler = () => {
    setapiLoader(true);

    updateBartSkills({ data: localData, token: user.token })
      .then((result) => {
        setapiLoader(false);

        dispatch(updateUser({ accSetCount: user.accSetCount + 1 }));
        setActiveTab((prev) => prev + 1);
      })
      .catch((err) => {
        toast.error(err.message, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setapiLoader(false);

        console.log("err", err);
      });
  };

  const changeInputHandler = ({ name, value }) => {
    let x = { ...localData };
    x[name] = value;

    setLocalData(x);
  };

  const checkBoxHandler = ({ checked, key, value }) => {
    if (checked) {
      if (localData[key].indexOf(value) === -1) {
        setLocalData((prev) => ({
          ...prev,
          [key]: [...prev[key], value],
        }));
      }
    } else {
      let x = { ...localData };
      x[key].splice(x[key].indexOf(value), 1);

      setLocalData(x);
    }
  };
  return (
    <div className="flex relative justify-center items-center self-center px-16 py-9  w-full rounded-3xl bg-white bg-opacity-80 max-w-[1599px] max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col items-center max-w-full w-[1301px]">
        <div className={`text-2xl leading-9 text-center  } ${styles.header}`}>
          Your Skills & Interest
        </div>
        <div className="mt-3.5 text-base whitespace-nowrap text-neutral-600">
          We would like to know your skills & interests.
        </div>

        <div className="flex gap-4 justify-between self-stretch px-5 py-3.5 mt-8 text-xs bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full">
          <img
            loading="lazy"
            src="/images/completeProfile/pen.png"
            className="my-auto aspect-square w-[23px]"
          />
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div className="text-left text-black max-md:max-w-full">
              Add Your Skills
            </div>
            <div className="mt-2 text-left text-zinc-500 max-md:max-w-full">
              <Select
                variant="borderless"
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Tags Mode"
                onChange={(val) =>
                  changeInputHandler({
                    name: "skills",
                    value: val,
                  })
                }
                value={localData.skills}
                options={[]}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col self-stretch p-3.5 mt-4 text-black bg-white rounded-2xl shadow-md max-md:max-w-full">
          <div className="flex gap-5 justify-between px-0.5 text-sm font-medium max-md:flex-wrap max-md:max-w-full">
            <div className="flex-auto pl-1 my-auto text-left">
              Suggested Skills
            </div>
            <img
              loading="lazy"
              src="/images/completeProfile/cross.png"
              className="w-6 aspect-square"
            />
          </div>
          <div className="flex gap-2.5 self-start px-px mt-2.5 text-xs max-md:flex-wrap max-md:max-w-full">
            {suggestedSkills.map((elem, ind) => (
              <button
                key={`skl${ind}`}
                onClick={(e) => {
                  if (localData.skills.indexOf(e.target.value) === -1) {
                    changeInputHandler({
                      name: "skills",
                      value: [...localData.skills, e.target.value],
                    });
                  }
                }}
                value={`${elem}`}
                style={{ background: "gray", color: "white" }}
                className="grow justify-center px-4 py-2.5 whitespace-nowrap bg-neutral-100 rounded-[100px]">
                {elem}
              </button>
            ))}
          </div>
        </div>
        <div
          className={`mt-16 text-2xl leading-9 text-center text-black capitalize max-md:mt-10 ${styles.header}`}>
          Choose your interest
        </div>
        <div className="mt-4 text-base whitespace-nowrap text-neutral-600">
          We would like to know your skills & interests.
        </div>
        <div className="flex items-center self-stretch justify-center px-16 mt-5 bg-white shadow-lg py-7 rounded-2xl max-md:px-5 max-md:max-w-full">
          <div className="max-w-full w-[1060px]">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
              <div className="flex flex-col w-[45%] max-md:ml-0 max-md:w-full">
                <div className="px-px grow max-md:mt-10">
                  <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
                    <div className="flex flex-col w-[63%] max-md:ml-0 max-md:w-full">
                      <div className="flex flex-col text-sm text-black grow max-md:mt-10">
                        <div className="text-base text-left whitespace-nowrap text-neutral-500">
                          Professional Sports
                        </div>

                        {professionalSports.map((elem, ind) => (
                          <div
                            key={`pro${ind}`}
                            className="flex gap-3 mt-4 whitespace-nowrap">
                            <div className="flex w-full">
                              <div className="flex w-1/6">
                                <input
                                  id={`pro${ind}`}
                                  className="w-5 h-5 border border-solid rounded-md aspect-square border-neutral-400"
                                  type="checkbox"
                                  value={elem}
                                  onChange={(e) =>
                                    checkBoxHandler({
                                      checked: e.target.checked,
                                      key: "professionalSports",
                                      value: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <label
                                htmlFor={`pro${ind}`}
                                className="flex-auto my-auto text-left">
                                {elem}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="flex flex-col ml-5 w-[37%] max-md:ml-0 max-md:w-full">
                      <div className="flex flex-col text-sm text-black grow max-md:mt-10">
                        <div className="text-base text-left whitespace-nowrap text-neutral-500">
                          Collegiate Sports
                        </div>
                        {collegiateSports.map((elem, ind) => (
                          <div
                            key={`pro${ind}`}
                            className="flex gap-3 mt-4 whitespace-nowrap">
                            <div className="flex w-full">
                              <div className="flex w-1/6">
                                <input
                                  id={`colg${ind}`}
                                  className="w-5 h-5 border border-solid rounded-md border-neutral-400"
                                  type="checkbox"
                                  value={elem}
                                  onChange={(e) =>
                                    checkBoxHandler({
                                      checked: e.target.checked,
                                      key: "collegiateSports",
                                      value: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <label
                                htmlFor={`colg${ind}`}
                                className="flex-auto my-auto text-left">
                                {elem}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col ml-5 w-[55%] max-md:ml-0 max-md:w-full">
                <div className="px-px max-md:mt-10 max-md:max-w-full">
                  <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
                    <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                      <div className="flex flex-col text-sm text-black whitespace-nowrap max-md:mt-10">
                        <div className="text-base text-left text-neutral-500">
                          Festivals
                        </div>
                        {festivals.map((elem, ind) => (
                          <div
                            key={`pro${ind}`}
                            className="flex justify-between gap-3 mt-4 whitespace-nowrap">
                            <div className="flex w-full">
                              <div className="flex w-1/6">
                                <input
                                  id={`fest${ind}`}
                                  className="w-5 h-5 border border-solid rounded-md border-neutral-400"
                                  type="checkbox"
                                  value={elem}
                                  onChange={(e) =>
                                    checkBoxHandler({
                                      checked: e.target.checked,
                                      key: "festivals",
                                      value: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <label
                                htmlFor={`fest${ind}`}
                                className="flex-auto my-auto text-left">
                                {elem}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                      <div className="flex flex-col text-sm text-black grow whitespace-nowrap max-md:mt-10">
                        <div className="text-base text-left text-neutral-500">
                          Others
                        </div>
                        {others.map((elem, ind) => (
                          <div
                            key={`pro${ind}`}
                            className="flex justify-between gap-3 mt-4 whitespace-nowrap">
                            <div className="flex w-full">
                              <div className="flex w-1/6">
                                <input
                                  id={`oth${ind}`}
                                  className="w-5 h-5 border border-solid rounded-md border-neutral-400"
                                  type="checkbox"
                                  value={elem}
                                  onChange={(e) =>
                                    checkBoxHandler({
                                      checked: e.target.checked,
                                      key: "others",
                                      value: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <label
                                htmlFor={`oth${ind}`}
                                className="flex-auto my-auto text-left">
                                {elem}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Button
          loading={apiLoader}
          type="text"
          style={{ height: "auto" }}
          onClick={submitHandler}
          className="justify-center px-5 py-5 mt-10 text-sm w-[55%] text-center text-black bg-amber-300 rounded-[92.207px] max-md:max-w-full">
          Save & Next
        </Button>
      </div>
    </div>
  );
};
export default Cp3;
