import { useState } from "react";
import styles from '../../../styles/createPass.module.css'
import Wrapper from "../../authWrapper/Wrapper";
import { useNavigate } from "react-router-dom";
import { resetPassApi } from "../../../axios/axios";// Import your resetPassApi function
import { useLocation } from "react-router-dom";
import { Button } from "antd";

export default function CreatePass(props) {
  // const { state } = useLocation();
  // console.log("After otp verified  ", state.data.token);
  const navigate = useNavigate();
  const [password, setPassword] = useState(""); // State for password
  const [confirmPassword, setConfirmPassword] = useState(""); // State for confirm password
  const [error,setError] = useState("");

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = () => {
    // Check if passwords match

    props.resetPassword(password,confirmPassword);

    // Call the resetPassApi function
  
  };

  return (
    <Wrapper>
      <div className="flex flex-col p-4 items-center bg-white border-solid border-[5.241px] w-[450px] border-[color:var(--ffcc-42,#FFCC42)] rounded-[41.924px] max-md:px-5 max-md:max-w-full">
        <div className={styles.header}>
          Create New Password
        </div>
        <section className="mt-5 text-sm text-center text-neutral-600 max-md:max-w-full">
          <p>Your new password must be different from previously used passwords.</p>
        </section>
        <div className="flex gap-5 justify-between items-center self-stretch px-3 py-2 ml-7 mr-7 mt-3.5 text-sm bg-white border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <img
            loading="lazy"
            src="/images/lock.png"
            className="self-stretch my-auto aspect-[1.03] w-[25px]"
          />
          <div className="flex flex-col self-stretch flex-1 px-0 max-md:max-w-full">
            <div className="text-black max-md:max-w-full">New Password</div>
            <div className="mt-1 text-zinc-500 max-md:max-w-full ">
              <input
                className="w-full border-none outline-none"
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={handlePasswordChange}
              />
            </div>
          </div>
          {/* <img
            loading="lazy"
            src="/images/eye.png"
            className="self-stretch my-auto aspect-[1.03] w-[25px]"
          /> */}
        </div>
        <div className="flex gap-5 justify-between items-center self-stretch px-3 py-2 ml-7 mr-7 mt-3.5 text-sm bg-white border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <img
            loading="lazy"
            src="/images/lock.png"
            className="self-stretch my-auto aspect-[1.03] w-[25px]"
          />
          <div className="flex flex-col self-stretch flex-1 px-0 max-md:max-w-full">
            <div className="text-black max-md:max-w-full">Confirm New Password</div>
            <div className="mt-1 text-zinc-500 max-md:max-w-full ">
              <input
                className="w-full border-none outline-none"
                type="password"
                placeholder="Confirm your password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
            </div>
          </div>
          {/* <img
            loading="lazy"
            src="/images/eye.png"
            className="self-stretch my-auto aspect-[1.03] w-[25px]"
          /> */}
        </div>
        <Button
        loading={props.loading}
          type="text"
          style={{height:"auto"}}
          onClick={handleSubmit}
          className="justify-center w-[85%] p-3 mt-4 text-small text-center text-black bg-amber-300 rounded-[124.047px] max-md:px-5 max-md:max-w-full my-3"
        >
         Reset Password
        </Button>
      </div>
    </Wrapper>
  );
}


