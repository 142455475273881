import * as React from "react";
import { useSelector } from "react-redux";
import BartenderWrapperDash from "../components/dashboard/WrapperDash";
import EstablishmentMains from "../components/dashboard/Establishment/mains/mains";
import EstabProfileE from "../components/DropCompo/EstablishmentUser/EstabProfileE";
import ProfileEst from "../components/Profile/Establishment/ProfileEst";
import MainProfile from "../components/Profile/Bartender/MainProfile";

function Profile() {
  const user = useSelector((state) => state.user.user);
  return (
    <>
      {user && (
        <>
          {user.role === 1 && <MainProfile />}
          {user.role === 2 && <ProfileEst />}
        </>
      )}
    </>
  );
}
export default Profile;
