import { useState } from "react";
import style from "../../dashboard.module.css";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Switch } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import DashHeader from "../../DashHeader";
import EstabDashHeader from "../EstabDashHeader";
import {
  FetchEstabCurrentGigs,
  FetchPastGigs,
  FetchPendingAcceptanceGig,
} from "../../../../axios/axios";
import CurrentG from "../../Bartender/mains/components/currentG";
import CurrentGigsEstablishment from "../main/CurrentGigs_Establishment";
import { Link } from "react-router-dom";
import configuration from "../../../../config/configuration";
import PastGigsEstablishment from "../main/PastGigsEstablishment";
import PendingAcceptance from "../main/PendingAcceptance";
import BartenderAvailToday from "../../BartenderAvailToday";
import InternalBar from "../../../internalBar/InternalBar";
import useSwipeHandler from "../../../useSwipeHandler";
dayjs.extend(customParseFormat);

function EstablishmentDashboard(props) {
  const [tab, setTab] = useState(0);
  const user = useSelector((state) => state.user.user);
  const [myTimeOut, setMytimeOut] = useState();
  const [showfilter, setshowfilter] = useState(false);

  const [data, setData] = useState({
    cg: {
      prev: false,
      next: false,
      search: "",
      page: 1,
      total_page: 1,
      datas: [],
    },
    pa: {
      prev: false,
      next: false,
      search: "",
      page: 1,
      total_page: 1,
      datas: [],
    },
    pg: {
      prev: false,
      next: false,
      search: "",
      page: 1,
      total_page: 1,
      filter: "",
      filter1: "",
      datas: [],
    },
  });

  const fetchConditionalGigs = () => {
    if (tab === 0) {
      FetchEstabCurrentGigs({
        page: data.cg.page,
        search: data.cg.search,
        token: user?.token,
      })
        .then((result) => {
          let res = result.data.data;
          let x = { ...data };

          x.cg = {
            ...x.cg,
            next: res.next,
            prev: res.prev,
            total_page: res.total_page,
            datas: [...res.history],
          };

          setData(x);
        })
        .catch((err) => {
          console.log("cg", err);
        });
    } else if (tab === 1) {
      FetchPendingAcceptanceGig({
        page: data.pa.page,
        search: data.pa.search,
        token: user?.token,
      })
        .then((result) => {
          let res = result.data.data;
          let x = { ...data };

          x.pa = {
            ...x.pa,
            next: res.next,
            prev: res.prev,
            total_page: res.total_page,
            datas: [...res.history],
          };

          setData(x);
        })
        .catch((err) => {
          console.log("pa", err);
        });
    } else if (tab === 2) {
      FetchPastGigs({
        page: data.pg.page,
        search: data.pg.search,
        filter: data.pg.filter,
        filter1: data.pg.filter1,
        token: user?.token,
      })
        .then((result) => {
          let res = result.data.data;
          let x = { ...data };

          x.pg = {
            ...x.pg,
            next: res.next,
            prev: res.prev,
            total_page: res.total_page,
            datas: [...res.history],
          };

          setData(x);
        })
        .catch((err) => {
          console.log("pg", err);
        });
    }
  };

  useEffect(() => {
    fetchConditionalGigs();
  }, [tab, data.pg.filter1, data.pg.filter]);

  useEffect(() => {
    clearTimeout(myTimeOut);

    setMytimeOut(
      setTimeout(() => {
        fetchConditionalGigs();
      }, [700])
    );
  }, [data?.cg?.search, data?.no?.search, data?.pg?.search]);

  useEffect(() => {
    fetchConditionalGigs();
  }, [data?.cg?.page, data?.no?.page, data?.pg?.page]);

  const getSearchValue = () => {
    if (tab === 0) {
      return data.cg.search;
    } else if (tab === 1) {
      return data.pa.search;
    } else if (tab === 2) {
      return data.pg.search;
    }
  };

  const searchHandler = (val) => {
    let x = { ...data };
    if (tab === 0) {
      x.cg = { ...x.cg, search: val };
    } else if (tab === 1) {
      x.pa = { ...x.pa, search: val };
    } else if (tab === 2) {
      x.pg = { ...x.pg, search: val };
    }

    setData(x);
  };

  const getPreviousValue = () => {
    if (tab === 0) {
      return data.cg.prev;
    } else if (tab === 1) {
      return data.pa.prev;
    } else if (tab === 2) {
      return data.pg.prev;
    }
  };

  const getNextValue = () => {
    if (tab === 0) {
      return data.cg.next;
    } else if (tab === 1) {
      return data.pa.next;
    } else if (tab === 2) {
      return data.pg.next;
    }
  };

  const getTotalPage = () => {
    if (tab === 0) {
      return data.cg.total_page;
    } else if (tab === 1) {
      return data.pa.total_page;
    } else if (tab === 2) {
      return data.pg.total_page;
    }
  };

  const setPage = (val) => {
    let x = { ...data };

    if (tab === 0) {
      x.cg = { ...x.cg, page: val };
    } else if (tab === 1) {
      x.no = { ...x.pa, page: val };
    } else if (tab === 2) {
      x.pg = { ...x.pg, page: val };
    }

    setData(x);
  };

  const increasePage = () => {
    let x = { ...data };

    if (tab === 0) {
      x.cg = { ...x.cg, page: x.cg.page + 1 };
    } else if (tab === 1) {
      x.pa = { ...x.pa, page: x.no.page + 1 };
    } else if (tab === 2) {
      x.pg = { ...x.pg, page: x.pg.page + 1 };
    }

    setData(x);
  };

  const decreasePage = () => {
    let x = { ...data };

    if (tab === 0) {
      x.cg = { ...x.cg, page: x.cg.page - 1 };
    } else if (tab === 1) {
      x.pa = { ...x.pa, page: x.no.page - 1 };
    } else if (tab === 2) {
      x.pg = { ...x.pg, page: x.pg.page - 1 };
    }

    setData(x);
  };

  const getCurrentPage = () => {
    if (tab === 0) {
      return data.cg.page;
    } else if (tab === 1) {
      return data.pa.page;
    } else if (tab === 2) {
      return data.pg.page;
    }
  };

  const NavItems = [
    { index: 0, label: "Current Gigs" },
    { index: 1, label: "Pending Acceptance" },
    { index: 2, label: "Past Gigs" },
  ];

  const swipeHandlers = useSwipeHandler({
    setTab: setTab,
    maxCount: 2,
    lessCount: 0,
  });

  const handelTabClick = (ind) => {
    setTab(ind);
  };

  return (
    <>
      <EstabDashHeader
        leftContent={
          <>
            <div className="pt-3 my-auto uppercase grow">
              <span className=" text-neutral-700">Welcome</span>{" "}
              <span className="text-establishment-red">{user?.name}</span>
            </div>
            <img
              loading="lazy"
              src="/images/completeProfile/hand.png"
              className="shrink-1 aspect-square mt-5 h-[43px] w-[40px]"
            />
          </>
        }
      />

      {/* ................ */}
      <div
        className="pt-6 min-h-[85%] px-2 gap-6 justify-between w-full lg:flex"
        {...swipeHandlers}>
        <div className="flex-1 w-full lg:min-h-full">
          <div className="relative self-center px-4 pb-6 bg-white shadow-2xl lg:min-h-full rounded-3xl pt-7 ">
            <InternalBar
              NavItems={NavItems}
              onClick={handelTabClick}
              tab={tab}
            />

            <div>
              <div className="flex justify-between gap-5 mt-5 lg:min-h-full max-lg:flex-wrap max-md:max-w-full">
                <div className="flex-auto my-auto ml-2 text-[18px] text-black">
                  {tab === 0 && "Current Gigs"}
                  {tab === 1 && "Pending Acceptance"}
                  {tab === 2 && "Past Gigs"}
                </div>
                <div className="flex gap-5 justify-between px-5 py-4 mr-1 text-sm h-[50px] w-[280px] whitespace-nowrap bg-white border border-gray-200 border-solid shadow-sm rounded-[100px] text-neutral-400">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <input
                      style={{ width: "100%" }}
                      className="border-none outline-none"
                      type="text"
                      placeholder="Search"
                      value={getSearchValue()}
                      onChange={(e) => searchHandler(e.target.value)}></input>
                  </div>
                  <img
                    loading="lazy"
                    src="/images/completeProfile/search.png"
                    className="shrink-0 aspect-[1.05] w-[20px]"
                  />
                </div>
                {tab == 2 && (
                  <img
                    loading="lazy"
                    src="/images/completeProfile/filter.png"
                    className="shrink-0 aspect-square w-[50px] h-auto cursor-pointer"
                    onClick={() => setshowfilter(!showfilter)}
                  />
                )}
                {tab == 2 && showfilter && (
                  <>
                    <div className="absolute z-50 flex overflow-x-hidden overflow-y-auto translate-y-12 outline-none right-5 w-fit h-fit focus:outline-none">
                      <div className="flex flex-col px-4 py-6 text-base text-black rounded-3xl border border-solid bg-white max-w-[375px]">
                        <div className="flex flex-col w-full py-4 pl-3 pr-10 ">
                          <div
                            onClick={() => setshowfilter(false)}
                            className="absolute text-2xl cursor-pointer top-1 right-4">
                            &#10005;
                          </div>
                          <div className="flex flex-col w-64">
                            <p className="text-lg font-medium leading-7 text-establishment-red font-poppins">
                              Earnings
                            </p>
                            <div
                              className="flex cursor-pointer"
                              onClick={() =>
                                setData((prevState) => ({
                                  ...prevState,
                                  pg: {
                                    ...prevState.pg,
                                    filter:
                                      prevState.pg.filter === "processed"
                                        ? ""
                                        : "processed",
                                  },
                                }))
                              }>
                              <div className="flex items-center justify-center w-1/5">
                                <input
                                  type="checkbox"
                                  className="w-5 h-5"
                                  checked={data.pg.filter === "processed"}
                                />
                              </div>
                              <p className="w-full text-lg font-normal font-poppins">
                                Earnings Processed
                              </p>
                            </div>
                            <div
                              className="flex mt-1 cursor-pointer"
                              onClick={() =>
                                setData((prevState) => ({
                                  ...prevState,
                                  pg: {
                                    ...prevState.pg,
                                    filter:
                                      prevState.pg.filter === "initialized"
                                        ? ""
                                        : "initialized",
                                  },
                                }))
                              }>
                              <div className="flex items-center justify-center w-1/5">
                                <input
                                  type="checkbox"
                                  className="w-5 h-5"
                                  checked={data.pg.filter === "initialized"}
                                />
                              </div>
                              <p className="w-full text-lg font-normal font-poppins">
                                Earnings Not Processed
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col mt-2 w-60">
                            <p className="text-lg font-medium leading-7 text-establishment-red font-poppins">
                              Reviews
                            </p>
                            <div
                              className="flex cursor-pointer"
                              onClick={() =>
                                setData((prevState) => ({
                                  ...prevState,
                                  pg: {
                                    ...prevState.pg,
                                    filter1:
                                      prevState.pg.filter1 === true
                                        ? ""
                                        : !prevState.pg.filter1,
                                  },
                                }))
                              }>
                              <div className="flex items-center justify-center w-1/5">
                                <input
                                  type="checkbox"
                                  className="w-5 h-5"
                                  checked={data.pg.filter1 === true}
                                />
                              </div>
                              <p className="w-full text-lg font-normal font-poppins">
                                Rated Bartender
                              </p>
                            </div>
                            <div
                              className="flex mt-1 cursor-pointer"
                              onClick={() =>
                                setData((prevState) => ({
                                  ...prevState,
                                  pg: {
                                    ...prevState.pg,
                                    filter1:
                                      prevState.pg.filter1 === false
                                        ? ""
                                        : false,
                                  },
                                }))
                              }>
                              <div className="flex items-center justify-center w-1/5">
                                <input
                                  type="checkbox"
                                  className="w-5 h-5"
                                  checked={data.pg.filter1 === false}
                                />
                              </div>
                              <p className="w-full text-lg font-normal font-poppins">
                                Not Rated Bartender
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {tab === 0 && (
                <CurrentGigsEstablishment data={data.cg.datas} dayjs={dayjs} />
              )}
              {tab === 1 && (
                <PendingAcceptance data={data.pa.datas} dayjs={dayjs} />
              )}
              {tab === 2 && (
                <PastGigsEstablishment data={data.pg.datas} dayjs={dayjs} />
              )}

              {getTotalPage() > 0 && (
                <div
                  className="flex justify-end"
                  style={{
                    width: "auto",
                    right: "10px",
                    bottom: "15px",
                    alignItems: "center",
                    margin: "10px 0 0 0",
                    padding: "0 20px",
                  }}>
                  <Button
                    type="text"
                    disabled={!getPreviousValue()}
                    onClick={decreasePage}>
                    Previous
                  </Button>

                  {Array.from({ length: getTotalPage() }).map((elem, ind) => (
                    <Button
                      key={`Pagi${ind}${tab}`}
                      style={{
                        height: "20px",
                        width: "20px",
                        padding: "0",
                        fontSize: "12px",
                        margin: "0 3px",
                        background:
                          getCurrentPage() === ind + 1
                            ? "rgb(255, 51, 51)"
                            : "white",
                        color: getCurrentPage() === ind + 1 ? "white" : "black",
                      }}
                      onClick={() => setPage(ind + 1)}>
                      {ind + 1}
                    </Button>
                  ))}

                  <Button
                    type="text"
                    disabled={!getNextValue()}
                    onClick={increasePage}>
                    Next
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-4 lg:mt-0">
          <BartenderAvailToday />
        </div>
      </div>
    </>
  );
}
export default EstablishmentDashboard;
