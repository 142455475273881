import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  unauthorized: false,
  alreadySignedIn: false
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setUnAuthorized(state, action) {
      state.unauthorized = action.payload;
    },
    setAlreadySigned(state, action) {
      state.alreadySignedIn = action.payload;
    }
  }
});

export const {
  setUnAuthorized,
  setAlreadySigned
} = globalSlice.actions;

export default globalSlice.reducer;
