import { useEffect } from "react";
import styles from "../../../styles/successMsg.module.css";
import { Link, useNavigate } from "react-router-dom";
export default function Cp8(props) {  
  const navigate = useNavigate();

  useEffect(()=>{

    setTimeout(()=>{

        navigate("/dashboard");

    },2000)

  },[]);
  
  return (
    <div className={styles.mainContainer}>
      <img src="/images/Group.png"></img>
     
    </div>
  );
}
