import { useEffect, useState } from "react";
import styles from "./wrappers.module.css";
function Wrappers(props) {
  const [percWidth, setPercwidth] = useState(0);

  useEffect(() => {
    let currStep = props.activeTab || 1;
    let total = props.totalSteps || 6;

    let perc = (currStep / total) * 100;

    setPercwidth(perc);
  }, [props.activeTab]);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.upperImg}>
          {/* <img src='./images/completeProfile/upper_img.png' /> */}
          {props.showHeader && (
            <div className={styles.upperForeground}>
              <p className={styles.createProfileTxt}>Create Your Profile</p>
              <div className={styles.upperForegroundBot}>
                <p className={styles.upperForegroundBotTxt}>
                  Step {props.activeTab} of {props.totalSteps}
                </p>
                <div className={styles.sliderCnt}>
                  <div
                    className={styles.slider}
                    style={{
                      width: `${percWidth}%`,
                      background:
                        props.type && props.type === "esta"
                          ? "#FF3333"
                          : "#FFCC00",
                    }}></div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={styles.box}>
          <div className={styles.dashboardcontainer}>{props.children}</div>
        </div>
      </div>
    </>
  );
}
export default Wrappers;
