import React, { useState } from "react";
import { submitReviewOnEstab } from "../../axios/axios";
import { useSelector } from "react-redux";
import { Rating } from "@mui/material";

function ReviewModal({
  setShowModal3,
  activeEstab,
  givenrating,
  setGivenRating,
}) {
  const user = useSelector((state) => state.user.user);

  const handleSubmitReviewonEstab = () => {
    submitReviewOnEstab({
      token: user?.token,
      establishmentId: givenrating.establishmentId,
      detail: givenrating.detail,
      overall: givenrating.overall,
      experience: givenrating.experience,
      staff: givenrating.staff,
      value: givenrating.value,
    })
      .then((result) => setShowModal3(false))
      .catch((err) => console.log(err));
  };

  return (
    <div className="flex flex-col items-center w-[360px] max-h-[80%] rounded-3xl overflow-y-scroll pb-3 no-scrollbar bg-neutral-50">
      <style>
        {`  textarea::placeholder {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0.36px;
        text-transform: capitalize;
        padding: 0px !important;
        `}
      </style>
      <div className="flex overflow-hidden relative flex-col rounded-t-3xl  items-end self-stretch w-full min-h-[120px] max-md:pl-5 max-md:max-w-full">
        <img
          loading="lazy"
          src="/images/completeProfile/review_back_img.png"
          className="absolute inset-0 object-cover size-full"
        />
        <div
          className="relative pt-2 pr-4 text-xl text-white cursor-pointer "
          onClick={() => setShowModal3(false)}>
          {" "}
          &#10005;{" "}
        </div>
        <div className="relative self-center pl-1 text-base text-white">
          Rate
        </div>
        <div className="relative self-center pl-1 text-lg text-center text-amber-300 max-md:max-w-full">
          {activeEstab?.establishmentName}
        </div>
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="flex z-10 flex-col self-center items-center px-10 pt-3.5 pb-1.5 -mt-2.5 bg-white max-w-[380px] rounded-lg max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col items-center max-w-full ">
            <div className="text-sm text-black">Overall</div>
            <div className="text-xs text-center text-zinc-500">
              Tell us about your overall expeeriece at Baroooom, How did you
              enjoy your visit?
            </div>
            <Rating
              name="simple-controlled"
              value={givenrating.overall}
              precision={0.5}
              onChange={(event, newValue) => {
                setGivenRating({
                  ...givenrating,
                  overall: newValue,
                });
              }}
            />
          </div>
        </div>
        <div className="flex justify-center  items-center px-10 py-2.5 mt-2 w-full bg-white rounded-lg max-w-[380px] max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col items-center max-w-full ">
            <div className="text-sm text-black">Experience</div>
            <div className="text-xs text-center text-zinc-500">
              Describe your time at Barooom{" "}
            </div>
            <Rating
              name="simple-controlled"
              value={givenrating.experience}
              precision={0.5}
              onChange={(event, newValue) => {
                setGivenRating({
                  ...givenrating,
                  experience: newValue,
                });
              }}
            />
          </div>
        </div>
        <div className="flex justify-center  items-center px-10 py-2.5 mt-2 w-full bg-white rounded-lg max-w-[380px] max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col items-center max-w-full ">
            <div className="text-sm text-black">Staff</div>
            <div className="text-xs text-center text-zinc-500">
              How was the support from our team? Were tehy attentive and
              helpful?{" "}
            </div>
            <Rating
              name="simple-controlled"
              value={givenrating.staff}
              precision={0.5}
              onChange={(event, newValue) => {
                setGivenRating({
                  ...givenrating,
                  staff: newValue,
                });
              }}
            />
          </div>
        </div>
        <div className="flex justify-center  items-center px-10  py-2.5 mt-2 w-full bg-white rounded-lg max-w-[380px] max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col items-center max-w-full ">
            <div className="text-sm text-black">Value</div>
            <div className="text-xs text-center text-zinc-500">
              Did you fell that your visit was worth the cost? How do you rate
              value for money?{" "}
            </div>
            <Rating
              name="simple-controlled"
              value={givenrating.value}
              precision={0.5}
              onChange={(event, newValue) => {
                setGivenRating({
                  ...givenrating,
                  value: newValue,
                });
              }}
            />
          </div>
        </div>
        <div className="flex flex-col items-center pt-5 mt-5 w-full bg-white rounded-lg border border-gray-200 border-solid max-w-[370px] max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col w-full p-2 mb-2">
            <div className="self-center text-sm text-center text-black">
              Write Detailed Review
            </div>
            <textarea
              style={{
                textAlign: "center",
                padding: 0,
                margin: 0,
                height: "100%",
                width: "100%",
                resize: "none",
              }}
              className="mt-2.5 text-xs text-zinc-500 resize-none h-full w-full"
              value={givenrating.detail}
              rows={5}
              cols={5}
              placeholder="Share your highlights and suggestions. Your feedback is invaluable to us!"
              onChange={(e) =>
                setGivenRating({
                  ...givenrating,
                  detail: e.target.value,
                })
              }></textarea>
          </div>
        </div>
        <div
          className="justify-center self-center px-4 py-3 mt-8  text-base text-center text-black bg-amber-300 w-[80%] rounded-[85.613px] max-md:max-w-full"
          onClick={() => handleSubmitReviewonEstab()}>
          Submit Review
        </div>
      </div>
    </div>
  );
}

export default ReviewModal;
