import React, { useState } from "react";
import EditEsta1 from "../components/eventDetails/Establishment/eventDetail1/editEsta/EditEsta1";
import EditEsta2 from "../components/eventDetails/Establishment/eventDetail1/editEsta/EditEsta2";
import EditEsta3 from "../components/eventDetails/Establishment/eventDetail1/editEsta/EditEsta3";
import EditEsta4 from "../components/eventDetails/Establishment/eventDetail1/editEsta/EditEsta4";
import { ensureNumber } from "../utils/inputFormatter";

function NewEstablishment() {
  const [activejtab, setActiveJTab] = useState(3);
  let totaltab = 4;
  let progress = (activejtab / totaltab) * 100;
  const handlePreviousClick = () => {
    setActiveJTab(activejtab - 1);
  };

  const [newestablishmentdata, setNewEstablishmentData] = useState({
    EprofileImg: "",
    establishmentName: "",
    discription: "",
    serviceType: "",
    dressCode: "",
    posSystem: "",

    accommodates: "",
    customerFlow: "",
    yearsOfExperience: "",
    websiteLink: "",

    timeZone: "",
    location: {
      streetName: "",
      city: "",
      state: "",
      postalCode: "",
    },
    latitude: 0,
    longitude: 0,

    images: [],
  });

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setNewEstablishmentData({ ...newestablishmentdata, [name]: value });
  };

  const handleLocationChange = (e) => {
    let { name, value } = e.target;
    if (name == "postalCode") {
      value = ensureNumber({ value: e.target.value, maxLength: 8 });
    }
    setNewEstablishmentData((prevState) => ({
      ...prevState,
      location: {
        ...prevState.location,
        [name]: value,
      },
    }));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    setNewEstablishmentData((prevState) => ({
      ...prevState,
      images: [...prevState.images, ...files],
    }));
  };

  const handleEProfileChange = (e) => {
    setNewEstablishmentData((prevState) => ({
      ...prevState,
      EprofileImg: e.target.files[0],
    }));
  };

  const handleRemoveImage = (index) => {
    setNewEstablishmentData((prevData) => ({
      ...prevData,
      images: prevData.images.filter((_, i) => i !== index),
    }));
  };

  const handleAutoComplete = (data) => {
    const {
      streetName,
      city,
      state,
      postalCode,
      latitude,
      longitude,
      timeZone,
    } = data;

    setNewEstablishmentData((prevData) => ({
      ...prevData,
      location: {
        streetName,
        city,
        state,
        postalCode,
      },
      latitude: latitude,
      longitude: longitude,
      timeZone: timeZone,
    }));
  };

  return (
    <div className="w-[100vw] h-[100vh] flex flex-col pb-4">
      <div
        className="min-h-[15%] w-full flex flex-col justify-center p-4 px-24 text-white gap-2 relative"
        style={{
          backgroundImage: `url('/images/headerBackground.svg')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "0 0 25px 25px",
        }}>
        {activejtab > 1 && (
          <p
            className="absolute translate-x-[-100%]  pr-5 text-3xl hover:cursor-pointer"
            onClick={() => handlePreviousClick()}>
            &#60;
          </p>
        )}
        <p className="text-white font-normal text-3xl h-fit">
          Register New Establishment
        </p>
        <div className="flex flex-col gap-1">
          <p className=" text-l">
            Step {activejtab} of {totaltab}
          </p>
          <div className="relative w-full h-2 rounded">
            <div
              className="bg-red-500 absolute h-2 z-10 rounded"
              style={{ width: `${progress}%` }}
            />
            <div className="bg-white absolute h-2 w-full rounded" />
          </div>
        </div>
      </div>
      <div className="h-full overflow-hidden overflow-y-scroll no-scrollbar">
        <div className="flex justify-center items-center">
          {activejtab == 1 && (
            <EditEsta1
              activejtab={activejtab}
              setActiveJTab={setActiveJTab}
              handleChange={handleChange}
              handleEProfileChange={handleEProfileChange}
              newestablishmentdata={newestablishmentdata}
            />
          )}
          {activejtab == 2 && (
            <EditEsta2
              activejtab={activejtab}
              setActiveJTab={setActiveJTab}
              handleChange={handleChange}
              setNewEstablishmentData={setNewEstablishmentData}
              newestablishmentdata={newestablishmentdata}
            />
          )}
          {activejtab == 3 && (
            <EditEsta3
              activejtab={activejtab}
              setActiveJTab={setActiveJTab}
              handleChange={handleChange}
              handleLocationChange={handleLocationChange}
              newestablishmentdata={newestablishmentdata}
              handleAutoComplete={handleAutoComplete}
            />
          )}
          {activejtab == 4 && (
            <EditEsta4
              activejtab={activejtab}
              setActiveJTab={setActiveJTab}
              handleImageChange={handleImageChange}
              handleRemoveImage={handleRemoveImage}
              newestablishmentdata={newestablishmentdata}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default NewEstablishment;
