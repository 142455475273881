import React, { useState } from "react";
import Jp1 from "./Jp1";
import Jp2 from "./Jp2";
import Jp3 from "./Jp3";
import { countWords } from "../../../../utils/countwords";

function JobWraper() {
  const [activejtab, setActiveJTab] = useState(1);
  let totaltab = 3;
  let progress = (activejtab / totaltab) * 100;

  const [jobdata, setJobData] = useState({
    urgent: false,
    establishmentid: "",
    jobTitle: "",
    jobDiscription: "",
    starTime: "",
    endTime: "",
    daytype: false,
    startdate: "",
    enddate: "",
    yearsOfExperience: 0,
    payHourly: "",
    skillsRequired: [],
    dutiesRes: [],
    benifits: [],
  });

  const handleChange = ({ e, maxlen }) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value.trim() == "" && value != "") {
      return;
    } else if (maxlen && countWords(value) >= maxlen) {
      return;
    } else {
      setJobData({ ...jobdata, [name]: value });
    }
  };

  const handlNextClick = () => {
    setActiveJTab(activejtab + 1);
  };

  const handlePreviousClick = () => {
    setActiveJTab(activejtab - 1);
  };

  return (
    <div className="w-[100vw] h-[100vh] flex flex-col pb-4">
      <div
        className="min-h-[15%] w-full flex flex-col justify-center p-4 px-24 text-white gap-2 relative"
        style={{
          backgroundImage: `url('/images/headerBackground.svg')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "0 0 25px 25px",
        }}>
        {activejtab > 1 && (
          <p
            className="absolute translate-x-[-100%]  pr-5 text-3xl hover:cursor-pointer"
            onClick={() => handlePreviousClick()}>
            &#60;
          </p>
        )}
        <p className="text-white font-normal text-3xl h-fit">Create New Job</p>
        <div className="flex flex-col gap-1">
          <p className=" text-l">
            Step {activejtab} of {totaltab}
          </p>
          <div className="relative w-full h-2 rounded">
            <div
              className="bg-red-500 absolute h-2 z-10 rounded"
              style={{ width: `${progress}%` }}
            />
            <div className="bg-white absolute h-2 w-full rounded" />
          </div>
        </div>
      </div>
      <div className="mx-16 mt-8 h-full overflow-hidden">
        {activejtab == 1 && (
          <Jp1
            setJobData={setJobData}
            jobdata={jobdata}
            setActiveJTab={setActiveJTab}
            activejtab={activejtab}
            handleChange={handleChange}
          />
        )}
        {activejtab == 2 && (
          <Jp2
            setJobData={setJobData}
            jobdata={jobdata}
            setActiveJTab={setActiveJTab}
            activejtab={activejtab}
          />
        )}
        {activejtab == 3 && (
          <Jp3
            setJobData={setJobData}
            jobdata={jobdata}
            setActiveJTab={setActiveJTab}
            activejtab={activejtab}
          />
        )}
      </div>
    </div>
  );
}

export default JobWraper;
