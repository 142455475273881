import React from 'react'
import ContentWrapper from '../components/ContentWrapper/ContentWrapper'
import Footer from '../components/landing/Footer'

export default function PrivacyPolicy() {
  return (
    <>

  <ContentWrapper
      type={"PrivacyPolicy"}
      title={["Privacy","POLICY"]}
      content={"<h1>Just and HTML</h1>"}
      />

    <Footer />
      
    </>
  )
}
