import React from "react";
import { useState } from "react";
import Dropdown from "../../Dropdown";
import Notification from "../../Notification";
import Filter from "../../Filter";
import { Modal, Rating } from "@mui/material";

import config from "../../../../../config/configuration";
import { submitReviewOnEstab } from "../../../../../axios/axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BarJobCards from "../../../../jobCards/BarJobCards";
import ReviewModal from "../../../../Reviews/ReviewModal";

function PastGig({ data, dayjs }) {
  const navigate = useNavigate();
  const [givenrating, setGivenRating] = useState({
    establishmentId: "",
    detail: "",
    overall: 0,
    experience: 0,
    staff: 0,
    value: 0,
  });
  const [activeEstab, setactiveEstab] = useState();
  const [showModal1, setShowModal1] = useState(false);
  const [showModal3, setShowModal3] = useState(false);

  return (
    <>
      {showModal1 ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="relative w-auto max-w-3xl mx-auto my-6">
              <div className="relative flex flex-col w-full bg-white outline-none  rounded-xl focus:outline-none">
                <button
                  className="self-end pt-3 pr-4 text-2xl"
                  onClick={() => setShowModal1(false)}>
                  {" "}
                  &#10005;
                </button>
                <img
                  src="/images/Success1.png"
                  className="w-[400px] px-20 pt-10 pb-20"
                />
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}

      <Modal
        open={showModal3}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <ReviewModal
          setShowModal3={setShowModal3}
          activeEstab={activeEstab}
          setGivenRating={setGivenRating}
          givenrating={givenrating}
        />
      </Modal>

      <div className="flex flex-wrap w-full gap-6 px-2 pt-6 max-md:max-w-full ">
        {data.map((elem, index) => {
          return (
            <BarJobCards
              elem={elem}
              Jtype={3}
              index={index}
              setShowModal3={setShowModal3}
              setactiveEstab={setactiveEstab}
              setGivenRating={setGivenRating}
            />
          );
        })}

        {data?.length < 1 ? (
          <div className="flex items-center justify-center w-full">
            <img src="/images/EmptyGig.svg" sizes="20" alt="" />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default PastGig;
