import { useEffect, useState } from "react";
import styles from "../community.module.css";
import Dropdown from "../../dashboard/Bartender/Dropdown";
import Notification from "../../dashboard/Bartender/Notification";
import { Link, useNavigate } from "react-router-dom";
import DropdownEst from "../../dashboard/Establishment/DropdownEst";
import NotificationEst from "../../dashboard/Establishment/NotificationEst";
import { getNearbyBaretendersEstab } from "../../../axios/axios";
import { useSelector } from "react-redux";
import configuration from "../../../config/configuration";
import EstabDashHeader from "../../dashboard/Establishment/EstabDashHeader";
import { Button } from "antd";
import { CircularProgress } from "@mui/material";

export default function NearbyBartEst() {
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const [data, setData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    item_per_page: 12,
    total_page: 1,
    total_count: 1,
    history: [],
  });

  const nearbybardata = () => {
    getNearbyBaretendersEstab({
      token: user?.token,
      search: data.search,
      page: data.page,
    })
      .then((result) => {
        let res = result.data;
        let x = { ...data };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          total_page: res.total_page,
          total_count: res.total_count,
          item_per_page: res.item_per_page,
          history: [...res.history],
        };

        setData(x);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    nearbybardata();
  }, [data.page]);

  const getTotalPage = () => {
    return data.total_page;
  };

  const getCurrentPage = () => {
    return data.page;
  };

  const decreasePage = () => {
    if (data.prev) {
      setData({ ...data, page: data.page - 1 });
    }
  };
  const increasePage = () => {
    if (data.next) {
      setData({ ...data, page: data.page + 1 });
    }
  };

  return (
    <div>
      <div className="flex flex-col px-3 pb-6 rounded-3xl bg-zinc-50 max-md:px-5 max-md:max-w-full">
        <EstabDashHeader
          leftContent={
            <>
              <div className="pt-3 my-auto uppercase grow">
                <span className=" text-neutral-700">Community</span>{" "}
              </div>
            </>
          }
        />
        <div className="flex flex-col px-10 py-12 mt-8 text-sm leading-6 bg-white shadow-2xl rounded-3xl text-neutral-700 max-md:px-5 max-md:max-w-full">
          <div className="flex w-full gap-5 max-md:flex-wrap max-md:pr-5 max-md:max-w-full">
            <div
              className={`flex-auto my-auto text-lg text-black} ${styles.header}`}>
              Nearby Bartenders ({data.total_count})
            </div>
          </div>

          <div className="flex flex-wrap gap-6">
            {loading && (
              <div className="flex items-center justify-center w-full">
                <CircularProgress />{" "}
                <p className="pl-2 text-xl font-poppins">Loading...</p>
              </div>
            )}

            {!loading &&
              data.history.map((item) => {
                return (
                  <div
                    key={`NearBarEstab_${item.friendsDetial[0]._id}`}
                    onClick={() =>
                      navigate(
                        `/bartender-details/${item.friendsDetial[0]._id}`
                      )
                    }>
                    <div className="flex mt-8  relative flex-col items-center max-w-full rounded-3xl border border-solid border-neutral-200 w-[180px]">
                      <img
                        loading="lazy"
                        src={
                          `${configuration.cdnFront}/${item.friendsDetial[0].bartenderDetails[0].profileImg}` ||
                          ""
                        }
                        className="z-10 mt-0 w-[60px] absolute top-[-25px] rounded-full border-white border-solid shadow-lg aspect-square border-[3px]"
                      />
                      <div
                        className={` text-base  mt-11 text-stone-900} ${styles.header}`}>
                        {item.friendsDetial[0].name}
                      </div>
                      <div className="flex gap-2 text-xs">
                        <div className="flex gap-1 text-black whitespace-nowrap">
                          <img
                            loading="lazy"
                            src="/images/Comminity/location.svg"
                            className="shrink-0 aspect-square w-[17px]"
                          />
                          <div className="my-auto">
                            {item.friendsDetial[0].bartenderDetails[0].location
                              .city ||
                              item.friendsDetial[0].bartenderDetails[0].location
                                .state}
                          </div>
                        </div>
                        <div className="my-auto text-zinc-400">
                          {parseFloat(item.distance).toFixed(2)} mi
                        </div>
                      </div>
                      <div className="flex gap-5 self-stretch py-1.5 pr-5 pl-2.5 mt-3.5 text-sm font-medium text-black whitespace-nowrap rounded-b-3xl bg-neutral-100 max-md:pr-5">
                        <div className="flex flex-1 gap-1">
                          <img
                            loading="lazy"
                            src="/images/Group.svg"
                            className="w-4 shrink-0 aspect-square"
                          />
                          <div className="my-auto">
                            {" "}
                            {item.mutualFriends.TotalFriend}
                          </div>
                        </div>
                        <div className="flex flex-1 gap-1 pl-2">
                          <img
                            loading="lazy"
                            src="/images/Building.svg"
                            className="w-4 shrink-0 aspect-square"
                          />
                          <div className="my-auto">
                            {" "}
                            {item.friendsDetial[0].totalJobs}
                          </div>
                        </div>
                        <div className="flex flex-1 gap-1">
                          <img
                            loading="lazy"
                            src="/images/StartRating.svg"
                            className="w-4 shrink-0 aspect-square"
                          />
                          <div className="my-auto">
                            {" "}
                            {item.friendsDetial[0].totalReviews}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>

          {getTotalPage() > 0 && !loading && (
            <div
              className="flex justify-end"
              style={{
                width: "auto",
                right: "10px",
                bottom: "15px",
                alignItems: "center",
                margin: "10px 0 0 0",
                padding: "0 20px",
              }}>
              <Button type="text" disabled={!data.prev} onClick={decreasePage}>
                Previous
              </Button>

              {Array.from({ length: getTotalPage() }).map((elem, ind) => (
                <Button
                  key={`Pagi${ind}`}
                  style={{
                    height: "20px",
                    width: "20px",
                    padding: "0",
                    fontSize: "12px",
                    margin: "0 3px",
                    background:
                      getCurrentPage() === ind + 1
                        ? "rgb(255, 51, 51)"
                        : "white",
                    color: getCurrentPage() === ind + 1 ? "white" : "black",
                  }}
                  onClick={() => setData({ ...data, page: ind + 1 })}>
                  {ind + 1}
                </Button>
              ))}

              <Button type="text" disabled={!data.next} onClick={increasePage}>
                Next
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
