import React, { useEffect, useState } from "react";
import style from "../Earning.module.css";
import { Link, useNavigate } from "react-router-dom";
import EstabDashHeader from "../../dashboard/Establishment/EstabDashHeader";
import { getEstabPayment, sendPaymentRemind } from "../../../axios/axios";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import config from "../../../config/configuration";
import { toast } from "react-toastify";
import { formatNumber } from "../../../utils/helper";
import { useSwipeable } from "react-swipeable";
import { CircularProgress } from "@mui/material";

export default function CompletedPaymentEst() {
  const [filter, setfilter] = useState(true);
  const user = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [myTimeOut, setMytimeOut] = useState();
  const navigate = useNavigate();
  const [paydata, setPayData] = useState({
    prev: false,
    next: false,
    search: "",
    pending: "",
    paid: "",
    filter: "",
    page: 1,
    total_page: 1,
    history: [],
  });

  const fetchPaymentDetails = async () => {
    await getEstabPayment({ filter, token: user?.token, search })
      .then((res) => {
        setPayData(res.data);
        setTimeout(() => {
          setLoading(false)
        }, 300);
      })
      .catch((err) => toast.error(err.message));
  };

  const sendPaymentReminder = async ({ eid }) => {
    await sendPaymentRemind({ token: user?.token, eid });
  };

  useEffect(() => {
    fetchPaymentDetails();
  }, [filter]);

  useEffect(() => {
    clearTimeout(myTimeOut);

    setMytimeOut(
      setTimeout(() => {
        fetchPaymentDetails();
      }, [700])
    );
  }, [search]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () =>
      setfilter(false),
    onSwipedRight: () =>
      setfilter(true)
  });

  return (
    <>
      <EstabDashHeader
        leftContent={
          <>
            <div className="pt-3 my-auto uppercase grow">
              <span className=" text-neutral-700">Earnings</span>{" "}
              {/* <span className="text-yellow-400 ">{user?.name}</span> */}
            </div>
          </>
        }
      />

      <div className="flex flex-col px-5 py-10 mt-8 text-sm leading-6 bg-white shadow-2xl rounded-3xl text-neutral-700 max-md:px-5 max-md:max-w-full" {...swipeHandlers}>
        {/* header */}
        <div className="flex gap-5 self-center px-1 py-1  text-[15px] leading-5 bg-white border border-gray-200 border-solid rounded-[100px] max-md:ml-2.5">
          <div
            className={`flex-auto my-auto cursor-pointer px-5 py-3.5 rounded-[100px] ${filter ? "text-white bg-red-500 font-medium" : ""
              }`}
            onClick={() => setfilter(true)}>
            Completed Payment
          </div>
          {/* text-neutral-700 */}
          <div
            className={`flex-auto my-auto cursor-pointer px-5 py-3.5 rounded-[100px] ${!filter ? "text-white bg-red-500 font-medium" : ""
              }`}
            onClick={() => setfilter(false)}>
            Pending Payment
          </div>
        </div>
        <div className="flex justify-between text-center">
          <div className="z-10 flex self-start">
            <div className="relative">
              <img
                loading="lazy"
                src="/images/coins.png"
                className="shrink-0 max-w-full aspect-square mt-6 w-[90px]"
              />
              <span className="absolute right-0 text-lg top-5 ">&#10022;</span>
              <span className="absolute right-0 top-[60px] text-lg">
                &#10022;
              </span>
              <span className="absolute text-lg left-2 top-8 ">&#10022;</span>
            </div>
            <div className="flex flex-col self-start ml-4 mt-9">
              <div className="text-[13px] text-neutral-400">
                {filter ? "Total Payments Received" : "Total Upcoming Payments"}
              </div>
              <div className="mt-2 text-[25px] self-start font-medium text-black">
                ${" "}
                {filter
                  ? formatNumber(paydata.paid)
                  : formatNumber(paydata.pending)}
              </div>
            </div>
          </div>
          <div className="flex gap-2 self-end px-5 py-3.5 mb-7 max-w-full text-sm bg-white border border-gray-200 border-solid shadow-sm rounded-[100px] text-neutral-400 w-[300px] max-md:px-5 max-md:mt-10">
            <input
              className="flex-auto my-auto border-none outline-none"
              type="text"
              value={search}
              placeholder="Search establishments"
              onChange={(e) => setSearch(e.target.value)}
            />
            <img
              loading="lazy"
              src="/images/search.png"
              className=" h-[16px] w-[16px]"
            />
          </div>
        </div>
        {!loading ? paydata.history.length > 0 ? (
          <div className="grid max-w-full grid-cols-2 gap-5 mx-auto mt-5 xl:grid-cols-3 ">
            {paydata.history.map((item, index) => {
              return (
                <div
                  key={`${item?._id}_PAY_${index}`}
                  style={{
                    boxShadow: "0px 0px 14.764px 0px rgba(0, 0, 0, 0.12)",
                  }}
                  className="flex flex-col grow px-3.5 py-3 w-full bg-white rounded-md shadow-sm max-md:px-5 max-md:mt-8 max-md:max-w-full">
                  <div className="flex items-center justify-between gap-3">
                    <img
                      loading="lazy"
                      src={
                        `${config.cdnFront}/${item?.Establishment?.EprofileImg}` ||
                        ""
                      }
                      className="shrink-0 self-stretch aspect-square h-[50px] w-[50px]"
                    />
                    <div className="flex flex-col self-stretch my-auto">
                      <div
                        className={`text-[14px] flex flex-col text-black ${style.header}`}>
                        <p>{item?.Establishment?.establishmentName}</p>
                        <p>{item?.Bar?.[0]?.name}</p>
                      </div>
                      {/* <div className="flex gap-1 mt-[1px] text-xs text-neutral-700">
                        <img
                          loading="lazy"
                          src="/images/completeProfile/Clock.png"
                          className="shrink-0 aspect-[0.93] h-[13px] w-[13px]"
                        />
                        <div className="my-auto text-[11px] ">
                          Shift Time: 7:00 PM - 11:30 PM <br />
                        </div>
                      </div> */}
                    </div>
                    <div className="self-stretch my-auto text-2xl font-medium text-red-500">
                      ${formatNumber(item?.NewTotal[0])}
                    </div>
                  </div>
                  <div className="shrink-0 mt-2.5 h-0.5 bg-gray-200 border border-gray-200 border-solid" />
                  <div className="mt-1.5 text-[12px] text-neutral-400">
                    {item.isSuccess ? (
                      <p
                        className="underline cursor-pointer"
                        onClick={() =>
                          (window.location.href = item?.pay?.invoicePdf)
                        }>
                        View Invoice
                      </p>
                    ) : (
                      ""
                    )}
                    {!item.isSuccess ? (
                      <div className="">
                        {`${Math.abs(
                          dayjs(item?.enddate).diff(
                            dayjs(new Date()).toDate(),
                            "day"
                          )
                        )}${" "}`}
                        days are past!{" "}
                        <span
                          className="font-semibold text-red-500 hover:cursor-pointer"
                          onClick={() =>
                            // sendPaymentReminder({ eid: item.establishmentid })
                            navigate(
                              `/make-payment/${item?.Bar[0]?._id}/${item?._id}`
                            )
                          }>
                          Make Payment
                        </span>{" "}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center">
            <img
              loading="lazy"
              src="/images/no_earnings.svg"
              sizes="20"
              alt=""
            />
            <p className="text-xl font-medium font-Roboto">No Payments</p>
          </div>
        ) : <div className="flex items-center justify-center h-[11.6rem]">
          <CircularProgress />{" "}
          <p className="pl-2 text-xl font-poppins">Loading...</p>
        </div>}
      </div>
    </>
  );
}
