import dayjs from "dayjs";
import {
  convertremainingtime,
  RemainingTime,
  upconvertremainingtime,
} from "./helper";

export const CalculateReaminTime = (startdate) => {
  return RemainingTime(startdate) > 0
    ? RemainingTime(startdate) === 1
      ? `${upconvertremainingtime(startdate)} to go`
      : `${RemainingTime(startdate) - 1} days to go`
    : RemainingTime(startdate) <= 0
    ? `Started ${convertremainingtime(startdate)} ago`
    : Math.abs(RemainingTime(startdate)) === 1
    ? `Posted ${Math.abs(RemainingTime(startdate))} day ago`
    : `Posted ${Math.abs(RemainingTime(startdate))} days ago`;
};

export const getTimeDifferenceFromNow = (date) => {
  const targetDate = dayjs(date);
  const currentDate = dayjs(); 

  const diffInMinutes = targetDate.diff(currentDate, "minute");
  const diffInDays = targetDate.diff(currentDate, "day");

  if (diffInMinutes <= 0) {
    return "Ongoing";
  } else if (diffInDays >= 1) {
    return `${diffInDays} days to Go`;
  } else {
    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;
    return `${hours} hrs, ${minutes} minutes to Go`;
  }
};
