import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const NotAuthenticatedLayout = ({ children }) => {
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const [allowView, setAllowView] = useState(false);
  const { gid } = useParams();

  useEffect(() => {
    if (user) {
      if (!user.profileSetup) {
        navigate("/setup");
      } else if (user.account_status == 5) {
        navigate("/verification-pending");
      } else {
        if (gid && gid != " ") {
          navigate(`/gig/${gid}`);
        } else {
          navigate("/dashboard");
        }
      }
    } else {
      setAllowView(true);
    }
  }, [user]);

  return <>{allowView && children}</>;
};

export default NotAuthenticatedLayout;
