import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import style from "../setting.module.css";
import { useSelector } from "react-redux";
import DashHeader from "../../dashboard/DashHeader";
import EstabDashHeader from "../../dashboard/Establishment/EstabDashHeader";
import {
  fetchTicket,
  getChatMessages,
  postNewTicket,
  sendChatMessages,
} from "../../../axios/axios";
import { toast } from "react-toastify";
import { Button } from "antd";
import MessagesSection from "../../Chat/MessagesSection";
import setupNotificationListener from "../../NotificaionHandler";

function ChatSupportBart() {
  const user = useSelector((state) => state.user.user);

  const [inputtext, setInputText] = useState("");
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [myTimeOut, setMytimeOut] = useState();
  const [change, setChange] = useState(false);

  const [RaisedTickets, setRaisedTickets] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });

  const [ticketdata, setTicketData] = useState({
    title: "",
    subject: "",
    description: "",
  });

  const CreateTicket = () => {
    setLoading(true);
    if (!ticketdata.title || !ticketdata.subject || !ticketdata.description) {
      toast.error("Mandatory Fields Empty!");
    } else {
      postNewTicket({ token: user?.token, data: ticketdata })
        .then((res) => {
          setShowModal1(false);
          toast.success(res?.message);
          setTicketData({ title: "", subject: "", description: "" });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err?.message);
        });
    }
  };

  const onChatMessage = ({ data }) => {
    if (data.fromUsr !== user._id && activeuser._id == data.fromUsr) {
      setMsgs((prevMsgs) => ({
        ...prevMsgs,
        history: [...prevMsgs.history, formatMsgs({ msgData: data })],
      }));
      setChange(!change);
    }
  };

  useEffect(() => {
    setupNotificationListener({ onChatMessage });
  }, []);

  const GetAllTicket = () => {
    fetchTicket({ token: user?.token, search })
      .then((result) => {
        let res = result.data;
        let x = { ...RaisedTickets };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          history: [...res.history],
        };
        setRaisedTickets(x);
      })
      .catch((err) => toast.error(err?.message));
  };

  const navigate = useNavigate();
  const [showModal1, setShowModal1] = useState(false);
  const [activeuser, setActiveUser] = useState({
    pic: "",
    name: "",
    _id: "",
    role: "",
    chatId: "",
    isBlocked: "",
    subject: "",
  });
  const [msgs, setMsgs] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });

  const handleActiveUser = (data) => {
    const { chatId, title, isopen, subject } = data;
    if (chatId != activeuser.chatId) {
      setMsgs({
        prev: false,
        next: false,
        search: "",
        page: 1,
        total_page: 1,
        history: [],
      });
      setActiveUser({
        ...activeuser,
        chatId,
        title,
        isBlocked: !isopen,
        subject,
      });
    }
  };

  const formatMsgs = ({ msgData }) => {
    if (Array.isArray(msgData)) {
      return msgData.map(
        ({ chatId, conversationId, from, fromUsr, text, Msg, createdAt }) => ({
          chatId: chatId || conversationId,
          from: from || fromUsr,
          text: text || Msg,
          createdAt: createdAt || new Date().toISOString(),
        })
      );
    } else {
      let { chatId, conversationId, from, fromUsr, text, Msg, createdAt } =
        msgData;
      console.log(chatId, conversationId, from, fromUsr, text, Msg, createdAt);
      return {
        chatId: chatId || conversationId,
        from: from || fromUsr,
        text: text || Msg,
        createdAt: createdAt || new Date().toISOString(),
      };
    }
  };

  const fetchChatMsgs = ({ _id, page }) => {
    let chatid = _id ? _id : activeuser.chatId;

    getChatMessages({
      chatId: chatid,
      page: page,
      search: "",
      token: user?.token,
    })
      .then((result) => {
        let res = result.data;

        if (_id && _id == activeuser.chatId) {
          console.log("Here");
          let x = { ...msgs };
          const formattedMessages = formatMsgs({ msgData: res.history });
          x = {
            ...x,
            next: res.next,
            total_page: res.total_page,
            total_page: res.total_page,
            page: res.page,
            prev: res.prev,
            history: [...formattedMessages, ...x.history],
          };
          setMsgs(x);
        } else {
          console.log("There");
          const formattedMessages = formatMsgs({ msgData: res.history });
          setMsgs({
            next: res.next,
            total_page: res.total_page,
            total_page: res.total_page,
            page: res.page,
            prev: res.prev,
            history: [...formattedMessages],
          });
        }
      })
      .catch((err) => toast.error(err?.message));
  };

  useEffect(() => {
    GetAllTicket();
  }, []);

  useEffect(() => {
    if (activeuser?.chatId && activeuser?.chatId != "")
      fetchChatMsgs({ _id: activeuser?.chatId, page: 1 });
  }, [activeuser?.chatId]);

  useEffect(() => {
    clearTimeout(myTimeOut);
    const timeout = setTimeout(() => {
      GetAllTicket();
    }, 700);
    setMytimeOut(timeout);
  }, [search]);

  return (
    <div className="h-full">
      <style>
        {`  textarea::placeholder {
        font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.36px;
  text-transform: capitalize;

                            padding: 0px !important;
                      `}
      </style>
      {user?.role == 1 ? (
        <DashHeader
          btn={
            <div
              className="uppercase w-[60px] flex justify-center items-center border-2 border-black rounded-xl cursor-pointer"
              onClick={() => navigate("/faq")}>
              <span className="text-neutral-700">&lt;</span>{" "}
            </div>
          }
        />
      ) : (
        <EstabDashHeader
          btn={
            <div
              className="uppercase w-[60px] flex justify-center items-center border-2 border-black rounded-xl cursor-pointer"
              onClick={() => navigate("/faq")}>
              <span className="text-neutral-700">&lt;</span>{" "}
            </div>
          }
        />
      )}
      <div className="flex gap-4 mt-5 h-[85%] w-full">
        <div className="p-4 bg-white flex-col rounded-3xl shadow-2xl overflow-hidden w-[25%]">
          {" "}
          <div className="flex gap-2 px-2 py-3.5 mt-5 text-base leading-6 rounded-xl bg-neutral-100 text-stone-300 ">
            <img
              loading="lazy"
              src="/images/search.png"
              className="shrink-0 aspect-square w-[14px] py-1"
            />
            <div className="flex-auto">
              <input
                type="text"
                value={search}
                className="w-full text-black outline-none bg-neutral-100"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="h-[75%] pb-5 overflow-y-scroll no-scrollbar mt-2">
            {RaisedTickets.history.length > 0 &&
              RaisedTickets.history.map((elem) => (
                <div
                  className={`flex px-2 py-2 mb-2 rounded-xl cursor-pointer ${
                    activeuser?.chatId == elem?.chatId
                      ? `${
                          user?.role == 1 ? "bg-yellow-200" : "bg-red-200"
                        } text-white`
                      : ""
                  } `}
                  onClick={() => handleActiveUser(elem)}
                  key={`${elem?._id}_Tickt`}>
                  <div className="flex flex-col w-full">
                    <div className="flex justify-between text-base ">
                      <span
                        className={`text-[14px] text-black leading-4} ${style.header}`}>
                        {elem?.title} &nbsp; &nbsp;
                      </span>
                      <span
                        className={`text-[14px] font-medium leading-5   ${
                          elem.isopen
                            ? user?.role == 2 &&
                              activeuser?.chatId == elem?.chatId
                              ? "text-red-600"
                              : "text-amber-300"
                            : `text-emerald-500`
                        } `}>
                        {elem.isopen ? `(Open)` : `(Closed)`}
                      </span>
                    </div>
                    <div className="mt-1 text-[13px] leading-6 text-stone-500">
                      {elem?.subject}
                    </div>
                    <div
                      className={`self-start text-[12px]    ${
                        activeuser?.chatId == elem?.chatId
                          ? `text-black`
                          : "text-stone-300"
                      }  `}>
                      {elem?.Created}
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div
            className="w-full p-2 text-center bg-black rounded-full cursor-pointer h-fit "
            onClick={() => setShowModal1(true)}>
            <p className="text-[15px] text-white font-medium font-poppins">
              Create New Ticket
            </p>
          </div>
        </div>

        <div className="flex-col w-full p-4 pt-5 bg-white shadow-2xl flex-2 rounded-3xl">
          {activeuser.chatId.trim() != "" ? (
            <MessagesSection
              setActiveUser={setActiveUser}
              activeuser={activeuser}
              inputtext={inputtext}
              fetchChatMsgs={fetchChatMsgs}
              setInputText={setInputText}
              msgs={msgs}
              setMsgs={setMsgs}
              isFaq={true}
              change={change}
              setChange={setChange}
            />
          ) : (
            <></>
          )}
        </div>
      </div>

      {showModal1 ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="relative w-auto max-w-3xl mx-auto my-6">
              <div className="relative flex flex-col w-full bg-white border-0 shadow-lg outline-none rounded-2xl focus:outline-none">
                <div className="flex flex-col bg-white rounded-3xl max-w-[615px]">
                  <div className="flex self-center">
                    <div className={` text-[22px] mt-7 } ${style.header}`}>
                      Create Ticket
                    </div>
                    <button
                      onClick={() => setShowModal1(false)}
                      className="absolute text-xl right-4 top-2">
                      &#10005;
                    </button>
                  </div>
                  <div className="flex flex-col px-7 mt-8 text-xs max-md:px-5 w-[400px] max-md:max-w-full">
                    <div className="flex flex-col px-5 py-2 bg-white border  border-gray-200 border-solid rounded-[92.207px] ">
                      <div className="pl-1 text-black max-md:max-w-full">
                        Ticket Title
                      </div>

                      <input
                        className="pl-1 mt-1 border-none outline-none text-zinc-500 max-md:max-w-full"
                        type="text"
                        placeholder="Enter Ticket Title"
                        value={ticketdata.title}
                        onChange={(e) =>
                          setTicketData({
                            ...ticketdata,
                            title: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="flex gap-4 px-5 py-2 mt-4 bg-white border border-gray-200 border-solid rounded-[92.207px] max-md:flex-wrap">
                      <div className="flex flex-col flex-1 max-md:max-w-full">
                        <div className="pl-1 text-black max-md:max-w-full">
                          Select Subject
                        </div>
                        <div className="w-full pl-1 mt-1 border-none outline-none text-zinc-500 max-md:max-w-full">
                          <select
                            id="countries"
                            className="w-full border-none outline-none"
                            onChange={(e) => {
                              setTicketData({
                                ...ticketdata,
                                subject: e.target.value,
                              });
                            }}>
                            <option selected>Select Subject</option>
                            <option value="Bartender not available">
                              Bartender not available
                            </option>
                            <option value="Payment not received">
                              Payment not received
                            </option>
                            <option value="Service delay">Service delay</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-start  px-5 py-2  mt-3.5 border border-gray-200 border-solid rounded-[30px] max-md:px-5 max-md:max-w-full">
                      <div className="text-black">Ticket Description</div>
                      <textarea
                        className="w-full border-none outline-none resize-none no-scrollbar"
                        style={{ padding: "0", margin: "0" }}
                        rows={5}
                        placeholder=" Enter Ticket Description"
                        value={ticketdata.description}
                        onChange={(e) =>
                          setTicketData({
                            ...ticketdata,
                            description: e.target.value,
                          })
                        }></textarea>
                    </div>
                    <Button
                      loading={loading}
                      className={`justify-center py-3.5 my-8 text-sm text-center  rounded-[92.207px] max-md:max-w-full ${
                        user?.role == 1
                          ? "bg-amber-300 text-black "
                          : "bg-estab-back text-white"
                      }`}
                      onClick={CreateTicket}>
                      Create Ticket
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-30"></div>
        </>
      ) : null}
    </div>
  );
}

export default ChatSupportBart;
