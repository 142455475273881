import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getFriendsList } from "../../../axios/axios";
import config from "../../../config/configuration";
import { formatNumber } from "../../../utils/helper";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

export default function Myfrnd({
  activefriendId,
  setactivefriendId,
  setShowModal1,
}) {
  const user = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    item_per_page: 12,
    total_page: 1,
    history: [],
  });

  const getfriendslist = () => {
    getFriendsList({
      token: user?.token,
      search: "",
      page: data.page,
    })
      .then((result) => {
        let res = result.data;
        let x = { ...data };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          total_page: res.total_page,
          item_per_page: res.item_per_page,
          history: [...res.history],
        };

        setData(x);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const decreasePage = () => {
    let x = { ...data };
    x = { ...x, page: x.page - 1 };
    setData(x);
  };
  const increasePage = () => {
    let x = { ...data };
    x = { ...x, page: x.page + 1 };
    setData(x);
  };

  useEffect(() => {
    getfriendslist();
  }, []);

  useEffect(() => {
    getfriendslist();
  }, [data.page]);

  const handleActiveFriend = (item) => {
    setShowModal1(true);
    setactivefriendId({
      _id: item?.friendDetial?._id,
      name: item.friendDetial.name,
      pic: item.friendDetial?.bartenderDetails?.[0]?.profileImg,
      distance: formatNumber(item.distance) || 0,
      city: item.friendDetial.bartenderDetails[0].location?.city,
      friendsCount: item.mutualFriends.TotalFriend,
      jobCount: item.friendDetial.totalJobs,
      reviewCount: item.friendDetial.totalReviews,
    });
  };

  return (
    <div>
      {!loading ? (
        data.history.length > 0 ? (
          <div className="grid grid-cols-5 gap-5">
            {data.history.map((item, ind) => {
              return (
                <div
                  key={`${ind}_FriendList`}
                  className="flex mt-8 relative cursor-pointer flex-col items-center  max-w-full rounded-3xl border border-solid border-neutral-200 w-[178px]"
                  onClick={() => handleActiveFriend(item)}>
                  <img
                    loading="lazy"
                    src={
                      `${config.cdnFront}/${item.friendDetial?.bartenderDetails?.[0]?.profileImg}` ||
                      ""
                    }
                    className="z-10 mt-0 w-[60px] absolute top-[-25px] rounded-full border-white border-solid shadow-lg aspect-square border-[3px]"
                  />
                  <div className={` text-base  mt-11 text-stone-900}`}>
                    {item.friendDetial.name}
                  </div>
                  <div className="flex gap-2 text-xs">
                    <div className="flex gap-1 text-black whitespace-nowrap">
                      <img
                        loading="lazy"
                        src="/images/Comminity/location.svg"
                        className="shrink-0 aspect-square w-[17px]"
                      />
                      <div className="my-auto">
                        {item.friendDetial.bartenderDetails[0].location?.city}
                      </div>
                    </div>
                    <div className="my-auto text-zinc-400">
                      {formatNumber(item.distance)} mi
                    </div>
                  </div>
                  <div className="flex gap-5 self-stretch py-1.5 pr-5 pl-2.5 mt-3.5 text-sm font-medium text-black whitespace-nowrap rounded-b-3xl bg-neutral-100 max-md:pr-5">
                    <div className="flex flex-1 gap-1">
                      <img
                        loading="lazy"
                        src="/images/Group.svg"
                        className="w-4 shrink-0 aspect-square"
                      />
                      <div className="my-auto">
                        {item.mutualFriends.TotalFriend}
                      </div>
                    </div>
                    <div className="flex flex-1 gap-1 pl-2">
                      <img
                        loading="lazy"
                        src="/images/Building.svg"
                        className="w-4 shrink-0 aspect-square"
                      />
                      <div className="my-auto">
                        {item.friendDetial.totalJobs}
                      </div>
                    </div>
                    <div className="flex flex-1 gap-1">
                      <img
                        loading="lazy"
                        src="/images/StartRating.svg"
                        className="w-4 shrink-0 aspect-square"
                      />
                      <div className="my-auto">
                        {item.friendDetial.totalReviews}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center w-full">
            <img src="/images/No_Friends.svg" sizes="20" alt="" />
            <p className="text-lg font-medium font-Roboto">
              No Friends Currently
            </p>
          </div>
        )
      ) : (
        <div className="flex items-center justify-center w-full h-[11.6rem]">
          <CircularProgress />{" "}
          <p className="pl-2 text-xl font-poppins">Loading...</p>
        </div>
      )}
      {data.total_page > 1 ? (
        <div className="flex items-center justify-center w-full gap-2">
          <button type="text" onClick={decreasePage}>
            Previous
          </button>
          {Array.from({ length: data.total_page }).map((elem, ind) => (
            <button
              key={`Pagi${ind}`}
              style={{
                height: "20px",
                width: "20px",
                padding: "0",
                fontSize: "12px",
                margin: "0 3px",
                background:
                  data.page === ind + 1 ? "rgb(255, 51, 51)" : "white",
                color: data.page === ind + 1 ? "white" : "black",
              }}>
              {ind + 1}
            </button>
          ))}

          <button type="text" onClick={() => increasePage()}>
            Next
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
