import React, { useEffect, useState } from "react";
import EstabDashHeader from "../../dashboard/Establishment/EstabDashHeader";
import WrapperDashEst from "../../authWrapper/WrapperDashEst";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MdOutlineDeleteSweep } from "react-icons/md";
import { Button } from "antd";
import { HiBadgeCheck } from "react-icons/hi";
import {
  DeleteCard,
  fetchAllCards,
  GetPaymentDetails,
  makePayment,
} from "../../../axios/axios";
import { CircularProgress, Skeleton } from "@mui/material";

function PaymentManage() {
  const user = useSelector((state) => state.user?.user);
  const [isallow, setIsAllow] = useState(false);
  const [change, setChange] = useState(false);
  const [availcard, setAvailCard] = useState([]);
  const [activeCard, setActiveCard] = useState("");

  const navigate = useNavigate();

  const getAllCards = () => {
    fetchAllCards({ token: user?.token })
      .then((res) => {
        setAvailCard(res.data);
        setIsAllow(true);
      })
      .catch((err) => console.log(err));
  };

  const deleteCard = (cardId) => {
    DeleteCard({ token: user?.token, cardId })
      .then((res) => {
        setChange(!change);
        toast.success(res?.message);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllCards();
  }, [change]);

  return (
    <div>
      <EstabDashHeader
        leftContent={
          <>
            <div className="pt-3 my-auto uppercase grow">
              <span className=" text-neutral-700">Payment Options</span>{" "}
            </div>
          </>
        }
      />
      <div className=" relative flex h-[80%] max-w-[900px] mx-auto mt-5 items-start pt-5 pb-6 pl-6 w-full bg-white rounded-3xl shadow-2xl max-md:pr-5 max-md:mt-6 max-md:max-w-full">
        <div className="w-full h-full">
          <div className="flex justify-start w-[350px]">
            <div
              className="flex px-6 cursor-pointer w-[260px] py-2.5 text-sm text-white bg-red-500 rounded-xl max-md:px-5 max-md:mt-10"
              onClick={() => navigate("./manage-pay-method/add-card")}>
              <div className="flex gap-2.5 py-1">
                <img
                  loading="lazy"
                  src="/images/completeProfile/white-plus.png"
                  className="w-5 shrink-0 aspect-square"
                />
                <div className="flex-auto my-auto">Add New Card</div>
              </div>
            </div>
          </div>
          {isallow ? <div className="flex gap-5 flex-wrap mt-3.5">
            {availcard &&
              availcard.map((elem, ind) => (
                <div
                  className="flex flex-col w-[260px] max-md:ml-0 max-md:w-full cursor-pointer"
                  key={`cards_${elem.id}_${ind}`}>
                  <div
                    className="flex flex-col w-full pt-3 mx-auto bg-red-500 grow rounded-2xl max-md:mt-3"
                    onClick={() => setActiveCard(elem?.id)}>
                    <div
                      to={"/CodeVerifyOp"}
                      className="flex flex-col px-2 tracking-wider text-white">
                      <div className="flex justify-between gap-5 text-xs font-medium">
                        <div className="items-center p-2 bg-red-600 w-fit h-fit rounded-xl ">
                          {elem?.brand}
                        </div>
                        {activeCard == elem?.id ? (
                          <HiBadgeCheck
                            className="h-[80%] w-auto "
                            onClick={() => deleteCard(elem.id)}
                          />
                        ) : (
                          <MdOutlineDeleteSweep
                            className="h-[80%] w-auto "
                            onClick={() => deleteCard(elem.id)}
                          />
                        )}
                      </div>
                      <div className="mt-3 text-base">
                        **** **** **** {elem?.last4}
                      </div>
                    </div>
                    <div className="flex h-full gap-5 p-3 mt-8 bg-red-600 whitespace-nowrap rounded-b-2xl">
                      <div className="flex flex-col flex-1 tracking-wider">
                        <div className="text-xs text-slate-50">
                          Name
                        </div>
                        <div className="text-sm text-white ">
                          {elem?.name}
                        </div>
                      </div>
                      <div className="my-auto text-sm tracking-wider text-right text-white">
                        {elem?.exp_month < 10
                          ? `0${elem.exp_month}`
                          : elem.exp_month}
                        /{elem?.exp_year % 100}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div> :
            <div className="flex items-center justify-center h-20">
              <CircularProgress /> <p className="pl-2 text-xl font-poppins">Loading...</p>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default PaymentManage;
