import React, { useState } from "react";
import styles from "../styles/forgetPass.module.css";
import Wrapper from "../components/authWrapper/Wrapper";
import { useNavigate } from "react-router-dom";
import { forgetPassApi, resendOtpApi, resetPassApi } from "../axios/axios";
import CodeVerifyPass from "../components/LoginSignUp/Bartender/CodeVerifyAccount";
import { verifyApi } from "../axios/axios";
import CreatePass from "../components/LoginSignUp/Bartender/CreatePass";
import Alert from "../components/Alerts/SuccessResetPassAlert";
import NotAuthenticatedLayout from "../layouts/NotAuthenticatedLayout";
import { Bounce, toast } from "react-toastify";
import { Button } from "antd";

export default function ForgetPass(props) {
  const [token, setToken] = useState(null);
  const [id, setId] = useState(null);
  const [steps, setSteps] = useState(0);
  const navigate = useNavigate();
  const [success, setSuccess] = useState("");
  const [user, setUser] = useState({
    email: "", // Initialize email as an empty string
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
    // Clear any previous errors when the user starts typing
    setError(null);
  };

  const handleSendOTP = async () => {
    try {
      // Basic input validation
      if (!user.email.trim()) {
        toast.error("Please enter your email address", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        return;
      }

      setLoading(true);
      // Call forgetPassApi function with the email
      const response = await forgetPassApi({ email: user.email });

      // Handle successful response
      setLoading(false);
      setId(response.data.data.id);
      setSteps(1);

      // Navigate to the code verification page
      //navigate("/codeVerifyPass", { state: response.data.data.id });
    } catch (error) {
      // Handle error
      setLoading(false);
      toast.error(error.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      console.error("Error sending OTP:", error);
    }
  };
  const verifyOtp = (otp) => {
    // Concatenate OTP values

    // Check if props.id and otp are not null or undefined

    setLoading(true);
    verifyApi({ refId: id, otp: otp, type: 1 })
      .then((response) => {
        // Handle success response here
        setLoading(false);
        setToken(response.data.data.token);
        setSteps(2);
        // const verData = response.data
        // console.log(Token)
        // Redirect or perform any action after successful verification
        //navigate("/CreatePass", { state: verData }); // Example redirect to reset password page
      })
      .catch((error) => {
        // Handle error response here
        setLoading(false);
        toast.error(error.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setSuccess("");
        console.log(error); // Update error state with error message
      });
  };

  const resetPassword = (password, confirmPassword) => {
    // Check if passwords match
    // if (password !== confirmPassword) {
    //   alert("Passwords do not match");
    //   return;
    // }
    if (password !== confirmPassword) {
      toast.error("Password did not match", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }

    // Call the resetPassApi function
    setLoading(true);
    resetPassApi({
      newPassword: password,
      cPassword: confirmPassword,
      token: token,
    })
      .then((response) => {
        // Handle success, maybe redirect to another page
        console.log("Password reset successful", response);
        setLoading(false);

        toast.success("Password reset successfully. Redirecting in 3 seconds", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });

        setTimeout(() => {
          navigate("/login");
        }, 3000);
        // Alert.alert("Password reset successfully ")
        // setTimeout

        // navigate("/")
      })
      .catch((error) => {
        // Handle error, maybe show an error message
        setLoading(false);
        toast.error(error.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        console.error("Error resetting password", error);
      });
  };

  const newOtp = () => {
    setLoading(true);
    resendOtpApi({ refId: id })
      .then((response) => {
        // Handle success response here
        setLoading(false);
        toast.success("Otp resent successfully ", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      })
      .catch((error) => {
        // Handle error response here
        //  setError("xyz");
        setLoading(false);
        toast.error(error.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setSuccess("");
        console.log(error); // Update error state with error message
      });
  };
  return (
    <NotAuthenticatedLayout>
      {steps === 0 && (
        <Wrapper>
          <div className="flex flex-col px-5 items-center bg-white border-solid w-[450px] border-[5.241px] border-[color:var(--ffcc-42,#FFCC42)] rounded-[41.924px] max-md:px-5 max-md:max-w-full}">
            <div className={styles.header}>Forgot Your Password?</div>
            <div className="mt-4 text-base text-center tracking-tight text-neutral-550 max-md:max-w-full">
              Don’t worry! Enter the email address or phone number and we’ll
              send an email with instructions with OTP to reset your password.
            </div>
            <div className="flex gap-5 mt-5 justify-between items-center self-stretch px-3 py-2 w-full text-sm bg-white border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
              <img
                loading="lazy"
                src="/images/person.png"
                className="my-auto aspect-[1.03] w-[25px]"
              />
              <div className="flex flex-col flex-1 max-md:max-w-full">
                <div className="text-black max-md:max-w-full">
                  Enter Email ID
                </div>
                <div className="text-zinc-500 max-md:max-w-full">
                  <input
                    className="border-none outline-none w-full"
                    type="text"
                    name="email"
                    value={user.email}
                    onChange={handleInputChange}
                    placeholder="Your email address or phone number"
                    aria-label="Email address or phone number"
                  />
                </div>
              </div>
            </div>
            {error && <div className="text-red-500 mt-2">{error}</div>}
            <Button
              loading={loading}
              type="text"
              style={{ height: "auto" }}
              className="justify-center p-3 text-small w-full mx-7 mt-4 mb-6 text-center text-black bg-amber-300 rounded-[124.047px] max-md:px-5 max-md:max-w-full my-3"
              onClick={handleSendOTP}>
              Send OTP
            </Button>
          </div>
        </Wrapper>
      )}

      {steps === 1 && (
        <CodeVerifyPass
          loading={loading}
          verifyOtp={verifyOtp}
          error={error}
          setError={setError}
          newOtp={newOtp}
          success={success}
          type={"forget"}
        />
      )}

      {steps === 2 && (
        <CreatePass resetPassword={resetPassword} loading={loading} />
      )}
    </NotAuthenticatedLayout>
  );
}
