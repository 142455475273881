import React, { useState, useEffect } from "react";
import { PiDeviceRotateBold } from "react-icons/pi";
import { motion } from "framer-motion";

const OrientationModal = () => {
    const [isPortrait, setIsPortrait] = useState(false);

    const checkOrientation = () => {
        if (window.innerHeight > window.innerWidth) {
            setIsPortrait(true);  // Portrait mode
        } else {
            setIsPortrait(false); // Landscape mode
        }
    };

    useEffect(() => {
        checkOrientation(); // Initial check
        window.addEventListener("resize", checkOrientation); // Check on resize

        return () => {
            window.removeEventListener("resize", checkOrientation); // Cleanup on unmount
        };
    }, []);

    return (
        <>
            {isPortrait && (
                <div className="fixed w-[100vw] h-[100vh] flex justify-center items-center z-[9999] text-white top-0 left-0 bg-[#000000d9]">
                    <div className="flex flex-wrap p-4 bg-white w-[20rem] rounded-[25px] justify-center items-center">
                        <p className="text-xl font-medium text-black font-poppins text-wrap">For a better and smoother experience, please rotate your device to landscape mode </p>
                        {/* <motion.div
                            animate={{ rotate: [0, -90, 0] }}
                            transition={{
                                duration: 2,
                                times: [0, 0.5, 1],
                                ease: "linear", 
                                repeat: Infinity, 
                                repeatType: "loop"
                            }}
                        >
                            <PiDeviceRotateBold className="w-auto h-20 text-black shrink-0 aspect-square" />
                        </motion.div> */}
                    </div>
                </div>
            )}
        </>
    );
};

export default OrientationModal;
