import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Switch } from "antd";
import style from "../eventDeatail.module.css";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
// import AboutGig from "./AboutGig";
// import AboutEstab from "./AboutEstab";
// import Benifits from "./Benifits";
import { toast } from "react-toastify";
import WrapperDashEst from "../../authWrapper/WrapperDashEst";
import { fetchEstabbGigDetails, fetchGigDetails } from "../../../axios/axios";
import EstabDashHeader from "../../dashboard/Establishment/EstabDashHeader";
import AboutGig from "../Bartender/AcceptReject/AboutGig";
import AboutEstab from "../Bartender/AcceptReject/AboutEstab";
import Benifits from "../Bartender/AcceptReject/Benifits";
import BartenderAvailToday from "../../dashboard/BartenderAvailToday";
import configuration from "../../../config/configuration";
import AppliedBartender from "./AppliedBartender";
import EstabPhotos from "../Bartender/AcceptReject/EstabPhotos";
import InternalBar from "../../internalBar/InternalBar";
import CancelBenefit1 from "./eventDetail1/CancelBenefit1";
import Payment1 from "./makePayment/Payment1";
import useSwipeHandler from "../../useSwipeHandler";

function GigDetails() {
  const user = useSelector((state) => state.user.user);
  const { gid } = useParams();
  const { state } = useLocation();

  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [loading, setLoading] = useState(true);
  const [jobdata, setJobData] = useState();
  const [activetab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  const fetchGigData = () => {
    fetchEstabbGigDetails({
      Job_Id: gid,
      token: user?.token,
    })
      .then((result) => {
        setJobData(result.data[0]);
        setLoading(false);
      })
      .catch((err) => {
        console.log("new Opp", err);
      });
  };

  const handelTabClick = (ind) => {
    setActiveTab(ind);
  };

  const swipeHandlers = useSwipeHandler({
    setTab: setActiveTab,
    maxCount: 3,
    lessCount: 0,
  });

  const NavItems = [
    {
      index: 0,
      label: "About The Gig",
      imgSrc: "/images/completeProfile/cocktail.png",
    },
    {
      index: 1,
      label: "About Establishment",
      imgSrc: "/images/completeProfile/cocktail.png",
    },
    {
      index: 2,
      label: "Benefits",
      imgSrc: "/images/completeProfile/cocktail.png",
    },
    {
      index: 3,
      label: "Photos",
      imgSrc: "/images/completeProfile/cocktail.png",
    },
  ];

  useEffect(() => {
    fetchGigData();
    return () => {
      setJobData();
    };
  }, [gid]);

  return (
    <WrapperDashEst>
      <EstabDashHeader
        leftContent={
          <>
            <div className="pt-3 my-auto uppercase grow">
              <span className=" text-neutral-700">Gig</span>{" "}
              <span className="text-yellow-400">Details</span>
            </div>
          </>
        }
      />
      {!loading && (
        <div className="flex h-full max-w-full gap-5 mt-7">
          {/* first */}
          <div
            className="relative w-[70%] h-full flex flex-col pt-5 pr-7 pl-4 bg-white rounded-3xl shadow-2xl "
            {...swipeHandlers}>
            <div className="flex min-h-[30%] overflow-hidden relative flex-col gap-5 self-stretch px-5 py-5 w-full mb-3.5 ">
              <img
                loading="lazy"
                src="/images/completeProfile/Background-Image.png"
                className="absolute inset-0 rounded-xl size-full"
              />
              <div className="relative flex-auto max-md:max-w-full">
                <div className="flex gap-1 max-md:flex-col max-md:gap-0">
                  <div className="flex flex-col max-md:ml-0 max-md:w-full">
                    <img
                      loading="lazy"
                      src={
                        `${configuration.cdnFront}/${jobdata?.establishmentid?.EprofileImg}` ||
                        "/images/completeProfile/Bar-full.png"
                      }
                      className="shrink-0 max-w-full rounded-xl  border-2 border-white border-solid aspect-square w-[120px] max-md:mt-8"
                    />
                  </div>
                  <div className="flex flex-col ml-5 w-[71%] max-md:ml-0 max-md:w-full">
                    <div className="relative flex flex-col self-stretch my-auto max-md:mt-10 max-md:max-w-full">
                      <div className="mt-1 text-lg font-semibold leading-6 tracking-tight text-yellow-400 max-md:max-w-full">
                        {jobdata?.establishmentid?.establishmentName}
                      </div>
                      <div className="mt-1 text-sm leading-5 text-white max-md:max-w-full">
                        {`${jobdata?.establishmentid?.location?.streetName}, ${jobdata?.establishmentid?.location?.city}, ${jobdata?.establishmentid?.location?.state}, ${jobdata?.establishmentid?.location?.postalCode}`}
                      </div>
                      <div className="flex w-full gap-4 mt-4 text-xs max-md:flex-wrap max-md:max-w-full">
                        <div className="flex gap-1.5 items-start self-start">
                          <img
                            loading="lazy"
                            src="/images/completeProfile/yellow-timer.png"
                            className="shrink-0 aspect-square w-[15px]"
                          />
                          <div className="flex flex-col">
                            <div className="text-white text-opacity-80">
                              Shift Time
                            </div>
                            <div className="mt-1 font-medium text-white">
                              {dayjs(jobdata?.startTime, "HH:mm").format(
                                "hh:mm A"
                              )}{" "}
                              -{" "}
                              {dayjs(jobdata?.endTime, "HH:mm").format(
                                "hh:mm A"
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-auto gap-6">
                          <div className="flex flex-col">
                            <div className="flex gap-1.5 whitespace-nowrap text-white text-opacity-80">
                              <img
                                loading="lazy"
                                src="/images/completeProfile/yellow-user.png"
                                className="shrink-0 aspect-square w-[17px]"
                              />
                              <div className="flex-auto my-auto">
                                Accommodates
                              </div>
                            </div>
                            <div className="self-center mt-1.5 font-medium text-white">
                              {jobdata?.establishmentid?.accommodates}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <button className=" absolute top-3 right-3 bg-white flex w-fit px-3 py-1  rounded-[110px] h-fit ">
                  <img
                    loading="lazy"
                    src="/images/completeProfile/Vector.png"
                    className=" aspect-square w-[18px] h-[18px]   max-md:mt-10 max-md:ml-2.5"
                  />

                  <div className="pl-1 font-semibold">{jobdata?.rating}</div>
                </button>
              </div>
            </div>
            <div>
              <InternalBar
                NavItems={NavItems}
                onClick={handelTabClick}
                tab={activetab}
              />
            </div>
            <div className="pb-3 overflow-y-scroll no-scrollbar">
              {activetab === 0 && <AboutGig jobdata={jobdata} />}
              {activetab === 1 && (
                <AboutEstab establishmentid={jobdata?.establishmentid} />
              )}
              {activetab === 2 && <Benifits jobdata={jobdata} />}
              {activetab === 3 && (
                <EstabPhotos Estabpic={jobdata?.establishmentid} />
              )}
            </div>
          </div>
          {/* second */}
          {state == 2 && (
            <div className="w-[30%] overflow-y-scroll no-scrollbar">
              <AppliedBartender
                bartenders={jobdata?.applied}
                jobId={jobdata?._id}
              />
            </div>
          )}
          {state == 1 && (
            <div className="w-[30%] overflow-y-scroll no-scrollbar p-2">
              <CancelBenefit1
                bartenders={jobdata?.assigned}
                jobdata={jobdata}
              />
            </div>
          )}
          {state == 3 && (
            <div className="w-[30%] overflow-y-scroll no-scrollbar bg-white shadow-xl rounded-3xl">
              <Payment1 bartenders={jobdata?.assigned} jobdata={jobdata} />
            </div>
          )}
        </div>
      )}

      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="relative w-auto max-w-3xl mx-auto my-6">
              {/*content*/}
              <div className="flex flex-col px-7 py-5 text-center bg-white rounded-3xl max-w-[432px]">
                <div className="self-center text-base font-medium leading-8 text-black">
                  Are you sure you want to
                  <br />
                  cancel your gig?
                </div>
                <div className="flex gap-2.5 mt-4 text-base capitalize">
                  <button
                    onClick={() => setShowModal(false)}
                    className="justify-center px-8 py-3 text-black whitespace-nowrap bg-white border border-amber-300 border-solid rounded-[121px]">
                    Cancel
                  </button>
                  <button className="justify-center px-5 py-3 text-black bg-amber-300 rounded-[121px]">
                    <Link to={"/GigsNewApply"}>Yes, I’m Sure </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="inset-0 z-40 bg-black opacity-230fixed"></div>
        </>
      ) : null}

      {showModal1 ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="relative w-auto max-w-3xl mx-auto my-6">
              {/*content*/}
              <div className="relative flex flex-col w-full bg-white outline-none rounded-xl focus:outline-none">
                {/*header*/}
                <button
                  className="self-end pt-3 pr-4 text-2xl"
                  onClick={() => setShowModal1(false)}>
                  {" "}
                  &#10005;
                </button>
                <img
                  src="/images/Success1.png"
                  className="w-[400px] px-20 pt-10 pb-20"
                />
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}
    </WrapperDashEst>
  );
}

export default GigDetails;
