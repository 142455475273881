import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/userSlice";
import AuthenticatedLayout from "../layouts/AuthenticatedLayout";
import { LogoutUser } from "../axios/axios";

export default function Logout() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const ntoken = useSelector((state) => state.ntoken.ntoken);
  const userlogout = async () => {
    try {
      await LogoutUser({ token: user?.token, notifytoken: ntoken });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    userlogout();
    dispatch(logout());
  }, []);
  return <AuthenticatedLayout />;
}
