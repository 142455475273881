import React, { useEffect, useState } from "react";
import Wrappers from "../Wrappers";
import Cp1 from "./Cp1";
import Cp2 from "./Cp2";
import Cp3 from "./Cp3";
import Cp4 from "./Cp4";
import Cp5 from "./Cp5";
import Cp7 from "./Cp7";
import Cp8 from "./Cp8";
import Cp6 from "./Cp6";
import { useSelector } from "react-redux";
import { fetchBartenderDetailsApi } from "../../../axios/axios";

export default function BartenderSetup() {
  const user = useSelector((state) => state.user.user);

  const [activeTab, setActiveTab] = useState(0);
  const [data, setData] = useState([
    {
      bio: "",
      yearsOfExperience: "",
      birthDate: "",
      profile: "",
      location: {
        state: "",
        city: "",
        postalCode: "",
        streetName: "",
      },
      latitude: "",
      longitude: "",
    },
    {
      designation: [],
    },
    {
      skills: [],
      professionalSports: [],
      collegiateSports: [],
      festivals: [],
      others: [],
    },
    {
      portfoliolink: "",
      linkedinlink: "",
      instagramlink: "",
      resume: null,
      certificates: [],
    },
    {
      ssNumber: "",
    },
    {
      hourlyRates: "",
      accountDetail: {
        bankName: "",
        holderName: "",
        bankCode: "",
        accountNumber: "",
        routingNumber: "",
        accountType: "individual",
      },
    },
  ]);

  const fetchBartenderDetails = () => {
    fetchBartenderDetailsApi(user.token)
      .then((result) => {
        setActiveTab(user.accSetCount ? user.accSetCount : 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchBartenderDetails();
  }, []);

  return (
    <>
      {activeTab >= 1 && activeTab <= 6 && (
        <Wrappers
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          showHeader={true}
          totalSteps={6}>
          {activeTab === 1 && (
            <Cp1
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={data[0]}
            />
          )}
          {activeTab === 2 && (
            <Cp2
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={data[1]}
            />
          )}
          {activeTab === 3 && (
            <Cp3
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={data[2]}
            />
          )}
          {activeTab === 4 && (
            <Cp4
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={data[3]}
            />
          )}
          {/* {
                    activeTab === 5 &&
                    <Cp5 activeTab={activeTab} setActiveTab={setActiveTab} />
                } */}
          {activeTab === 5 && (
            <Cp6
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={data[4]}
            />
          )}
          {activeTab === 6 && (
            <Cp7
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={data[5]}
            />
          )}
        </Wrappers>
      )}
      {activeTab > 6 && <Cp8 />}
    </>
  );
}
