import React from 'react'

export default function CameraSvg() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="solar:camera-broken">
    <g id="Group">
    <path id="Vector" d="M11 14.668C12.5188 14.668 13.75 13.4368 13.75 11.918C13.75 10.3992 12.5188 9.16797 11 9.16797C9.48122 9.16797 8.25 10.3992 8.25 11.918C8.25 13.4368 9.48122 14.668 11 14.668Z" stroke="black" stroke-width="1.2"/>
    <path id="Vector_2" d="M1.83398 12.2504C1.83398 9.44079 1.83398 8.03646 2.52057 7.02813C2.81863 6.58964 3.20059 6.21454 3.6444 5.92446C4.3044 5.49088 5.13123 5.33596 6.39715 5.28096C7.00123 5.28096 7.52098 4.83179 7.63923 4.24971C7.7296 3.82334 7.9644 3.44124 8.30395 3.168C8.64351 2.89475 9.06698 2.74712 9.50282 2.75004H12.4985C13.4041 2.75004 14.1842 3.37796 14.3621 4.24971C14.4803 4.83179 15.0001 5.28096 15.6041 5.28096C16.8691 5.33596 17.696 5.49179 18.3569 5.92446C18.8015 6.21596 19.1837 6.59088 19.4807 7.02813C20.1673 8.03646 20.1673 9.44079 20.1673 12.2504C20.1673 15.059 20.1673 16.4634 19.4807 17.4726C19.1821 17.9107 18.8003 18.2857 18.3569 18.5763C17.3293 19.25 15.8984 19.25 13.0375 19.25H8.96382C6.1029 19.25 4.67198 19.25 3.6444 18.5763C3.20123 18.2854 2.81967 17.91 2.52148 17.4717C2.3228 17.1748 2.17618 16.8462 2.0879 16.5M17.4173 9.16671H16.5006" stroke="black" stroke-width="1.2" stroke-linecap="round"/>
    </g>
    </g>
    </svg>
  )
}
