// useSwipeHandler.js
import { useSwipeable } from 'react-swipeable';

const useSwipeHandler = ({setTab,maxCount,lessCount}) => {
  return useSwipeable({
    onSwipedLeft: () => setTab((prevTab) => (prevTab < maxCount ? prevTab + 1 : prevTab)),
    onSwipedRight: () => setTab((prevTab) => (prevTab > lessCount ? prevTab - 1 : prevTab)),
    trackMouse: true, // Allows swiping with mouse as well
  });
};

export default useSwipeHandler;
