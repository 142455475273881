import React from "react";
import { Link, useNavigate } from "react-router-dom";
import configuration from "../../../config/configuration";

function EditProfile({ userdata, selectedImage, handleFileChange }) {
  const navigate = useNavigate();
  return (
    <div className=" flex flex-col py-4 mx-2 px-8 w-[370px] max-w-lg text-base text-black  max-md:px-5">
      <div className="flex flex-col items-center ">
        <div className="relative">
          {selectedImage != null ? (
            <img
              loading="lazy"
              src={selectedImage}
              className="h-32 w-32 rounded-[50%]"
            />
          ) : (
            <img
              loading="lazy"
              src={`${configuration.cdnFront}/${userdata?.profileImg}` || ""}
              className="h-32 w-32 rounded-[50%]"
            />
          )}
          <div className="absolute bottom-0 right-0">
            <img loading="lazy" src="/images/Camera_Icon.svg" />
          </div>
          <input
            type="file"
            className="absolute top-0 w-full h-full opacity-0"
            // value={selectedImage}
            onChange={(e) => handleFileChange(e)}
          />
        </div>
        <p className="mt-1 text-lg text-red-400">{userdata?.uid?.name}</p>
      </div>

      <div
        className="flex justify-between gap-3 py-2 mt-5 bg-white border-2 border-solid cursor-pointer px-7 rounded-xl border-amber-300 max-md:flex-wrap max-md:px-5 max-md:mt-10 max-md:max-w-full "
        onClick={() =>
          navigate("/profile/bartender-edit", { state: { passactive: 1 } })
        }>
        <div>Basic Info</div>
        <span className="text-xl">&#10095;</span>
      </div>
      <div
        className="flex justify-between gap-3 py-2 mt-5 bg-white border-2 border-solid cursor-pointer px-7 whitespace-nowrap rounded-xl border-amber-300 max-md:flex-wrap max-md:px-5 max-md:max-w-full"
        onClick={() =>
          navigate("/profile/bartender-edit", { state: { passactive: 2 } })
        }>
        <div className="my-auto">Designation</div>
        <span className="text-xl">&#10095;</span>
      </div>
      <div
        className="flex gap-3 py-2 mt-5 bg-white border-2 border-solid cursor-pointer px-7 rounded-xl border-amber-300 max-md:flex-wrap max-md:px-5 max-md:max-w-full"
        onClick={() =>
          navigate("/profile/bartender-edit", { state: { passactive: 3 } })
        }>
        <div className="flex-auto">Skills & Interest</div>
        <span className="text-xl">&#10095;</span>
      </div>
      <div
        className="flex gap-3 py-2 mt-5 bg-white border-2 border-solid cursor-pointer px-7 rounded-xl border-amber-300 max-md:flex-wrap max-md:px-5 max-md:max-w-full"
        onClick={() =>
          navigate("/profile/bartender-edit", { state: { passactive: 4 } })
        }>
        <div className="flex-auto">Links & Documents</div>
        <span className="text-xl">&#10095;</span>
      </div>
      <div
        className="flex gap-3 py-2 mt-5 bg-white border-2 border-solid cursor-pointer px-7 rounded-xl border-amber-300 max-md:flex-wrap max-md:px-5 max-md:max-w-full"
        onClick={() =>
          navigate("/profile/bartender-edit", { state: { passactive: 5 } })
        }>
        <div className="flex-auto my-auto">Background Check</div>
        <span className="text-xl">&#10095;</span>
      </div>
      <div
        className="flex gap-3 py-2 mt-5 bg-white border-2 border-solid cursor-pointer px-7 rounded-xl border-amber-300 max-md:flex-wrap max-md:px-5 max-md:max-w-full"
        onClick={() =>
          navigate("/profile/bartender-edit", { state: { passactive: 6 } })
        }>
        <div className="flex-auto my-auto">Payment Setup</div>
        <span className="text-xl">&#10095;</span>
      </div>
    </div>
  );
}

export default EditProfile;
