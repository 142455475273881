import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { UpdateNoitfyToken } from "./axios/axios";

const firebaseConfig = {
  apiKey: "AIzaSyAO7FHZ97nRhSynG9UoqsCpxY6zR99-usc",
  authDomain: "barooom-app.firebaseapp.com",
  projectId: "barooom-app",
  storageBucket: "barooom-app.appspot.com",
  messagingSenderId: "163811567172",
  appId: "1:163811567172:web:e401acef52cd2cc5a7be11",
  measurementId: "G-07P0JDTLGP",
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const notifytoken = await getToken(messaging, {
          vapidKey:
            "BCjoKlhc791En9xNRcAP9GJC6AMhnGHmCiKcYZ-1TYrz7BTi6YNnsncHq9dPNJjQV3AvUjBHfRlkm4GJ69JZWnE",
        });
        try {
          UpdateNoitfyToken({
            token: token,
            notifytoken: notifytoken,
          });
        } catch (error) {
          console.log(error);
        }

        resolve(token);
      }
    } catch (error) {
      reject(error);
    }
  });
};

//BPm_YCwRPVN8nk54PToJLbzah3wePkQ7hzICBQlai4sGljgUg6pVQ6pdsT0daqYkS-x1SqWSM5HlKXkL1X4MoK4
