import React, { useState } from "react";
import style from "../../eventDeatail.module.css";
import { Link, useNavigate } from "react-router-dom";
import WrapperDashEst from "../../../authWrapper/WrapperDashEst";
import DropdownEst from "../../../dashboard/Establishment/DropdownEst";
import NotificationEst from "../../../dashboard/Establishment/NotificationEst";
import configuration from "../../../../config/configuration";
import dayjs from "dayjs";

function CancelBenefit1({ bartenders, jobdata }) {
  const items = [1, 2, 3, 4, 5];
  const [barList, setBarlist] = useState(bartenders);
  console.log(jobdata);
  const navigate = useNavigate();
  const [showModal1, setShowModal1] = React.useState(false);
  const [showModal2, setShowModal2] = React.useState(false);

  return (
    <div>
      {/* <div className="flex gap-2 text-sm mt-10 self-center max-md:flex-wrap">
            <button
              onClick={() => setShowModal1(true)}
              className="grow justify-center py-3 my-1.5 bg-amber-300 rounded-[92.207px] w-[400px] max-md:max-w-full">
              Cancel Gig
            </button>
            <img
              onClick={() => setShowModal2(true)}
              loading="lazy"
              src="/images/completeProfile/emptyPen.png"
              className="shrink-0 aspect-square border-2 border-yellow-400 m-2 p-2 rounded-[15px] h-[50px] w-[50px]"
            />
          </div> */}
      {/* second */}
      <div className=" max-md:flex-wrap flex-2">
        <div className="bg-white rounded-3xl shadow-2xl min-h-full ">
          <div className=" p-4  ">
            <div className={`text-[17px] text-black} ${style.header}`}>
              Bartenders of {jobdata?.establishmentData?.[0]?.establishmentName}{" "}
              Establishment
            </div>
            <div className="mt-10">
              {barList?.length > 0 &&
                barList?.map((elem, ind) => {
                  return (
                    <div
                      key={`AssingBar_${elem._id}_${ind}`}
                      style={{
                        boxShadow: "0px 0px 21.461px 0px rgba(0, 0, 0, 0.10)",
                      }}
                      className="flex flex-col px-2 pt-1.5 pb-2 mt-2  rounded-xl border-l-4 border-red-500 border-solid max-md:mr-2.5 max-md:max-w-full">
                      <div className="flex gap-2.5">
                        <img
                          loading="lazy"
                          src={
                            `${configuration.cdnFront}/${elem?.pic}` ||
                            "/images/completeProfile/user1.png"
                          }
                          className="shrink-0 self-start size-14 rounded-xl"
                        />

                        <div className="flex flex-col grow shrink-0 basis-0 w-fit">
                          <div className="flex gap-5 justify-between px-px">
                            <div className="flex-auto  text-sm tracking-tighter font-medium text-black">
                              {elem?.name}
                            </div>
                          </div>
                          <div className="text-[12px] mt-[2px] flex text-neutral-400">
                            <img
                              loading="lazy"
                              src="/images/completeProfile/clock.png"
                              className="shrink-0 self-start mr-1 aspect-square"
                            />
                            Shift Time:{" "}
                            {dayjs(jobdata?.startTime, "HH:mm").format(
                              "hh:mm A"
                            )}{" "}
                            -{" "}
                            {dayjs(jobdata?.endTime, "HH:mm").format("hh:mm A")}
                          </div>
                          <div className="flex-auto text-[#9D9D9D] my-auto text-[12px]">
                            <Link to={`/bartender-details/${elem?._id}`}>
                              View Profile
                            </Link>
                          </div>
                        </div>
                        <div
                          onClick={() =>
                            navigate(`/dashboard/chat/${elem?._id}`)
                          }
                          className="cursor-pointer">
                          <img
                            loading="lazy"
                            src="/images/completeProfile/yellowChat.png"
                            className="shrink-0 self-start  aspect-square"
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      <>
        {showModal1 ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative rounded-[20px] flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 ">
                    <div className="flex flex-col p-3 text-sm bg-white max-w-[438px]">
                      <div className="self-center text-base font-medium leading-7 text-center text-black">
                        Are you sure you want to cancel your gig?
                      </div>
                      <ul
                        style={{ listStyle: "disc" }}
                        className=" text-neutral-400 ml-3">
                        <li>
                          <div className="mt-5 text-neutral-400 max-md:max-w-full">
                            24 hours - No charge
                          </div>
                          <li>
                            <div className="mt-1 text-neutral-400 max-md:max-w-full">
                              12 hours - $4.99
                            </div>
                          </li>
                        </li>
                        <li>
                          <div className="mt-1 text-neutral-400 max-md:max-w-full">
                            6 hours - Pay bartender 1 hour of the wage expected
                          </div>
                        </li>
                      </ul>

                      <div className="flex gap-2.5 self-center mt-7 text-center capitalize">
                        <button
                          onClick={() => setShowModal1(false)}
                          className="justify-center px-12 py-3 text-black whitespace-nowrap bg-white border border-amber-300 border-solid rounded-[122px] max-md:px-5">
                          Cancel
                        </button>
                        <button className="justify-center px-7 py-3 text-black bg-amber-300 rounded-[122px] max-md:px-5">
                          <Link to={"/CurrentGigsEstablishment"}>
                            {" "}
                            Yes, I’m Sure
                          </Link>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
      <>
        {showModal2 ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative rounded-[20px] flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex flex-col items-center  pb-10  text-base text-center capitalize bg-white rounded-3xl max-w-[538px] max-md:px-5">
                    <span
                      onClick={() => setShowModal2(false)}
                      className="text-[22px] cursor-pointer self-end pt-2 pr-4 mb-2">
                      &#10005;
                    </span>
                    <Link
                      to={"/GigDetail1"}
                      className="justify-center items-center mx-10 px-20 py-3  text-black bg-amber-300 rounded-[122px] max-md:px-5">
                      Edit Gig Details
                    </Link>
                    <Link
                      to={"/EditEsta1"}
                      className="justify-center items-start px-8 py-3 mx-10 mt-4 text-black bg-white border border-amber-300 border-solid rounded-[122px] max-md:pr-5 max-md:pl-6">
                      Edit Establishment Details
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    </div>
  );
}

export default CancelBenefit1;
