import React from "react";

function AboutEstab({ establishmentid }) {
  return (
    <div>
      {" "}
      <div className=" flex flex-col mt-10 ml-7 text-sm text-neutral-400 max-md:ml-2.5">
        About the Establishment
        <span className="mt-1  text-sm text-neutral-800 max-md:ml-2.5">
          {establishmentid?.discription}
        </span>
      </div>
      <div className=" flex flex-col mt-6 ml-7 text-sm text-neutral-400 max-md:ml-2.5">
        Type of cuisine/service
        <span className="mt-1  text-sm text-neutral-800 max-md:ml-2.5">
          {establishmentid?.serviceType}
        </span>
      </div>
      <div className=" flex flex-col mt-6 ml-7 text-sm text-neutral-400 max-md:ml-2.5">
        Dress Code (Optional)
        <span className="mt-1  text-sm text-neutral-800 max-md:ml-2.5">
          {establishmentid?.dressCode}
        </span>
      </div>
      <div className=" flex flex-col mt-6 ml-7 text-sm text-neutral-400 max-md:ml-2.5">
        POS System (Optional)
        <span className="mt-1  text-sm text-neutral-800 max-md:ml-2.5">
          {establishmentid?.posSystem}
        </span>
      </div>
      <div className=" flex flex-col mt-6 ml-7 text-sm text-neutral-400 max-md:ml-2.5">
        Peek Hours
        <span className="mt-1  text-sm text-neutral-800 max-md:ml-2.5">
          {establishmentid?.customerFlow}
        </span>
      </div>
      <div className=" flex flex-col mt-6 ml-7 text-sm text-neutral-400 max-md:ml-2.5">
        Number of years in business
        <span className="mt-1  text-sm text-neutral-800 max-md:ml-2.5">
          {establishmentid?.yearsOfExperience} Years
        </span>
      </div>
      <div className=" flex flex-col mt-6 ml-7 text-sm text-neutral-400 max-md:ml-2.5">
        Establishment Website Link
        <span className="mt-1  text-sm text-neutral-800 max-md:ml-2.5">
          {establishmentid?.websiteLink}
        </span>
      </div>
    </div>
  );
}

export default AboutEstab;
