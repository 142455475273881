import React from "react";
import style from "./BaroomKeyFeature.module.css";

const BaroomKeyFeature = () => {
  return (
    <>
      <div className={style.main}>
        <div className={style.part1}>
          <div className={style.innerPart1}>
            <div className={style.leftPart}>
              <img
                loading="lazy"
                src={"/KeyFeature/KeyFeatrure/iPhone1.png"}
                alt=""
              />
            </div>
            <div className={style.rightPart}>
              <div>
                <h3>Key Features of Barooom</h3>
                <div>
                  <ul>
                    <li>Location-Based Hiring</li>
                    <li>Innovative Instant Hiring Process</li>
                    <li>User Friendly Interface For Seamless Interaction</li>
                    <li>
                      Unique Open Community Allowing Bartenders To “Friend”
                      Others And Share Reviews with Full Transparency
                    </li>
                  </ul>
                  <button className={style.button}>Downlaod Now</button>
                </div>
              </div>
            </div>
          </div>
          <img
            loading="lazy"
            className={style.lemonImg1}
            src={"/KeyFeature/KeyFeatrure/LemonImg.png"}
          />
        </div>

        <div className={style.part1}>
          <div className={style.innerPart1}>
            <div className={style.leftPart}>
              <img
                loading="lazy"
                src={"/KeyFeature/KeyFeatrure/iPhone2.png"}
                alt=""
              />
            </div>
            <div className={style.rightPart}>
              <div>
                <h3>BENEFITS FOR BARTENDER </h3>
                <div>
                  <ul>
                    <li>Turn On AVAILABLE NOW For Same Day Hiring</li>
                    <li>Set Your Own Hourly Wage</li>
                    <li>Explore New Opportunities</li>
                    <li>Take Control Of Your Schedule. Work When You Want. </li>
                    <li>Chat With Your Cohorts To Locate The Best Gigs </li>
                  </ul>
                  <button className={style.button}>Downlaod Now</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={style.part1}>
          <img
            loading="lazy"
            className={style.lemonLeft2}
            src={"/KeyFeature/KeyFeatrure/lemonLeft.png"}
          />
          <div className={style.innerPart1}>
            <div className={style.leftPart}>
              <img
                loading="lazy"
                src={"/KeyFeature/KeyFeatrure/iPhone3.png"}
                alt=""
              />
            </div>
            <div className={style.rightPart}>
              <div>
                <h3> BENEFITS FOR ESTABLISHMENTS</h3>
                <div>
                  <ul>
                    <li>
                      Enhance Customer Experience With Professional Bartenders
                    </li>
                    <li>Trusted, Vetted & Proven Bartenders</li>
                    <li>Instantaneous (Available Now) Hiring Solutions</li>
                    <li>Community Approach Enables Peer Reviews</li>
                    <li>
                      Hire 20 Or Just One, Increase Your Bottom Line By Paying
                      Only Hours Required Rather Than Shifts
                    </li>
                  </ul>
                  <button className={style.button}>Downlaod Now</button>
                </div>
              </div>
            </div>
          </div>
          <img
            loading="lazy"
            className={style.lemonImg3}
            src={"/KeyFeature/KeyFeatrure/LemonImg.png"}
          />
        </div>
      </div>
    </>
  );
};

export default BaroomKeyFeature;
