import React, { useState } from "react";
import CodeVerifyPass from "../components/LoginSignUp/Bartender/CodeVerifyAccount";
import { resendOtpApi, verifyApi } from "../axios/axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../redux/userSlice";
import { Bounce, toast } from "react-toastify";
export default function Verify() {
  const navigate = useNavigate();
  const loc = useLocation();
  const [id, setId] = useState(loc.state);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const verifyOtp = (otp) => {
    if (!otp || otp.length < 4) {
      toast.error("Otp can't be 4 less than four digits", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }

    setLoading(true);

    verifyApi({ refId: id, otp: otp, type: 0 })
      .then((response) => {
        // Handle success response here
        setLoading(false);
        toast.success(response.data.message);
        console.log("Code verify successfully ", response);
        let usr = {
          ...response.data.data.user,
          token: response.data.data.token,
        };

        dispatch(login(usr));
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        console.log(error); // Update error state with error message
      });
  };

  const newOtp = () => {
    setLoading(true);
    resendOtpApi({ refId: id })
      .then((response) => {
        // Handle success response here
        setLoading(false);
        console.log("Otp resent successfully ", response);
        toast.success("Otp resent successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      })
      .catch((error) => {
        // Handle error response here
        //  setError("xyz");
        toast.success(error.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setLoading(false);
        console.log(error); // Update error state with error message
      });
  };

  return (
    <>
      <CodeVerifyPass
        loading={loading}
        verifyOtp={verifyOtp}
        error={error}
        setError={setError}
        newOtp={newOtp}
        success={success}
        type={"signup"}
      />
    </>
  );
}
