import React from "react";

function Benifits({ jobdata }) {
  return (
    <div className=" flex flex-col mt-6 ml-7 text-sm text-neutral-400 max-md:ml-2.5">
      Benifits
      <span className="mt-1  text-sm text-neutral-800 max-md:ml-2.5">
        <ul className="list-disc ml-7">
          {jobdata?.benifits.length > 0 &&
            jobdata.benifits.map((elem) => <li>{elem}</li>)}
        </ul>
      </span>
    </div>
  );
}

export default Benifits;
