import React, { useEffect, useState } from "react";
import configuration from "../../../config/configuration";
import { Rating } from "@mui/material";
import style from "../Review.module.css";
import {
  RequestReviewEstabNotify,
  RequestReviewNotify,
} from "../../../axios/axios";
import { toast } from "react-toastify";
import { formatNumber } from "../../../utils/helper";
import { Button } from "antd";

function ReviewCardEstablishment({ item, token, role }) {
  const [isReviewRequested, setIsReviewRequested] = useState(false); // New state to track request
  const [loading, setloading] = useState(false); // New state to track request

  const RequestReivew = ({ bid, eid, establishmentId }) => {
    setloading(true);
    if (role == 2) {
      RequestReviewEstabNotify({ token, bid, eid })
        .then((result) => {
          toast.success("Review Requested Successfully");
          setloading(false);
          setIsReviewRequested(true);
        })
        .catch((err) => console.log("Error at Fetching Data, ", err));
    } else {
      RequestReviewNotify({ token, establishmentId })
        .then((result) => {
          toast.success("Review Requested Successfully");
          setloading(false);
          setIsReviewRequested(true);
        })
        .catch((err) => console.log("Error at Fetching Data, ", err));
    }
  };

  useEffect(() => {
    return () => {
      setIsReviewRequested(false);
    };
  }, []);

  return (
    <div className="p-2 shadow-lg rounded-2xl w-[18rem] h-[11.6rem]">
      <div className="flex flex-col w-full h-full">
        <div className="flex justify-between">
          <div className="flex gap-2">
            <img
              loading="lazy"
              src={
                role == 2 && item?.Bar?.pic
                  ? `${configuration.cdnFront}/${item?.Bar?.pic}`
                  : `${configuration.cdnFront}/${item?.EstablishmentDetails?.EprofileImg}`
              }
              className="size-[55px] rounded-xl"
            />
            <div>
              <p className="text-[1.25rem] font-normal font-Righteous">
                {" "}
                {role == 2
                  ? item?.Bar?.name
                  : item?.EstablishmentDetails?.establishmentName}
              </p>
              <div className="flex items-center">
                <img
                  loading="lazy"
                  src="/images/Comminity/location.svg"
                  className="size-[20px]"
                />
                <p>
                  {role != 2
                    ? `${formatNumber(item?.distance)} mi `
                    : `${item?.establishmentName}`}
                </p>
              </div>
            </div>
            <img
              loading="lazy"
              src="/images/Comminity/quotes.png"
              className="size-[50px] translate-y-[-0.5rem]"
            />
          </div>
        </div>
        <div className="bg-[#E8E8E8] h-[1px] my-2" />

        {item?.isReviewed ? (
          <div className="flex flex-col flex-grow gap-2 py-2 pl-1 overflow-hidden">
            <div className="flex gap-4">
              <p>
                {parseFloat(
                  Math.round((item?.Reviews?.overall || 0) * 2) / 2
                ).toFixed(1)}
              </p>{" "}
              <Rating
                name="read-only"
                precision={0.5}
                value={Math.round((item?.Reviews?.overall || 0) * 2) / 2}
                readOnly
              />
            </div>
            <div className="flex-grow pr-1 overflow-y-scroll no-scrollbar">
              <p className="text-[0.8rem] italic font-Roboto leading-4 font-medium text-neutral-400 text-wrap">
                {item?.Reviews?.detail}
              </p>
            </div>
          </div>
        ) : (
          <div className="flex items-end flex-grow">
            <Button
              className="w-full h-10 border-2 border-[#FFCC42] rounded-2xl"
              loading={loading}
              disabled={isReviewRequested}
              onClick={() =>
                RequestReivew({
                  bid: item?.bartender_id,
                  eid: item?.establishmentid,
                  establishmentId: item?.EstablishmentDetails?._id,
                })
              }>
              {isReviewRequested ? "Review Request Sent" : "Request Review"}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ReviewCardEstablishment;
