import { Button } from "antd";
import React from "react";
import config from "../../../../../config/configuration";
import { handleFloat } from "../../../../../utils/helper";

export default function RightSideTile({ elem, dayjs, handleApplyGig }) {
  return (
    <div className="flex flex-col px-2 pt-1.5 pb-3 mt-3  rounded-xl border-l-4 border-red-500 border-solid shadow-sm max-md:mr-2.5 max-md:flex-wrap max-md:max-w-full">
      <div className="flex gap-2.5">
        <img
          loading="lazy"
          style={{ borderRadius: "10px" }}
          src={
            `${config.cdnFront}/${elem.establishmentDatail.EprofileImg}` || ""
          }
          className="shrink-0 self-start mt-1.5 w-14 aspect-square"
        />
        <div className="flex flex-col grow shrink-0 basis-0 ">
          <div className="flex gap-5 justify-between max-xl:flex-wrap">
            <div className="flex-auto  text-[15px] tracking-tighter font-medium text-black">
              {elem?.establishmentDatail?.establishmentName}
            </div>
            <div className="justify-center px-2 pr-1 text-[11px] whitespace-nowrap bg-white rounded border border-solid border-zinc-100 text-neutral-500">
              {elem.applied.length + elem.assigned.length} applicants
            </div>
          </div>
          <div className="text-[11px] text-neutral-400">
            {elem.establishmentDatail.location?.city},{" "}
            {elem.establishmentDatail.location?.state}
          </div>
          <div className="flex gap-1 mt-1 text-xs text-neutral-700">
            <img
              loading="lazy"
              src="/images/completeProfile/clock.png"
              className=" w-[13px] h-[13px] mt-[2px]"
            />
            <div className="flex-auto my-auto text-[11px]">
              Shift Time: {dayjs(elem.startTime, "HH:mm").format("hh:mm A")} -{" "}
              {dayjs(elem.endTime, "HH:mm").format("hh:mm A")}
              <br />
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-3 mt-2">
        <div className="grow my-auto text-[15px] text-blue-500">
          <span className="font-medium">${handleFloat(elem?.payHourly)}</span>
          <span className="text-xs font-medium text-neutral-400">/ Hour</span>
        </div>
        <Button
          type="text"
          style={{ height: "auto" }}
          onClick={() => handleApplyGig(elem._id)}
          className="grow justify-center py-2.5 w-[150px] text-[13px] text-center text-black bg-amber-300 rounded-[92.207px] ">
          Apply
        </Button>
      </div>
    </div>
  );
}
