import React from "react";
import style from "./LandingPage.module.css";
import { Link } from "react-router-dom";

const LandingPage = () => {
  return (
    <>
      <header className={style.header}>
        <Link to={"login"} className={style.changeColor}>
          Login
        </Link>
        <Link to={"/signup"} className={style.changeColor}>
          Signup
        </Link>
      </header>

      <Link to={"/"}>
        <img className={style.barrom} src="./images/barooom.png" alt="logo" />
      </Link>

      <div className={style.lowerPart}>
        {/* <img className={style.barrom} src={baroom} alt="" /> */}
        <div className={style.backgroung}>
          <img
            loading="lazy"
            className={style.backgroundImg}
            src={"/LandingPageImages/backgroundImage.png"}
            alt=""
          />
        </div>
        <div className={style.contentArea}>
          <div className={style.left}>
            <img
              loading="lazy"
              className={style.bottle}
              src={"/LandingPageImages/Bottle.png"}
              alt=""
            />
            <img
              loading="lazy"
              className={style.bottle1}
              src={"/LandingPageImages/Bottle.png"}
              alt=""
            />
            <img
              loading="lazy"
              className={style.manImg}
              src={"/LandingPageImages/ManImage.png"}
              alt=""
            />
          </div>
          <div className={style.middleSection}>
            <h1>BAROOOM - Elevate your </h1>
            <h2>Bartending Experience</h2>
            <p>
              Establishments, register now! Bartenders, unlock new
              opportunities.
            </p>
            <div className={style.middleSectionImg}>
              <img
                loading="lazy"
                src={"/LandingPageImages/googleplay.png"}
                alt=""
              />
              <img
                loading="lazy"
                src={"/LandingPageImages/Appstore.png"}
                alt=""
              />
            </div>
          </div>

          <div className={style.rightPart}>
            <img
              loading="lazy"
              src={"/LandingPageImages/mobileImg.png"}
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
