import React, { useEffect, useState } from "react";

import styles from "../community.module.css";
import menu from "./SmallCp";
import UserReview from "./UserReview";
import {
  fetchCommunityFigure,
  FriendsDetails,
  getFriendsList,
} from "../../../axios/axios";
import { useSelector } from "react-redux";
import Myfrnd from "./Myfrnd";
import InvitationBartAPI from "./InvitationBartAPI";
import NearbyBart from "./NearbyBart";
import InvitationSent from "./InvitationSent";
import NearbyEst from "./NearbyEst";
import DashHeader from "../../dashboard/DashHeader";
import configuration from "../../../config/configuration";
import { useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";

export default function CommunityBart() {
  const user = useSelector((state) => state.user.user);
  const [showModal1, setShowModal1] = useState(false);
  const [activefriendId, setactivefriendId] = useState({
    _id: "",
    name: "",
    pic: "",
    distance: "",
    city: "",
    friendsCount: "",
    jobCount: "",
    reviewCount: "",
  });
  const [commfig, setCommFig] = useState({
    Fnds: 0,
    Invite: 0,
    NearB: 0,
    NearE: 0,
  });

  // Define swipe handlerss
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => setTab((prevTab) => (prevTab < 3 ? prevTab + 1 : prevTab)),
    onSwipedRight: () => setTab((prevTab) => (prevTab > 0 ? prevTab - 1 : prevTab)),
    trackMouse: true, // Allows swiping with mouse as well
  });

  const [friendsdata, setfriendsData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    item_per_page: 12,
    total_page: 1,
    history: [],
  });
  const navigate = useNavigate();
  const [active, setActive] = useState([]);
  const [tab, setTab] = useState(0);
  const [parchange, setParChange] = useState(false);

  const getCommunityFigure = () => {
    fetchCommunityFigure({ token: user?.token })
      .then((result) => {
        setCommFig(result.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getFriendsDetails = () => {
    FriendsDetails({ token: user?.token, bid: activefriendId._id })
      .then((result) => {
        let res = result.data;
        let x = { ...friendsdata };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          total_page: res.total_page,
          item_per_page: res.item_per_page,
          history: [...res.history],
        };

        setfriendsData(x);
        // console.log("Friends Data: ", result.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getCommunityFigure();
  }, [parchange, tab]);

  useEffect(() => {
    if (showModal1) getFriendsDetails();
  }, [showModal1]);

  return (
    <div {...swipeHandlers} className="min-h-[75vh] max-h-full">
      <DashHeader
        leftContent={
          <>
            <div className="pt-3 my-auto uppercase grow">
              <span className=" text-neutral-700">Community</span>{" "}
              {/* <span className="text-yellow-400 ">{user?.name}</span> */}
            </div>
          </>
        }
      />
      <div className="flex flex-col flex-grow min-h-[75vh] max-h-full px-10 py-12 mt-8 text-sm leading-6 bg-white shadow-2xl rounded-3xl text-neutral-700">
        <div className="flex flex-col self-center max-w-full">
          <div className="flex gap-3 items-center py-1 px-1 text-sm leading-5 bg-white border border-gray-200 border-solid rounded-[100px] text-neutral-700">
            <div
              className={`flex-auto my-auto cursor-pointer px-5 py-3.5 rounded-[100px] w-fit ${tab === 0 ? "text-white bg-red-500 font-medium" : ""
                }`}
              onClick={() => setTab(0)}>
              My Friends ({commfig?.Fnds})
            </div>
            <div
              className={`flex-auto my-auto cursor-pointer px-5 py-3.5 rounded-[100px] self-stretch ${tab === 1 ? "text-white bg-red-500 font-medium" : ""
                }`}
              onClick={() => setTab(1)}>
              Invitations ({commfig?.Invite})
            </div>
            <div
              className={`flex-auto my-auto cursor-pointer px-5 py-3.5 rounded-[100px] self-stretch ${tab === 2 ? "text-white bg-red-500 font-medium" : ""
                }`}
              onClick={() => setTab(2)}>
              Nearby Bartenders ({commfig?.NearB})
            </div>
            <div
              className={`flex-auto my-auto cursor-pointer px-5 py-3.5 rounded-[100px] self-stretch ${tab === 3 ? "text-white bg-red-500 font-medium" : ""
                }`}
              onClick={() => setTab(3)}>
              Nearby Establishments ({commfig?.NearE})
            </div>
          </div>
        </div>

        <div className="w-full h-full mt-4">
          {tab === 0 && (
            <Myfrnd
              activefriendId={activefriendId}
              setactivefriendId={setactivefriendId}
              setShowModal1={setShowModal1}
            />
          )}
          {tab === 1 && (
            <InvitationSent parchange={parchange} setParChange={setParChange} />
          )}
          {tab === 2 && (
            <NearbyBart setParChange={setParChange} parchange={parchange} />
          )}
          {tab === 3 && <NearbyEst />}
        </div>

        {showModal1 && (
          <>
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
              <div className="relative w-auto max-w-3xl mx-auto my-6">
                <div className="relative flex flex-col w-full pb-2 bg-white border-0 outline-none rounded-2xl focus:outline-none">
                  <div className="flex overflow-hidden relative flex-col items-end min-w-[354px] min-h-[204px] max-md:px-5 max-md:max-w-full">
                    <img
                      loading="lazy"
                      src="/images/Comminity/reviewBack.png"
                      className="absolute inset-0 object-cover rounded-2xl size-full"
                    />

                    <div className="absolute flex flex-col w-full text-lg text-white ">
                      <button
                        className="items-end justify-end pt-2 pr-5 text-xl text-end"
                        onClick={() => setShowModal1(false)}>
                        <span className="">&#10005;</span>
                      </button>
                      <div
                        className={` flex  justify-center pb-1 } ${styles.header}`}>
                        {activefriendId.name} Reviews
                      </div>
                      <div className="flex justify-center ">
                        <img
                          loading="lazy"
                          src={
                            `${configuration.cdnFront}/${activefriendId.pic}` ||
                            ""
                          }
                          className="w-[60px] h-[60px] border-[1px] rounded-[50%] border-white"
                        />
                      </div>
                      <div className="flex justify-center">
                        <div className={styles.header}>
                          {activefriendId.name}
                        </div>
                      </div>
                      <div className="flex  justify-center text-sm text-[#8C8C8C] ">
                        <div>
                          <img
                            loading="lazy"
                            src="/images/Comminity/location.svg"
                          />
                        </div>
                        <p className="text-stone-300">
                          {activefriendId.city} {activefriendId.distance} mi
                        </p>
                      </div>
                      <div className="flex justify-center gap-5 py-1 ">
                        <div className="flex gap-1">
                          <img
                            loading="lazy"
                            src="/images/Comminity/yellowUsers.png"
                            className="w-5 shrink-0 aspect-square"
                          />
                          <div className="text-sm">
                            {activefriendId.friendsCount}
                          </div>
                        </div>
                        <div className="flex gap-1 ">
                          <img
                            loading="lazy"
                            src="/images/Comminity/yellowBuilding.png"
                            className="w-5 shrink-0 aspect-square"
                          />
                          <div className="text-sm">
                            {activefriendId.jobCount}
                          </div>
                        </div>
                        <div className="flex gap-1 ">
                          <img
                            loading="lazy"
                            src="/images/Comminity/yellowStar.png"
                            className="shrink-0 aspect-[1.05] w-[21px]"
                          />
                          <div className="text-sm">
                            {activefriendId.reviewCount}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="absolute justify-center cursor-pointer bottom-2 right-2 whitespace-nowrap text-neutral-500"
                      onClick={() =>
                        navigate(`/dashboard/chat/`, {
                          state: { uid: activefriendId._id },
                        })
                      }>
                      <img
                        loading="lazy"
                        src="/images/completeProfile/yellowChat.png"
                        className="self-start shrink-0 aspect-square"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="fixed inset-0 z-40 bg-black opacity-20"></div>
          </>
        )}
      </div>
    </div >
  );
}
