import React from "react";
import DashHeader from "../../dashboard/DashHeader";
import Chatroom from "../Chatroom";
import { useSelector } from "react-redux";
import BartenderWrapperDash from "../../dashboard/WrapperDash";

function ChatBrt() {
  return (
    <BartenderWrapperDash>
      <DashHeader
        leftContent={
          <>
            <div className="pt-3 my-auto uppercase grow">
              <span className=" text-neutral-700">Chat</span>{" "}
              {/* <span className="text-yellow-400 ">{user?.name}</span> */}
            </div>
          </>
        }
      />
      <Chatroom />
    </BartenderWrapperDash>
  );
}

export default ChatBrt;
