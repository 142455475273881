import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "../../profile.module.css";
import { useEffect, useState } from "react";
import { CircularProgress, Modal } from "@mui/material";
import {
  fetchEstablishmentDetails,
  getReverseGeoCode,
  updateEstabLocation,
} from "../../../../axios/axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function Edit2() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user?.user);
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [coordinates, setCoordinates] = useState({ latitude: 0, longitude: 0 });
  const [estabLocation, setLocation] = useState({
    streetName: "",
    city: "",
    state: "",
    postalCode: "",
  });
  const [estabTimeZone, setTimeZone] = useState("");

  const fetchEstabDetails = () => {
    fetchEstablishmentDetails({ token: user?.token, eid: id })
      .then((res) => {
        if (res.data) {
          const { location, timeZone } = res.data;
          setLocation({
            streetName: location.streetName,
            city: location.city,
            state: location.state,
            postalCode: location.postalCode,
          });
          setTimeZone(timeZone);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchEstabDetails();
  }, []);

  const fetchCoodinatetoLocation = (latitude, longitude) => {
    getReverseGeoCode({ token: user?.token, latitude, longitude })
      .then((res) => {
        const locationData = res.data;

        const streetName =
          locationData.find((item) =>
            item.types.includes("sublocality_level_1")
          )?.long_name || "";

        const city =
          locationData.find((item) => item.types.includes("locality"))
            ?.long_name || "";

        const state =
          locationData.find((item) =>
            item.types.includes("administrative_area_level_1")
          )?.long_name || "";

        const postalCode =
          locationData.find((item) => item.types.includes("postal_code"))
            ?.long_name || "";

        setLocation({
          streetName,
          city,
          state,
          postalCode,
        });
        setCoordinates({ latitude, longitude });
      })
      .catch((err) => console.log(err));
  };

  const fetchLocation = () => {
    if (navigator.geolocation) {
      setLoader(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          let latitude = position.coords.latitude;
          let longitude = position.coords.longitude;
          fetchCoodinatetoLocation(latitude, longitude);
          setLoader(false);
        },
        (err) => {
          console.log("Error fetching Location", err);
          setLoader(false);
        }
      );
    }
  };

  const handleSubmit = () => {
    if (!coordinates.latitude && !coordinates.longitude) {
      fetchLocation();
    }

    updateEstabLocation({
      token: user?.token,
      location: JSON.stringify(estabLocation),
      eid: id,
      timeZone: estabTimeZone,
      latitude: coordinates.latitude,
      longitude: coordinates.longitude,
    })
      .then((res) => navigate(`/establishment-details/${id}`))
      .catch((err) => toast.error(err?.message));
  };

  return (
    <div>
      <Modal
        open={loader}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
        }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CircularProgress sx={{ margin: "0 10px 0 0" }} />
          Auto Fetching Location...
        </div>
      </Modal>
      <img
        loading="lazy"
        src="/images/completeProfile/complete_Profile_backImg.png"
        className="object-cover fixed inset-0 size-full"
      />
      <div
        style={{
          backgroundImage: `url('/images/headerBackground.svg')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "0 0 25px 25px",
        }}
        className="flex overflow-hidden relative px-20 text-2xl w-[100%] text-white min-h-[100px] rounded-b-3xl max-md:flex-wrap max-md:px-5">
        <div className="my-auto flex min-w-full">
          <div
            onClick={() => navigate(`/establishment-details/${id}/`)}
            className="border-2 px-4 rounded-[10px] py-1 mr-5 cursor-pointer">
            &#10094;
          </div>
          <span className={`${styles.header} `}>Edit Your Profile</span>
        </div>
      </div>
      <div className="flex relative justify-center items-center self-center px-16 py-10 mx-auto my-7 rounded-[20px] w-full bg-white text-xs max-w-[1099px] max-md:px-5 max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-col mx-[50px] items-center max-w-full w-[1300px]">
          <div
            className={`text-xl leading-9 text-center text-black} ${styles.header}`}>
            Location
          </div>

          <div
            className="flex justify-center items-center px-16 py-5 mt-10 max-w-full text-center rounded-xl border border-dashed bg-red-500 bg-opacity-10 border-[color:var(--ff-3333,#F33)] leading-[153.3%] w-[80%] h-[120px] max-md:px-5 max-md:mt-10"
            onClick={fetchLocation}>
            <div className="flex flex-col items-center max-w-full w-[164px]">
              <img
                loading="lazy"
                src="/images/completeProfile/redLoction.png"
                className="aspect-square w-[31px]"
              />
              <div className="self-stretch mt-2.5 text-xs text-black">
                Auto Locate Your Location
              </div>
            </div>
          </div>
          <div className="mt-8 text-base font-bold text-black">OR</div>
          <div className="flex flex-col flex-1 px-5 py-3.5 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] min-w-full">
            <div className="text-black text-left max-md:max-w-full">
              Time Zone
            </div>
            <div className="mt-2 text-zinc-500 text-start max-md:max-w-full">
              <input
                className="border-none outline-none w-full"
                type="text"
                placeholder="Establishment Time Zone"
                value={estabTimeZone}
                onChange={(e) => setTimeZone(e.target.value)}
              />
            </div>
          </div>
          <div className="flex gap-5 justify-between self-stretch mt-7 text-xs max-md:flex-wrap max-md:max-w-full">
            <div className="flex flex-col flex-1 px-5 py-3.5 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:max-w-full">
              <div className="text-black text-left max-md:max-w-full">
                Street Name
              </div>
              <div className="mt-2 text-zinc-500 text-start max-md:max-w-full">
                <input
                  className="border-none outline-none w-full"
                  type="text"
                  placeholder="Street Name"
                  value={estabLocation.streetName}
                  onChange={(e) =>
                    setLocation({
                      ...estabLocation,
                      streetName: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="flex flex-col flex-1 px-5 py-3.5 whitespace-nowrap bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:max-w-full">
              <div className="text-black text-left max-md:max-w-full">City</div>
              <div className="mt-2 text-zinc-500 text-left max-md:max-w-full">
                <input
                  className="border-none outline-none w-full"
                  type="text"
                  placeholder="City"
                  value={estabLocation.city}
                  onChange={(e) =>
                    setLocation({
                      ...estabLocation,
                      city: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="flex gap-5 justify-between self-stretch mt-6 text-xs max-md:flex-wrap max-md:max-w-full">
            <div className="flex flex-col flex-1 px-5 py-3.5 whitespace-nowrap bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:max-w-full">
              <div className="text-black text-left max-md:max-w-full">
                State
              </div>
              <div className="mt-2 text-zinc-500 text-left max-md:max-w-full">
                <input
                  className="border-none outline-none w-full"
                  type="text"
                  placeholder="State"
                  value={estabLocation.state}
                  onChange={(e) =>
                    setLocation({
                      ...estabLocation,
                      state: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="flex flex-col flex-1 px-5 py-3.5 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:max-w-full">
              <div className="text-black text-left max-md:max-w-full">
                Postal Code
              </div>
              <div className="mt-2 text-zinc-500 text-left max-md:max-w-full">
                <input
                  className="border-none outline-none w-full"
                  type="text"
                  placeholder="Postal Code"
                  value={estabLocation.postalCode}
                  onChange={(e) =>
                    setLocation({
                      ...estabLocation,
                      postalCode: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <Link
            onClick={() => handleSubmit()}
            className="justify-center px-5 py-5 mt-20 w-[60%] cursor-pointer text-sm text-center text-white bg-red-500 rounded-[92.207px] max-md:mt-10 max-md:max-w-full">
            Save
          </Link>
        </div>
      </div>
    </div>
  );
}
export default Edit2;
