import React, { useEffect, useState } from "react";
import NotificationEst from "../../dashboard/Establishment/NotificationEst";
import DropdownEst from "../../dashboard/Establishment/DropdownEst";
import { fetchReviewOnEstablishment } from "../../../axios/axios";
import { useSelector } from "react-redux";
import ReviewCards from "../Bartendar/ProfileReviewCards";
import ReviewFilter from "../Bartendar/ReviewFilter";
import ReviewCardEstablishment from "./ReviewCardEstablishment";
import EstabDashHeader from "../../dashboard/Establishment/EstabDashHeader";
import { CircularProgress } from "@mui/material";

export default function ReviewsEst() {
  const [showModal, setShowModal] = useState(false);
  const [filter, setFilter] = useState("");
  const user = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(true);

  const [reviews, setReviews] = useState({
    prev: false,
    next: false,
    search: "",
    pending: "",
    paid: "",
    filter: "",
    page: 1,
    total_page: 1,
    history: [],
  });

  const fetchReviewsonestab = () => {
    fetchReviewOnEstablishment({ token: user?.token, filter })
      .then((result) => {
        let res = result.data.data;
        let x = { ...reviews };

        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          filter: res.filter,
          history: [...res.history],
        };

        setReviews(x);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((err) => console.log("Error at Fetching Data, ", err));
  };

  const handlefilter = () => {
    fetchReviewsonestab();
  };

  useEffect(() => {
    handlefilter();
  }, [filter]);

  useEffect(() => {
    fetchReviewsonestab();
  }, []);

  return (
    <div>
      <EstabDashHeader
        leftContent={
          <>
            <div className="pt-3 my-auto uppercase grow">
              <span className=" text-neutral-700">Reviews</span>{" "}
              {/* <span className="text-yellow-400 ">{user?.name}</span> */}
            </div>
          </>
        }
      />
      <div className="flex flex-col px-5 py-12 mt-8 text-sm leading-6 bg-white shadow-2xl rounded-3xl text-neutral-700 max-md:px-5 max-md:max-w-full">
        {/* header */}
        <div className="flex min-h-5 w-full text-[19px] font-medium text-black relative items-center justify-center">
          <p className="font-medium font-poppins text=[1.25rem] w-full text-center">
            Your Reviews By Bartenders
          </p>
          {!loading ? (
            <img
              onClick={() => setShowModal((setShowModal) => !setShowModal)}
              loading="lazy"
              src="/images/completeProfile/filter.png"
              className="shrink-0 aspect-[1.06] w-[45px]"
            />
          ) : (
            <div className="shrink-0 aspect-[1.06] w-[45px]" />
          )}
          {showModal && (
            <>
              <div
                className="absolute top-0 bg-white right-4 rounded-xl"
                style={{ marginTop: "2.5rem" }}>
                <div className="">
                  <div className="">
                    <div className="flex text-black ">
                      <ReviewFilter filter={filter} setFilter={setFilter} />

                      <button
                        className="absolute right-5 top-2"
                        onClick={() => setShowModal(false)}>
                        &#10005;
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {!loading ? (
          reviews.history.length > 0 ? (
            <div className="flex flex-wrap items-stretch content-center justify-start gap-6 px-4 mt-4 ">
              {reviews.history.map((item, index) => (
                <ReviewCardEstablishment
                  key={`${index}_Review`}
                  item={item}
                  token={user?.token}
                  role={2}
                />
              ))}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center">
              <img
                src="/images/no_review.svg"
                loading="lazy"
                sizes="20"
                alt=""
              />
            </div>
          )
        ) : (
          <div className="flex items-center justify-center h-[11.6rem]">
            <CircularProgress />{" "}
            <p className="pl-2 text-xl font-poppins">Loading...</p>
          </div>
        )}
      </div>
    </div>
  );
}
