import React, { useState } from "react";
import { useSelector } from "react-redux";
import config from "../../config/configuration";
import Dropdown from "./Bartender/Dropdown";
import Notification from "./Bartender/Notification";
import { Modal } from "@mui/material";
import NotificationEst from "./Establishment/NotificationEst";

export default function DashHeader({ leftContent, btn }) {
  const user = useSelector((state) => state.user.user);
  const notifyState = useSelector((state) => state.isnotify.isUnread);
  const [showNotification, setShowNotification] = useState(false);
  const [profileDrop, setProfileDrop] = useState(false);

  return (
    <>
      {/* DropDown Menu */}
      <Modal
        sx={{ minWidth: "1000px" }}
        open={profileDrop}
        onClose={() => setProfileDrop((prev) => !prev)}>
        <Dropdown />
      </Modal>

      {/* Notification Dropdown */}

      <div className="flex gap-5 justify-between items-center px-5  max-md:flex-wrap max-md:pr-5 border-solid border-b-2 max-md:max-w-full pb-2">
        <div className="flex gap-5 my-auto text-[30px] font-semibold tracking-tighter text-yellow-400 whitespace-nowrap leading-[58.8px]">
          {leftContent}
          {btn}
        </div>
        <div className="flex gap-5 justify-between">
          <div className="relative h-fit">
            <img
              onClick={() => {
                setShowNotification(!showNotification);
              }}
              loading="lazy"
              src="/images/solar_bell-bold.png"
              className="shrink-0 self-start mt-2.5 aspect-square w-[42px]"
            />
            {notifyState && (
              <img
                src="/images/Red_Dot.svg"
                className="absolute top-0 right-2 translate-y-2 "
                alt=""
              />
            )}
          </div>
          <div
            onClick={() => setProfileDrop((prev) => !prev)}
            style={{ cursor: "pointer" }}
            className="flex shadow-lg items-center p-1 rounded-lg border-black gap-3 justify-center">
            <div>
              <img
                loading="lazy"
                style={{ borderRadius: "10px" }}
                src={`${config.cdnFront}/${user?.pic}`}
                className="shrink-0 self-start size-[42px]"
              />
            </div>
            <button className="">
              <img src="/images/Down 2.svg" className="size-4" />
            </button>
          </div>
          {showNotification && (
            <NotificationEst
              setShowNotification={setShowNotification}
              showNotification={showNotification}
            />
          )}
        </div>
      </div>
    </>
  );
}
