import { useEffect, useState } from "react";
import React from "react";
import style from "../profile.module.css";
import { Link, useNavigate } from "react-router-dom";
import WrapperDashEst from "../../authWrapper/WrapperDashEst";
import DropdownEst from "../../dashboard/Establishment/DropdownEst";
import NotificationEst from "../../dashboard/Establishment/NotificationEst";
import EstabDashHeader from "../../dashboard/Establishment/EstabDashHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllEstab,
  PatchBarProfileImg,
  PatchEstabProfileImg,
  updateEstabBasic,
} from "../../../axios/axios";
import { updateUser } from "../../../redux/userSlice";
import configuration from "../../../config/configuration";
import { ensureNumber } from "../../../utils/inputFormatter";
import { toast } from "react-toastify";

function ProfileEst() {
  const user = useSelector((state) => state.user?.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [newUser, setNewUser] = useState({
    name: user?.name || "",
    email: user?.email || "",
    phoneNo: user?.phoneNo || 0,
  });

  const [establist, setEstabList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);

  const handlesubmit = () => {
    let msg = "";
    if (!newUser.name) msg = "User Name is required";
    else if (!newUser.phoneNo) msg = "Phone Number is required";
    else if (newUser.phoneNo.length < 10) msg = "Invalid Phone Number";
    else if (!newUser.email) msg = "User Email required";

    if (msg) {
      toast.error(msg);
    } else {
      updateEstabBasic({ token: user?.token, user: newUser })
        .then((res) => {
          toast.success(res?.message);
          dispatch(
            updateUser({ name: res.data.name, phoneNo: res.data.phoneNo })
          );
        })
        .catch((err) => toast.error(err?.message));
    }
  };

  const handleProfileImgChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);

      PatchBarProfileImg({ token: user?.token, profileImg: e.target.files[0] })
        .then((result) => {
          dispatch(updateUser({ pic: result.data.data }));
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  const handleEstabImageChange = (e, eid, index) => {
    const file = e.target.files[0];
    if (file) {
      PatchEstabProfileImg({
        token: user?.token,
        EprofileImg: e.target.files[0],
        eid,
      })
        .then((result) => {
          const updatedEstabList = [...establist];
          updatedEstabList[index].EprofileImg = result?.data?.data?.EprofileImg;
          setEstabList(updatedEstabList); // Update the state with the new image
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const getAllEstab = () => {
    fetchAllEstab({ token: user?.token })
      .then((res) => {
        setEstabList(res.data.history);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const addEmptyEstablishment = () => {
    // const newEstablishment = {
    //   _id: `temp_${Date.now()}`,
    // };
    // setEstabList([...establist, newEstablishment]);
    navigate("/new-establishment-setup");
  };

  useEffect(() => {
    getAllEstab();
  }, []);

  return (
    <WrapperDashEst>
      <div className="flex flex-col max-w-full grow shrink-0 basis-0 max-md:max-w-full">
        <div className="flex flex-col px-5 bg-zinc-50 max-md:px-5 max-md:max-w-full">
          <EstabDashHeader
            leftContent={
              <>
                <div className="pt-3 my-auto uppercase grow">
                  <span className=" text-neutral-700">Your</span>{" "}
                  <span className="text-red-400 ">Profile</span>
                </div>
              </>
            }
          />

          {/* <div className="h-px mt-5 border border-solid shrink-0 bg-zinc-100 border-zinc-300 max-md:max-w-full" /> */}
          <div className="mt-9 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-[66%] max-md:ml-0 max-md:w-full">
                <div className="w-full px-16 pt-12 pb-20 bg-white shadow-2xl grow rounded-3xl max-md:px-5 max-md:mt-6 max-md:max-w-full">
                  <div className="flex max-md:flex-col max-md:gap-0">
                    <div className="flex flex-col w-[20%] max-md:ml-0 max-md:w-full">
                      <div className="relative flex flex-col text-base font-bold leading-6 text-white w-fit whitespace-nowrap max-md:mt-10">
                        {selectedImage != null ? (
                          <img
                            loading="lazy"
                            src={selectedImage}
                            className="lg:h-36 lg:w-36 lg:mt-0 mt-4 rounded-[50%] aspect-square shrink-0"
                          />
                        ) : user?.pic ? (
                          <img
                            loading="lazy"
                            src={`${configuration.cdnFront}/${user?.pic}` || ""}
                            className="lg:h-36 lg:w-36 lg:mt-0 mt-4 rounded-[50%] aspect-square shrink-0"
                          />
                        ) : (
                          <img
                            loading="lazy"
                            src="/images/completeProfile/Mask.png"
                            className="lg:h-36 lg:w-36 lg:mt-0 mt-4 rounded-[50%] aspect-square shrink-0"
                          />
                        )}

                        <img
                          loading="lazy"
                          src="/images/completeProfile/cam.svg"
                          className="self-center absolute aspect-[0.99] bottom-0 right-0 w-fit cursor-pointer"
                        />
                        <input
                          type="file"
                          className="absolute top-0 w-full h-full opacity-0"
                          onChange={(e) => handleProfileImgChange(e)}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col ml-5 w-[81%] max-md:ml-0 max-md:w-full">
                      <div className="flex flex-col mt-1.5 text-xs max-md:mt-10 max-md:max-w-full">
                        <div className="text-base font-semibold text-red-500 max-md:max-w-full">
                          {user?.name}
                        </div>
                        <div className="text-sm tracking-wide text-slate-950 max-md:max-w-full">
                          {user?.email}
                        </div>
                        <div className="flex gap-4 px-5 py-2 mt-10 bg-white border border-gray-200 border-solid rounded-[92.207px] max-md:flex-wrap max-md:mt-10">
                          <img
                            loading="lazy"
                            src="/images/person.png"
                            className="shrink-0 my-auto aspect-square w-[22px]"
                          />
                          <div className="flex flex-col flex-1 w-full">
                            <div className="w-full text-black">Full Name</div>
                            <div className="w-full mt-2 text-zinc-500">
                              <input
                                type="text"
                                className="w-full outline-none "
                                value={newUser.name}
                                onChange={(e) => {
                                  setNewUser({
                                    ...newUser,
                                    name: e.target.value,
                                  });
                                }}
                                placeholder=" Your Full Name"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex gap-4 px-5 py-2 mt-5 bg-white border border-gray-200 border-solid rounded-[92.207px] max-md:flex-wrap">
                          <img
                            loading="lazy"
                            src="/images/mail.png"
                            className="shrink-0 my-auto aspect-square w-[23px]"
                          />
                          <div className="flex flex-col flex-1 max-md:max-w-full">
                            <div className="text-black max-md:max-w-full">
                              Email ID
                            </div>
                            <div className="mt-2 text-zinc-500 max-md:max-w-full">
                              <input
                                type="text"
                                className="w-full outline-none"
                                disabled={true}
                                value={newUser.email}
                                placeholder="Email Id"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex gap-4 px-5 py-2 mt-5 bg-white border border-gray-200 border-solid rounded-[92.207px] max-md:flex-wrap">
                          <img
                            loading="lazy"
                            src="/images/contactLogo.png"
                            className="shrink-0 my-auto aspect-[1.04] w-[23px]"
                          />
                          <div className="flex flex-col flex-1 max-md:max-w-full">
                            <div className="text-black max-md:max-w-full">
                              Phone Number
                            </div>
                            <div className="mt-2 text-zinc-500 max-md:max-w-full">
                              <input
                                type="text"
                                className="w-full outline-none"
                                value={newUser.phoneNo}
                                onChange={(e) => {
                                  const value = ensureNumber({
                                    value: e.target.value,
                                    maxLength: 10,
                                  });
                                  setNewUser({
                                    ...newUser,
                                    phoneNo: value,
                                  });
                                }}
                                placeholder="Phone Number"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="justify-center py-3 mt-10 text-base text-center text-white whitespace-nowrap bg-red-500 rounded-[124.047px] max-md:px-5 max-md:mt-10 max-md:max-w-full cursor-pointer"
                          onClick={() => handlesubmit()}>
                          Save
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col  w-[34%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow pt-8 px-5 pb-20 max-h-[100vh] overflow-scroll no-scrollbar w-full bg-white rounded-3xl shadow-2xl max-md:px-5 max-md:mt-6 max-md:max-w-full">
                  <div
                    className={`self-start text-lg tracking-wider leading-8 text-black} ${style.header}`}>
                    Your Establishments
                  </div>

                  {!loading
                    ? establist.map((elem, ind) => (
                        <div
                          className="flex self-center justify-between gap-5 mt-20 max-md:mt-10"
                          key={`ran_${ind}_${elem?._id}`}>
                          <div className="relative">
                            <img
                              loading="lazy"
                              src={
                                elem?.pic
                                  ? `${configuration.cdnFront}/${elem?.EprofileImg}`
                                  : "/images/EstablishmentNewAdd.svg"
                              }
                              className="aspect-[0.98] h-[85px] w-[85px] rounded-[50%]"
                            />
                            <img
                              loading="lazy"
                              src="/images/completeProfile/cam.svg"
                              className="self-center absolute aspect-[0.99] bottom-0 right-0 w-[30px] cursor-pointer"
                            />
                            <input
                              type="file"
                              className="absolute top-0 w-full h-full opacity-0"
                              onChange={(e) =>
                                handleEstabImageChange(e, elem?._id, ind)
                              }
                            />
                          </div>

                          <div className="flex flex-col my-auto">
                            <div
                              className={`text-base text-black} ${style.header}`}>
                              {elem?.establishmentName ||
                                `Establishment ${ind}`}
                            </div>
                            <div className="text-sm text-red-500 cursor-pointer">
                              <p
                                onClick={() =>
                                  navigate(`/establishment-details/${elem._id}`)
                                }>
                                View details
                              </p>
                            </div>
                          </div>
                        </div>
                      ))
                    : ""}
                  {!loading && establist.length <= 10 ? (
                    <img
                      loading="lazy"
                      src="/images/completeProfile/plusDott.png"
                      className="mt-14 ml-11 max-w-full aspect-square w-[75px] max-md:mt-10 max-md:ml-2.5 cursor-pointer"
                      onClick={() => {
                        addEmptyEstablishment();
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WrapperDashEst>
  );
}

export default ProfileEst;
