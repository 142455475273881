import { useEffect, useState } from "react";
import style from "../community.module.css";
import config from "../../../config/configuration";
import {
  friendRequestAction,
  getFriendsRequestReceived,
  getFriendsRequestSent,
} from "../../../axios/axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { formatNumber } from "../../../utils/helper";

export default function InvitationSent({ setParChange, parchange }) {
  const user = useSelector((state) => state.user.user);
  const [receivedreq, setReceivedReq] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    item_per_page: 12,
    total_page: 1,
    history: [],
  });
  const [sentreq, setSentReq] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    item_per_page: 12,
    total_page: 1,
    history: [],
  });
  const [invitetype, setInviteType] = useState(1);
  const [change, setChange] = useState(true);

  const handleAction = (id, action) => {
    friendRequestAction({ token: user?.token, id, action })
      .then((result) => {
        setParChange(!parchange);
        toast.success(result?.message);
        setChange(!change);
        // setReceivedReq(result.data.received_Request);
        // setSentReq(result.data.send_Request);
      })
      .catch((err) => console.log(err));
  };

  const getFriendSent = () => {
    getFriendsRequestSent({ token: user?.token })
      .then((result) => {
        let res = result.data;
        let x = { ...sentreq };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          total_page: res.total_page,
          item_per_page: res.item_per_page,
          history: [...res.history],
        };

        setSentReq(x);
      })
      .catch((err) => console.log(err));
  };
  const getRecivedFriendRequests = () => {
    getFriendsRequestReceived({ token: user?.token })
      .then((result) => {
        let res = result.data;
        let x = { ...receivedreq };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          total_page: res.total_page,
          item_per_page: res.item_per_page,
          history: [...res.history],
        };

        setReceivedReq(x);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (invitetype == 1) {
      getRecivedFriendRequests();
    } else if (invitetype == 2) {
      getFriendSent();
    }
  }, [invitetype, change]);

  return (
    <div className="flex flex-col flex-grow min-h-[75vh] h-full px-10 py-12 text-sm leading-6 bg-white shadow-2xl rounded-3xl text-neutral-700">
      <div className="flex flex-col items-center self-center px-16 max-w-full text-base whitespace-nowrap border-b border-gray-200 border-solid w-[385px] max-md:px-5">
        <div className="flex flex-col max-w-full w-[173px]">
          <div className="flex justify-between gap-5">
            <div
              className={`cursor-pointer  ${
                invitetype === 1
                  ? "font-semibold text-red-500 border-b-red-500 border-b-4 p-2"
                  : "text-black p-2"
              }`}
              onClick={() => setInviteType(1)}>
              Received
            </div>
            <div
              className={`cursor-pointer  ${
                invitetype === 2
                  ? "font-semibold text-red-500 border-b-red-500 border-b-4 p-2"
                  : "text-black p-2"
              }`}
              onClick={() => setInviteType(2)}>
              Sent
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5"></div>
      {invitetype == 1 ? (
        <>
          {receivedreq.history.length > 0 ? (
            receivedreq.history.map((item) => {
              return (
                <div className="z-10 flex flex-col pt-2 pb-2 mt-1 bg-white border-b border-solid rounded-md border-neutral-200 max-md:px-5 max-md:max-w-full">
                  <div className="z-10 flex justify-between w-full gap-5 max-md:flex-wrap max-md:max-w-full">
                    <div className="flex items-center gap-5 max-md:flex-wrap max-md:max-w-full">
                      <div className="flex self-stretch justify-between gap-5 text-xl text-black">
                        <img
                          loading="lazy"
                          src={
                            `${config.cdnFront}/${item.friendsDetial[0].bartenderDetails[0].profileImg}` ||
                            ""
                          }
                          className="shrink-0 rounded-full border-4 border-white border-solid shadow-lg aspect-square h-[60px] w-[60px]"
                        />
                        <div
                          className={`my-auto text-[17px] flex-auto self-stretch} ${style.header}`}>
                          {item.friendsDetial[0].name}
                        </div>
                      </div>
                      <div className="self-stretch flex-auto my-auto text-base text-zinc-500">
                        {item.connections}
                      </div>
                      <div className="flex items-start self-end gap-1 ">
                        <img
                          loading="lazy"
                          src="/images/Comminity/location.svg"
                          className="shrink-0 aspect-square mt-[6px] w-[22px]"
                        />
                        <div className="flex flex-col mt-1.5">
                          <div className="text-base text-black">
                            {
                              item.friendsDetial[0]?.bartenderDetails[0]
                                ?.location?.city
                            }
                          </div>
                          <div className="text-sm text-zinc-400">
                            {formatNumber(item.distance)} mi
                          </div>
                        </div>
                      </div>
                      <div className="flex self-stretch gap-2 my-auto text-xl font-medium text-black whitespace-nowrap">
                        <img
                          loading="lazy"
                          src="/images/Group.svg"
                          className="shrink-0 aspect-[0.96] w-[23px]"
                        />
                        <div className="my-auto text-base">
                          {item.mutualFriends.TotalFriend}
                        </div>
                      </div>
                      <div className="flex gap-1.5 self-stretch my-auto text-xl font-medium text-black whitespace-nowrap">
                        <img
                          loading="lazy"
                          src="/images/Building.svg"
                          className="w-6 shrink-0 aspect-square"
                        />
                        <div className="my-auto text-base">
                          {item.friendsDetial[0]?.totalJobs}
                        </div>
                      </div>
                      <div className="flex self-stretch gap-2 my-auto text-xl font-medium text-black whitespace-nowrap">
                        <img
                          loading="lazy"
                          src="/images/StartRating.svg"
                          className="w-6 shrink-0 aspect-square"
                        />
                        <div className="my-auto text-base">
                          {item.friendsDetial[0]?.totalReviews}
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-3.5 my-auto text-sm text-center whitespace-nowrap">
                      <div
                        className="justify-center px-5 py-3 text-green-500 border border-green-500 border-solid rounded-[92.207px] cursor-pointer"
                        onClick={() => handleAction(item.reqId, true)}>
                        Accept
                      </div>
                      <div
                        className="justify-center px-5 py-3 text-red-500 border border-red-500 border-solid rounded-[92.207px] cursor-pointer"
                        onClick={() => handleAction(item.reqId, false)}>
                        Cancel
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="flex flex-col items-center justify-center w-full">
              <img src="/images/No_Data.svg" sizes="20" alt="" />
              <p className="text-lg font-medium font-Roboto">
                No Invitations Currently{" "}
              </p>
            </div>
          )}
        </>
      ) : (
        <>
          {sentreq.history.length > 0 ? (
            sentreq.history.map((item) => {
              return (
                <div className="w-full pt-2 pb-2 mt-1 bg-white border-b border-solid rounded-md border-neutral-200">
                  <div className="flex justify-between w-full grid-flow-col gap-5">
                    <div className="flex items-center justify-between w-3/4 ">
                      <div className="flex min-w-[250px] gap-3 text-black">
                        <img
                          loading="lazy"
                          src={
                            `${config.cdnFront}/${item.friendsDetial[0].bartenderDetails[0].profileImg}` ||
                            ""
                          }
                          className="shrink-0 rounded-full border-4 border-white border-solid shadow-lg aspect-square h-[60px] w-[60px]"
                        />
                        <div className={`my-auto text-lg ${style.header}`}>
                          {item.friendsDetial[0].name}
                        </div>
                      </div>
                      <div className="self-stretch my-auto text-base text-zinc-500">
                        {item?.mutualFriends?.MutualFriend} mutual connections
                      </div>
                      <div className="items-start self-end gap-1 ">
                        <img
                          loading="lazy"
                          src="/images/Comminity/location.svg"
                          className="shrink-0 aspect-square mt-[6px] w-[22px]"
                        />
                        <div className="mt-1.5">
                          <div className="text-base text-black">
                            {
                              item.friendsDetial[0]?.bartenderDetails[0]
                                ?.location?.city
                            }
                          </div>
                          <div className="text-sm text-zinc-400">
                            {formatNumber(item.distance)} mi
                          </div>
                        </div>
                      </div>
                      <div className="self-stretch gap-2 my-auto text-xl font-medium text-black whitespace-nowrap">
                        <img
                          loading="lazy"
                          src="/images/Group.svg"
                          className="shrink-0 aspect-[0.96] w-[23px]"
                        />
                        <div className="my-auto text-base">
                          {item.mutualFriends.TotalFriend}
                        </div>
                      </div>
                      <div className=" gap-1.5 self-stretch my-auto text-xl font-medium text-black whitespace-nowrap">
                        <img
                          loading="lazy"
                          src="/images/Building.svg"
                          className="w-6 shrink-0 aspect-square"
                        />
                        <div className="my-auto text-base">
                          {item.friendsDetial[0]?.totalJobs}
                        </div>
                      </div>
                      <div className="self-stretch gap-2 my-auto text-xl font-medium text-black whitespace-nowrap">
                        <img
                          loading="lazy"
                          src="/images/StartRating.svg"
                          className="w-6 shrink-0 aspect-square"
                        />
                        <div className="my-auto text-base">
                          {item.friendsDetial[0]?.totalReviews}
                        </div>
                      </div>
                    </div>
                    <div className=" gap-3.5 my-auto text-sm text-center whitespace-nowrap cursor-pointer">
                      <div
                        className="justify-center px-5 py-3 text-establishment-red border border-red-500 border-solid rounded-[92.207px]"
                        onClick={() => handleAction(item.reqId, false)}>
                        Cancel
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="flex flex-col items-center justify-center w-full">
              <img src="/images/No_Data.svg" sizes="20" alt="" />
              <p className="text-lg font-medium font-Roboto">
                No Invitations Currently{" "}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
}
