import JobCards from "../../../jobCards/JobCards";

function CurrentGigsEstablishment({ data, dayjs }) {
  return (
    <div>
      <div className="flex pt-6 px-2 gap-6 flex-wrap w-full max-md:max-w-full ">
        {data.map((elem, index) => {
          return (
            <JobCards
              elem={elem}
              index={index}
              key={`job_${elem?._id}_${index}_Internal`}
              Jtype={1}
            />
          );
        })}
        {data?.length < 1 ? (
          <div className="w-full flex justify-center items-center">
            <img src="/images/EmptyGig.svg" sizes="20" alt="" />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
export default CurrentGigsEstablishment;
