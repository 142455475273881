import React, { useEffect } from "react";
import config from "../../../../../config/configuration";
import { Button } from "antd";
import { ApplyForGig } from "../../../../../axios/axios";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import BarJobCards from "../../../../jobCards/BarJobCards";

function NewOpportunities({ data, handleApplyGig }) {
  return (
    <div className="flex pt-6 px-2 gap-6 flex-wrap w-full max-md:max-w-full">
      {data.map((elem, index) => {
        return (
          <BarJobCards
            elem={elem}
            Jtype={2}
            index={index}
            handleApplyGig={handleApplyGig}
          />
        );
      })}
      {data?.length < 1 ? (
        <div className="w-full flex justify-center items-center">
          <img src="/images/EmptyGig.svg" sizes="20" alt="" />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default NewOpportunities;
