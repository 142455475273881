import { useEffect } from "react";
import BaroomIntro from "../components/landing/BaroomIntro";
import BaroomKeyFeature from "../components/landing/BaroomKeyFeature";
import Footer from "../components/landing/Footer";
import LandingPage from "../components/landing/LandingPage";
import PhotoGrid from "../components/landing/PhotoGrid";
import Register from "../components/landing/Register";

const Landing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <LandingPage />
      <BaroomIntro />
      <BaroomKeyFeature />
      <PhotoGrid />
      <Register />
      <Footer />
    </>
  );
};

export default Landing;
