import { useState } from "react";
import WrapperDash from "../../dashboard/WrapperDash";
import style from "../setting.module.css";
import Dropdown from "../../dashboard/Bartender/Dropdown";
import Notification from "../../dashboard/Bartender/Notification";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate } from "react-router-dom";
import * as React from "react";
import { changepassword } from "../../../axios/axios";
import { useSelector } from "react-redux";
import DashHeader from "../../dashboard/DashHeader";
import { toast } from "react-toastify";
import EstabDashHeader from "../../dashboard/Establishment/EstabDashHeader";

function ChangePassSetting() {
  const user = useSelector((state) => state.user.user);
  const [showModal, setShowModal] = useState(false);
  const [passwords, setPasswords] = useState({
    currentpassword: "",
    newPassword: "",
    cPassword: "",
  });
  const navigate = useNavigate();
  const mainModal = <Dropdown></Dropdown>;
  const [showModalnotify, setShowModalnotify] = useState(false);
  const mainModalnotify = <Notification></Notification>;
  const [passwordVisible, setPasswordVisible] = useState({
    old: false,
    new: false,
    confirm: false,
  });

  const togglePasswordVisibility = ({ name }) => {
    setPasswordVisible({ ...passwordVisible, [name]: !passwordVisible[name] });
  };

  const handleSubmitChangePass = () => {
    if (
      !passwords.cPassword ||
      !passwords.currentpassword ||
      !passwords.newPassword
    ) {
      toast.error("Mandatory Fields Empty!");

      return;
    }
    changepassword({
      token: user?.token,
      currentpassword: passwords.currentpassword,
      newPassword: passwords.newPassword,
      cPassword: passwords.cPassword,
    })
      .then((result) => {
        toast.success(result.data.message);
        setPasswords({ currentpassword: "", newPassword: "", cPassword: "" });
      })
      .catch((err) => {
        toast.error(err.message);
        console.log(err);
      });
  };

  return (
    <div>
      {user?.role == 1 ? (
        <DashHeader
          btn={
            <div
              className="uppercase w-[60px] flex justify-center items-center border-2 border-black rounded-xl cursor-pointer"
              onClick={() => navigate("/settings")}>
              <span className="text-neutral-700">&lt;</span>{" "}
            </div>
          }
        />
      ) : (
        <EstabDashHeader
          btn={
            <div
              className="uppercase w-[60px] flex justify-center items-center border-2 border-black rounded-xl cursor-pointer"
              onClick={() => navigate("/settings")}>
              <span className="text-neutral-700">&lt;</span>{" "}
            </div>
          }
        />
      )}
      <div className="flex flex-col max-w-full grow shrink-0 basis-0 max-md:max-w-full">
        <div className="flex flex-col px-5 bg-zinc-50 max-md:px-5 max-md:max-w-full">
          {/* <div className="h-px mt-5 border border-solid shrink-0 bg-zinc-100 border-zinc-300 max-md:max-w-full" /> */}
          <div className="flex flex-col mt-8 bg-white shadow-2xl px-14 py-9 rounded-3xl max-md:px-5 max-md:max-w-full">
            <div className="flex flex-col self-center max-w-full w-[556px]">
              <div
                className={`self-center text-[23px] tracking-wide text-center text-black capitalize} ${style.header}`}>
                Change Password
              </div>
              <div className="mt-1 text-[16px] text-center text-neutral-600 max-md:max-w-full">
                Your new password must be different from previous used passwords
              </div>
              <div className="flex gap-5 items-center px-6 py-2 mt-14 ml-3 bg-white border border-gray-200 border-solid rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:mt-10">
                <img
                  loading="lazy"
                  src="/images/lock.png"
                  className="shrink-0 self-stretch my-auto aspect-[1.03] w-[30px]"
                />
                <div className="flex flex-col flex-1 text-[16px] self-stretch max-md:max-w-full">
                  <div className="text-black max-md:max-w-full">
                    Old Password
                  </div>

                  <input
                    className="mt-1 border-none outline-none text-zinc-500 max-md:max-w-full"
                    type={passwordVisible.old ? "text" : "password"}
                    placeholder="Enter your password"
                    value={passwords.currentpassword}
                    onChange={(e) =>
                      setPasswords({
                        ...passwords,
                        currentpassword: e.target.value,
                      })
                    }
                  />
                </div>
                {passwordVisible.old ? (
                  <VisibilityOffIcon
                    sx={{ color: "gray", cursor: "pointer" }}
                    onClick={() => togglePasswordVisibility({ name: "old" })}
                  />
                ) : (
                  <VisibilityIcon
                    sx={{ color: "gray", cursor: "pointer" }}
                    onClick={() => togglePasswordVisibility({ name: "old" })}
                  />
                )}
              </div>
              <div className="flex gap-5 items-center px-6 py-2 mt-3.5 ml-3 bg-white border border-gray-200 border-solid rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:mt-10">
                <img
                  loading="lazy"
                  src="/images/lock.png"
                  className="shrink-0 self-stretch my-auto aspect-[1.03] w-[30px]"
                />
                <div className="flex flex-col flex-1 text-[16px] self-stretch max-md:max-w-full">
                  <div className="text-black max-md:max-w-full">
                    New Password
                  </div>

                  <input
                    className="mt-1 border-none outline-none text-zinc-500 max-md:max-w-full"
                    type={passwordVisible.new ? "text" : "password"}
                    placeholder="Enter new password"
                    value={passwords.newPassword}
                    onChange={(e) =>
                      setPasswords({
                        ...passwords,
                        newPassword: e.target.value,
                      })
                    }
                  />
                </div>
                {passwordVisible.new ? (
                  <VisibilityOffIcon
                    sx={{ color: "gray", cursor: "pointer" }}
                    onClick={() => togglePasswordVisibility({ name: "new" })}
                  />
                ) : (
                  <VisibilityIcon
                    sx={{ color: "gray", cursor: "pointer" }}
                    onClick={() => togglePasswordVisibility({ name: "new" })}
                  />
                )}
              </div>
              <div className="flex gap-5 items-center px-6 py-2 mt-3.5 ml-3 bg-white border border-gray-200 border-solid rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:mt-10">
                <img
                  loading="lazy"
                  src="/images/lock.png"
                  className="shrink-0 self-stretch my-auto aspect-[1.03] w-[30px]"
                />
                <div className="flex flex-col text-[16px] flex-1 self-stretch max-md:max-w-full">
                  <div className="text-black max-md:max-w-full">
                    Confirm New Password
                  </div>

                  <input
                    className="mt-1 border-none outline-none text-zinc-500 max-md:max-w-full"
                    type={passwordVisible.confirm ? "text" : "password"}
                    placeholder="Enter new password to confirm"
                    value={passwords.cPassword}
                    onChange={(e) =>
                      setPasswords({ ...passwords, cPassword: e.target.value })
                    }
                  />
                </div>
                {passwordVisible.confirm ? (
                  <VisibilityOffIcon
                    sx={{ color: "gray", cursor: "pointer" }}
                    onClick={() =>
                      togglePasswordVisibility({ name: "confirm" })
                    }
                  />
                ) : (
                  <VisibilityIcon
                    sx={{ color: "gray", cursor: "pointer" }}
                    onClick={() =>
                      togglePasswordVisibility({ name: "confirm" })
                    }
                  />
                )}
              </div>

              <button
                className={`justify-center py-4 mt-7 ml-3 text-[18px] text-center text-black ${
                  user?.role == 1 ? "bg-amber-300" : "bg-estab-back text-white"
                } rounded-[124.047px] max-md:px-5 max-md:max-w-full`}
                onClick={() => handleSubmitChangePass()}>
                Update Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassSetting;
