import React, { useEffect, useState } from "react";
import styles from "../celender.module.css";
import { postAvailability } from "../../../axios/axios";
import { useSelector } from "react-redux";

function SetAvailibility({ activeslot, day, setShowModal3 }) {
  const user = useSelector((state) => state.user.user);
  const [availtime, setAvailTime] = useState([{ starttime: "", endtime: "" }]);

  const handleAddSlot = (availtime) => {
    console.log(availtime);

    setAvailTime([...availtime, { starttime: "", endtime: "" }]);
  };

  const handleSaveSlot = () => {
    postAvailability({ token: user?.token, availtime, day: [day] })
      .then((result) => {
        setShowModal3(false);
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (index, field, value) => {
    const newAvailTime = [...availtime];
    newAvailTime[index][field] = value;
    setAvailTime(newAvailTime);
  };

  const handleRemoveSlot = (index) => {
    const newAvailTime = availtime.filter((_, i) => i !== index);
    setAvailTime(newAvailTime);
  };

  useEffect(() => {
    if (activeslot && activeslot.length > 0) {
      setAvailTime(
        activeslot.map((slot) => ({
          starttime: slot.starttime,
          endtime: slot.endtime,
        }))
      );
    }
  }, [activeslot]);
  return (
    <div>
      <div className="flex flex-col w-full px-11 max-md:px-5 max-md:max-w-full">
        <div
          className={`flex-auto self-center text-[23px] text-center ${styles.header}`}>
          Set Your Availability
        </div>
        <div className="text-[15px] tracking-wide leading-7 text-black max-md:max-w-full">
          Select time
        </div>
        {availtime.map((slot, index) => (
          <div
            key={`Rans_${index}_TME`}
            className="flex items-start justify-between w-full gap-5 px-5 py-1 mt-4 tracking-wide whitespace-nowrap rounded-xl bg-slate-100 max-md:flex-wrap max-md:px-5">
            <div className="flex flex-col">
              <div className="text-sm font-medium leading-7 text-slate-400">
                From
              </div>
              <input
                type="time"
                className="flex-auto py-2 text-xl leading-6 border border-gray-300 rounded-lg w-[150px] text-slate-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                value={slot.starttime}
                onChange={(e) =>
                  handleChange(index, "starttime", e.target.value)
                }
              />
            </div>
            <div className="flex flex-col">
              <div className="text-sm font-medium leading-7 text-neutral-400">
                To
              </div>
              <input
                type="time"
                className="flex-auto py-2 text-xl leading-6 border border-gray-300 rounded-lg w-[150px] text-slate-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                value={slot.endtime}
                onChange={(e) => handleChange(index, "endtime", e.target.value)}
              />
            </div>
            <button
              onClick={() => handleRemoveSlot(index)}
              className="px-3 py-2 text-xl text-red-500">
              &#10005;
            </button>
          </div>
        ))}
        <img
          loading="lazy"
          src="/images/completeProfile/red-plus.png"
          className="self-end mt-1.5 aspect-square w-[25px] max-md:mr-2.5"
          onClick={() => handleAddSlot(availtime)}
          alt="Add Slot"
        />
      </div>
      <div className="flex flex-col w-full text-base text-black px-11 whitespace-nowrap max-md:px-5 max-md:max-w-full">
        <div
          className="justify-center p-4 mt-8 text-center bg-amber-300 rounded-[99.611px] max-md:max-w-full"
          onClick={() => handleSaveSlot()}>
          Save
        </div>
      </div>
    </div>
  );
}

export default SetAvailibility;
