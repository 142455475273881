import React from "react";
import style from "./BaroomIntro.module.css";
import { Link } from "react-router-dom";

const BaroomIntro = () => {
  return (
    <>
      <div className={style.main}>
        <div className={style.left}>
          <div className={style.innerLeft}>
            <h5>Who are we</h5>
            <h4>What is BAROOOM?</h4>
            <p>
              BAROOOM is your trusted companion in finding reliable and local
              bartending help. It’s an innovative app designed to connect
              establishments with nearby bartenders who come recommended by
              friends and neighbors.
            </p>
            <Link to={"/about-us"} className="w-fit whitespace-nowrap">
              <button>Read More</button>
            </Link>
          </div>
        </div>
        <div className={style.right}>
          <>
            <div className={style.box1}>
              <div className={style.innerbox1}>
                <img src={"/LandingPageImages/location.svg"} />
                <h6>Location Based Hiring</h6>
                <p>
                  Find Bartenders & Staff in your area with just a few clicks.
                </p>
              </div>
            </div>
            <div className={style.box2}>
              <div className={style.innerbox2}>
                <img src={"/LandingPageImages/handshake.svg"} alt="" />
                <h6>Instant Hiring Process</h6>
                <p>
                  Bartenders & Staff activate AVAILABLE NOW and can be only
                  minutes away from working.
                </p>
              </div>
            </div>
            <div className={style.box3}>
              <div className={style.innerbox3}>
                <img src={"/LandingPageImages/community.svg"} alt="" />
                <h6>Unique Open Community</h6>
                <p>
                  Allows Bartenders to “Friend” cohorts and share reviews with
                  full transparency.
                </p>
              </div>
            </div>
            <div className={style.box4}>
              <div className={style.innerbox4}>
                <img src={"/LandingPageImages/userInterface.svg"} alt="" />
                <h6>User Friendly Interface</h6>
                <p>
                  Seamless interaction includes Chatting, Scheduling, Payments
                  and History on a simple dashboard.
                </p>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default BaroomIntro;
