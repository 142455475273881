import { useEffect } from "react";
import { login, logout } from "../redux/userSlice";
import EstablishmentMains from "../components/dashboard/Establishment/mains/mains";
import BartenderWrapperDash from "../components/dashboard/WrapperDash";
import { useDispatch, useSelector } from "react-redux";
import WrapperDashEst from "../components/authWrapper/WrapperDashEst";
import { generateToken } from "../firebase";
import { addtoken } from "../redux/tokenslice";

function Dashboard() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const updateNotifyToken = async () => {
    try {
      const notifytoken = await generateToken(user?.token);
      dispatch(addtoken(notifytoken));
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    updateNotifyToken();
  }, []);
  return (
    <>
      {user && (
        <>
          {user.role === 1 && <BartenderWrapperDash />}
          {user.role === 2 && <WrapperDashEst />}
        </>
      )}
    </>
  );
}
export default Dashboard;
