import { useState, useEffect } from "react";
import WrapperDash from "../../dashboard/WrapperDash";
import style from "../setting.module.css";
import Dropdown from "../../dashboard/Bartender/Dropdown";
import Notification from "../../dashboard/Bartender/Notification";
import { Link, useNavigate } from "react-router-dom";
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { getfaqcontnet } from "../../../axios/axios";
import DashHeader from "../../dashboard/DashHeader";
import { useSelector } from "react-redux";
import EstabDashHeader from "../../dashboard/Establishment/EstabDashHeader";

function FreqAsk() {
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const [faq, setFaq] = useState();

  const getFAQQuestions = () => {
    getfaqcontnet({ type: "FAQ" })
      .then((result) => {
        const res = result.data.data;
        setFaq(res.FAQ);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFAQQuestions();
  }, []);

  return (
    <div>
      <div className="flex flex-col max-w-full grow shrink-0 basis-0 max-md:max-w-full">
        <div className="flex flex-col px-5 bg-zinc-50 max-md:px-5 max-md:max-w-full">
          {user?.role == 1 ? (
            <DashHeader
              btn={
                <div
                  className="uppercase w-[60px] flex justify-center items-center border-2 border-black rounded-xl cursor-pointer"
                  onClick={() => navigate("/settings")}>
                  <span className="text-neutral-700">&lt;</span>{" "}
                </div>
              }
            />
          ) : (
            <EstabDashHeader
              btn={
                <div
                  className="uppercase w-[60px] flex justify-center items-center border-2 border-black rounded-xl cursor-pointer"
                  onClick={() => navigate("/settings")}>
                  <span className="text-neutral-700">&lt;</span>{" "}
                </div>
              }
            />
          )}

          {/* <div className="h-px mt-5 border border-solid shrink-0 bg-zinc-100 border-zinc-300 max-md:max-w-full" /> */}
          <div className="flex flex-col mt-8 bg-white shadow-2xl px-14 py-9 rounded-3xl max-md:px-5 max-md:max-w-full">
            <div className="text-[20px] text-black max-md:max-w-full">
              Frequently Asked Questions
            </div>
            {faq?.map((elem, ind) => (
              <div className="mt-5" key={`${ind}_FAQ`}>
                <Accordion
                  style={{ boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.12)" }}>
                  <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                    <span className="text-red-500 text-[16px]">
                      {" "}
                      {elem.Question}
                    </span>
                  </AccordionSummary>
                  <AccordionDetails className="text-[13px]">
                    <div dangerouslySetInnerHTML={{ __html: elem.Answer }} />
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}

            <div className="flex justify-center mt-12">
              <button
                onClick={() => navigate("/ticket")}
                className={`justify-center px-14 py-4 w-[300px] text-[13px] text-center text-black ${
                  user?.role == 1 ? "bg-amber-300" : "bg-estab-back text-white"
                }  rounded-[92.207px]`}>
                Chat With Support
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FreqAsk;
