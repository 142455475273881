import React from "react";
import style from "./Footer.module.css";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const openExternalLink = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <>
      <div className={style.main}>
        <div className={style.innerDiv}>
          <img
            loading="lazy"
            className={style.baroom}
            src={"/LandingPageImages/Barrom.svg"}
            alt=""
          />
          <div className={style.linkItems}>
            <Link to={"/about-us"} className={style.istLink}>
              About Us
            </Link>
            <Link to={"/privacy-policy"} className={style.istLink}>
              Privacy Policy
            </Link>
            <Link to={"/terms-conditions"}>Terms & Conditions</Link>
          </div>
          <p>Subscribe To Our Newsletter</p>
          <div className={style.inputArea}>
            <input type="email" placeholder="Enter Your Email Address" />
            <button className={style.inpBtn}>Subscribe</button>
          </div>
          <div className={style.logoPart}>
            <img
              src={"/Footer/facebook.svg"}
              alt=""
              className="cursor-pointer"
              onClick={() =>
                openExternalLink(
                  "https://www.facebook.com/profile.php?id=61559697518079&mibextid=ZbWKwL"
                )
              }
            />
            <img
              src={"/Footer/insta.svg"}
              alt=""
              className="cursor-pointer"
              onClick={() =>
                openExternalLink(
                  "https://www.instagram.com/barooombartenders?igsh=Z2ZlYTE0a3VianI3"
                )
              }
            />
            <img
              src={"/Footer/crossicon.png"}
              alt=""
              className="cursor-pointer"
              onClick={() =>
                openExternalLink("https://www.linkedin.com/company/barooom/")
              }
            />
          </div>
          <hr />
          <h6 style={{lineHeight:'0px !important'}}>Barooom: Product By The Cottrill Group.</h6>
          <h6>Copyright © 2024 barooom. All Rights Reserved.</h6>
        </div>
      </div>
    </>
  );
};

export default Footer;
