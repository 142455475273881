import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "./JobStyle.css";
import { ensureNumber } from "../../../../utils/inputFormatter";

function Jp2({ jobdata, setJobData, setActiveJTab, activejtab }) {
  const [errormsg, setErrorMsg] = useState({});
  const checkInput = ["yearsOfExperience", "payHourly", "skillsRequired"];
  const [currentSkill, setCurrentSkill] = useState("");
  const [suggestSkills, setShowSuggestSkills] = useState(true);

  const handlePayHourlyChange = (e) => {
    const value = ensureNumber({ value: e.target.value, maxLength: 8 });
    if (value > 0) {
      delete errormsg["payHourly"];
    }
    setJobData((prevState) => ({
      ...prevState,
      payHourly: value ? parseFloat(value) : 0,
    }));
  };

  const [initialSuggestedSkills] = useState([
    "Bartending",
    "Barback",
    "FOH Support",
    "Mixologist",
    "Host",
    "Cashier",
    "Server",
  ]);

  const [suggestedSkills, setSuggestedSkills] = useState([
    ...initialSuggestedSkills,
  ]);

  const handleSaveNext = () => {
    const errors = checkInput.reduce((acc, field) => {
      if (!jobdata[field] || jobdata[field].length === 0) {
        acc[field] = `${field} is required`;
      }
      return acc;
    }, {});
    if (Object.keys(errors).length > 0) {
      toast.error("Mandatory Fields Empty");
      setErrorMsg(errors);
    } else {
      setErrorMsg({});
      setActiveJTab(3);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (currentSkill.trim()) {
        setJobData((prevState) => ({
          ...prevState,
          skillsRequired: [...prevState.skillsRequired, currentSkill.trim()],
        }));
        setSuggestedSkills((prevSkills) =>
          prevSkills.filter(
            (skill) => skill.toLowerCase() !== currentSkill.trim().toLowerCase()
          )
        );
        delete errormsg["skillsRequired"];
        setCurrentSkill("");
      }
    } else if (e.key === "Backspace" && currentSkill === "") {
      e.preventDefault();
      setJobData((prevState) => {
        const updatedSkills = [...prevState.skillsRequired];
        const poppedValue = updatedSkills.pop();
        if (poppedValue && initialSuggestedSkills.includes(poppedValue)) {
          setSuggestedSkills((prevSkills) => [...prevSkills, poppedValue]);
        }
        return { ...prevState, skillsRequired: updatedSkills };
      });
    }
  };
  return (
    <div className="flex flex-col items-center bg-white shadow-lg rounded-[25px] p-4 h-full overflow-auto no-scrollbar">
      <style>
        {`
          .hide-calendar::-webkit-calendar-picker-indicator {
            display: none;
          }
          .hide-calendar::-moz-calendar-picker-indicator {
            display: none;
          }
          .hide-calendar::-ms-clear {
            display: none;
          }
        `}
      </style>
      <div className="flex flex-col items-center">
        <p className="text-2xl font-righteous">Post A Job</p>
        <p className="text-sm font-poppins text-subtext">Add New Job</p>
      </div>
      <div className="w-[90%] flex flex-col gap-8">
        <div className="flex justify-between w-full px-6">
          <div
            className={
              errormsg?.yearsOfExperience
                ? `eFrrorInputField`
                : "validInputField"
            }>
            <img
              loading="lazy"
              src="/images/completeProfile/solarStar.png"
              className="shrink-0 my-auto aspect-square w-[22px]"
            />
            <div className="w-[90%]">
              <div
                className={
                  errormsg?.yearsOfExperience
                    ? "text-red-500 text-sm"
                    : "text-sm"
                }>
                {" "}
                Minimum years of experience{" "}
                <span className="text-error">*</span>
              </div>
              <div>
                <input
                  className={`${
                    errormsg?.yearsOfExperience
                      ? "text-sm outline-none w-full placeholder:text-red-600"
                      : " text-sm outline-none w-full"
                  }
              `}
                  type="text"
                  value={jobdata.yearsOfExperience}
                  onChange={(e) => {
                    if (e.target.value.length > 0) {
                      delete errormsg["yearsOfExperience"];
                    }
                    setJobData({
                      ...jobdata,
                      yearsOfExperience: ensureNumber({
                        value: e.target.value,
                        maxLength: 2,
                      }),
                    });
                  }}
                  placeholder="Minimum years of experience"
                />
              </div>
            </div>
          </div>
          <div
            className={
              errormsg?.payHourly ? `eFrrorInputField` : "validInputField"
            }>
            <img
              loading="lazy"
              src="/images/Dollor_Icon.svg"
              className="size-5"
            />
            <div className="w-[90%]">
              <div
                className={
                  errormsg?.payHourly ? "text-red-500 text-sm" : "text-sm"
                }>
                Pay <span className="text-error">*</span>
              </div>
              <div>
                <input
                  className={`${
                    errormsg?.payHourly
                      ? "text-sm outline-none w-full placeholder:text-red-600"
                      : " text-sm outline-none w-full"
                  }
                `}
                  type="text"
                  placeholder="$20/hr"
                  value={jobdata.payHourly > 0 ? jobdata.payHourly : ""}
                  onChange={(e) => {
                    if (e.target.value.length > 0) {
                      delete errormsg["yearsOfExperience"];
                    }
                    handlePayHourlyChange(e);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            errormsg?.skillsRequired
              ? `errorTextAreaField`
              : "validTextAreaField"
          }>
          <img
            loading="lazy"
            src="/images/completeProfile/pen.png"
            className="shrink-0 self-start aspect-[1.04] w-[23px]"
          />
          <div className="flex flex-col text-left grow shrink-0 basis-0 w-fit max-md:max-w-full">
            <div
              className={
                errormsg?.skillsRequired ? "text-red-500 text-sm" : "text-sm"
              }>
              Skills Required <span className="text-error">*</span>
            </div>
            <div className="mt-2 text-zinc-500 max-md:max-w-full">
              <ul className="flex flex-wrap gap-2 p-2">
                {jobdata.skillsRequired.map((skill, index) => (
                  <li
                    key={index}
                    className="bg-gray-500 py-1 px-[5px] text-sm rounded-full text-black">
                    {skill}
                  </li>
                ))}
              </ul>
              <input
                className={`${
                  errormsg?.skillsRequired
                    ? "text-sm outline-none w-full placeholder:text-red-600 py-2"
                    : " text-sm outline-none w-full py-2"
                }
              `}
                type="text"
                placeholder="Type the skill required and press Enter to register the skill"
                value={currentSkill}
                onChange={(e) => {
                  setCurrentSkill(e.target.value);
                }}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
        </div>
        {suggestSkills && (
          <div className="flex flex-col px-3.5 pt-3 pb-7 mx-5 mt-1 text-black bg-white rounded-2xl shadow-sm max-md:mr-2.5 max-md:max-w-full">
            <div className="flex gap-5 px-0.5 text-sm font-medium max-md:flex-wrap max-md:max-w-full">
              <div className="flex-auto text-left">Suggested Skills</div>
              <img
                loading="lazy"
                src="/images/completeProfile/cross.png"
                className="w-6 shrink-0 aspect-square"
                onClick={() => setShowSuggestSkills(!suggestSkills)}
              />
            </div>
            <div className="flex flex-wrap self-start gap-2 mt-3 text-sm">
              {suggestedSkills.map((elem, ind) => (
                <button
                  key={`sugskill_${ind}`}
                  className="justify-center px-4 py-2.5 whitespace-nowrap bg-neutral-100 rounded-[100px]"
                  onClick={() => {
                    setJobData((prevState) => ({
                      ...prevState,
                      skillsRequired: [...prevState.skillsRequired, elem],
                    }));
                    setSuggestedSkills((prevSkills) =>
                      prevSkills.filter((_, index) => index !== ind)
                    );
                    delete errormsg["skillsRequired"];
                  }}>
                  {elem}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
      <div
        className="bg-red-600 w-[40%] rounded-[40px] min-h-16 mt-8 flex justify-center items-center text-white text-lg hover:cursor-pointer"
        onClick={() => handleSaveNext()}>
        Save & Next
      </div>
    </div>
  );
}

export default Jp2;
