import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "@mui/material";
import configuration from "../../../config/configuration";
import { useNavigate } from "react-router-dom";
import DropdownEst from "./DropdownEst";
import NotificationEst from "./NotificationEst";

export default function EstabDashHeader({ leftContent, btn }) {
  const user = useSelector((state) => state.user.user);
  const notifyState = useSelector((state) => state.isnotify.isUnread);
  const [showNotification, setShowNotification] = useState(false);
  const [profileDrop, setProfileDrop] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <Modal
        sx={{ minWidth: "1000px" }}
        open={profileDrop}
        onClose={() => setProfileDrop((prev) => !prev)}>
        <DropdownEst />
      </Modal>
      <div className="flex justify-between w-full gap-5 px-5 pb-5 mt-3 border-b-2 border-solid ">
        <div className="flex gap-5 my-auto text-[30px] uppercase font-semibold tracking-tight leading-[50px]">
          {leftContent}
          {btn}
        </div>
        <div className="flex justify-between gap-5 text-lg text-white max-md:flex-wrap">
          <div
            className="flex flex-col justify-center flex-1 px-3 rounded-full cursor-pointer bg-estab-back"
            onClick={() => navigate("/post-job")}>
            <div className="flex gap-2.5 ">
              <img
                loading="lazy"
                src="/images/Add_Job.svg"
                className="w-5 lg:block"
              />
              <button className="font-poppins font-normal text-[1rem] lg:text-sm">Create New Job Post</button>
            </div>
          </div>
          <div className="flex justify-between gap-5">
            <div className="relative cursor-pointer h-fit">
              <img
                onClick={() => {
                  setShowNotification(!showNotification);
                }}
                loading="lazy"
                src="/images/solar_bell-bold.png"
                className="shrink-0 self-start mt-2.5 aspect-square w-[42px]"
              />
              {notifyState && (
                <img
                  src="/images/Red_Dot.svg"
                  className="absolute top-0 translate-y-2 right-2 "
                  alt=""
                />
              )}
            </div>
            <div
              className="flex items-center gap-3 px-2 border-black rounded-lg shadow-lg"
              onClick={() => setProfileDrop(!profileDrop)}>
              <div>
                {" "}
                <img
                  loading="lazy"
                  src={`${configuration.cdnFront}/${user?.pic}`}
                  className="size-[42px] rounded-xl"
                />
              </div>
              <button className="">
                <img src="/images/Down 2.svg" className="size-4" />
              </button>
            </div>
          </div>
          {showNotification && (
            <NotificationEst
              setShowNotification={setShowNotification}
              showNotification={showNotification}
            />
          )}
        </div>
      </div>
    </>
  );
}
