import React from "react";
import Wrappers from "../Wrappers";
import styles from "./CpE.module.css";
import { Bounce, toast } from "react-toastify";
import { useState } from "react";
import { CircularProgress, Modal } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ensureNumber } from "../../../utils/inputFormatter";
import { TimeZoneList } from "../../../utils/List";
import AutoComplete from "../../AutoComplete";

dayjs.extend(utc);
dayjs.extend(timezone);
// dayjs.tz.guess();

function CpE5({ activeTab, setActiveTab, changeInputHandler, data }) {
  const [loader, setLoader] = useState(false);
  const [loading, setloading] = useState(true);

  const submitHandler = () => {
    let msg = "";

    if (!data.latitude || !data.longitude)
      msg = "Longitude and Latitude are Critical and cannot be skipped";
    else if (!data.timeZone) msg = "Time zone is crucial and cannot be skipped";
    else if (
      !data.location.streetName ||
      !data.location.postalCode ||
      !data.location.city ||
      !data.location.state
    )
      msg = "Street name, Postal code, city and state cannot be empty";

    if (msg) {
      toast.error(msg, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } else {
      setActiveTab((prev) => prev + 1);
    }
  };

  const handleAutoComplete = (data) => {
    const {
      streetName,
      city,
      state,
      postalCode,
      latitude,
      longitude,
      timeZone,
    } = data;
    changeInputHandler({
      name: "latitude",
      value: latitude,
    });

    changeInputHandler({
      name: "longitude",
      value: longitude,
    });

    changeInputHandler({
      name: "timeZone",
      value: timeZone,
    });
    changeInputHandler({
      name: "location",
      value: { ...data.location, streetName, city, state, postalCode },
    });
  };
  return (
    <div className="flex relative justify-center items-center self-center px-16  py-12 w-full max-w-[1599px] max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col mx-[50px] items-center max-w-full w-[1300px]">
        <div
          className={`text-2xl leading-9 text-center text-black} ${styles.header}`}>
          Location
        </div>
        <div className="flex justify-center items-center py-5 mt-10 max-w-full text-center rounded-xl border border-dashed leading-[153.3%] w-[80%] h-fit">
          <AutoComplete
            handleAutoComplete={handleAutoComplete}
            setloading={setloading}
          />
        </div>

        {!loading && (
          <>
            <div className="mt-8 text-base font-bold text-black">OR</div>
            <div className="flex gap-5 justify-between self-stretch mt-7 text-xs items-center max-md:flex-wrap max-md:max-w-full">
              <div className="flex flex-col flex-1 px-5 py-3.5 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:max-w-full">
                <div className="text-black text-left max-md:max-w-full">
                  Select Time Zone <span style={{ color: "#ff3333" }}>*</span>
                </div>
                <div className="mt-2 text-zinc-500 text-start max-md:max-w-full">
                  <select
                    className="w-full border-none outline-none"
                    name="timeZone"
                    value={data.timeZone}
                    id="timeZone"
                    onChange={(e) =>
                      changeInputHandler({
                        name: e.target.name,
                        value: e.target.value,
                      })
                    }>
                    {TimeZoneList?.map((elem, ind) => (
                      <option key={`${elem.value}_${ind}`} value={elem.value}>
                        {elem.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="flex gap-5 justify-between self-stretch mt-7 text-xs max-md:flex-wrap max-md:max-w-full">
              <div className="flex flex-col flex-1 px-5 py-3.5 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:max-w-full">
                <div className="text-black text-left max-md:max-w-full">
                  Street Name <span style={{ color: "#ff3333" }}>*</span>
                </div>
                <div className="mt-2 text-zinc-500 text-start max-md:max-w-full">
                  <input
                    className="border-none outline-none w-full"
                    type="text"
                    placeholder="Street Name"
                    name="location"
                    value={data.location.streetName}
                    onChange={(e) =>
                      changeInputHandler({
                        name: e.target.name,
                        value: { ...data.location, streetName: e.target.value },
                      })
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col flex-1 px-5 py-3.5 whitespace-nowrap bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:max-w-full">
                <div className="text-black text-left max-md:max-w-full">
                  City <span style={{ color: "#ff3333" }}>*</span>
                </div>
                <div className="mt-2 text-zinc-500 text-left max-md:max-w-full">
                  <input
                    className="border-none outline-none w-full"
                    type="text"
                    placeholder="City"
                    name="location"
                    value={data.location.city}
                    onChange={(e) =>
                      changeInputHandler({
                        name: e.target.name,
                        value: { ...data.location, city: e.target.value },
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="flex gap-5 justify-between self-stretch mt-6 text-xs max-md:flex-wrap max-md:max-w-full">
              <div className="flex flex-col flex-1 px-5 py-3.5 whitespace-nowrap bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:max-w-full">
                <div className="text-black text-left max-md:max-w-full">
                  State <span style={{ color: "#ff3333" }}>*</span>
                </div>
                <div className="mt-2 text-zinc-500 text-left max-md:max-w-full">
                  <input
                    className="border-none outline-none w-full"
                    type="text"
                    placeholder="State"
                    name="location"
                    value={data.location.state}
                    onChange={(e) =>
                      changeInputHandler({
                        name: e.target.name,
                        value: { ...data.location, state: e.target.value },
                      })
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col flex-1 px-5 py-3.5 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:max-w-full">
                <div className="text-black text-left max-md:max-w-full">
                  Postal Code <span style={{ color: "#ff3333" }}>*</span>
                </div>
                <div className="mt-2 text-zinc-500 text-left max-md:max-w-full">
                  <input
                    className="border-none outline-none w-full"
                    type="text"
                    placeholder="Postal Code"
                    name="location"
                    value={data.location.postalCode}
                    onChange={(e) =>
                      changeInputHandler({
                        name: e.target.name,
                        value: {
                          ...data.location,
                          postalCode: ensureNumber({
                            value: e.target.value,
                            maxLength: 6,
                          }),
                        },
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div
              onClick={submitHandler}
              style={{ cursor: "pointer" }}
              className="justify-center px-5 py-5 mt-20 w-[60%] text-sm text-center text-white bg-red-500 rounded-[92.207px] max-md:mt-10 max-md:max-w-full">
              Save & Next
            </div>
          </>
        )}
      </div>
    </div>
  );
}
export default CpE5;
