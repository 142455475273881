import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BarJobCards from "../../../../jobCards/BarJobCards";
import { CancleForGig } from "../../../../../axios/axios";
import { toast } from "react-toastify";

export default function AppliedGigs({ data, setChange, change }) {
  const user = useSelector((state) => state.user.user);

  const handleCancleGig = (job_id) => {
    CancleForGig({ token: user?.token, job_id })
      .then((result) => {
        toast.success(result?.data.message);
        setChange(!change);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(()=>{

  // },[])

  return (
    <div className="flex flex-wrap w-full gap-6 px-2 pt-6 max-md:max-w-full ">
      {data.map((elem, index) => {
        return (
          <BarJobCards
            elem={elem}
            Jtype={1}
            index={index}
            handleCancleGig={handleCancleGig}
          />
        );
      })}
      {data?.length < 1 ? (
        <div className="flex items-center justify-center w-full">
          <img src="/images/EmptyGig.svg" sizes="20" alt="" />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
