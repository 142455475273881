import React, { useEffect, useState } from "react";
import styles from "../components/Profile/profile.module.css";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { FetchBartenderDetails } from "../axios/axios";
import configuration from "../config/configuration";
import ReviewCards from "../components/Reviews/Bartendar/ProfileReviewCards";

function BartenderDetails() {
  const user = useSelector((state) => state.user.user);
  const [userdata, setUserData] = useState();
  const [availability, setAvailability] = useState();
  const [availloading, setAvailLoading] = useState(true);
  const { bid } = useParams();
  let totalreview = 0;
  let totalJobs = 0;
  const startOfWeek = dayjs().startOf("week");
  const month = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const week = [
    {
      day: "Monday",
    },
    {
      day: "Tuesday",
    },
    {
      day: "Wednesday",
    },
    {
      day: "Thursday",
    },
    {
      day: "Friday",
    },
    {
      day: "Saturday",
    },
    {
      day: "Sunday",
    },
  ];

  const [reviews, setReviews] = useState([]);
  const getUserData = () => {
    FetchBartenderDetails({ token: user?.token, uid: bid })
      .then((result) => {
        setUserData(result.data.userDetail);
        totalreview = result.data.totalreview;
        totalJobs = result.data.totalJobs;
        setReviews(result.data.reviews);
        setAvailability(result.data.availability);
        setAvailLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div>
      <div className="flex justify-between mt-8">
        <div className="w-[70%]">
          <div
            style={{ boxShadow: "0px 4px 44px 0px rgba(0, 0, 0, 0.06)" }}
            className="relative px-5 pb-5 bg-white rounded-3xl max-md:px-5 max-md:max-w-full">
            <div className="flex items-center max-xl:flex-col max-md:gap-0">
              <div className="size-[150px] relative rounded-round">
                <img
                  loading="lazy"
                  src={
                    `${configuration.cdnFront}/${userdata?.profileImg}` || ""
                  }
                  className="rounded-full size-full"
                />
              </div>
              <div className="flex flex-col ml-3 w-[83%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col mt-0 grow max-md:mt-2 max-md:max-w-full">
                  <div className="flex items-start justify-between w-full gap-5 max-md:flex-wrap max-md:max-w-full">
                    <div className="flex flex-col self-end mt-4 max-xl:mx-auto max-md:mt-10">
                      <div className="text-xl font-semibold text-amber-300">
                        {userdata?.uid?.name}
                      </div>
                      <div className="text-sm tracking-wide  max-xl:mx-auto text-slate-950">
                        {userdata?.designation}
                      </div>
                      <div className="h-px mt-3 border border-solid shrink-0 bg-neutral-400 border-neutral-400" />
                    </div>
                    <div className="flex absolute top-[-20px] right-[20px] gap-5 justify-between items-start self-start text-center">
                      <div className="flex flex-col whitespace-nowrap bg-white rounded-full border border-solid border-zinc-300 size-[80px]">
                        <img
                          loading="lazy"
                          src="/images/completeProfile/Vector.png"
                          className="z-10 self-center mt-0 aspect-[1.10] absolute top-[-8px]  fill-yellow-400 w-[28px]"
                        />
                        <div className="mt-5 text-sm font-medium text-black">
                          {parseFloat(totalreview).toFixed(1)}
                        </div>
                        <div className="text-[11px]">Rating</div>
                      </div>
                      <div className="flex rounded-round size-[80px] justify-center rounded-full items-center flex-col border-4 border-white bg-slate-100 p-2">
                        <div className="text-2xl font-medium text-red-500">
                          {totalJobs}
                        </div>
                        <div className=" text-[11px] text-neutral-400">
                          Gigs
                        </div>
                      </div>
                      <div className="flex rounded-round size-[80px] justify-center items-center rounded-full flex-col border-4 border-white bg-[#F4F5FA] p-2">
                        <div className="text-2xl font-medium text-red-500">
                          {userdata?.yearsOfExperience}
                          <span className="text-sm"> Yrs</span>
                        </div>
                        <p className="text-[11px] text-neutral-400">
                          Experience
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`mt-3 text-sm text-black  max-md:max-w-full} ${styles.header}`}>
                    About me
                  </div>
                  <div className="mt-1 text-sm leading-5 text-neutral-700 max-md:max-w-full">
                    {userdata?.bio}
                  </div>
                  <div className="flex gap-1.5 self-start mt-3 text-xs text-black">
                    {userdata?.skills.map((d) => (
                      <div className="justify-center px-3.5 py-2.5 bg-neutral-100 rounded-[100px]">
                        {d}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ boxShadow: "0px 4px 44px 0px rgba(0, 0, 0, 0.06)" }}
            className="py-8 pl-10 pr-20 bg-white mt-7 rounded-3xl max-md:px-5 max-md:max-w-full">
            <div className="flex gap-10 text-sm max-xl:flex-col">
              <div>
                <div className={`text-sm font-bold`}>Website Link</div>
                <div>{userdata?.portfoliolink}</div>
              </div>
              <div>
                <div className={`text-sm font-bold`}>LinkedIn Link</div>
                <div>{userdata?.linkedinlink}</div>
              </div>
            </div>
            <div className={`text-base mt-7 font-bold`}>Documents</div>
            {/*  <img
                    loading="lazy"
                    src="/images/completeProfile/pdf.png"
                    className="w-5 aspect-[0.95] fill-neutral-400"
                  /> */}
            <div className="flex flex-wrap gap-10 mt-3 max-md:gap-3 max-md:flex-col">
              <div className="flex flex-col items-center pb-3 mt-3 max-w-[150px] rounded-xl bg-zinc-100">
                <div className="flex items-center self-stretch justify-center px-5 py-6 bg-white border-4 border-solid rounded-xl border-zinc-100 max-md:px-5">
                  <img
                    loading="lazy"
                    src="/images/completeProfile/pdf.png"
                    className="w-5 aspect-[0.95] fill-neutral-400"
                  />
                </div>
                <div className="px-8 mt-2 text-xs leading-4 text-black">
                  {userdata?.resume?.resumenName}
                </div>
                <div className="px-8 mt-3 text-xs leading-4 text-stone-500">
                  {userdata?.resume?.size} KB
                </div>
              </div>
              {userdata?.certifications.length > 1
                ? userdata?.certifications?.map((elem) => (
                  <div className="flex flex-col items-center pb-3 mt-3 max-w-[150px] rounded-xl bg-zinc-100 overflow-hidden">
                    {elem.fileType != "pdf" ? (
                      <div className="flex items-center self-stretch justify-center px-5 py-6 bg-white border-4 border-solid rounded-xl border-zinc-100 max-md:px-5">
                        <img
                          loading="lazy"
                          src="/images/completeProfile/pdf.png"
                          className="w-5 aspect-[0.95] fill-neutral-400"
                        />
                      </div>
                    ) : (
                      <div className="max-h-[50%] max-w-[150px] rounded-xl overflow-hidden m-1">
                        <img
                          loading="lazy"
                          src={
                            `${configuration.cdnFront}/certificates_1_8B0G2R1C3M6C8RP.jpeg` ||
                            ""
                          }
                        />
                      </div>
                    )}
                    <div className="px-8 mt-2 text-xs leading-4 text-black text-nowrap">
                      {elem?.certificateName}
                    </div>
                    <div className="px-8 mt-3 text-xs leading-4 text-stone-500">
                      {parseFloat(elem?.size / (1024 * 1024)).toFixed(2)} MB
                    </div>
                  </div>
                ))
                : ""}
            </div>
          </div>

          <div
            style={{ boxShadow: "0px 4px 44px 0px rgba(0, 0, 0, 0.06)" }}
            className="py-8 bg-white pl-9 mt-7 rounded-3xl max-md:px-5 max-md:max-w-full">
            <div className={`text-base text-black mb-5} ${styles.header}`}>
              Reviews
            </div>
            <div className="grid max-w-full grid-cols-2 gap-5 mx-auto mt-8 xl:grid-cols-3">
              {reviews.map((item, index) => {
                return <ReviewCards key={`rando_${item._id}_${index}`} item={item} />;
              })}
            </div>
            <div className="flex gap-5 max-xl:flex-col max-md:gap-0">{ }</div>
          </div>
        </div>

        <div className="flex flex-col flex-2 px-8 pt-5 pb-20 max-w-[25%] min-w-[25%] bg-white rounded-3xl shadow-2xl max-xl:px-5 max-xl:mx-3 max-xl:mt-7 max-xl:max-w-full">
          <div className="flex items-start w-full gap-8 px-px max-md:flex-wrap max-md:max-w-full">
            <div
              className={`flex-auto text-xl text-slate-900} ${styles.header}`}>
              Your Availability
            </div>
          </div>
          <div className="mt-8">
            {week.map((elem, ind) => (
              <div className="flex items-center gap-8 my-2">
                <div className="min-w-[76px] bg-slate-100  border-t-[4.69px] border-amber-300 rounded-3xl min-h-[76px] flex flex-col justify-center">
                  <div className="text-center text-neutral-400">
                    {month[startOfWeek.month()]}
                  </div>
                  <div className="text-center text-black">
                    {startOfWeek.add(ind, "day").get("date")}
                  </div>
                </div>
                <div className="">
                  <div className="text-neutral-400">{elem.day}</div>
                  {!availloading ? (
                    <div>
                      {availability?.[week[ind]?.day.toLowerCase().slice(0, 3)]
                        .length < 1 ? (
                        <div>
                          <p className="">Unavailable</p>
                        </div>
                      ) : (
                        ""
                      )}
                      {availability?.[
                        week[ind]?.day.toLowerCase().slice(0, 3)
                      ]?.map((el, ind) => (
                        <p key={`Slot_${ind}`}>
                          {console.log(el)}
                          {dayjs(el.starttime, "HH:mm").format(
                            "hh:mm A"
                          )} - {dayjs(el.endtime, "HH:mm").format("hh:mm A")}
                        </p>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BartenderDetails;
