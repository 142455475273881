import React, { useEffect, useRef, useState } from "react";
import styles from "../dashboard.module.css";
import { useDispatch, useSelector } from "react-redux";
import { FetchNotification } from "../../../axios/axios";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import { ScreenMap } from "../../../utils/notificationMap";
import setupNotificationListener from "../../NotificaionHandler";
import { checkfalse } from "../../../redux/notificationSlice";

export default function NotificationEst({
  setShowNotification,
  showNotification,
}) {
  const user = useSelector((state) => state.user.user);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [activepage, setActivePage] = useState(1);
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });

  const GetAllNotification = () => {
    FetchNotification({ token: user?.token, page: activepage })
      .then((result) => {
        let res = result.data;
        let x = { ...notifications };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          history: [...x.history, ...res.history],
        };

        setNotifications(x);
      })
      .catch((err) => console.log(err));
  };

  const handleScroll = () => {
    const node = ref.current;

    if (node.scrollTop + node.clientHeight >= node.scrollHeight - 100) {
      if (notifications.next) {
        setActivePage(activepage + 1);
        GetAllNotification();
      }
    }
  };

  useEffect(() => {
    if (!showNotification) {
      setNotifications();
      return;
    } else if (showNotification) {
      GetAllNotification();
      dispatch(checkfalse(false));
    }
  }, [showNotification]);

  return (
    <div
      className=" absolute z-50 top-[70px] right-[100px] z-100 overflow-y-scroll h-[400px] w-[300px] no-scrollbar  flex flex-col pt-9 ml-20 pb-7  bg-gray-100 rounded-3xl border border-solid border-neutral-200 max-w-[390px] "
      ref={ref}
      onScroll={() => handleScroll()}>
      <div className="flex gap-5 justify-between self-center px-5 w-full text-2xl leading-8 whitespace-nowrap max-w-[326px] text-neutral-700">
        <div className={`flex-auto} ${styles.header}`}>Notifications</div>

        <button
          className="shrink-0 self-start w-5 aspect-square"
          onClick={() => setShowNotification(false)}>
          &#10005;
        </button>
      </div>
      {notifications?.history?.map((elem) => (
        <div
          className={`flex flex-col px-7 py-3 mt-5 cursor-pointer  w-full ${
            !elem?.read && "bg-red-100"
          }`}
          onClick={() => {
            let nav = ScreenMap.find((obj) => obj.value === elem?.path?.screen);
            if (nav && nav != undefined) {
              const id = elem?.path?.id;
              const navigateTo =
                id && nav.value != "Review-Request"
                  ? `${nav.page}/${id}`
                  : nav.page;
              navigate(navigateTo);
            }
          }}>
          <div className="flex gap-2.5 self-start ">
            <div
              className={`flex justify-center items-center px-2.5 h-[42px] rounded-[100px] w-[42px] ${
                elem?.read ? "bg-neutral-400" : "bg-red-500"
              }`}>
              <img
                loading="lazy"
                src="/images/completeProfile/bell.png"
                className="aspect-[0.69] w-[22px]"
              />
            </div>
            <div className="flex flex-col flex-1 self-start mt-1">
              <div className="text-xs text-black">{elem?.title}</div>
              <div className=" text-xs text-neutral-400">{elem?.Created}</div>
            </div>
          </div>
          <div className="mt-2.5 text-xs font-light text-black">
            {elem?.body}
          </div>
        </div>
      ))}
    </div>
  );
}
