import * as React from "react";
import { Link } from "react-router-dom";

function Dropdown() {
  return (
    <div className="flex absolute z-50 right-16 top-20 bg-gray-100 flex-col justify-center mt-5 ml-5 text-sm text-black border border-solid border-neutral-200 rounded-2xl w-fit max-w-[290px] p-5 pr-10">
      <div className="flex flex-col items-start gap-6 ">
        <div className="flex gap-3 whitespace-nowrap">
          <img
            loading="lazy"
            src="/images/DropDown/Profile.svg"
            className="w-6 shrink-0 aspect-square"
          />
          <Link to={"/profile"} className="self-start flex-auto mt-1">
            My Profile
          </Link>
        </div>
        <div className="flex gap-3 whitespace-nowrap">
          <img
            loading="lazy"
            src="/images/DropDown/Setting.svg"
            className="w-6 shrink-0 aspect-square"
          />
          <Link to={"/settings"} className="my-auto">
            Settings
          </Link>
        </div>
        <div className="flex gap-2.5">
          <img
            loading="lazy"
            src="/images/DropDown/AboutUs.svg"
            className="w-6 shrink-0 aspect-square"
          />
          <Link to={"/aboutus"} className="self-start ">
            About Us
          </Link>
        </div>
        <div className="flex gap-2.5">
          <img
            loading="lazy"
            src="/images/DropDown/Logout.svg"
            className="w-6 h-6 shrink-0 aspect-square"
          />
          <Link to={"/logout"} className="self-start ">
            Logout
          </Link>
        </div>
      </div>
    </div>
  );
}
export default Dropdown;
