import React, { useState } from "react";
import style from "../../eventDeatail.module.css";
import { Link } from "react-router-dom";
import WrapperDashEst from "../../../authWrapper/WrapperDashEst";
import DropdownEst from "../../../dashboard/Establishment/DropdownEst";
import NotificationEst from "../../../dashboard/Establishment/NotificationEst";

function PendingAcceptance1() {
  const [showModaldrop, setShowModal] = useState(false);
  const mainModaldrop = <DropdownEst></DropdownEst>;
  const [showModalnotify, setShowModalnotify] = useState(false);
  const mainModalnotify = <NotificationEst></NotificationEst>;
  const items = [1, 2, 3, 4, 5];
  const [showModal1, setShowModal1] = React.useState(false);
  const [showModal2, setShowModal2] = React.useState(false);
  const [showModal3, setShowModal3] = React.useState(false);


  return (
    <WrapperDashEst>
      {showModaldrop && mainModaldrop}

      {showModalnotify && mainModalnotify}

      <div className="flex gap-5 justify-between px-5 pb-5 mt-3 w-full max-md:flex-wrap max-md:pr-5 border-solid border-b-2 max-md:max-w-full">
        <div className="flex gap-5 my-auto  text-[30px] uppercase font-semibold tracking-tight leading-[50px]">
          Gig details
        </div>
        <div className="flex gap-5  justify-between text-lg text-white max-md:flex-wrap">
          <div className="flex flex-col flex-1  mt-2  justify-center px-3 bg-red-500 rounded-[113.703px] max-md:px-5">
            <div className="flex gap-2.5 ">
              <img
                loading="lazy"
                src="/images/completeProfile/plus.png"
                className=" w-5 "
              />
              <Link to={"/Jp1"} className=" text-sm">
                Create New Job Post
              </Link>
            </div>
          </div>
          <div className="flex gap-5 justify-between">
            <img
              onClick={() =>
                setShowModalnotify((setShowModalnotify) => !setShowModalnotify)
              }
              loading="lazy"
              src="/images/completeProfile/yellowNotify.png"
              className="shrink-0 self-start mt-2.5 aspect-square w-[42px]"
            />
            <div className="flex shadow-lg items-center rounded-lg px-2 border-black gap-3 ">
              <div>
                {" "}
                <img
                  loading="lazy"
                  src="/images/completeProfile/user1.png"
                  className="shrink-0 self-start mt-2.5 my-1 aspect-square w-[42px]"
                />
              </div>
              <button
                onClick={() => setShowModal((setShowModal) => !setShowModal)}
                className="text-5xl items-center relative bottom-4 text-black "
              >
                &#751;
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex mt-7 gap-5 max-lg:flex-col">
        {/* first */}
        <div className=" relative flex flex-2 max-w-[680px] flex-col grow items-start pt-5 pr-7 pb-20 pl-4 w-full bg-white rounded-3xl shadow-2xl max-md:pr-5 max-md:mt-6 max-md:max-w-full">
          <div className="flex overflow-hidden relative flex-col gap-5 self-stretch px-5 py-5 w-full   max-md:flex-wrap max-md:pl-5 max-md:max-w-full">
            <img
              loading="lazy"
              src="/images/completeProfile/Background-Image.png"
              className=" absolute inset-0  rounded-xl size-full"
            />
            <div className="flex relative gap-5 justify-between items-start w-full text-xl font-semibold tracking-tight leading-6 text-yellow-400 max-md:flex-wrap max-md:max-w-full">
              <div className="flex gap-5 mt-2.5">
                <div className="grow my-auto">ABG Establishment</div>
                <div className="flex bg-white rounded-2xl gap-1 py-1 px-2">
                  <img
                    src="/images/completeProfile/Vector.png"
                    className="w-[18px] h-[18px] mt-[1px]"
                  />
                  <div className="text-black text-[15px]">4.7</div>
                </div>
              </div>
              <span className="text-white text-4xl font-thin">&#x1F6C8;</span>
            </div>
            <div className="flex relative  justify-between items-start  max-md:flex-wrap max-md:max-w-full">
              <div className="flex flex-col self-start max-md:max-w-full">
                <div className="text-sm leading-5 text-white max-md:max-w-full">
                  10130 Perimeter Parkway Suite, Charloette, North Carolina,
                  1087
                </div>
                <div className="flex gap-10 mt-4 text-xs max-md:flex-wrap">
                  <div className="flex flex-col self-start">
                    <div className="flex gap-1.5 text-white text-opacity-80">
                      <img
                        loading="lazy"
                        src="/images/completeProfile/yellow-timer.png"
                        className="shrink-0 aspect-square w-[15px]"
                      />
                      <div className="flex-auto my-auto">Shift Time</div>
                    </div>
                    <div className="mt-1.5 font-medium text-white">
                      7:00 PM - 11:30 PM
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex gap-1.5 whitespace-nowrap text-white text-opacity-80">
                      <img
                        loading="lazy"
                        src="/images/completeProfile/yellow-user.png"
                        className="shrink-0 aspect-square w-[17px]"
                      />
                      <div className="flex-auto">Capacity</div>
                    </div>
                    <div className="mt-1.5 font-medium text-white">
                      100 - 200
                    </div>
                  </div>
                </div>
              </div>
              <img
                loading="lazy"
                src="/images/completeProfile/white-chat.png"
                className="shrink-0 self-end mt-7 aspect-square w-[50px]"
              />
            </div>
          </div>
          <div className="flex gap-7 ml-28 mt-7 max-w-[700px]  py-1 pl-2 pr-1  text-base leading-5 text-center max-lg:rounded-[10px] bg-white border border-gray-200 border-solid rounded-[100px] text-neutral-700 max-2xl:flex-wrap">
            <div className="flex gap-2 self-stretch px-2 py-2 max-lg:ml-10 font-medium text-white whitespace-nowrap bg-red-500 rounded-[100px] max-md:px-5">
              <img
                loading="lazy"
                src="/images/completeProfile/cocktail.png"
                className="shrink-0 w-7 aspect-square"
              />
              <div className="  mt-1 text-[14px]">About The Gig</div>
            </div>
            <div className="flex-auto text-sm self-stretch my-auto">
              <Link to={"/PendingAcceptance2"} className="grow text-sm mt-1">About Establishment</Link>
            </div>
            <Link to={"/PendingAcceptance3"} className="flex-auto text-sm self-stretch my-auto">
              Benefits
            </Link>
          </div>
          <div className=" flex flex-col mt-10 ml-7 text-sm text-neutral-400 max-md:ml-2.5">
            Job Title
            <span className="mt-1  text-sm text-neutral-800 max-md:ml-2.5">
              Lorem Ipsum
            </span>
          </div>
          <div className=" flex flex-col mt-6 ml-7 text-sm text-neutral-400 max-md:ml-2.5">
            About the Gig
            <span className="mt-1  text-sm text-neutral-800 max-md:ml-2.5">
              Lorem ipsum dolor sit amet consectetur. Gravida vehicula varius
              ornare ac nec velit gravida at. Lorem ipsum dolor sit amet
              consectetur. Gravida vehicula varius ornare ac nec velit gravida
              at.
            </span>
          </div>
          <div className=" flex flex-col mt-6 ml-7 text-sm text-neutral-400 max-md:ml-2.5">
            Shift Time
            <span className="mt-1  text-sm text-neutral-800 max-md:ml-2.5">
              7:00 PM - 11:30 PM{" "}
            </span>
          </div>
          <div className=" flex flex-col mt-6 ml-7 text-sm text-neutral-400 max-md:ml-2.5">
            Years of experience
            <span className="mt-1  text-sm text-neutral-800 max-md:ml-2.5">
              2-3 years{" "}
            </span>
          </div>
          <div className=" flex flex-col mt-6 ml-7 text-sm text-neutral-400 max-md:ml-2.5">
            Pay
            <span className="mt-1  text-sm text-neutral-800 max-md:ml-2.5">
              $20/hour{" "}
            </span>
          </div>
          <div className=" flex flex-col mt-6 ml-7 text-sm text-neutral-400 max-md:ml-2.5">
            Skills Required
            <span className="mt-1  text-sm text-neutral-800 max-md:ml-2.5">
              Lorem Ipsum{" "}
            </span>
          </div>
          <div className=" flex flex-col mt-6 ml-7 text-sm text-neutral-400 max-md:ml-2.5">
            Duties & Responsibilities{" "}
            <span className="mt-1  text-sm text-neutral-800 max-md:ml-2.5">
              <ul className="list-disc ml-7">
                <li>Lorem ipsum dolor sit amet consectetur. Gravida </li>
                <li>Lorem ipsum dolor sit amet consectetur. Gravida </li>
                <li>Lorem ipsum dolor sit amet consectetur. Gravida </li>
              </ul>
            </span>
          </div>
          <div className="flex gap-2 text-sm mt-10 self-center max-md:flex-wrap">
            <button onClick={()=>setShowModal1(true)}
            className="grow justify-center py-3 my-1.5 bg-amber-300 rounded-[92.207px] w-[400px] max-md:max-w-full">
              Cancel Gig
            </button>
            <img onClick={()=>setShowModal2(true)}
              loading="lazy"
              src="/images/completeProfile/emptyPen.png"
              className="shrink-0 aspect-square border-2 border-yellow-400 m-2 p-2 rounded-[15px] h-[50px] w-[50px]"
            />
          </div>
        </div>
        {/* second */}
      
      </div>

      <>
        {showModal1 ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative rounded-[20px] flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 ">
                    <div className="flex flex-col p-3 text-sm bg-white max-w-[438px]">
                      <div className="self-center text-base font-medium leading-7 text-center text-black">
                        Are you sure you want to cancel your gig?
                      </div>
                      <ul
                        style={{ listStyle: "disc" }}
                        className=" text-neutral-400 ml-3"
                      >
                        <li>
                          <div className="mt-5 text-neutral-400 max-md:max-w-full">
                            24 hours - No charge
                          </div>
                          <li>
                            <div className="mt-1 text-neutral-400 max-md:max-w-full">
                              12 hours - $4.99
                            </div>
                          </li>
                        </li>
                        <li>
                          <div className="mt-1 text-neutral-400 max-md:max-w-full">
                            6 hours - Pay bartender 1 hour of the wage expected
                          </div>
                        </li>
                      </ul>

                      <div className="flex gap-2.5 self-center mt-7 text-center capitalize">
                        <button
                          onClick={() => setShowModal1(false)}
                          className="justify-center px-12 py-3 text-black whitespace-nowrap bg-white border border-amber-300 border-solid rounded-[122px] max-md:px-5"
                        >
                          Cancel
                        </button>
                        <button className="justify-center px-7 py-3 text-black bg-amber-300 rounded-[122px] max-md:px-5">
                          <Link to={"/CurrentGigsEstablishment"}>
                            {" "}
                            Yes, I’m Sure
                          </Link>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
      <>
        {showModal2 ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative rounded-[20px] flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex flex-col items-center  pb-10  text-base text-center capitalize bg-white rounded-3xl max-w-[538px] max-md:px-5">
                  <span onClick={()=>setShowModal2(false)}
                   className="text-[22px] cursor-pointer self-end pt-2 pr-4 mb-2">&#10005;</span>
                    <Link to={"/GigDetail1"} className="justify-center items-center mx-10 px-20 py-3  text-black bg-amber-300 rounded-[122px] max-md:px-5">
                      Edit Gig Details
                    </Link>
                    <Link to={"/EditEsta1"}
                     className="justify-center items-start px-8 py-3 mx-10 mt-4 text-black bg-white border border-amber-300 border-solid rounded-[122px] max-md:pr-5 max-md:pl-6">
                      Edit Establishment Details
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
      <>
            {showModal3 ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-2xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex flex-col items-center px-7 pt-10 pb-5 text-base bg-white rounded-3xl max-w-[549px]">
                    <div className="font-medium text-center text-black leading-[145.9%] max-md:max-w-full">
                      Congratulations on finalizing your applicant.
                    </div>
                    <div className="flex gap-3 mt-5 text-neutral-400">
                     <input className="w-5 h-5" type="checkbox"  />
                      <div onClick={()=>setShowModal3(false)}
                      className="my-auto cursor-pointer">Close Job</div>
                    </div>
                    <Link to={"/CurrentGigsEstablishment"} className="justify-center items-center self-stretch px-16 py-3 mt-6 text-base text-center text-white capitalize bg-red-500 rounded-[111px] max-md:px-5 max-md:max-w-full">
                      Back To Dashboard
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>

    </WrapperDashEst>
  );
}

export default PendingAcceptance1;
 
