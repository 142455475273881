export default {
  cdnFront: "https://d28qcxi1juf339.cloudfront.net",
  apiUrl: "https://dev-baroom-api.flynautstaging.com",
  appleClientId: "com.barooom.app.client",
  appleRedirectUri: "https://dev-baroom.flynautstaging.com",
  googleClientId:
    "1020391111902-fc2ja6c48alh780b0l17trco2dt1984c.apps.googleusercontent.com",
  stripeKey:
    "sk_test_51OgX4YB8nRV0LVFHVkZIiPvS2zuPmpdEHHPApmkrXoCFumu1yTbmXfHg8N2WgCz2lFEI2EBhwGadWFSkDULHa2MU00DMqzvtK5",
  vapidKey:
    "BCjoKlhc791En9xNRcAP9GJC6AMhnGHmCiKcYZ-1TYrz7BTi6YNnsncHq9dPNJjQV3AvUjBHfRlkm4GJ69JZWnE",
};
