export const CuisineList = [
  { label: "Custom...", value: "Custom..." },
  { label: "American", value: "American" },
  { label: "Asian Fusion", value: "Asian Fusion" },
  { label: "Barbecue", value: "Barbecue" },
  { label: "Brazilian", value: "Brazilian" },
  { label: "British", value: "British" },
  { label: "Cajun/Creole", value: "Cajun/Creole" },
  { label: "Caribbean", value: "Caribbean" },
  { label: "Chinese", value: "Chinese" },
  { label: "Continental", value: "Continental" },
  { label: "Cuban", value: "Cuban" },
  { label: "French", value: "French" },
  { label: "German", value: "German" },
  { label: "Greek", value: "Greek" },
  { label: "Indian", value: "Indian" },
  { label: "Indonesian", value: "Indonesian" },
  { label: "Irish", value: "Irish" },
  { label: "Italian", value: "Italian" },
  { label: "Japanese", value: "Japanese" },
  { label: "Korean", value: "Korean" },
  { label: "Latin American", value: "Latin American" },
  { label: "Mediterranean", value: "Mediterranean" },
  { label: "Mexican", value: "Mexican" },
  { label: "Middle Eastern", value: "Middle Eastern" },
  { label: "Peruvian", value: "Peruvian" },
  { label: "Portuguese", value: "Portuguese" },
  { label: "Seafood", value: "Seafood" },
  { label: "Soul Food", value: "Soul Food" },
  { label: "Southern", value: "Southern" },
  { label: "Spanish", value: "Spanish" },
  { label: "Steakhouse", value: "Steakhouse" },
  { label: "Sushi", value: "Sushi" },
  { label: "Thai", value: "Thai" },
  { label: "Vietnamese", value: "Vietnamese" },
];

export const DressCodeList = [
  { label: "Casual", value: "Casual" },
  { label: "Smart Casual", value: "Smart Casual" },
  { label: "Business Casual", value: "Business Casual" },
  { label: "Cocktail Attire", value: "Cocktail Attire" },
  { label: "Formal/Black Tie", value: "Formal/Black Tie" },
  { label: "Semi-Formal", value: "Semi-Formal" },
  { label: "Business Formal", value: "Business Formal" },
  { label: "Theme-Based", value: "Theme-Based" },
  { label: "Beachwear", value: "Beachwear" },
  { label: "Sportswear", value: "Sportswear" },
  { label: "No Dress Code", value: "No Dress Code" },
  { label: "Upscale Casual", value: "Upscale Casual" },
  { label: "Vintage/Retro", value: "Vintage/Retro" },
  { label: "Festive", value: "Festive" },
];

export const PosSystemsList = [
  { label: "Square", value: "Square" },
  { label: "Toast", value: "Toast" },
  { label: "Clover", value: "Clover" },
  { label: "Lightspeed", value: "Lightspeed" },
  {
    label: "Upserve (formerly Breadcrumb)",
    value: "Upserve (formerly Breadcrumb)",
  },
  { label: "ShopKeep", value: "ShopKeep" },
  { label: "TouchBistro", value: "TouchBistro" },
  { label: "Revel Systems", value: "Revel Systems" },
  { label: "Aloha POS by NCR", value: "Aloha POS by NCR" },
  { label: "Harbortouch", value: "Harbortouch" },
  { label: "POSitouch", value: "POSitouch" },
  { label: "SpeedLine", value: "SpeedLine" },
  { label: "Lavu", value: "Lavu" },
  { label: "Bindo", value: "Bindo" },
  { label: "Cake POS by Sysco", value: "Cake POS by Sysco" },
  { label: "SpotOn", value: "SpotOn" },
  { label: "Rezku", value: "Rezku" },
  { label: "Ordyx", value: "Ordyx" },
  { label: "Tabit", value: "Tabit" },
  { label: "Dinerware", value: "Dinerware" },
  { label: "Others", value: "Others" },
];

export const TimeSlotsList = [
  { label: "Morning (8 AM - 12 PM)", value: "Morning (8 AM - 12 PM)" },
  { label: "Lunch (12 PM - 2 PM)", value: "Lunch (12 PM - 2 PM)" },
  { label: "Afternoon (2 PM - 5 PM)", value: "Afternoon (2 PM - 5 PM)" },
  {
    label: "Early Evening (5 PM - 8 PM)",
    value: "Early Evening (5 PM - 8 PM)",
  },
  { label: "Evening (8 PM - 11 PM)", value: "Evening (8 PM - 11 PM)" },
  { label: "Late Night (11 PM - 2 AM)", value: "Late Night (11 PM - 2 AM)" },
  { label: "Weekdays (Mon - Fri)", value: "Weekdays (Mon - Fri)" },
  { label: "Weekends (Sat - Sun)", value: "Weekends (Sat - Sun)" },
  { label: "Consistent All Day", value: "Consistent All Day" },
  { label: "Varies by Day", value: "Varies by Day" },
];

export const CustomerRangeList = [
  { label: "Up to 20 customers", value: "Up to 20 customers" },
  { label: "21-50 customers", value: "21-50 customers" },
  { label: "51-100 customers", value: "51-100 customers" },
  { label: "101-200 customers", value: "101-200 customers" },
  { label: "201-300 customers", value: "201-300 customers" },
  { label: "301-500 customers", value: "301-500 customers" },
  { label: "More than 500 customers", value: "More than 500 customers" },
  { label: "Custom...", value: "Custom..." },
];

export const YearRangeList = [
  { label: "1 year", value: "1 year" },
  { label: "2 years", value: "2 years" },
  { label: "3 years", value: "3 years" },
  { label: "4 years", value: "4 years" },
  { label: "5 years", value: "5 years" },
  { label: "6-10 years", value: "6-10 years" },
  { label: "11-20 years", value: "11-20 years" },
  { label: "21-50 years", value: "21-50 years" },
  { label: "51-100 years", value: "51-100 years" },
  { label: "101-200 years", value: "101-200 years" },
  { label: "More than 200 years", value: "More than 200 years" },
];

export const TimeZoneList = [
  { label: "US/Alaska", value: "US/Alaska" },
  { label: "US/Arizona", value: "US/Arizona" },
  { label: "US/Central", value: "US/Central" },
  { label: "US/Eastern", value: "US/Eastern" },
  { label: "US/Hawaii", value: "US/Hawaii" },
  { label: "US/Mountain", value: "US/Mountain" },
  { label: "US/Pacific", value: "US/Pacific" },
];
