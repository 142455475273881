import styles from "./cp.module.css";
import { useState } from "react";

import { Button } from "antd";
import { Bounce, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../redux/userSlice";
import { updateBartPayment } from "../../../axios/axios";
import {
  ensureEmail,
  ensureNumber,
  ensureString,
} from "../../../utils/inputFormatter";
import configuration from "../../../config/configuration";
import Stripe from "stripe";
const stripe = Stripe(configuration.stripeKey);

function Cp7({ activeTab, setActiveTab, data }) {
  const user = useSelector((state) => state.user.user);
  const [apiLoader, setapiLoader] = useState(false);
  const [localData, setLocalData] = useState({
    hourlyRates: data.hourlyRates,
    accountDetail: {
      bankName: data.accountDetail.bankName,
      holderName: data.accountDetail.holderName,
      bankCode: data.accountDetail.bankCode,
      accountNumber: data.accountDetail.accountNumber,
      routingNumber: data.accountDetail.routing_number,
      accountType: data.accountDetail.accountType,
    },
  });
  const dispatch = useDispatch();

  const submitHandler = async () => {
    setapiLoader(true);
    if (
      !localData.accountDetail.holderName ||
      !localData.accountDetail.accountType ||
      !localData.accountDetail.routingNumber ||
      !localData.accountDetail.accountNumber
    ) {
      toast.error("Bank Details Empty!");
      setapiLoader(false);
      return;
    }
    let tok;
    try {
      tok = await stripe.tokens.create({
        bank_account: {
          country: "US",
          currency: "usd",
          account_holder_name: localData.accountDetail.holderName,
          account_holder_type: localData.accountDetail.accountType,
          routing_number: localData.accountDetail.routingNumber,
          account_number: localData.accountDetail.accountNumber,
        },
      });
    } catch (error) {
      toast.error(error.message || error);
      setapiLoader(false);
    }
    if (tok) {
      updateBartPayment({
        data: { hourlyRates: localData.hourlyRates, bankToken: tok.id },
        token: user.token,
      })
        .then((result) => {
          setapiLoader(false);

          dispatch(
            updateUser({
              accSetCount: user.accSetCount + 1,
              profileSetup: true,
            })
          );
          setActiveTab((prev) => prev + 1);
        })
        .catch((err) => {
          toast.error(err.message, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
          setapiLoader(false);

          console.log("err", err);
        });
    }
  };

  const changeInputHandler = ({ name, value }) => {
    let x = { ...localData };
    x[name] = value;

    setLocalData(x);
  };

  return (
    <div className="flex relative justify-center items-center self-center px-16 py-12 mt-14 w-full rounded-3xl bg-white bg-opacity-80 max-w-[1599px] max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col items-center mt-7 mb-1.5 max-w-full w-[1213px]">
        <div
          className={`text-2xl leading-9 text-center text-black whitespace-nowrap} ${styles.header}`}>
          Payment setup
        </div>
        <div className="self-stretch mt-1.5 text-base text-center text-neutral-600 max-md:max-w-full">
          Establishments will see your expected hourly rate, but their posting
          may offer a higher/lower offer for a gig, you choose whether to accept
          or not at time of posting.
        </div>
        <div className="flex flex-col mt-16 max-w-full text-xs w-[639px] max-md:mt-10">
          <div className="flex gap-4 justify-between px-5 py-3.5 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full">
            <img
              loading="lazy"
              src="/images/completeProfile/tick-circle-solid.png"
              className="my-auto aspect-square w-[23px]"
            />
            <div className="flex flex-col flex-1 max-md:max-w-full">
              <div className="text-black text-start max-md:max-w-full">
                Setup Hourly Rates <span className="text-error">*</span>
              </div>

              <div className="mt-2 text-left text-zinc-500 max-md:max-w-full">
                <input
                  style={{ width: "60px" }}
                  className="border-none outline-none w-[100]"
                  type="text"
                  name={"hourlyRates"}
                  value={localData.hourlyRates}
                  onChange={(e) =>
                    changeInputHandler({
                      name: e.target.name,
                      value: ensureNumber({
                        value: e.target.value,
                        maxLength: 8,
                      }),
                    })
                  }
                />
                /hr
              </div>
            </div>
          </div>
          <div
            className={`mt-16 text-xl leading-9 text-left text-black capitalize max-md:mt-10 max-md:max-w-full} ${styles.header}`}>
            Set up the Payment Account
          </div>
          <div className="mt-2.5 text-neutral-600 text-left max-md:max-w-full">
            Your Bank.
          </div>
          <div className="px-5 pt-3.5 pb-4 mt-6 mb-0 text-black text-start bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:max-w-full">
            <div>
              Bank Name <span className="text-error">*</span>
            </div>
            <input
              className="w-full pt-2 border-none outline-none"
              type="text"
              name={"accountDetail"}
              value={localData.accountDetail.bankName}
              onChange={(e) =>
                changeInputHandler({
                  name: e.target.name,
                  value: {
                    ...localData.accountDetail,
                    bankName: ensureString({ value: e.target.value }),
                  },
                })
              }
            />
          </div>
          <div className="px-5 pt-3.5 pb-4 mt-3.5 mb-0 text-black text-start bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:max-w-full">
            <div>
              Account Holder’s Name <span className="text-error">*</span>
            </div>
            <input
              className="w-full pt-2 border-none outline-none"
              type="text"
              name={"accountDetail"}
              value={localData.accountDetail.holderName}
              onChange={(e) =>
                changeInputHandler({
                  name: e.target.name,
                  value: {
                    ...localData.accountDetail,
                    holderName: ensureString({ value: e.target.value }),
                  },
                })
              }
            />
          </div>
          <div className="px-5 pt-3.5 pb-4 mt-3.5 mb-0 text-black text-start bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:max-w-full">
            <div>
              Bank Code <span className="text-error">*</span>
            </div>
            <input
              className="w-full pt-2 border-none outline-none"
              type="text"
              name={"accountDetail"}
              value={localData.accountDetail.bankCode}
              onChange={(e) =>
                changeInputHandler({
                  name: e.target.name,
                  value: {
                    ...localData.accountDetail,
                    bankCode: ensureEmail({ value: e.target.value }),
                  },
                })
              }
            />
          </div>
          <div className="px-5 pt-3.5 pb-4 mt-3.5 mb-0 text-black text-start bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:max-w-full">
            <div>
              Account Number <span className="text-error">*</span>
            </div>
            <input
              className="w-full pt-2 border-none outline-none"
              type="text"
              name={"accountDetail"}
              value={localData.accountDetail.accountNumber}
              onChange={(e) =>
                changeInputHandler({
                  name: e.target.name,
                  value: {
                    ...localData.accountDetail,
                    accountNumber: ensureNumber({ value: e.target.value }),
                  },
                })
              }
            />
          </div>
          <div className="px-5 pt-3.5 pb-4 mt-3.5 mb-0 text-black text-start bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:max-w-full">
            <div>
              Routing Number <span className="text-error">*</span>
            </div>
            <input
              className="w-full pt-2 border-none outline-none"
              type="text"
              name={"accountDetail"}
              value={localData.accountDetail.routingNumber}
              onChange={(e) =>
                changeInputHandler({
                  name: e.target.name,
                  value: {
                    ...localData.accountDetail,
                    routingNumber: ensureNumber({
                      value: e.target.value,
                      maxLength: 9,
                    }),
                  },
                })
              }
            />
          </div>
          <div className="px-5 pt-3.5 pb-4 mt-3.5 mb-0 text-black text-start bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:max-w-full">
            <div>
              Account Type <span className="text-error">*</span>
            </div>
            <select
              style={{ background: "transparent" }}
              className="w-full pt-2 border-none outline-none"
              type="text"
              name={"accountDetail"}
              value={localData.accountDetail.accountType}
              onChange={(e) =>
                changeInputHandler({
                  name: e.target.name,
                  value: {
                    ...localData.accountDetail,
                    accountType: ensureEmail({ value: e.target.value }),
                  },
                })
              }>
              <option value={"individual"}>Individual</option>
              <option value={"company"}>Company</option>
            </select>
          </div>
          <Button
            loading={apiLoader}
            type="text"
            style={{ height: "auto" }}
            onClick={submitHandler}
            className="justify-center px-5 py-5 mt-3.5 text-sm text-center text-black whitespace-nowrap bg-amber-300 rounded-[92.207px] max-md:max-w-full">
            Finish
          </Button>
        </div>
      </div>
    </div>
  );
}
export default Cp7;
