export const ensureEmail = ({ value }) => {
  return value.replace(/ /g, "");
};

export const ensureCusEmail = ({ value }) => {
  let x = value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
  return x !== null ? x[0] : "";
};

export const ensureString = ({ value }) => {
  let x = value.match(/[a-zA-Z ]+/g, "");
  return x !== null ? x[0] : "";
};

export const ensureNumber = ({ value, maxLength = null }) => {
  let x = value.match(/[0-9]+/g);
  if (x !== null) {
    if (maxLength !== null) {
      x = x[0].substr(0, maxLength);
    } else {
      x = x[0];
    }
  } else {
    x = "";
  }

  return x;
};
