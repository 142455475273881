import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DropdownEst from "../../dashboard/Establishment/DropdownEst";
import NotificationEst from "../../dashboard/Establishment/NotificationEst";
import WrapperDashEst from "../../authWrapper/WrapperDashEst";
import style from "../profile.module.css";
import EstabDashHeader from "../../dashboard/Establishment/EstabDashHeader";
import { fetchEstablishmentDetails } from "../../../axios/axios";
import { useSelector } from "react-redux";
import AboutEstab from "../../eventDetails/Bartender/AcceptReject/AboutEstab";

function GigDetails() {
  const user = useSelector((state) => state.user?.user);
  const [showModaldrop, setShowModal] = useState(false);
  const mainModaldrop = <DropdownEst></DropdownEst>;
  const [showModalnotify, setShowModalnotify] = useState(false);
  const mainModalnotify = <NotificationEst></NotificationEst>;
  const [showModal1, setShowModal1] = useState(false);
  const [establishment, setEstablishment] = useState();
  const navigate = useNavigate();
  const { id } = useParams();

  const fetchEstabDetails = () => {
    fetchEstablishmentDetails({ token: user?.token, eid: id })
      .then((res) => setEstablishment(res.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!id) return;

    fetchEstabDetails();
  }, []);

  return (
    <WrapperDashEst>

      <EstabDashHeader
        leftContent={
          <>
            <div className="grow pt-3 uppercase my-auto">
              <span className=" text-neutral-700">Establishment</span>{" "}
              <span className="text-red-500">Details</span>
            </div>
          </>
        }
      />

      <div className=" relative flex flex-1  flex-col grow items-start pt-5 mt-5 pr-7 pb-20 pl-4 bg-white rounded-3xl shadow-2xl max-md:pr-5 max-md:mt-6 max-w-full">
        <div className="flex overflow-hidden relative flex-col gap-5 self-stretch px-5 py-5  max-md:flex-wrap max-md:pl-5 max-md:max-w-full">
          <img
            loading="lazy"
            src="/images/completeProfile/Background-Image.png"
            className=" absolute inset-0  rounded-xl size-full"
          />
          <div className="flex relative gap-5 justify-between items-start w-full text-xl font-semibold tracking-tight leading-6 text-yellow-400 max-md:flex-wrap max-md:max-w-full">
            <div className="flex gap-5 mt-2.5">
              <div className="grow my-auto">
                {establishment?.establishmentName}
              </div>
              <div className="flex bg-white rounded-2xl gap-1 py-1 px-2">
                <img
                  src="/images/completeProfile/Vector.png"
                  className="w-[18px] h-[18px] mt-[1px]"
                />
                <div className="text-black text-[15px]">
                  {establishment?.rating}
                </div>
              </div>
            </div>
            <img
              onClick={() => setShowModal1(true)}
              src="/images/editProfile.png"
              alt=""
            />
          </div>
          <div className="flex relative  justify-between items-start  max-md:flex-wrap max-md:max-w-full">
            <div className="flex flex-col self-start max-md:max-w-full">
              <div className="text-sm leading-5 text-white max-md:max-w-full">
                {`${establishment?.location?.streetName}, ${establishment?.location?.city}, ${establishment?.location?.state}, ${establishment?.location?.postalCode}`}
              </div>
              <div className="mt-4 text-xs max-md:flex-wrap">
                <div className="flex flex-col">
                  <div className="flex gap-1.5 whitespace-nowrap text-white text-opacity-80">
                    <img
                      loading="lazy"
                      src="/images/completeProfile/yellow-user.png"
                      className="shrink-0 aspect-square w-[17px]"
                    />
                    <div className="flex-auto">Capacity</div>
                  </div>
                  <div className="mt-1.5 ml-6 font-medium text-white">
                    {establishment?.accommodates}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <AboutEstab establishmentid={establishment} />
      </div>

      {showModal1 ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-2xl shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center p-2">
                  <div className="flex flex-col px-4 pb-10 max-w-lg text-base text-black w-[370px]  max-md:px-5">
                    <span
                      onClick={() => setShowModal1(false)}
                      className="text-2xl self-end cursor-pointer">
                      &#10005;
                    </span>
                    <span className="text-center text-lg">
                      <span className={style.header}>Edit Profile</span>
                    </span>
                    <div
                      onClick={() =>
                        navigate(`/establishment-details/${id}/basic-info`)
                      }>
                      <div className="flex gap-5 justify-between px-7 py-3 mt-10 bg-white rounded-xl border-2 border-amber-300 border-solid max-md:flex-wrap max-md:px-5 max-md:mt-10 max-md:max-w-full">
                        <div className="mt-1">Basic Info</div>
                        <div className="text-2xl ">&gt;</div>
                      </div>
                    </div>
                    <div
                      onClick={() =>
                        navigate(`/establishment-details/${id}/more-info`)
                      }>
                      <div className="flex gap-5 justify-between px-7 py-3 mt-5 whitespace-nowrap bg-white rounded-xl border-2 border-amber-300 border-solid max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                        <div className="mt-1">More Info</div>
                        <div className="text-2xl ">&gt;</div>
                      </div>
                    </div>
                    <div
                      onClick={() =>
                        navigate(`/establishment-details/${id}/location`)
                      }>
                      <div className="flex gap-5 justify-between px-7 py-3 mt-5 whitespace-nowrap bg-white rounded-xl border-2 border-amber-300 border-solid max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                        <div className="mt-1">Location</div>
                        <div className="text-2xl ">&gt;</div>
                      </div>
                    </div>
                    <div
                      onClick={() =>
                        navigate(`/establishment-details/${id}/images`)
                      }>
                      <div className="flex gap-5 justify-between px-7 py-3 mt-5 whitespace-nowrap bg-white rounded-xl border-2 border-amber-300 border-solid max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                        <div className="mt-1">Photos</div>
                        <div className="text-2xl ">&gt;</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </WrapperDashEst>
  );
}

export default GigDetails;
