import { Link } from "react-router-dom";
import styles from "../../../eventDeatail.module.css";
import {
  CustomerRangeList,
  TimeSlotsList,
  YearRangeList,
} from "../../../../../utils/List";
import { useState } from "react";
import { toast } from "react-toastify";
function EditEsta2({
  activejtab,
  setActiveJTab,
  handleChange,
  setNewEstablishmentData,
  newestablishmentdata,
}) {
  const [errormsg, setErrorMsg] = useState({});

  const handleRegistration2 = () => {
    let msg;
    if (!newestablishmentdata.customerFlow && TimeSlotsList.length > 0) {
      setNewEstablishmentData((prevData) => ({
        ...prevData,
        customerFlow: TimeSlotsList[0].value,
      }));
    }
    if (!newestablishmentdata.yearsOfExperience && YearRangeList.length > 0) {
      setNewEstablishmentData((prevData) => ({
        ...prevData,
        yearsOfExperience: YearRangeList[0].value,
      }));
    }
    if (!newestablishmentdata.accommodates && CustomerRangeList.length > 0) {
      setNewEstablishmentData((prevData) => ({
        ...prevData,
        accommodates: CustomerRangeList[0].value,
      }));
    }
    setActiveJTab(activejtab + 1);
  };
  return (
    <div className="flex relative justify-center items-center self-center px-16 pb-12 w-full  max-w-[1599px] max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col items-center mt-10 mx-[80px] mb-28 max-w-full w-[1299px] max-md:my-10">
        <div
          className={`text-2xl leading-9 text-center text-black max-md:max-w-full} ${styles.header}`}>
          Tell Us More About Your Establishment
        </div>

        <div className="flex gap-5 justify-between self-stretch mt-10 text-xs max-md:flex-wrap max-md:max-w-full">
          <div
            className={`flex gap-4 justify-between w-[50%] px-5 py-3.5 bg-white ${
              errormsg?.customerFlow ? "border-error" : "border-gray-200"
            } border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full`}>
            <img
              loading="lazy"
              src="/images/completeProfile/multiUser.png"
              className="my-auto aspect-[1.3] w-[22px]"
            />
            <div className="flex flex-col flex-1  max-md:max-w-full">
              <div
                className={`${
                  errormsg?.customerFlow ? "text-error" : "text-black"
                } text-left max-md:max-w-full`}>
                Peak Hours
              </div>
              <div
                className={`mt-2 text-left ${
                  errormsg?.customerFlow ? "text-error" : "text-zinc-500 "
                } max-md:max-w-full`}>
                <select
                  className="w-full border-none outline-none"
                  name="customerFlow"
                  id="customerFlow"
                  value={newestablishmentdata.customerFlow}
                  onChange={(e) => handleChange(e)}>
                  {TimeSlotsList?.map((elem, ind) => (
                    <option key={`${elem.value}_${ind}`} value={elem.value}>
                      {elem.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="flex justify-between gap-4 w-[50%] px-5 py-3.5 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full">
            <img
              loading="lazy"
              src="/images/completeProfile/solarStar.png"
              className="my-auto aspect-square w-[22px]"
            />
            <div className="flex flex-col flex-1 max-md:max-w-full">
              <div className="text-black text-left max-md:max-w-full">
                Number of years in business
              </div>
              <div className="mt-2 text-zinc-500  text-left max-md:max-w-full">
                <select
                  className="w-full border-none outline-none"
                  name="yearsOfExperience"
                  id="yearsOfExperience"
                  value={newestablishmentdata.yearsOfExperience}
                  onChange={(e) => handleChange(e)}>
                  {YearRangeList?.map((elem, ind) => (
                    <option key={`${elem.value}_${ind}`} value={elem.value}>
                      {elem.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-5 justify-between self-stretch mt-10 text-xs max-md:flex-wrap max-md:max-w-full">
          <div
            className={`flex gap-4 justify-between w-[50%] px-5 py-3.5 bg-white ${
              errormsg?.customerFlow ? "border-error" : "border-gray-200"
            } border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full`}>
            <img
              loading="lazy"
              src="/images/completeProfile/multiUser.png"
              className="my-auto aspect-[1.3] w-[22px]"
            />
            <div className="flex flex-col flex-1  max-md:max-w-full">
              <div
                className={`${
                  errormsg?.customerFlow ? "text-error" : "text-black"
                } text-left max-md:max-w-full`}>
                Accommodates How Many Peoples
              </div>
              <div
                className={`mt-2 text-left ${
                  errormsg?.customerFlow ? "text-error" : "text-zinc-500 "
                } max-md:max-w-full`}>
                <select
                  className="w-full border-none outline-none"
                  name="customerFlow"
                  id="customerFlow"
                  value={newestablishmentdata.customerFlow}
                  onChange={(e) => handleChange(e)}>
                  {CustomerRangeList?.map((elem, ind) => (
                    <option key={`${elem.value}_${ind}`} value={elem.value}>
                      {elem.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="flex justify-between gap-4 w-[50%] px-5 py-3.5 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full">
            <img
              loading="lazy"
              src="/images/completeProfile/portFolio.png"
              className="my-auto aspect-square w-[22px]"
            />
            <div className="flex flex-col flex-1 max-md:max-w-full">
              <div className="text-black text-left max-md:max-w-full">
                Website Link
              </div>
              <div className="mt-2 text-zinc-500  text-left max-md:max-w-full">
                <input
                  className="border-none outline-none w-full"
                  type="text"
                  id="websiteLink"
                  name="websiteLink"
                  placeholder="Website Link"
                  value={newestablishmentdata.websiteLink}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
          </div>
        </div>

        <button
          onClick={() => handleRegistration2()}
          className="justify-center px-5 py-5 mt-10 w-[60%] text-sm text-center text-white bg-red-500 rounded-[92.207px] max-md:max-w-full">
          Save & Next
        </button>
      </div>
    </div>
  );
}
export default EditEsta2;
