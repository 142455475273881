import { useState } from "react";
import WrapperDashEst from "../../../authWrapper/WrapperDashEst";
import style from "../../dashboard.module.css";
import DropdownEst from "../DropdownEst";
import NotificationEst from "../NotificationEst";
import { Link, useNavigate } from "react-router-dom";
import configuration from "../../../../config/configuration";
import {
  CalculateReaminTime,
  convertremainingtime,
  RemainingTime,
  upconvertremainingtime,
} from "../../../../utils/TimeFormatting";
import JobCards from "../../../jobCards/JobCards";

function PendingAcceptance({ data, dayjs }) {
  const [showModal, setShowModal] = useState(false);
  const mainModal = <DropdownEst></DropdownEst>;
  const [showModalnotify, setShowModalnotify] = useState(false);
  const mainModalnotify = <NotificationEst></NotificationEst>;

  const FormatTime = (msg) => {
    const today = dayjs().startOf("day");
    const yesterday = today.subtract(1, "day");

    const msgDate = dayjs(msg.createdAt).startOf("day");
    let dateLabel;

    if (msgDate.isSame(today)) {
      dateLabel = "Today";
    } else if (msgDate.isSame(yesterday)) {
      dateLabel = "Yesterday";
    } else {
      dateLabel = msgDate.format("D MMMM YYYY");
    }
    return dateLabel;
  };

  return (
    <div>
      {showModal && mainModal}
      {showModalnotify && mainModalnotify}
      <div className="flex pt-6 px-2 gap-6 flex-wrap w-full max-md:max-w-full ">
        {data.map((elem, index) => {
          return <JobCards elem={elem} index={index} Jtype={2}  key={`job_${elem?._id}_${index}_pending`}/>;
        })}
        {data?.length < 1 ? (
          <div className="w-full flex justify-center items-center">
            <img src="/images/EmptyGig.svg" sizes="20" alt="" />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
export default PendingAcceptance;
