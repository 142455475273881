import React, { useState, useRef, useEffect } from "react";
import { useLoadScript } from "@react-google-maps/api";

const libraries = ["places"];

const AutoComplete = ({ handleAutoComplete, setloading }) => {
  const [address, setAddress] = useState("");
  const autocompleteRef = useRef(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDZXHxJ02pyWfAvaEFw7_aGA7KkkNTf_eQ",
    libraries,
  });

  const handleLocationSet = (e) => {
    setAddress(e.target.value);
  };

  const extractAddressComponents = async (place) => {
    const addressComponents = place.address_components;
    const components = {
      streetName: "",
      city: "",
      state: "",
      postalCode: "",
    };

    addressComponents.forEach((component) => {
      const types = component.types;

      if (types.includes("route")) {
        components.streetName = component.long_name;
      } else if (types.includes("locality")) {
        components.city = component.long_name;
      } else if (types.includes("administrative_area_level_1")) {
        components.state = component.long_name || component.short_name;
      } else if (types.includes("postal_code")) {
        components.postalCode = component.long_name;
      }
    });

    if (place.geometry) {
      components.latitude = place.geometry.location.lat();
      components.longitude = place.geometry.location.lng();
    }
    try {
      const timeZoneData = await getTimeZoneData(
        components.latitude,
        components.longitude
      );

      components.timeZone = timeZoneData.timeZoneId;
    } catch (error) {
      console.log(error);
    }
    return components;
  };
  const getTimeZoneData = async (latitude, longitude) => {
    const apiKey = "AIzaSyDZXHxJ02pyWfAvaEFw7_aGA7KkkNTf_eQ";
    const timestamp = Math.floor(Date.now() / 1000);
    const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${timestamp}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === "OK") {
        return {
          timeZoneId: data.timeZoneId,
          // timeZoneName: data.timeZoneName,
        };
      } else {
        console.error("Timezone API Error:", data.status);
        return null;
      }
    } catch (error) {
      console.error("Failed to fetch timezone data:", error);
      return null;
    }
  };
  useEffect(() => {
    if (isLoaded && !loadError) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        autocompleteRef.current,
        {
          types: ["address"],
        }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        setAddress(place.formatted_address);

        extractAddressComponents(place)
          .then((addressComponents) => {
            handleAutoComplete(addressComponents);
            setloading(false);
          })
          .catch((err) => console.log(err));
      });
    }
  }, [isLoaded, loadError]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;

  return (
    <div className="w-full h-full">
      <input
        ref={autocompleteRef}
        type="text"
        className="w-full outline-none"
        placeholder="Search Your Establishment Address"
        value={address}
        onChange={(e) => handleLocationSet(e)}
      />
    </div>
  );
};

export default AutoComplete;
