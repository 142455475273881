import React, { useEffect, useState } from "react";
import Wrappers from "../Wrappers";
import CpE1 from "./CpE1";
import CpE2 from "./CpE2";

export default function EstablishmentSetup() {
  const [activeTab, setActiveTab] = useState(1);
  const [estaCount, setEstaCount] = useState(1);

  const [estaDetails, setEstadetails] = useState([]);

  useEffect(() => {
    let prep = [];
    Array.from({ length: estaCount }).forEach(() => {
      prep.push([
        {
          eid: "",
          EprofileImg: "",
          establishmentName: "",
          discription: "",
          serviceType: "",
          dressCode: "",
          posSystem: "",
        },
        {
          accommodates: "",
          customerFlow: "",
          yearsOfExperience: "",
          websiteLink: "",
        },
        {
          timeZone: "",
          location: {
            streetName: "",
            city: "",
            state: "",
            postalCode: "",
          },
          latitude: "",
          longitude: "",
        },
        {
          pic: [],
          Images: [],
        },
      ]);
    });

    setEstadetails([...prep]);
  }, [estaCount]);

  useEffect(() => {}, [estaDetails]);

  return (
    <>
      {activeTab <= 2 && (
        <Wrappers
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          showHeader={false}
          totalSteps={6}>
          {activeTab === 1 && (
            <CpE1
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              estaCount={estaCount}
              setEstaCount={setEstaCount}
            />
          )}
          {activeTab === 2 && (
            <CpE2 estaDetails={estaDetails} setEstadetails={setEstadetails} />
          )}
        </Wrappers>
      )}
    </>
  );
}
