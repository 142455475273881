import React from "react";
import style from "./PhotoGrid.module.css";

const PhotoGrid = () => {
  return (
    <>
      <div className={style.main}>
        <div className={style.box1}>
          <p>
            Innovative hiring. Say goodbye to lengthy processes and hello to
            instant solutions!
          </p>
        </div>
        <div className={style.box2}></div>
        <div className={style.box3}></div>
        <div className={style.box4}></div>
        <div className={style.box5}>
          <div>
            <p>Downloads</p>
            <h1>10k+</h1>
          </div>
        </div>
        <div className={style.box6}>
          <div className={style.innerBox6}>
            <ul className={style.myul}>
              <li>
                Establishments, elevate your service with skilled bartenders
              </li>
              <li>
                Bartenders, showcase your talent and set your own hourly wage
              </li>
              <li>Download Barooom App today!</li>
            </ul>
          </div>
        </div>
        <div className={style.box7}>
          <div className={style.innerBox7}>
            <p>Reviews</p>
            <h1>16k+</h1>
            <div className={style.stars}>
              <img src={"/PhotoGrid/yellowStar.svg"} alt="" />
              <img src={"/PhotoGrid/yellowStar.svg"} alt="" />
              <img src={"/PhotoGrid/yellowStar.svg"} alt="" />
              <img src={"/PhotoGrid/yellowStar.svg"} alt="" />
              <img src={"/PhotoGrid/whiteStar.svg"} alt="" />
            </div>
          </div>
        </div>
        {/* <div className={style.box8}></div>
        <div className={style.box9}></div>
        <div className={style.box10}></div>
        <div className={style.box11}></div>
        <div className={style.box12}></div> */}
      </div>
    </>
  );
};

export default PhotoGrid;
