import React, { useEffect, useState } from "react";
import style from "../../dashboard/dashboard.module.css";
import { useSelector } from "react-redux";
import configuration from "../../../config/configuration";
import { JobActionBartender } from "../../../axios/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function AppliedBartender({ bartenders, jobId }) {
  const [barList, setBarlist] = useState(bartenders);
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const JobBartenderAction = (action, bId) => {
    JobActionBartender({ token: user?.token, jobId, action, bId })
      .then((res) => {
        toast.success(res.message);
        setBarlist(res?.data?.applied);
      })
      .catch((err) => toast.error(err?.message));
  };

  return (
    <div className=" max-md:flex-wrap flex-2">
      <div className="bg-white rounded-3xl shadow-2xl min-h-full ">
        <div className=" p-4  ">
          <div className="flex justify-between items-center p-1">
            <p className="font-bold text-xl ">Pending Acceptance</p>
            <div className="rounded-[25px] border-2 whitespace-nowrap font-bold border-yellow-300 text-xs items-center p-2">
              {barList?.length} Applicants
            </div>
          </div>
          <div className="mt-10">
            {barList?.length > 0 &&
              barList?.map((elem, ind) => (
                <div
                  style={{
                    boxShadow: "0px 0px 21.461px 0px rgba(0, 0, 0, 0.10)",
                  }}
                  key={`Bar_${elem._id}_${ind}`}
                  className="flex flex-col px-2 pt-1.5 pb-3 mt-2  rounded-xl border-l-4 border-red-500 border-solid max-md:mr-2.5 max-md:max-w-full">
                  <div className="flex gap-2.5 justify-center items-center">
                    <div>
                      <img
                        loading="lazy"
                        src={
                          `${configuration.cdnFront}/${elem?.bartender_id?.pic}` ||
                          ""
                        }
                        className="shrink-0 self-start size-14"
                      />
                    </div>
                    <div className="flex flex-col grow shrink-0 basis-0 w-fit">
                      <div className="flex gap-5 justify-between">
                        <div className="flex justify-between w-full relative">
                          <div>
                            <p className="text-xl font-medium text-black">
                              {elem?.bartender_id?.name}
                            </p>
                            <div className="text-[12px] text-neutral-400 flex gap-1">
                              <div className="border-r-2 border-stone-300 px-1">
                                {elem?.bartender_id?.designation?.map((elu) => (
                                  <p>{elu}</p>
                                ))}
                              </div>
                              Experience:{" "}
                              {elem?.bartender_id?.yearsOfExperience} Yrs
                            </div>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              right: 0,
                              top: 0,
                              transform: "translate(0%,-10%)",
                            }}
                            onClick={() =>
                              navigate(`/dashboard/chat/${elem?.bartender_id}`)
                            }
                            className="cursor-pointer">
                            <img
                              loading="lazy"
                              src="/images/completeProfile/yellowChat.png"
                              className="shrink-0 self-start  aspect-square"
                            />
                          </div>
                        </div>
                      </div>
                      <p
                        className="text-xs text-neutral-400 px-1 underline cursor-pointer"
                        onClick={() =>
                          navigate(
                            `/bartender-details/${elem?.bartender_id?._id}`
                          )
                        }>
                        View Profile
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex justify-center p-1 gap-5">
                    <button
                      className="w-[50%] p-2 text-[18px] rounded-[25px] border-[1px] border-[#FF3333] text-[#FF3333]"
                      onClick={() =>
                        JobBartenderAction(false, elem?.bartender_id?._id)
                      }>
                      Reject
                    </button>
                    <button
                      className="w-[50%] p-1 text-[18px] rounded-[25px] bg-[#00B383] text-white"
                      onClick={() =>
                        JobBartenderAction(true, elem?.bartender_id?._id)
                      }>
                      Accept
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppliedBartender;
