import React from "react";
import { useSelector } from "react-redux";
import GigsNewApply from "../components/eventDetails/Bartender/AcceptReject/GigsNewApply";
import GigDetails from "../components/eventDetails/Establishment/GigDetails";

function GigDetailsWrap() {
  const user = useSelector((state) => state.user.user);

  return (
    <>
      {user && (
        <>
          {user.role === 1 && <GigsNewApply />}
          {user.role === 2 && <GigDetails />}
        </>
      )}
    </>
  );
}

export default GigDetailsWrap;
