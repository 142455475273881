import styles from "./CpE.module.css";
import { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button } from "antd";

function CpE6({ loading, changeInputHandler, data, createEstablishment }) {
  const [prev, setPrev] = useState([]);

  const submitHandler = () => {
    let msg = "";
    if (data.Images.length < 1) {
      msg = "At least one image is required";
    }

    if (msg) {
      console.log(msg);
    } else {
      createEstablishment();
    }
  };

  useEffect(() => {
    if (data.Images.length < 1) {
      setPrev([]);
      return;
    }

    let p = [];
    try {
      data.Images.forEach((element, ind) => {
        p.push(
          new Promise((resolve, reject) => {
            var fr = new FileReader();
            fr.readAsDataURL(element);
            fr.onload = function (e) {
              resolve({ ind: ind, data: this.result });
            };
          })
        );
      });
    } catch (error) {
      console.log("Error Here");
    }

    Promise.all(p)
      .then((result) => {
        setPrev(result);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [data.Images]);

  return (
    <div className="flex relative justify-center items-center self-center px-16 py-12 w-full  max-w-[1599px] max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col items-center mx-20 max-w-full w-[1025px]">
        <div
          className={`text-2xl leading-9 text-center text-black} ${styles.header}`}>
          Establishment Photos
        </div>

        <div
          style={{ position: "relative" }}
          className="flex justify-center items-center self-stretch px-16 py-5 mt-16 text-center rounded-xl border border-dashed bg-red-500 bg-opacity-10 border-[color:var(--ff-3333,#F33)] leading-[153.3%] max-md:px-5 max-md:mt-10 max-md:max-w-full">
          <input
            type="file"
            id="images"
            name="Images"
            accept="image/png, image/jpeg, image/jpg, application/pdf"
            multiple
            onChange={(e) =>
              changeInputHandler({
                name: e.target.name,
                value: [...e.target.files],
              })
            }
            style={{ position: "absolute", opacity: 0 }}
          />
          <label
            htmlFor="images"
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              opacity: 0,
              cursor: "pointer",
            }}
          />

          <div className="flex flex-col max-w-full w-[258px]">
            <img
              loading="lazy"
              src="/images/completeProfile/redLoction.png"
              className="self-center aspect-square w-[31px]"
            />
            <div className="mt-2.5 text-xs text-black">
              <span className="text-red-500 underline"> Browse</span>
              <span> your file</span>
            </div>
            <div className="mt-2.5 text-xs text-neutral-400">
              Supports JPG, JPEG, PNG, PDF
            </div>
          </div>
        </div>

        <div className="flex gap-2.5 self-start mt-4 max-w-full w-[342px]">
          {prev.length > 0 &&
            prev.map((elem, ind) => (
              <div
                key={`ind${ind}`}
                style={{
                  width: "150px",
                  height: "auto",
                  position: "relative",
                }}>
                <img
                  width={{ width: "100%", height: "auto" }}
                  loading="lazy"
                  src={elem.data}
                  className="flex-1 shrink-0  aspect-[0.98] rounded-xl"
                />
                <CancelIcon
                  sx={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    transform: "translate(30%,-30%)",
                    cursor: "pointer",
                    color: "#FF3333",
                    background: "white",
                    fontSize: "24px",
                    borderRadius: "50%",
                  }}
                  onClick={() => {
                    let x = [...prev];
                    let index = x[ind].ind;

                    let y = [...data.Images];
                    y.splice(index, 1);

                    changeInputHandler({
                      name: "Images",
                      value: [...y],
                    });
                  }}
                />
              </div>
            ))}
        </div>
        <Button
          loading={loading}
          type="text"
          style={{ height: "auto" }}
          onClick={submitHandler}
          className="justify-center px-5 py-5 mt-40 w-[73%] text-sm text-center text-white bg-red-500 rounded-[92.207px] max-md:mt-10 max-md:max-w-full">
          Save & Next
        </Button>
      </div>
    </div>
  );
}
export default CpE6;
