import React, { useEffect, useState } from "react";
import ReviewFilter from "./ReviewFilter";
import DashHeader from "../../dashboard/DashHeader";
import {
  fetchReviewOnBartender,
  RequestReviewNotify,
} from "../../../axios/axios";
import { useSelector } from "react-redux";
import config from "../../../config/configuration";
import { CircularProgress, Rating } from "@mui/material";
import ReviewCards from "./ProfileReviewCards";
import ReviewCardEstablishment from "../Establishment/ReviewCardEstablishment";

export default function ReviewsBart() {
  const [showModal1, setShowModal1] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [reviews, setReviews] = useState({
    prev: false,
    next: false,
    search: "",
    pending: "",
    paid: "",
    filter: "",
    page: 1,
    total_page: 1,
    history: [],
  });
  const user = useSelector((state) => state.user.user);

  const fetchReivewsByEstab = () => {
    fetchReviewOnBartender({ token: user?.token, filter })
      .then((result) => {
        let res = result.data.data;
        let x = { ...reviews };

        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          filter: res.filter,
          history: [...res.history],
        };

        setReviews(x);
        setLoading(false);
      })
      .catch((err) => console.log("Error at Fetching Data, ", err));
  };

  const handlefilter = () => {
    fetchReivewsByEstab();
  };

  useEffect(() => {
    handlefilter();
  }, [filter]);

  useEffect(() => {
    fetchReivewsByEstab();
  }, []);
  return (
    <>
      <DashHeader
        leftContent={
          <>
            <div className="pt-3 my-auto uppercase grow">
              <span className=" text-neutral-700">Reviews</span>{" "}
              {/* <span className="text-yellow-400 ">{user?.name}</span> */}
            </div>
          </>
        }
      />

      <div className="flex flex-col px-5 py-12 mt-8 text-sm leading-6 bg-white shadow-2xl rounded-3xl text-neutral-700 max-md:px-5 max-md:max-w-full">
        {/* header */}
        <div className="flex justify-between min-h-5 max-w-full text-[19px] font-medium text-black w-full relative max-md:flex-wrap max-md:mr-2.5">
          <div className="my-auto">Your Reviews By Establishment</div>
          <img
            onClick={() => setShowModal((setShowModal) => !setShowModal)}
            loading="lazy"
            src="/images/completeProfile/filter.png"
            className="shrink-0 aspect-[1.06] w-[45px]"
          />
          {showModal && (
            <>
              <div
                className="absolute right-4 "
                style={{ marginTop: "2.5rem" }}>
                <div className="">
                  {/*content*/}
                  <div className="">
                    {/*header*/}

                    <div className="z-50 flex text-black">
                      <ReviewFilter filter={filter} setFilter={setFilter} />

                      <button
                        className="absolute z-50 right-5 top-2"
                        onClick={() => setShowModal(false)}>
                        &#10005;
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="grid max-w-full grid-cols-2 gap-5 mx-auto mt-8 xl:grid-cols-3">
          {!loading ? (
            reviews.history.map((item, index) => {
              return (
                <ReviewCardEstablishment
                  key={`rando_${item?._id}`}
                  item={item}
                  role={user?.role}
                  token={user?.token}
                />
              );
            })
          ) : (
            <div className="flex items-center justify-center h-20">
              <CircularProgress />{" "}
              <p className="pl-2 text-xl font-poppins">Loading...</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
