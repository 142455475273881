import * as React from "react";
import Footer from "../components/landing/Footer";
import ContentWrapper from "../components/ContentWrapper/ContentWrapper";



 function TermCondion() {
    
  return (
    <>

    <ContentWrapper
    type={"TermsCondition"}
    title={["Terms","& CONDITIONS"]}
    content={"<h1>Just and HTML</h1>"}
    />

    <Footer />
    
    </>
 
  );
}
export default TermCondion;


 
 
