import { useEffect, useRef, useState } from "react";
import styles from "./BarEdit.module.css";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { CircularProgress, Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Bounce, toast } from "react-toastify";
import { Button } from "antd";
import { fetchUserData, updateBartBasic } from "../../axios/axios";
import { updateUser } from "../../redux/userSlice";
import { ensureNumber, ensureString } from "../../utils/inputFormatter";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function BarEdit() {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [errormsg, setErrorMsg] = useState({});
  const [pickershow, setPickerShow] = useState(false);
  const [localData, setLocalData] = useState({
    name: user?.name,
    bio: "",
    yearsOfExperience: "",
    birthDate: "",
    phoneNo: user?.phoneNo,
    location: {
      state: "",
      city: "",
      postalCode: "",
      streetName: "",
    },
    latitude: "",
    longitude: "",
  });
  const checkInput = ["name", "bio", "yearsOfExperience", "enddate"];

  const submitHandler = () => {
    setApiLoader(true)
    updateBartBasic({
      ...localData,
      location: JSON.stringify(localData.location),
      token: user.token,
    })
      .then((result) => {
        toast.success("Profile updated successfully!");
        setApiLoader(false);
        // Dispatch updated user data if necessary
        // dispatch(updateUser({ ...user, name: localData.name }));

        navigate("/profile");
      })
      .catch((err) => {
        toast.error(err.message, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setApiLoader(false);
        console.log("err", err);
      });
  };

  function getLocation() {
    if (navigator.geolocation) {
      setLoader(true);

      navigator.geolocation.getCurrentPosition((position) => {
        let lat = position.coords.latitude;
        console.log("Called");
        let lng = position.coords.longitude;

        setLocalData((prevData) => ({
          ...prevData,
          latitude: lat,
          longitude: lng,
        }));

        setLoader(false);
      })
    }
  }
  const dateRef = useRef(null);

  const getUserData = () => {
    fetchUserData({ token: user?.token })
      .then((result) => {
        const userData = result.data.data;
        setLocalData((prevData) => ({
          ...prevData,
          bio: userData.bio,
          yearsOfExperience: userData.yearsOfExperience,
          birthDate: dayjs(userData.birthDate).format("YYYY-MM-DD"),
          location: {
            state: userData.location.state,
            city: userData.location.city,
            postalCode: userData.location.postalCode,
            streetName: userData.location.streetName,
          },
        }));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getLocation();
    getUserData();
  }, []);

  return (
    <div className="flex flex-col max-w-full w-[1299px]">
      <style>
        {`
          .hide-calendar::-webkit-calendar-picker-indicator {
            display: none;
          }
          .hide-calendar::-moz-calendar-picker-indicator {
            display: none;
          }
          .hide-calendar::-ms-clear {
            display: none;
          }
            
        `}
      </style>
      <div
        className={`self-center text-xl font-bold leading-9 text-center text-black max-md:max-w-full} `}>
        Basic Info
      </div>
      <div className="self-center text-base whitespace-nowrap text-neutral-600">
        What will be your title/designation?
      </div>
      <div className="flex self-stretch justify-center gap-4 mt-8 max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
        <div className="flex  items-center self-stretch px-3 py-2 w-[450px] h-[65px]  mt-3.5 text-sm bg-white border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full ">
          <img
            loading="lazy"
            src="/images/completeProfile/pen.png"
            className="my-auto aspect-[1.03] w-[25px]"
          />
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div className="pl-4 text-left text-black max-md:max-w-full">
              Full Name <span className="text-error">*</span>
            </div>
            <div className="pl-4 mt-1 text-left lowercase text-zinc-500 max-md:max-w-full">
              <input
                name="name"
                value={localData.name}
                onChange={(e) =>
                  setLocalData({ ...localData, name: e.target.value })
                }
                className="border-none outline-none"
                type="text"
                placeholder="Your Full Name"></input>
            </div>
          </div>
        </div>

        <div className="flex  items-center self-stretch px-3 py-2 w-[450px] h-[65px]  mt-3.5 text-sm bg-white border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full ">
          <img
            loading="lazy"
            src="/images/completeProfile/drink_glass.png"
            className="my-auto aspect-[1.03] w-[25px]"
          />
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div className="pl-4 text-left text-black max-md:max-w-full">
              Create Your Bio <span className="text-error">*</span>
            </div>
            <div className="pl-4 mt-1 text-left lowercase text-zinc-500 max-md:max-w-full">
              <input
                name="bio"
                value={localData.bio}
                onChange={(e) =>
                  setLocalData({ ...localData, bio: e.target.value })
                }
                className="w-full border-none outline-none"
                type="text"
                placeholder="Create Your Bio"></input>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center gap-4  self-stretch mt-1.5 max-md:flex-wrap max-md:mt-5 max-md:max-w-full">
        <div className="flex  items-center self-stretch px-3 py-2 w-[450px] h-[65px]  mt-3.5 text-sm bg-white border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full ">
          <img
            loading="lazy"
            src="/images/completeProfile/location.svg"
            className="my-auto aspect-[1.03] w-[25px]"
          />
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div className="pl-4 text-left text-black max-md:max-w-full">
              Based From Which State? <span className="text-error">*</span>
            </div>
            <div className="pl-4 mt-1 text-left lowercase text-zinc-500 max-md:max-w-full">
              <input
                name="location"
                value={localData.location.state}
                onChange={(e) =>
                  setLocalData({
                    ...localData,
                    location: {
                      ...localData.location,
                      state: e.target.value,
                    },
                  })
                }
                className="border-none outline-none"
                type="text"
                placeholder="Enter the State"></input>
            </div>
          </div>
        </div>
        <div className="flex  items-center self-stretch px-3 py-2 w-[450px] h-[65px]  mt-3.5 text-sm bg-white border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full ">
          <img
            loading="lazy"
            src="/images/completeProfile/location.svg"
            className="my-auto aspect-[1.03] w-[25px]"
          />
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div className="pl-4 text-left text-black max-md:max-w-full">
              Based From Which City? <span className="text-error">*</span>
            </div>
            <div className="pl-4 mt-1 text-left lowercase text-zinc-500 max-md:max-w-full">
              <input
                name="location"
                value={localData.location.city}
                onChange={(e) =>
                  setLocalData({
                    ...localData,
                    location: {
                      ...localData.location,
                      city: e.target.value,
                    },
                  })
                }
                className="border-none outline-none"
                type="text"
                placeholder="Enter the city"></input>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center gap-4  self-stretch mt-1.5 max-md:flex-wrap max-md:mt-5 max-md:max-w-full">
        <div className="flex  items-center self-stretch px-3 py-2 w-[450px] h-[65px]  mt-3.5 text-sm bg-white border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full ">
          <img
            loading="lazy"
            src="/images/completeProfile/location.svg"
            className="my-auto aspect-[1.03] w-[25px]"
          />
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div className="pl-4 text-left text-black max-md:max-w-full">
              Zip Code <span className="text-error">*</span>
            </div>
            <div className="pl-4 mt-1 text-left lowercase text-zinc-500 max-md:max-w-full">
              <input
                name="location"
                value={localData.location.postalCode}
                ref={dateRef}
                onChange={(e) =>
                  setLocalData({
                    ...localData,
                    location: {
                      ...localData.location,
                      postalCode: e.target.value,
                    },
                  })
                }
                className="border-none outline-none"
                type="text"
                placeholder="Enter Your Zip Code"></input>
            </div>
          </div>
        </div>
        <div className="flex  items-center self-stretch px-3 py-2 w-[450px] h-[65px]  mt-3.5 text-sm bg-white border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full ">
          <img
            loading="lazy"
            src="/images/completeProfile/location.svg"
            className="my-auto aspect-[1.03] w-[25px]"
          />
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div className="pl-4 text-left text-black max-md:max-w-full">
              Street Name <span className="text-error">*</span>
            </div>
            <div className="pl-4 mt-1 text-left lowercase text-zinc-500 max-md:max-w-full">
              <input
                name="location"
                value={localData.location.streetName}
                onChange={(e) =>
                  setLocalData({
                    ...localData,
                    location: {
                      ...localData.location,
                      streetName: e.target.value,
                    },
                  })
                }
                className="border-none outline-none"
                type="text"
                placeholder="Enter Your Street Name"></input>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center gap-4  self-stretch mt-1.5 max-md:flex-wrap max-md:mt-5 max-md:max-w-full">
        <div className="flex  items-center self-stretch px-3 py-2 w-[450px] h-[65px]  mt-3.5 text-sm bg-white border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full    ">
          <img
            loading="lazy"
            src="/images/completeProfile/celender.png"
            className="my-auto aspect-[1.03] w-[25px]"
          />
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div className="pl-4 text-left text-black max-md:max-w-full">
              Birthdate <span className="text-error">*</span>
            </div>
            <div
              className="pl-4 mt-1 text-left lowercase text-zinc-500 max-md:max-w-full"
              onClick={() => dateRef.current.showPicker()}>
              <input
                type="date"
                ref={dateRef}
                value={localData.birthDate}
                className="w-full hide-calendar"
                onChange={(e) =>
                  setLocalData({
                    ...localData,
                    birthDate: e.target.value,
                  })
                }
              />
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{
                    width: "95%",
                    "& .MuiInputBase-root": {
                      padding: 0,
                      "& .MuiButtonBase-root": {
                        transform: "translateY(-27%)",
                      },
                      "& .MuiInputBase-input": {
                        height: "8px",
                        width: "100%",
                        color: "gray",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    },
                  }}
                  format="DD/MM/YYYY"
                  slotProps={{ textField: { size: "small" } }}
                  value={
                    localData.birthDate
                      ? dayjs(localData.birthDate, "DD/MM/YYYY")
                      : dayjs("DD/MM/YYYY")
                  }
                  onChange={(e) =>
                    setLocalData({
                      ...localData,
                      birthDate: e.format("DD/MM/YYYY"),
                    })
                  }
                />
              </LocalizationProvider> */}
            </div>
          </div>
        </div>
        <div className="flex  items-center self-stretch px-3 py-2 w-[450px] h-[65px]  mt-3.5 text-sm bg-white border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full ">
          <img
            loading="lazy"
            src="/images/completeProfile/location.svg"
            className="my-auto aspect-[1.03] w-[25px]"
          />
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div className="pl-4 text-left text-black max-md:max-w-full">
              Phone No. <span className="text-error">*</span>
            </div>
            <div className="pl-4 mt-1 text-left lowercase text-zinc-500 max-md:max-w-full">
              <input
                name="phoneNo"
                value={localData.phoneNo}
                onChange={(e) => {
                  let value = ensureNumber({
                    value: e.target.value,
                    maxLength: 10,
                  });
                  setLocalData({ ...localData, phoneNo: value });
                }}
                className="border-none outline-none"
                type="text"
                placeholder="Enter Your Phone No."></input>
            </div>
          </div>
        </div>
      </div>

      <Button
        loading={apiLoader}
        className="justify-center self-center px-5 py-5 cursor-pointer mt-16 w-[60%] text-sm text-center text-white bg-amber-300  rounded-[92.207px] max-md:mt-10 max-md:max-w-full"
        onClick={submitHandler}>
        Save
      </Button>
    </div>
  );
}

export default BarEdit;
