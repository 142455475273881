const ReferAPI = [
  { image: "/images/chat1.png", name: "James Doe", phone: "+1 8765436789" },
  { image: "/images/chat2.png", name: "James Doe", phone: "+1 8765436789" },
  { image: "/images/chat3.png", name: "James Doe", phone: "+1 8765436789" },
  { image: "/images/chat4.png", name: "James Doe", phone: "+1 8765436789" },
  { image: "/images/chat1.png", name: "James Doe", phone: "+1 8765436789" },
  { image: "/images/chat2.png", name: "James Doe", phone: "+1 8765436789" },
  { image: "/images/chat3.png", name: "James Doe", phone: "+1 8765436789" },
  { image: "/images/chat4.png", name: "James Doe", phone: "+1 8765436789" },

];
export default ReferAPI;
