import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "../../profile.module.css";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  fetchEstablishmentDetails,
  updateEstabImages,
} from "../../../../axios/axios";
import configuration from "../../../../config/configuration";
import { toast } from "react-toastify";
function Edit3() {
  const { id } = useParams();
  const user = useSelector((state) => state.user?.user);
  const navigate = useNavigate();
  const [selectedImages, setSelectedImages] = useState([]);
  const [dbimg, setDbImg] = useState();

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedImages((prevImages) => [...prevImages, ...files]);
  };

  const fetchEstabDetails = () => {
    fetchEstablishmentDetails({ token: user?.token, eid: id })
      .then((res) => {
        if (res.data) {
          const { pic } = res.data;
          setDbImg(pic);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchEstabDetails();
  }, []);

  const handleFileClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleRemoveImage = (index) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleDBRemoveImage = (index) => {
    setDbImg((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleSubmit = () => {
    const formData = new FormData();
    selectedImages.forEach((image) => {
      formData.append("Images", image);
    });
    formData.append("eid", id);
    formData.append("pic", dbimg);
    updateEstabImages({ token: user?.token, formData })
      .then((res) => {
        toast.success(res.message);
        navigate(`/establishment-details/${id}`);
      })
      .catch((err) => toast.error(err.message));
  };

  return (
    <div>
      <div
        style={{
          backgroundImage: `url('/images/headerBackground.svg')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "0 0 25px 25px",
        }}
        className="flex overflow-hidden relative px-20 text-2xl w-[100%] text-white min-h-[100px] rounded-b-3xl max-md:flex-wrap max-md:px-5">
        <div className="my-auto flex min-w-full">
          <div
            onClick={() => navigate(`/establishment-details/${id}/`)}
            className="border-2 px-4 rounded-[10px] py-1 mr-5 cursor-pointer">
            &#10094;
          </div>
          <span className={`${styles.header} `}>Edit Your Profile</span>
        </div>
      </div>
      <div className="flex relative justify-center items-center self-center px-16 py-10 mx-auto my-7 rounded-[20px] w-full bg-white text-xs max-w-[1099px] max-md:px-5 max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-col items-center mx-20 max-w-full w-[1025px]">
          <div
            className={`text-xl leading-9 text-center text-black} ${styles.header}`}>
            Establishment Photos
          </div>

          <div
            className="flex justify-center items-center self-stretch px-16 py-5 mt-10 text-center rounded-xl border border-dashed bg-red-500 bg-opacity-10 border-[color:var(--ff-3333,#F33)] leading-[153.3%] max-md:px-5 max-md:mt-10 max-md:max-w-full"
            onClick={handleFileClick}>
            <div className="flex flex-col max-w-full w-[258px]">
              <img
                loading="lazy"
                src="/images/completeProfile/redLoction.png"
                className="self-center aspect-square w-[31px]"
              />
              <div className="mt-2 text-xs text-black">
                <span className="text-red-500 underline"> Browse</span>
                <span> your file</span>
              </div>
              <div className="mt-2 text-xs text-neutral-400">
                Supports JPEG, PNG, PDF
              </div>
            </div>
          </div>
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            multiple
            accept="image/jpeg, image/png, application/pdf"
            onChange={handleFileChange}
          />
          <div className="flex gap-2.5 self-start mt-4 w-full">
            {selectedImages &&
              selectedImages.map((image, index) => (
                <div className="flex relative">
                  <img
                    key={index}
                    src={URL.createObjectURL(image)}
                    alt="selected"
                    className="aspect-[0.98] h-[90px] w-[90px] rounded-lg"
                  />
                  <CancelIcon
                    sx={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      transform: "translate(30%,-30%)",
                      cursor: "pointer",
                      color: "#FF3333",
                      background: "white",
                      fontSize: "24px",
                      borderRadius: "50%",
                    }}
                    onClick={() => {
                      handleDBRemoveImage(index);
                    }}
                  />
                </div>
              ))}
            {dbimg &&
              dbimg.map((image, index) => (
                <div className="flex relative">
                  <img
                    key={`DB_IMG_${index}`}
                    src={`${configuration.cdnFront}/${image}`}
                    alt="selected"
                    className="aspect-[0.98] h-[90px] w-[90px] rounded-lg"
                  />
                  <CancelIcon
                    sx={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      transform: "translate(30%,-30%)",
                      cursor: "pointer",
                      color: "#FF3333",
                      background: "white",
                      fontSize: "24px",
                      borderRadius: "50%",
                    }}
                    onClick={() => {
                      handleDBRemoveImage(index);
                    }}
                  />
                </div>
              ))}
          </div>
          <div
            onClick={() => handleSubmit()}
            className="justify-center px-5 py-5 cursor-pointer mt-40 w-[73%] text-sm text-center text-white bg-red-500 rounded-[92.207px] max-md:mt-10 max-md:max-w-full">
            Save 
          </div>
        </div>
      </div>
    </div>
  );
}
export default Edit3;
