import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./wrapperDash.module.css";
import { useEffect, useState } from "react";
import BartenderDashboard from "./Bartender/mains/mains";
import CelenderBrt from "../celender/Bartender/CelenderBrt";
import EarningsBart from "../Earning/Bartendar/EarningsBart";
import ReviewsBart from "../Reviews/Bartendar/ReviewsBart";
import Reference from "../referFriends/Bartender/Reference";
import ChatBrt from "../Chat/Bartendar/ChatBrt";
import CommunityBart from "../community/Bartendar/CommunityBart";
import MainProfile from "../Profile/Bartender/MainProfile";
import SettingMain from "../settings/Bartender/SettingMain";
import MenuItem from "../authWrapper/MenuItem";

function BartenderWrapperDash(props) {
  const [activeMenuTab, setActiveMenuTab] = useState(0);
  const [showChildren, setShowChildren] = useState(!!props.children);
  const location = useLocation();
  const activevalue = location?.state;

  const navigate = useNavigate();
  useEffect(() => {
    setShowChildren(!!props.children);
  }, [props.children]);

  useEffect(() => {
    const path = window.location.pathname;

    if (path === "/dashboard/calendar") {
      setActiveMenuTab(1);
    } else if (path === "/dashboard/earnings") {
      setActiveMenuTab(2);
    } else if (path === "/dashboard/community") {
      setActiveMenuTab(3);
    } else if (path === "/dashboard/reviews") {
      setActiveMenuTab(4);
    } else if (path === "/dashboard/refer") {
      setActiveMenuTab(5);
    } else if (path === "/dashboard/chat") {
      setActiveMenuTab(6);
    }
  }, [window.location.pathname]);

  const handleTabClick = (tabIndex) => {
    if (tabIndex == activeMenuTab) return;
    setActiveMenuTab(tabIndex);
    setShowChildren(false);
  };
  return (
    <div
      style={{
        minHeight: "600px",
        minWidth: "1000px",
        overflow: "hidden",
        height: "100vh",
        position: "relative",
      }}>
      <div className={styles.backImg}></div>

      <div className={styles.foreground}>
        <div
          className="z-20 flex flex-col justify-start mt-10 text-lg text-white my-scroll-hider"
          style={{ height: "100%", width: "auto", overflowY: "scroll" }}>
          <img
            style={{ margin: "20px auto 70px auto" }}
            loading="lazy"
            src="/images/completeProfile/barooom.png"
            className="self-center max-w-full aspect-[1.3] w-20 lg:w-36 "
          />

          <MenuItem
            onClick={() => {
              handleTabClick(0);
              navigate("/dashboard");
            }}
            isActive={activeMenuTab == 0}
            iconSrc={"/images/NavIcon/Dashboard.svg"}
            label={"Dashboard"}
          />
          <MenuItem
            onClick={() => {
              handleTabClick(1);
              navigate("/dashboard/calendar");
            }}
            isActive={activeMenuTab == 1}
            iconSrc={"/images/NavIcon/Calander.svg"}
            label={"Calendar"}
          />
          <MenuItem
            onClick={() => {
              handleTabClick(2);
              navigate("/dashboard/earnings");
            }}
            isActive={activeMenuTab == 2}
            iconSrc={"/images/NavIcon/Earningn.svg"}
            label={"Earnings & Payment"}
          />

          <MenuItem
            onClick={() => {
              handleTabClick(3);
              navigate("/dashboard/community");
            }}
            isActive={activeMenuTab == 3}
            iconSrc={"/images/NavIcon/Community.svg"}
            label={"Community"}
          />

          <MenuItem
            onClick={() => {
              handleTabClick(4);
              navigate("/dashboard/reviews");
            }}
            isActive={activeMenuTab == 4}
            iconSrc={"/images/NavIcon/Review.svg"}
            label={"Reviews"}
          />
          {/* <MenuItem
            onClick={() => {
              handleTabClick(5);
              navigate("/dashboard/refer");
            }}
            isActive={activeMenuTab == 5}
            iconSrc={"/images/completeProfile/referDash.png"}
            label={"Refer to Friend"}
          /> */}
          <MenuItem
            onClick={() => {
              handleTabClick(6);
              navigate("/dashboard/chat");
            }}
            isActive={activeMenuTab == 6}
            iconSrc={"/images/NavIcon/Nav_Chat.svg"}
            label={"Chats"}
          />
        </div>

        <div className={styles.box}>
          <div className={styles.dashboardcontainer}>
            {showChildren ? (
              props.children
            ) : (
              <>
                {activeMenuTab === 0 && (
                  <BartenderDashboard activevalue={activevalue} />
                )}
                {activeMenuTab === 1 && <CelenderBrt />}
                {activeMenuTab === 2 && <EarningsBart />}
                {activeMenuTab === 3 && <CommunityBart />}
                {activeMenuTab === 4 && <ReviewsBart />}
                {activeMenuTab === 5 && <Reference />}
                {activeMenuTab === 6 && <ChatBrt />}
              </>
            )}
          </div>
          <div className={styles.boxFooter}>
            <div>
              <p>copyright &copy; Barooom</p>
            </div>

            <div>
              <Link to={"/privacy-policy"}>
                <p>Privacy Policy</p>
              </Link>
              <Link to={"/terms-conditions"}>
                <p>Terms & Conditions</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BartenderWrapperDash;
