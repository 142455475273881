import { configureStore } from "@reduxjs/toolkit";
import globalReducer from "./globalSlice";
import userReducer from "./userSlice";
import tokenReducer from "./tokenslice";
import notificationReducer from "./notificationSlice";

export const store = configureStore({
  reducer: {
    global: globalReducer,
    user: userReducer,
    ntoken: tokenReducer,
    isnotify: notificationReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;
