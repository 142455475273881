import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import { GlobalContext } from "./context/globalContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import config from "./config/configuration";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <GlobalContext>
      <GoogleOAuthProvider clientId={config.googleClientId}>
        <BrowserRouter>
          <App />
          <ToastContainer />
        </BrowserRouter>
      </GoogleOAuthProvider>
    </GlobalContext>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
