import { Link } from "react-router-dom";
// import Wrappers from "../../../../complete_profile/Bartender/Wrappers";
import styles from "../../../eventDeatail.module.css";
import "./EditEstab.css";
import {
  CuisineList,
  DressCodeList,
  PosSystemsList,
} from "../../../../../utils/List";
import { toast } from "react-toastify";
import { useState } from "react";
import { countWords } from "../../../../../utils/countwords";
function EditEsta1({
  activejtab,
  setActiveJTab,
  handleEProfileChange,
  handleChange,
  newestablishmentdata,
}) {
  const [errormsg, setErrorMsg] = useState({});

  const checkInput = [
    "EprofileImg",
    "establishmentName",
    "discription",
    "serviceType",
  ];

  const handleRegistration1 = () => {
    let msg;
    const errors = checkInput.reduce((acc, field) => {
      if (!newestablishmentdata[field] || newestablishmentdata[field] === "") {
        acc[field] = `${field} is required`;
      }
      return acc;
    }, {});
    if (Object.keys(errors).length > 0) {
      toast.error("Mandatory Fields Empty");
      setErrorMsg(errors);
    } else {
      setErrorMsg({});
      setActiveJTab(activejtab + 1);
    }
  };

  return (
    <div className="flex relative justify-center items-center self-center px-16 py-10  w-[85%] text-xs max-w-[1599px] max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col max-w-full w-[1299px]">
        <div
          className={`self-center text-2xl leading-9 text-center text-black max-md:max-w-full} ${styles.header}`}>
          Tell Us More About Your Establishment
        </div>

        <div className="w-full flex justify-center items-center mt-8">
          <div
            className={`${
              errormsg?.EprofileImg && "border-2 border-error"
            } w-fit relative rounded-[50%]`}>
            <img
              loading="lazy"
              src="/images/completeProfile/cam.svg"
              className="self-center absolute aspect-[0.99] bottom-0 right-0 w-fit max-w-[25px] cursor-pointer"
            />
            <input
              type="file"
              id="EprofileImg"
              name="EprofileImg"
              accept="image/png, image/jpeg, image/jpg"
              onChange={handleEProfileChange}
              className="w-full h-full"
              style={{ position: "absolute", opacity: 0 }}
            />

            <img
              src={
                newestablishmentdata?.EprofileImg
                  ? URL.createObjectURL(newestablishmentdata.EprofileImg)
                  : "/images/EstablishmentNewAdd.svg"
              }
              alt={`EprofileImg`}
              className={`aspect-[0.98] h-[100px] w-[100px] rounded-[50%]`}
            />
          </div>
        </div>
        <div
          className={`flex gap-4 justify-between px-5 py-3.5 mt-2 bg-white border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full  ${
            errormsg?.establishmentName ? "border-error" : " border-gray-200"
          } `}>
          <img
            loading="lazy"
            src="/images/completeProfile/building.png"
            className="my-auto aspect-square w-[22px]"
          />
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div
              className={` text-left max-md:max-w-full ${
                errormsg?.establishmentName ? "text-error" : "text-black"
              }`}>
              Name of Establishment
            </div>
            <div className={`mt-2  text-left max-md:max-w-full text-zinc-500`}>
              <input
                className={`border-none outline-none w-full ${
                  errormsg?.establishmentName && "placeholder-error"
                }`}
                type="text"
                placeholder="Name of Establishment"
                name="establishmentName"
                value={newestablishmentdata.establishmentName}
                onChange={(e) => {
                  delete errormsg["establishmentName"];
                  handleChange(e);
                }}
              />
            </div>
          </div>
        </div>
        <div
          className={`flex gap-4 justify-between items-start px-5 pt-3.5 mt-6 bg-white rounded-3xl border-solid border-[0.922px]   ${
            errormsg?.discription ? "border-error" : " border-gray-200"
          }  max-md:flex-wrap max-md:max-w-full`}>
          <img
            loading="lazy"
            src="/images/completeProfile/pen.png"
            className="aspect-[1.04] w-[23px]"
          />
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div
              className={` text-left max-md:max-w-full ${
                errormsg?.discription ? "text-error" : "text-black"
              }`}>
              Write about your Establishment
            </div>
            <div className="mt-2 text-zinc-500 text-left max-md:max-w-full">
              <textarea
                className="border-none outline-none"
                rows="6"
                cols="110"
                name="discription"
                value={newestablishmentdata.discription}
                onChange={(e) => {
                  delete errormsg["discription"];
                  const newValue = e.target.value;
                  if (
                    countWords(newValue) <= 200 ||
                    newValue.length < newestablishmentdata.discription.length
                  ) {
                    handleChange(e);
                  }
                }}></textarea>
            </div>
          </div>
        </div>
        <div className="self-end mt-4 mr-3 text-base text-neutral-600 max-md:mr-2.5">
          {countWords(newestablishmentdata.discription) || 0}/200
        </div>
        <div className="flex  justify-between  mt-5 max-md:flex-wrap max-md:max-w-full">
          <div
            className={`flex gap-4 justify-between w-[420px] px-5 py-3.5 bg-white border-solid border-[0.922px] rounded-[92.207px] ${
              errormsg.serviceType ? "border-error" : "border-gray-200"
            } max-md:flex-wrap max-md:max-w-full`}>
            <img
              loading="lazy"
              src="/images/completeProfile/drink_glass.png"
              className="my-auto aspect-square w-[23px]"
            />
            <div className="flex flex-col  flex-1 max-md:max-w-full">
              <div
                className={`text-left max-md:max-w-full ${
                  errormsg.serviceType ? "text-error" : "text-black"
                }`}>
                Type of Cuisine
              </div>
              <div
                className={`mt-2 text-left max-md:max-w-full ${
                  errormsg.serviceType ? "text-error" : "text-zinc-500"
                }`}>
                <select
                  className="w-full"
                  name="serviceType"
                  id="serviceType"
                  value={newestablishmentdata.serviceType}
                  onClick={() => {
                    delete errormsg["serviceType"];
                  }}
                  onChange={(e) => handleChange(e)}>
                  <option value="" disabled>
                    Select a Cuisine
                  </option>

                  {CuisineList?.map((elem, ind) => (
                    <option key={`${elem.value}_${ind}`} value={elem.value}>
                      {elem.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="flex gap-4 justify-between w-[420px] px-5 py-3.5 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full">
            <img
              loading="lazy"
              src="/images/completeProfile/dressCode.png"
              className="my-auto aspect-square w-[22px]"
            />
            <div className="flex flex-col flex-1 max-md:max-w-full">
              <div className="text-black text-left max-md:max-w-full">
                Dress Code (Optional)
              </div>
              <div className="mt-2 text-zinc-500 text-left max-md:max-w-full">
                <select
                  className="w-full"
                  name="dressCode"
                  id="dressCode"
                  value={newestablishmentdata.dressCode}
                  onChange={(e) => handleChange(e)}>
                  <option value="" disabled>
                    Select a Dress Code
                  </option>

                  {DressCodeList?.map((elem, ind) => (
                    <option key={`${elem.value}_${ind}`} value={elem.value}>
                      {elem.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-4 self-start px-5 py-3.5 mt-6 w-[420px] bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full">
          <img
            loading="lazy"
            src="/images/completeProfile/tick-circle-solid.png"
            className="my-auto aspect-square w-[23px]"
          />
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div className="text-black text-left max-md:max-w-full">
              POS Software (Optional)
            </div>
            <div className="mt-2 text-left text-zinc-500 max-md:max-w-full">
              <select
                className="w-full"
                name="posSystem"
                id="posSystem"
                value={newestablishmentdata.posSystem}
                onChange={(e) => handleChange(e)}>
                <option value="" disabled>
                  Select a POS System
                </option>

                {PosSystemsList?.map((elem, ind) => (
                  <option key={`${elem.value}_${ind}`} value={elem.value}>
                    {elem.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <button
          className="justify-center self-center px-5 py-5 mt-16 w-[60%] text-sm text-center text-white bg-red-500 rounded-[92.207px] max-md:mt-10 max-md:max-w-full"
          onClick={() => handleRegistration1()}>
          Save & Next
        </button>
      </div>
    </div>
  );
}
export default EditEsta1;
