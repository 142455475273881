import React from "react";
import Wrappers from "../components/complete_profile/Wrappers";
import { Alert } from "antd";

export default function VerificationPending() {
  return (
    <Wrappers
      activeTab={0}
      setActiveTab={() => null}
      showHeader={false}
      totalSteps={6}>
      <div style={{ padding: "20px 20px 100px 20px" }}>
        <Alert
          showIcon={true}
          type="warning"
          message={`Verification under Process.`}
        />

        <Alert
          showIcon={true}
          type="info"
          message={`Verification may take between 5 minutes and 3 days. As soon as your account is verified, you will receive an email.`}
        />
      </div>
    </Wrappers>
  );
}
{
  /* message={`Verification may take upto 5 minutes to 3 days. You will receive an email once your Account is verified.`} /> */
}
