import React from "react";
import configuration from "../../../config/configuration";
import { Rating } from "@mui/material";
import style from "../Review.module.css";
import { RequestReviewNotify } from "../../../axios/axios";
import { useSelector } from "react-redux";
import { PiUserCircleThin } from "react-icons/pi";

function ReviewCards({ item }) {
  const user = useSelector((state) => state.user.user);
  return (
    <div className="p-2 border-2 rounded-2xl ">
      <div className="">
        <div className="flex justify-between">
          <div className="flex gap-2">
            <div className="ImageCont">
              {item?.EstablishmentDetails?.EprofileImg ||
              item?.eid?.pic?.[0] ? (
                <img
                  loading="lazy"
                  src={`${
                    user?.role == 1
                      ? `${configuration.cdnFront}/${item?.EstablishmentDetails?.EprofileImg}`
                      : `${configuration.cdnFront}/${item?.eid?.pic?.[0]}`
                  }`}
                  className="shrink-0 aspect-square h-[55px] w-[55px] rounded-[50%]"
                />
              ) : (
                <PiUserCircleThin style={{ width: "52px", height: "52px" }} />
              )}
            </div>
            <div className="">
              <div className={`text-[16px] text-black} ${style.header}`}>
                {user?.role == 1
                  ? item?.EstablishmentDetails?.establishmentName
                  : item?.eid?.establishmentName || "Establishment"}
              </div>
              <div className="flex gap-2">
                <span style={{ fontWeight: "400" }}>
                  {user?.role == 1
                    ? parseFloat(item?.Reviews?.overall).toFixed(1)
                    : parseFloat(item?.overall).toFixed(1)}
                </span>
                <Rating
                  name="read-only"
                  precision={0.5}
                  value={
                    user?.role == 1 ? item?.Reviews?.overall : item?.overall
                  }
                  readOnly
                />
              </div>
            </div>
          </div>
          <div>
            <img
              loading="lazy"
              src="/images/Comminity/quotes.png"
              className="w-6 h-6"
            />
          </div>
        </div>
      </div>

      {user?.role == 1 ? (
        item?.isReviewed ? (
          <div>
            <p className="text-[14px] italic font-medium text-neutral-400 ">
              {item?.Reviews?.detail}
            </p>
          </div>
        ) : (
          <div className="flex items-center justify-center h-full">
            <button className="w-full h-10 border-2 border-[#FFCC42] rounded-2xl">
              Request Review
            </button>
          </div>
        )
      ) : (
        <div>
          <p className="text-[14px] italic font-medium text-neutral-400 mt-1">
            {item?.detail}
          </p>
        </div>
      )}
    </div>
  );
}

export default ReviewCards;
