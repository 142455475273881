import React from "react";

function ReviewFilter({ filter, setFilter }) {
  return (
    <div className="z-10 flex flex-col gap-2 p-4 bg-white shadow-xl rounded-xl">
      <p className="text-establishment-red">Reviews</p>
      <div className="flex flex-col gap-3">
        <label className="flex items-center justify-center">
          <input
            type="checkbox"
            className="w-4 h-4 aspect-square"
            name="filter"
            checked={filter === true}
            onChange={() => {
              setFilter(filter === true ? "" : true);
            }}
          />
          <p className="w-full text-sm font-normal">Reviews Reviewed</p>
        </label>
        <label className="flex items-center justify-center">
          <input
            type="checkbox"
            className="w-4 h-4 aspect-square"
            name="filter"
            checked={filter === false}
            onChange={() => {
              setFilter(filter === false ? "" : false);
            }}
          />
          <p className="w-full text-sm font-normal">Reviews Not Reviewed</p>
        </label>
      </div>
    </div>
  );
}

export default ReviewFilter;
