import { useState } from "react";
import React from "react";
import WrapperDash from "../../dashboard/WrapperDash";
import style from "../setting.module.css";
import Dropdown from "../../dashboard/Bartender/Dropdown";
import Notification from "../../dashboard/Bartender/Notification";
import { Link, useNavigate } from "react-router-dom";
import DashHeader from "../../dashboard/DashHeader";
import WrapperDashEst from "../../authWrapper/WrapperDashEst";
import { useSelector } from "react-redux";

function SettingMain() {
  const user = useSelector((state) => state?.user?.user);
  // const [showModal, setShowModal] = useState(false);
  // const mainModal = <Dropdown></Dropdown>;
  // const [showModalnotify, setShowModalnotify] = useState(false);
  // const mainModalnotify = <Notification></Notification>;
  const navigate = useNavigate();
  return (
    <WrapperDash>
      <div>
        <DashHeader
          leftContent={
            <>
              <div className="grow pt-3 uppercase my-auto">
                <span className=" text-neutral-700">settings</span>{" "}
              </div>
            </>
          }
        />
      </div>
      {/* {showModal && mainModal}
      {showModalnotify && mainModalnotify} */}
      <div className="flex flex-col grow shrink-0 basis-0 max-md:max-w-full max-w-full">
        <div className="flex flex-col px-5   bg-zinc-50 max-md:px-5 max-md:max-w-full">
          {/* <div className="shrink-0 mt-5 h-px border border-solid bg-zinc-100 border-zinc-300 max-md:max-w-full" /> */}
          <div className="mt-8 max-md:pr-5 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-[250px] max-md:ml-0 max-md:w-full">
                <div
                  className="flex flex-col grow p-5 w-full text-[15px] text-black bg-white rounded-3xl shadow-2xl max-md:px-5 max-md:mt-9 cursor-pointer"
                  onClick={() => navigate("/faq")}>
                  {user?.role === 2 && (
                    <img
                      loading="lazy"
                      src="/images/freq-que-red.png"
                      className="self-center aspect-square w-[60px]"
                    />
                  )}
                  {user?.role === 1 && (
                    <img
                      loading="lazy"
                      src="/images/freq.png"
                      className="self-center aspect-square w-[60px]"
                    />
                  )}
                  <Link to={"/faq"} className="mt-3.5 self-center">
                    Frequently Asked Questions
                  </Link>
                </div>
              </div>
              <div className="flex flex-col ml-5 w-[250px] max-md:ml-0 max-md:w-full">
                <div
                  className="flex flex-col grow p-5 w-full text-[15px] text-black bg-white rounded-3xl shadow-2xl max-md:px-5 max-md:mt-9 cursor-pointer"
                  onClick={() => navigate("/change-password")}>
                  {user?.role === 2 && (
                    <img
                      loading="lazy"
                      src="/images/solar-pass-red.png"
                      className="self-center aspect-square w-[60px]"
                    />
                  )}
                  {user?.role === 1 && (
                    <img
                      loading="lazy"
                      src="/images/solarPass.png"
                      className="self-center aspect-square w-[60px]"
                    />
                  )}

                  <Link
                    to={"/change-password"}
                    className="mx-2.5 mt-3.5 self-center">
                    Change Password
                  </Link>
                </div>
              </div>
              <div className="flex flex-col ml-5 w-[250px] max-md:ml-0 max-md:w-full">
                <div
                  className="flex flex-col grow p-5 w-full text-[15px] text-black bg-white rounded-3xl shadow-2xl max-md:px-5 max-md:mt-9 cursor-pointer"
                  onClick={() => navigate("/notification-setting")}>
                  {user?.role === 2 && (
                    <img
                      loading="lazy"
                      src="/images/solar-bell-red.png"
                      className="self-center aspect-square w-[60px]"
                    />
                  )}
                  {user?.role === 1 && (
                    <img
                      loading="lazy"
                      src="/images/solarBell.png"
                      className="self-center aspect-square w-[60px]"
                    />
                  )}

                  <Link
                    to={"/notification-setting"}
                    className="mt-3 max-md:ml-0.5 self-center">
                    Notification Settings
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WrapperDash>
  );
}

export default SettingMain;
