import React from "react";
import styles from "../../../eventDeatail.module.css";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  createEstablishmentApi,
  createEstablishmentApi1,
} from "../../../../../axios/axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
function EditEsta4({
  activejtab,
  setActiveJTab,
  handleImageChange,
  handleRemoveImage,
  newestablishmentdata,
}) {
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const handleStep4Submit = () => {
    if (
      !newestablishmentdata?.images ||
      newestablishmentdata?.images.length < 1
    ) {
      toast.error("At least one image is required");
    }
    if (newestablishmentdata?.images.length > 10) {
      toast.error("More than 10 Images Cannot be Recorded.");
    } else if (
      newestablishmentdata?.images &&
      newestablishmentdata?.images.length <= 10 &&
      newestablishmentdata?.images.length > 0
    ) {
      createEstablishmentApi1({
        token: user?.token,
        data: newestablishmentdata,
      })
        .then((res) => navigate("/profile"))
        .catch((err) => toast.error(err.data.message));
    }
  };
  return (
    <div className="flex relative justify-center items-center self-center px-16 py-12 w-full  max-w-[1599px] max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col items-center mx-20 max-w-full w-[1025px]">
        <div
          className={`text-2xl leading-9 text-center text-black} ${styles.header}`}>
          Establishment Photos
        </div>

        <div className="flex justify-center items-center self-stretch px-16 py-5 mt-10 text-center rounded-xl border border-dashed bg-red-500 bg-opacity-10 border-[color:var(--ff-3333,#F33)] leading-[153.3%] max-md:px-5 max-md:mt-10 max-md:max-w-full">
          <div className="flex flex-col max-w-full w-[258px]">
            <img
              loading="lazy"
              src="/images/completeProfile/redLoction.png"
              className="self-center aspect-square w-[31px]"
            />
            <div className="mt-2 text-xs text-black">
              <span className="text-red-500 underline"> Browse</span>
              <span> your file</span>
            </div>
            <div className="mt-2 text-xs text-neutral-400">
              Supports JPEG, PNG, PDF
            </div>
          </div>
          <input
            type="file"
            id="images"
            name="images"
            accept="image/png, image/jpeg, image/jpg, application/pdf"
            multiple
            onChange={handleImageChange}
            style={{ position: "absolute", opacity: 0 }}
          />
        </div>
        <div className="flex gap-2.5 self-start mt-4 max-w-full w-[342px]">
          {newestablishmentdata.images.map((image, index) => (
            <div
              key={index}
              style={{
                width: "150px",
                height: "150px",
              }}
              className="flex justify-center items-center relative">
              {image.type.startsWith("image/") ? (
                <div className=" relative">
                  <img
                    src={URL.createObjectURL(image)}
                    alt={`Selected Image ${index + 1}`}
                    className="w-[150px] h-[150px] rounded-lg"
                  />
                  <CancelIcon
                    sx={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      transform: "translate(30%,-30%)",
                      cursor: "pointer",
                      color: "#FF3333",
                      background: "white",
                      fontSize: "24px",
                      borderRadius: "50%",
                    }}
                    onClick={() => {
                      handleRemoveImage(index);
                    }}
                  />
                </div>
              ) : (
                <span className="text-xs text-neutral-500">{image.name}</span>
              )}
            </div>
          ))}
        </div>
        <button
          onClick={() => handleStep4Submit()}
          className="justify-center px-5 py-5 mt-40 w-[73%] text-sm text-center text-white bg-red-500 rounded-[92.207px] max-md:mt-10 max-md:max-w-full">
          Save & Next
        </button>
      </div>
    </div>
  );
}
export default EditEsta4;
