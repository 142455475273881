import React from "react";
import styles from "../dashboard/wrapperDash.module.css";
import { useSelector } from "react-redux";

function MenuItem({ isActive, onClick, iconSrc, label }) {
  const user = useSelector((state) => state?.user?.user);

  return (
    <div
      className={`${styles.menuBtn} ${isActive} flex gap-5 justify-between px-5 whitespace-nowrap`}
      onClick={onClick}>
      {isActive && (
        <span
          className={`h-full w-2 top-0 left-0 absolute ${
            user?.role == 1 ? "bg-[#FFCC42]" : "bg-estab-back"
          }`}></span>
      )}
      <img loading="lazy" src={iconSrc} />
      <div className="flex-auto hidden lg:block">{label}</div>
    </div>
  );
}

export default MenuItem;
